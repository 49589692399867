define('smoke-and-mirrors/components/occludable-area/component', ['exports', 'ember', 'smoke-and-mirrors/components/occludable-area/template'], function (exports, _ember, _smokeAndMirrorsComponentsOccludableAreaTemplate) {
  'use strict';

  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;

  exports['default'] = Component.extend({
    layout: _smokeAndMirrorsComponentsOccludableAreaTemplate['default'],
    hasRendered: false,
    isRendered: false,
    occludeAt: 3,

    satellite: null,
    inViewport: inject.service('in-viewport'),

    didInsertElement: function didInsertElement() {
      this._super();
      this.get('inViewport').register(this);
    },

    willDestroyElement: function willDestroyElement() {
      this._super();
      this.get('inViewport').unregister(this);
    },

    willDestroy: function willDestroy() {
      this._super();
      this.get('inViewport').unregister(this);
    },

    registerSatellite: function registerSatellite(satellite) {
      var _this = this;

      this.satellite = satellite;
      satellite.didShift = function () {
        var zones = satellite.radar.getSatelliteZones(satellite);

        _this._shouldRender(zones);
      };
    },
    unregisterSatellite: function unregisterSatellite() {
      this.satellite.didShift = null;
      this.satellite = null;
    },

    shouldRender: true,
    _shouldRender: function _shouldRender(zones) {
      var zoneX = zones.x;
      var zoneY = zones.y;
      var occludeAt = this.get('occludeAt');
      var willRender = undefined;

      if (zoneX > -1 * occludeAt && zoneX < occludeAt) {
        willRender = zoneY > -1 * occludeAt && zoneY < occludeAt;
      }

      if (!willRender && !this.hasRendered && zoneX < 0 || zoneY < 0) {
        this.hasRendered = true;
        this.set('shouldRender', true);
        return;
      }

      if (!willRender) {
        if (this.isRendered) {
          this.element.style.height = this.satellite.geography.height + 'px';
          this.element.style.width = this.satellite.geography.width + 'px';
          this.isRendered = false;
        }
      } else if (!this.isRendered) {
        this.element.style.height = '';
        this.element.style.width = '';
        this.isRendered = true;
      }

      this.set('shouldRender', willRender);
    }

  });
});