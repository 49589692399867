enifed('@glimmer/runtime', ['exports', 'ember-babel', '@glimmer/util', '@glimmer/reference', '@glimmer/wire-format'], function (exports, _emberBabel, _util, _reference2, _wireFormat) {
    'use strict';

    exports.ConcreteBounds = exports.ElementStack = exports.insertHTMLBefore = exports.isWhitespace = exports.DOMTreeConstruction = exports.IDOMChanges = exports.DOMChanges = exports.isComponentDefinition = exports.ComponentDefinition = exports.PartialDefinition = exports.Environment = exports.Scope = exports.isSafeString = exports.RenderResult = exports.UpdatingVM = exports.Layout = exports.BaselineSyntax = exports.resetDebuggerCallback = exports.setDebuggerCallback = exports.compileArgs = exports.InlineMacros = exports.BlockMacros = exports.getDynamicVar = exports.EvaluatedPositionalArgs = exports.EvaluatedNamedArgs = exports.EvaluatedArgs = exports.CompiledPositionalArgs = exports.CompiledNamedArgs = exports.CompiledArgs = exports.CompiledExpression = exports.normalizeTextValue = exports.readDOMAttr = exports.defaultPropertyManagers = exports.defaultAttributeManagers = exports.defaultManagers = exports.INPUT_VALUE_PROPERTY_MANAGER = exports.PropertyManager = exports.AttributeManager = exports.IAttributeManager = exports.CompiledProgram = exports.CompiledBlock = exports.compileLayout = exports.OpcodeBuilderDSL = exports.ConditionalReference = exports.PrimitiveReference = exports.UNDEFINED_REFERENCE = exports.NULL_REFERENCE = exports.templateFactory = exports.Simple = undefined;

    var PrimitiveReference = function (_ConstReference) {
        (0, _emberBabel.inherits)(PrimitiveReference, _ConstReference);

        function PrimitiveReference(value) {
            return (0, _emberBabel.possibleConstructorReturn)(this, _ConstReference.call(this, value));
        }

        PrimitiveReference.create = function (value) {
            if (value === undefined) {
                return UNDEFINED_REFERENCE;
            } else if (value === null) {
                return NULL_REFERENCE;
            } else if (value === true) {
                return TRUE_REFERENCE;
            } else if (value === false) {
                return FALSE_REFERENCE;
            } else if (typeof value === 'number') {
                return new ValueReference(value);
            } else {
                return new StringReference(value);
            }
        };

        PrimitiveReference.prototype.get = function () {
            return UNDEFINED_REFERENCE;
        };

        return PrimitiveReference;
    }(_reference2.ConstReference);

    var StringReference = function (_PrimitiveReference) {
        (0, _emberBabel.inherits)(StringReference, _PrimitiveReference);

        function StringReference() {

            var _this2 = (0, _emberBabel.possibleConstructorReturn)(this, _PrimitiveReference.apply(this, arguments));

            _this2.lengthReference = null;
            return _this2;
        }

        StringReference.prototype.get = function (key) {
            var lengthReference;

            if (key === 'length') {
                lengthReference = this.lengthReference;


                if (lengthReference === null) {
                    lengthReference = this.lengthReference = new ValueReference(this.inner.length);
                }
                return lengthReference;
            } else {
                return _PrimitiveReference.prototype.get.call(this, key);
            }
        };

        return StringReference;
    }(PrimitiveReference);

    var ValueReference = function (_PrimitiveReference2) {
        (0, _emberBabel.inherits)(ValueReference, _PrimitiveReference2);

        function ValueReference(value) {
            return (0, _emberBabel.possibleConstructorReturn)(this, _PrimitiveReference2.call(this, value));
        }

        return ValueReference;
    }(PrimitiveReference);

    var UNDEFINED_REFERENCE = new ValueReference(undefined);
    var NULL_REFERENCE = new ValueReference(null);
    var TRUE_REFERENCE = new ValueReference(true);
    var FALSE_REFERENCE = new ValueReference(false);

    var ConditionalReference = function () {
        function ConditionalReference(inner) {

            this.inner = inner;
            this.tag = inner.tag;
        }

        ConditionalReference.prototype.value = function () {
            return this.toBool(this.inner.value());
        };

        ConditionalReference.prototype.toBool = function (value) {
            return !!value;
        };

        return ConditionalReference;
    }();

    var Constants = function () {
        function Constants() {

            // `0` means NULL
            this.references = [];
            this.strings = [];
            this.expressions = [];
            this.arrays = [];
            this.blocks = [];
            this.functions = [];
            this.others = [];
            this.NULL_REFERENCE = this.reference(NULL_REFERENCE);
            this.UNDEFINED_REFERENCE = this.reference(UNDEFINED_REFERENCE);
        }

        Constants.prototype.getReference = function (value) {
            return this.references[value - 1];
        };

        Constants.prototype.reference = function (value) {
            var index = this.references.length;
            this.references.push(value);
            return index + 1;
        };

        Constants.prototype.getString = function (value) {
            return this.strings[value - 1];
        };

        Constants.prototype.string = function (value) {
            var index = this.strings.length;
            this.strings.push(value);
            return index + 1;
        };

        Constants.prototype.getExpression = function (value) {
            return this.expressions[value - 1];
        };

        Constants.prototype.expression = function (value) {
            var index = this.expressions.length;
            this.expressions.push(value);
            return index + 1;
        };

        Constants.prototype.getArray = function (value) {
            return this.arrays[value - 1];
        };

        Constants.prototype.array = function (values) {
            var index = this.arrays.length;
            this.arrays.push(values);
            return index + 1;
        };

        Constants.prototype.getBlock = function (value) {
            return this.blocks[value - 1];
        };

        Constants.prototype.block = function (_block2) {
            var index = this.blocks.length;
            this.blocks.push(_block2);
            return index + 1;
        };

        Constants.prototype.getFunction = function (value) {
            return this.functions[value - 1];
        };

        Constants.prototype.function = function (f) {
            var index = this.functions.length;
            this.functions.push(f);
            return index + 1;
        };

        Constants.prototype.getOther = function (value) {
            return this.others[value - 1];
        };

        Constants.prototype.other = function (_other) {
            var index = this.others.length;
            this.others.push(_other);
            return index + 1;
        };

        return Constants;
    }();

    var AppendOpcodes = function () {
        function AppendOpcodes() {

            this.evaluateOpcode = (0, _util.fillNulls)(51 /* EvaluatePartial */ + 1);
        }

        AppendOpcodes.prototype.add = function (name, evaluate) {
            this.evaluateOpcode[name] = evaluate;
        };

        AppendOpcodes.prototype.evaluate = function (vm, opcode) {
            var func = this.evaluateOpcode[opcode.type];
            func(vm, opcode);
        };

        return AppendOpcodes;
    }();

    var APPEND_OPCODES = new AppendOpcodes();

    var AbstractOpcode = function () {
        function AbstractOpcode() {

            (0, _util.initializeGuid)(this);
        }

        AbstractOpcode.prototype.toJSON = function () {
            return { guid: this._guid, type: this.type };
        };

        return AbstractOpcode;
    }();

    var UpdatingOpcode = function (_AbstractOpcode) {
        (0, _emberBabel.inherits)(UpdatingOpcode, _AbstractOpcode);

        function UpdatingOpcode() {

            var _this4 = (0, _emberBabel.possibleConstructorReturn)(this, _AbstractOpcode.apply(this, arguments));

            _this4.next = null;
            _this4.prev = null;
            return _this4;
        }

        return UpdatingOpcode;
    }(AbstractOpcode);

    APPEND_OPCODES.add(20 /* OpenBlock */, function (vm, _ref) {
        var _getBlock = _ref.op1,
            _args = _ref.op2;

        var inner = vm.constants.getOther(_getBlock);
        var rawArgs = vm.constants.getExpression(_args);
        var args = null;
        var block = inner.evaluate(vm);
        if (block) {
            args = rawArgs.evaluate(vm);
        }
        // FIXME: can we avoid doing this when we don't have a block?
        vm.pushCallerScope();
        if (block) {
            vm.invokeBlock(block, args || null);
        }
    });
    APPEND_OPCODES.add(21 /* CloseBlock */, function (vm) {
        return vm.popScope();
    });

    APPEND_OPCODES.add(0 /* PushChildScope */, function (vm) {
        return vm.pushChildScope();
    });
    APPEND_OPCODES.add(1 /* PopScope */, function (vm) {
        return vm.popScope();
    });
    APPEND_OPCODES.add(2 /* PushDynamicScope */, function (vm) {
        return vm.pushDynamicScope();
    });
    APPEND_OPCODES.add(3 /* PopDynamicScope */, function (vm) {
        return vm.popDynamicScope();
    });
    APPEND_OPCODES.add(4 /* Put */, function (vm, _ref2) {
        var reference = _ref2.op1;

        vm.frame.setOperand(vm.constants.getReference(reference));
    });
    APPEND_OPCODES.add(5 /* EvaluatePut */, function (vm, _ref3) {
        var expression = _ref3.op1;

        var expr = vm.constants.getExpression(expression);
        vm.evaluateOperand(expr);
    });
    APPEND_OPCODES.add(6 /* PutArgs */, function (vm, _ref4) {
        var args = _ref4.op1;

        vm.evaluateArgs(vm.constants.getExpression(args));
    });
    APPEND_OPCODES.add(7 /* BindPositionalArgs */, function (vm, _ref5) {
        var _symbols = _ref5.op1;

        var symbols = vm.constants.getArray(_symbols);
        vm.bindPositionalArgs(symbols);
    });
    APPEND_OPCODES.add(8 /* BindNamedArgs */, function (vm, _ref6) {
        var _names = _ref6.op1,
            _symbols = _ref6.op2;

        var names = vm.constants.getArray(_names);
        var symbols = vm.constants.getArray(_symbols);
        vm.bindNamedArgs(names, symbols);
    });
    APPEND_OPCODES.add(9 /* BindBlocks */, function (vm, _ref7) {
        var _names = _ref7.op1,
            _symbols = _ref7.op2;

        var names = vm.constants.getArray(_names);
        var symbols = vm.constants.getArray(_symbols);
        vm.bindBlocks(names, symbols);
    });
    APPEND_OPCODES.add(10 /* BindPartialArgs */, function (vm, _ref8) {
        var symbol = _ref8.op1;

        vm.bindPartialArgs(symbol);
    });
    APPEND_OPCODES.add(11 /* BindCallerScope */, function (vm) {
        return vm.bindCallerScope();
    });
    APPEND_OPCODES.add(12 /* BindDynamicScope */, function (vm, _ref9) {
        var _names = _ref9.op1;

        var names = vm.constants.getArray(_names);
        vm.bindDynamicScope(names);
    });
    APPEND_OPCODES.add(13 /* Enter */, function (vm, _ref10) {
        var start = _ref10.op1,
            end = _ref10.op2;
        return vm.enter(start, end);
    });
    APPEND_OPCODES.add(14 /* Exit */, function (vm) {
        return vm.exit();
    });
    APPEND_OPCODES.add(15 /* Evaluate */, function (vm, _ref11) {
        var _block = _ref11.op1;

        var block = vm.constants.getBlock(_block);
        var args = vm.frame.getArgs();
        vm.invokeBlock(block, args);
    });
    APPEND_OPCODES.add(16 /* Jump */, function (vm, _ref12) {
        var target = _ref12.op1;
        return vm.goto(target);
    });
    APPEND_OPCODES.add(17 /* JumpIf */, function (vm, _ref13) {
        var target = _ref13.op1,
            cache;

        var reference = vm.frame.getCondition();
        if ((0, _reference2.isConst)(reference)) {
            if (reference.value()) {
                vm.goto(target);
            }
        } else {
            cache = new _reference2.ReferenceCache(reference);

            if (cache.peek()) {
                vm.goto(target);
            }
            vm.updateWith(new Assert(cache));
        }
    });
    APPEND_OPCODES.add(18 /* JumpUnless */, function (vm, _ref14) {
        var target = _ref14.op1,
            cache;

        var reference = vm.frame.getCondition();
        if ((0, _reference2.isConst)(reference)) {
            if (!reference.value()) {
                vm.goto(target);
            }
        } else {
            cache = new _reference2.ReferenceCache(reference);

            if (!cache.peek()) {
                vm.goto(target);
            }
            vm.updateWith(new Assert(cache));
        }
    });
    var ConstTest = function (ref) {
        return new _reference2.ConstReference(!!ref.value());
    };
    var SimpleTest = function (ref) {
        return ref;
    };
    var EnvironmentTest = function (ref, env) {
        return env.toConditionalReference(ref);
    };
    APPEND_OPCODES.add(19 /* Test */, function (vm, _ref15) {
        var _func = _ref15.op1;

        var operand = vm.frame.getOperand();
        var func = vm.constants.getFunction(_func);
        vm.frame.setCondition(func(operand, vm.env));
    });

    var Assert = function (_UpdatingOpcode) {
        (0, _emberBabel.inherits)(Assert, _UpdatingOpcode);

        function Assert(cache) {

            var _this5 = (0, _emberBabel.possibleConstructorReturn)(this, _UpdatingOpcode.call(this));

            _this5.type = "assert";
            _this5.tag = cache.tag;
            _this5.cache = cache;
            return _this5;
        }

        Assert.prototype.evaluate = function (vm) {
            var cache = this.cache;

            if ((0, _reference2.isModified)(cache.revalidate())) {
                vm.throw();
            }
        };

        Assert.prototype.toJSON = function () {
            var type = this.type,
                _guid = this._guid,
                cache = this.cache;

            var expected = void 0;
            try {
                expected = JSON.stringify(cache.peek());
            } catch (e) {
                expected = String(cache.peek());
            }
            return {
                guid: _guid,
                type: type,
                args: [],
                details: { expected: expected }
            };
        };

        return Assert;
    }(UpdatingOpcode);

    var JumpIfNotModifiedOpcode = function (_UpdatingOpcode2) {
        (0, _emberBabel.inherits)(JumpIfNotModifiedOpcode, _UpdatingOpcode2);

        function JumpIfNotModifiedOpcode(tag, target) {

            var _this6 = (0, _emberBabel.possibleConstructorReturn)(this, _UpdatingOpcode2.call(this));

            _this6.target = target;
            _this6.type = "jump-if-not-modified";
            _this6.tag = tag;
            _this6.lastRevision = tag.value();
            return _this6;
        }

        JumpIfNotModifiedOpcode.prototype.evaluate = function (vm) {
            var tag = this.tag,
                target = this.target,
                lastRevision = this.lastRevision;

            if (!vm.alwaysRevalidate && tag.validate(lastRevision)) {
                vm.goto(target);
            }
        };

        JumpIfNotModifiedOpcode.prototype.didModify = function () {
            this.lastRevision = this.tag.value();
        };

        JumpIfNotModifiedOpcode.prototype.toJSON = function () {
            return {
                guid: this._guid,
                type: this.type,
                args: [JSON.stringify(this.target.inspect())]
            };
        };

        return JumpIfNotModifiedOpcode;
    }(UpdatingOpcode);

    var DidModifyOpcode = function (_UpdatingOpcode3) {
        (0, _emberBabel.inherits)(DidModifyOpcode, _UpdatingOpcode3);

        function DidModifyOpcode(target) {

            var _this7 = (0, _emberBabel.possibleConstructorReturn)(this, _UpdatingOpcode3.call(this));

            _this7.target = target;
            _this7.type = "did-modify";
            _this7.tag = _reference2.CONSTANT_TAG;
            return _this7;
        }

        DidModifyOpcode.prototype.evaluate = function () {
            this.target.didModify();
        };

        return DidModifyOpcode;
    }(UpdatingOpcode);

    var LabelOpcode = function () {
        function LabelOpcode(label) {

            this.tag = _reference2.CONSTANT_TAG;
            this.type = "label";
            this.label = null;
            this.prev = null;
            this.next = null;
            (0, _util.initializeGuid)(this);
            if (label) this.label = label;
        }

        LabelOpcode.prototype.evaluate = function () {};

        LabelOpcode.prototype.inspect = function () {
            return this.label + ' [' + this._guid + ']';
        };

        LabelOpcode.prototype.toJSON = function () {
            return {
                guid: this._guid,
                type: this.type,
                args: [JSON.stringify(this.inspect())]
            };
        };

        return LabelOpcode;
    }();

    var EMPTY_ARRAY = _util.HAS_NATIVE_WEAKMAP ? Object.freeze([]) : [];
    var EMPTY_DICT = _util.HAS_NATIVE_WEAKMAP ? Object.freeze((0, _util.dict)()) : (0, _util.dict)();

    var CompiledPositionalArgs = function () {
        function CompiledPositionalArgs(values) {

            this.values = values;
            this.length = values.length;
        }

        CompiledPositionalArgs.create = function (values) {
            if (values.length) {
                return new this(values);
            } else {
                return COMPILED_EMPTY_POSITIONAL_ARGS;
            }
        };

        CompiledPositionalArgs.empty = function () {
            return COMPILED_EMPTY_POSITIONAL_ARGS;
        };

        CompiledPositionalArgs.prototype.evaluate = function (vm) {
            var values = this.values,
                length = this.length,
                i;

            var references = new Array(length);
            for (i = 0; i < length; i++) {
                references[i] = values[i].evaluate(vm);
            }
            return EvaluatedPositionalArgs.create(references);
        };

        CompiledPositionalArgs.prototype.toJSON = function () {
            return '[' + this.values.map(function (value) {
                return value.toJSON();
            }).join(", ") + ']';
        };

        return CompiledPositionalArgs;
    }();

    var COMPILED_EMPTY_POSITIONAL_ARGS = new (function (_CompiledPositionalAr) {
        (0, _emberBabel.inherits)(_class, _CompiledPositionalAr);

        function _class() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _CompiledPositionalAr.call(this, EMPTY_ARRAY));
        }

        _class.prototype.evaluate = function () {
            return EVALUATED_EMPTY_POSITIONAL_ARGS;
        };

        _class.prototype.toJSON = function () {
            return '<EMPTY>';
        };

        return _class;
    }(CompiledPositionalArgs))();

    var EvaluatedPositionalArgs = function () {
        function EvaluatedPositionalArgs(values) {

            this.values = values;
            this.tag = (0, _reference2.combineTagged)(values);
            this.length = values.length;
        }

        EvaluatedPositionalArgs.create = function (values) {
            return new this(values);
        };

        EvaluatedPositionalArgs.empty = function () {
            return EVALUATED_EMPTY_POSITIONAL_ARGS;
        };

        EvaluatedPositionalArgs.prototype.at = function (index) {
            var values = this.values,
                length = this.length;

            return index < length ? values[index] : UNDEFINED_REFERENCE;
        };

        EvaluatedPositionalArgs.prototype.value = function () {
            var values = this.values,
                length = this.length,
                i;

            var ret = new Array(length);
            for (i = 0; i < length; i++) {
                ret[i] = values[i].value();
            }
            return ret;
        };

        return EvaluatedPositionalArgs;
    }();

    var EVALUATED_EMPTY_POSITIONAL_ARGS = new (function (_EvaluatedPositionalA) {
        (0, _emberBabel.inherits)(_class2, _EvaluatedPositionalA);

        function _class2() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _EvaluatedPositionalA.call(this, EMPTY_ARRAY));
        }

        _class2.prototype.at = function () {
            return UNDEFINED_REFERENCE;
        };

        _class2.prototype.value = function () {
            return this.values;
        };

        return _class2;
    }(EvaluatedPositionalArgs))();

    var CompiledNamedArgs = function () {
        function CompiledNamedArgs(keys, values) {

            this.keys = keys;
            this.values = values;
            this.length = keys.length;
            (0, _util.assert)(keys.length === values.length, 'Keys and values do not have the same length');
        }

        CompiledNamedArgs.empty = function () {
            return COMPILED_EMPTY_NAMED_ARGS;
        };

        CompiledNamedArgs.create = function (map$$1) {
            var keys = Object.keys(map$$1),
                values,
                i;
            var length = keys.length;
            if (length > 0) {
                values = [];

                for (i = 0; i < length; i++) {
                    values[i] = map$$1[keys[i]];
                }
                return new this(keys, values);
            } else {
                return COMPILED_EMPTY_NAMED_ARGS;
            }
        };

        CompiledNamedArgs.prototype.evaluate = function (vm) {
            var keys = this.keys,
                values = this.values,
                length = this.length,
                i;

            var evaluated = new Array(length);
            for (i = 0; i < length; i++) {
                evaluated[i] = values[i].evaluate(vm);
            }
            return new EvaluatedNamedArgs(keys, evaluated);
        };

        CompiledNamedArgs.prototype.toJSON = function () {
            var keys = this.keys,
                values = this.values;

            var inner = keys.map(function (key, i) {
                return key + ': ' + values[i].toJSON();
            }).join(", ");
            return '{' + inner + '}';
        };

        return CompiledNamedArgs;
    }();

    var COMPILED_EMPTY_NAMED_ARGS = new (function (_CompiledNamedArgs) {
        (0, _emberBabel.inherits)(_class3, _CompiledNamedArgs);

        function _class3() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _CompiledNamedArgs.call(this, EMPTY_ARRAY, EMPTY_ARRAY));
        }

        _class3.prototype.evaluate = function () {
            return EVALUATED_EMPTY_NAMED_ARGS;
        };

        _class3.prototype.toJSON = function () {
            return '<EMPTY>';
        };

        return _class3;
    }(CompiledNamedArgs))();

    var EvaluatedNamedArgs = function () {
        function EvaluatedNamedArgs(keys, values) {
            var _map = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

            this.keys = keys;
            this.values = values;
            this._map = _map;
            this.tag = (0, _reference2.combineTagged)(values);
            this.length = keys.length;
            (0, _util.assert)(keys.length === values.length, 'Keys and values do not have the same length');
        }

        EvaluatedNamedArgs.create = function (map$$1) {
            var keys = Object.keys(map$$1),
                values,
                i;
            var length = keys.length;
            if (length > 0) {
                values = new Array(length);

                for (i = 0; i < length; i++) {
                    values[i] = map$$1[keys[i]];
                }
                return new this(keys, values, map$$1);
            } else {
                return EVALUATED_EMPTY_NAMED_ARGS;
            }
        };

        EvaluatedNamedArgs.empty = function () {
            return EVALUATED_EMPTY_NAMED_ARGS;
        };

        EvaluatedNamedArgs.prototype.get = function (key) {
            var keys = this.keys,
                values = this.values;

            var index = keys.indexOf(key);
            return index === -1 ? UNDEFINED_REFERENCE : values[index];
        };

        EvaluatedNamedArgs.prototype.has = function (key) {
            return this.keys.indexOf(key) !== -1;
        };

        EvaluatedNamedArgs.prototype.value = function () {
            var keys = this.keys,
                values = this.values,
                i,
                key,
                ref;

            var out = (0, _util.dict)();
            for (i = 0; i < keys.length; i++) {
                key = keys[i];
                ref = values[i];

                out[key] = ref.value();
            }
            return out;
        };

        (0, _emberBabel.createClass)(EvaluatedNamedArgs, [{
            key: 'map',
            get: function () {
                var map$$1 = this._map,
                    i;

                if (map$$1) {
                    return map$$1;
                }
                map$$1 = this._map = (0, _util.dict)();
                var keys = this.keys,
                    values = this.values,
                    length = this.length;

                for (i = 0; i < length; i++) {
                    map$$1[keys[i]] = values[i];
                }
                return map$$1;
            }
        }]);
        return EvaluatedNamedArgs;
    }();

    var EVALUATED_EMPTY_NAMED_ARGS = new (function (_EvaluatedNamedArgs) {
        (0, _emberBabel.inherits)(_class4, _EvaluatedNamedArgs);

        function _class4() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _EvaluatedNamedArgs.call(this, EMPTY_ARRAY, EMPTY_ARRAY, EMPTY_DICT));
        }

        _class4.prototype.get = function () {
            return UNDEFINED_REFERENCE;
        };

        _class4.prototype.has = function () {
            return false;
        };

        _class4.prototype.value = function () {
            return EMPTY_DICT;
        };

        return _class4;
    }(EvaluatedNamedArgs))();

    var EMPTY_BLOCKS = {
        default: null,
        inverse: null
    };

    var CompiledArgs = function () {
        function CompiledArgs(positional, named, blocks) {

            this.positional = positional;
            this.named = named;
            this.blocks = blocks;
            this.type = "compiled-args";
        }

        CompiledArgs.create = function (positional, named, blocks) {
            if (positional === COMPILED_EMPTY_POSITIONAL_ARGS && named === COMPILED_EMPTY_NAMED_ARGS && blocks === EMPTY_BLOCKS) {
                return this.empty();
            } else {
                return new this(positional, named, blocks);
            }
        };

        CompiledArgs.empty = function () {
            return COMPILED_EMPTY_ARGS;
        };

        CompiledArgs.prototype.evaluate = function (vm) {
            var positional = this.positional,
                named = this.named,
                blocks = this.blocks;

            return EvaluatedArgs.create(positional.evaluate(vm), named.evaluate(vm), blocks);
        };

        return CompiledArgs;
    }();

    var COMPILED_EMPTY_ARGS = new (function (_CompiledArgs) {
        (0, _emberBabel.inherits)(_class5, _CompiledArgs);

        function _class5() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _CompiledArgs.call(this, COMPILED_EMPTY_POSITIONAL_ARGS, COMPILED_EMPTY_NAMED_ARGS, EMPTY_BLOCKS));
        }

        _class5.prototype.evaluate = function () {
            return EMPTY_EVALUATED_ARGS;
        };

        return _class5;
    }(CompiledArgs))();

    var EvaluatedArgs = function () {
        function EvaluatedArgs(positional, named, blocks) {

            this.positional = positional;
            this.named = named;
            this.blocks = blocks;
            this.tag = (0, _reference2.combineTagged)([positional, named]);
        }

        EvaluatedArgs.empty = function () {
            return EMPTY_EVALUATED_ARGS;
        };

        EvaluatedArgs.create = function (positional, named, blocks) {
            return new this(positional, named, blocks);
        };

        EvaluatedArgs.positional = function (values) {
            var blocks = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : EMPTY_BLOCKS;

            return new this(EvaluatedPositionalArgs.create(values), EVALUATED_EMPTY_NAMED_ARGS, blocks);
        };

        EvaluatedArgs.named = function (map$$1) {
            var blocks = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : EMPTY_BLOCKS;

            return new this(EVALUATED_EMPTY_POSITIONAL_ARGS, EvaluatedNamedArgs.create(map$$1), blocks);
        };

        return EvaluatedArgs;
    }();

    var EMPTY_EVALUATED_ARGS = new EvaluatedArgs(EVALUATED_EMPTY_POSITIONAL_ARGS, EVALUATED_EMPTY_NAMED_ARGS, EMPTY_BLOCKS);

    APPEND_OPCODES.add(22 /* PutDynamicComponent */, function (vm) {
        var reference = vm.frame.getOperand();
        var cache = (0, _reference2.isConst)(reference) ? undefined : new _reference2.ReferenceCache(reference);
        var definition = cache ? cache.peek() : reference.value();
        vm.frame.setImmediate(definition);
        if (cache) {
            vm.updateWith(new Assert(cache));
        }
    });
    APPEND_OPCODES.add(23 /* PutComponent */, function (vm, _ref16) {
        var _component = _ref16.op1;

        var definition = vm.constants.getOther(_component);
        vm.frame.setImmediate(definition);
    });
    APPEND_OPCODES.add(24 /* OpenComponent */, function (vm, _ref17) {
        var _args = _ref17.op1,
            _shadow = _ref17.op2;

        var rawArgs = vm.constants.getExpression(_args);
        var shadow = vm.constants.getBlock(_shadow);
        var definition = vm.frame.getImmediate();
        var dynamicScope = vm.pushDynamicScope();
        var callerScope = vm.scope();
        var manager = definition.manager;
        var args = manager.prepareArgs(definition, rawArgs.evaluate(vm), dynamicScope);
        var hasDefaultBlock = !!args.blocks.default; // TODO Cleanup?
        var component = manager.create(vm.env, definition, args, dynamicScope, vm.getSelf(), hasDefaultBlock);
        var destructor = manager.getDestructor(component);
        if (destructor) vm.newDestroyable(destructor);
        var layout = manager.layoutFor(definition, component, vm.env);
        var selfRef = manager.getSelf(component);
        vm.beginCacheGroup();
        vm.stack().pushSimpleBlock();
        vm.pushRootScope(selfRef, layout.symbols);
        vm.invokeLayout(args, layout, callerScope, component, manager, shadow);
        vm.updateWith(new UpdateComponentOpcode(definition.name, component, manager, args, dynamicScope));
    });
    // export class DidCreateElementOpcode extends Opcode {
    //   public type = "did-create-element";
    //   evaluate(vm: VM) {
    //     let manager = vm.frame.getManager();
    //     let component = vm.frame.getComponent();
    //     let action = 'DidCreateElementOpcode#evaluate';
    //     manager.didCreateElement(component, vm.stack().expectConstructing(action), vm.stack().expectOperations(action));
    //   }
    //   toJSON(): OpcodeJSON {
    //     return {
    //       guid: this._guid,
    //       type: this.type,
    //       args: ["$ARGS"]
    //     };
    //   }
    // }
    APPEND_OPCODES.add(25 /* DidCreateElement */, function (vm) {
        var manager = vm.frame.getManager();
        var component = vm.frame.getComponent();
        var action = 'DidCreateElementOpcode#evaluate';
        manager.didCreateElement(component, vm.stack().expectConstructing(action), vm.stack().expectOperations(action));
    });
    // export class ShadowAttributesOpcode extends Opcode {
    //   public type = "shadow-attributes";
    //   evaluate(vm: VM) {
    //     let shadow = vm.frame.getShadow();
    //     vm.pushCallerScope();
    //     if (!shadow) return;
    //     vm.invokeBlock(shadow, EvaluatedArgs.empty());
    //   }
    //   toJSON(): OpcodeJSON {
    //     return {
    //       guid: this._guid,
    //       type: this.type,
    //       args: ["$ARGS"]
    //     };
    //   }
    // }
    // Slow path for non-specialized component invocations. Uses an internal
    // named lookup on the args.
    APPEND_OPCODES.add(26 /* ShadowAttributes */, function (vm) {
        var shadow = vm.frame.getShadow();
        vm.pushCallerScope();
        if (!shadow) return;
        vm.invokeBlock(shadow, EvaluatedArgs.empty());
    });
    // export class DidRenderLayoutOpcode extends Opcode {
    //   public type = "did-render-layout";
    //   evaluate(vm: VM) {
    //     let manager = vm.frame.getManager();
    //     let component = vm.frame.getComponent();
    //     let bounds = vm.stack().popBlock();
    //     manager.didRenderLayout(component, bounds);
    //     vm.env.didCreate(component, manager);
    //     vm.updateWith(new DidUpdateLayoutOpcode(manager, component, bounds));
    //   }
    // }
    APPEND_OPCODES.add(27 /* DidRenderLayout */, function (vm) {
        var manager = vm.frame.getManager();
        var component = vm.frame.getComponent();
        var bounds = vm.stack().popBlock();
        manager.didRenderLayout(component, bounds);
        vm.env.didCreate(component, manager);
        vm.updateWith(new DidUpdateLayoutOpcode(manager, component, bounds));
    });
    // export class CloseComponentOpcode extends Opcode {
    //   public type = "close-component";
    //   evaluate(vm: VM) {
    //     vm.popScope();
    //     vm.popDynamicScope();
    //     vm.commitCacheGroup();
    //   }
    // }
    APPEND_OPCODES.add(28 /* CloseComponent */, function (vm) {
        vm.popScope();
        vm.popDynamicScope();
        vm.commitCacheGroup();
    });

    var UpdateComponentOpcode = function (_UpdatingOpcode4) {
        (0, _emberBabel.inherits)(UpdateComponentOpcode, _UpdatingOpcode4);

        function UpdateComponentOpcode(name, component, manager, args, dynamicScope) {

            var _this13 = (0, _emberBabel.possibleConstructorReturn)(this, _UpdatingOpcode4.call(this));

            _this13.name = name;
            _this13.component = component;
            _this13.manager = manager;
            _this13.args = args;
            _this13.dynamicScope = dynamicScope;
            _this13.type = "update-component";
            var componentTag = manager.getTag(component);
            if (componentTag) {
                _this13.tag = (0, _reference2.combine)([args.tag, componentTag]);
            } else {
                _this13.tag = args.tag;
            }
            return _this13;
        }

        UpdateComponentOpcode.prototype.evaluate = function () {
            var component = this.component,
                manager = this.manager,
                args = this.args,
                dynamicScope = this.dynamicScope;

            manager.update(component, args, dynamicScope);
        };

        UpdateComponentOpcode.prototype.toJSON = function () {
            return {
                guid: this._guid,
                type: this.type,
                args: [JSON.stringify(this.name)]
            };
        };

        return UpdateComponentOpcode;
    }(UpdatingOpcode);

    var DidUpdateLayoutOpcode = function (_UpdatingOpcode5) {
        (0, _emberBabel.inherits)(DidUpdateLayoutOpcode, _UpdatingOpcode5);

        function DidUpdateLayoutOpcode(manager, component, bounds) {

            var _this14 = (0, _emberBabel.possibleConstructorReturn)(this, _UpdatingOpcode5.call(this));

            _this14.manager = manager;
            _this14.component = component;
            _this14.bounds = bounds;
            _this14.type = "did-update-layout";
            _this14.tag = _reference2.CONSTANT_TAG;
            return _this14;
        }

        DidUpdateLayoutOpcode.prototype.evaluate = function (vm) {
            var manager = this.manager,
                component = this.component,
                bounds = this.bounds;

            manager.didUpdateLayout(component, bounds);
            vm.env.didUpdate(component, manager);
        };

        return DidUpdateLayoutOpcode;
    }(UpdatingOpcode);

    var Cursor = function (element, nextSibling) {

        this.element = element;
        this.nextSibling = nextSibling;
    };

    var ConcreteBounds = function () {
        function ConcreteBounds(parentNode, first, last) {

            this.parentNode = parentNode;
            this.first = first;
            this.last = last;
        }

        ConcreteBounds.prototype.parentElement = function () {
            return this.parentNode;
        };

        ConcreteBounds.prototype.firstNode = function () {
            return this.first;
        };

        ConcreteBounds.prototype.lastNode = function () {
            return this.last;
        };

        return ConcreteBounds;
    }();

    var SingleNodeBounds = function () {
        function SingleNodeBounds(parentNode, node) {

            this.parentNode = parentNode;
            this.node = node;
        }

        SingleNodeBounds.prototype.parentElement = function () {
            return this.parentNode;
        };

        SingleNodeBounds.prototype.firstNode = function () {
            return this.node;
        };

        SingleNodeBounds.prototype.lastNode = function () {
            return this.node;
        };

        return SingleNodeBounds;
    }();

    function single(parent, node) {
        return new SingleNodeBounds(parent, node);
    }
    function _move(bounds, reference) {
        var parent = bounds.parentElement(),
            next;
        var first = bounds.firstNode();
        var last = bounds.lastNode();
        var node = first;
        while (node) {
            next = node.nextSibling;

            parent.insertBefore(node, reference);
            if (node === last) return next;
            node = next;
        }
        return null;
    }
    function clear(bounds) {
        var parent = bounds.parentElement(),
            next;
        var first = bounds.firstNode();
        var last = bounds.lastNode();
        var node = first;
        while (node) {
            next = node.nextSibling;

            parent.removeChild(node);
            if (node === last) return next;
            node = next;
        }
        return null;
    }

    function isSafeString(value) {
        return !!value && typeof value['toHTML'] === 'function';
    }
    function isNode(value) {
        return value !== null && typeof value === 'object' && typeof value['nodeType'] === 'number';
    }
    function isString(value) {
        return typeof value === 'string';
    }

    var Upsert = function (bounds$$1) {

        this.bounds = bounds$$1;
    };

    function cautiousInsert(dom, cursor, value) {
        if (isString(value)) {
            return TextUpsert.insert(dom, cursor, value);
        }
        if (isSafeString(value)) {
            return SafeStringUpsert.insert(dom, cursor, value);
        }
        if (isNode(value)) {
            return NodeUpsert.insert(dom, cursor, value);
        }
        throw (0, _util.unreachable)();
    }
    function trustingInsert(dom, cursor, value) {
        if (isString(value)) {
            return HTMLUpsert.insert(dom, cursor, value);
        }
        if (isNode(value)) {
            return NodeUpsert.insert(dom, cursor, value);
        }
        throw (0, _util.unreachable)();
    }

    var TextUpsert = function (_Upsert) {
        (0, _emberBabel.inherits)(TextUpsert, _Upsert);

        TextUpsert.insert = function (dom, cursor, value) {
            var textNode = dom.createTextNode(value);
            dom.insertBefore(cursor.element, textNode, cursor.nextSibling);
            var bounds$$1 = new SingleNodeBounds(cursor.element, textNode);
            return new TextUpsert(bounds$$1, textNode);
        };

        function TextUpsert(bounds$$1, textNode) {

            var _this15 = (0, _emberBabel.possibleConstructorReturn)(this, _Upsert.call(this, bounds$$1));

            _this15.textNode = textNode;
            return _this15;
        }

        TextUpsert.prototype.update = function (_dom, value) {
            var textNode;

            if (isString(value)) {
                textNode = this.textNode;


                textNode.nodeValue = value;
                return true;
            } else {
                return false;
            }
        };

        return TextUpsert;
    }(Upsert);

    var HTMLUpsert = function (_Upsert2) {
        (0, _emberBabel.inherits)(HTMLUpsert, _Upsert2);

        function HTMLUpsert() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _Upsert2.apply(this, arguments));
        }

        HTMLUpsert.insert = function (dom, cursor, value) {
            var bounds$$1 = dom.insertHTMLBefore(cursor.element, value, cursor.nextSibling);
            return new HTMLUpsert(bounds$$1);
        };

        HTMLUpsert.prototype.update = function (dom, value) {
            var bounds$$1, parentElement, nextSibling;

            if (isString(value)) {
                bounds$$1 = this.bounds;
                parentElement = bounds$$1.parentElement();
                nextSibling = clear(bounds$$1);

                this.bounds = dom.insertHTMLBefore(parentElement, nextSibling, value);
                return true;
            } else {
                return false;
            }
        };

        return HTMLUpsert;
    }(Upsert);

    var SafeStringUpsert = function (_Upsert3) {
        (0, _emberBabel.inherits)(SafeStringUpsert, _Upsert3);

        function SafeStringUpsert(bounds$$1, lastStringValue) {

            var _this17 = (0, _emberBabel.possibleConstructorReturn)(this, _Upsert3.call(this, bounds$$1));

            _this17.lastStringValue = lastStringValue;
            return _this17;
        }

        SafeStringUpsert.insert = function (dom, cursor, value) {
            var stringValue = value.toHTML();
            var bounds$$1 = dom.insertHTMLBefore(cursor.element, stringValue, cursor.nextSibling);
            return new SafeStringUpsert(bounds$$1, stringValue);
        };

        SafeStringUpsert.prototype.update = function (dom, value) {
            var stringValue, bounds$$1, parentElement, nextSibling;

            if (isSafeString(value)) {
                stringValue = value.toHTML();

                if (stringValue !== this.lastStringValue) {
                    bounds$$1 = this.bounds;
                    parentElement = bounds$$1.parentElement();
                    nextSibling = clear(bounds$$1);

                    this.bounds = dom.insertHTMLBefore(parentElement, nextSibling, stringValue);
                    this.lastStringValue = stringValue;
                }
                return true;
            } else {
                return false;
            }
        };

        return SafeStringUpsert;
    }(Upsert);

    var NodeUpsert = function (_Upsert4) {
        (0, _emberBabel.inherits)(NodeUpsert, _Upsert4);

        function NodeUpsert() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _Upsert4.apply(this, arguments));
        }

        NodeUpsert.insert = function (dom, cursor, node) {
            dom.insertBefore(cursor.element, node, cursor.nextSibling);
            return new NodeUpsert(single(cursor.element, node));
        };

        NodeUpsert.prototype.update = function (dom, value) {
            var bounds$$1, parentElement, nextSibling;

            if (isNode(value)) {
                bounds$$1 = this.bounds;
                parentElement = bounds$$1.parentElement();
                nextSibling = clear(bounds$$1);

                this.bounds = dom.insertNodeBefore(parentElement, value, nextSibling);
                return true;
            } else {
                return false;
            }
        };

        return NodeUpsert;
    }(Upsert);

    var COMPONENT_DEFINITION_BRAND = 'COMPONENT DEFINITION [id=e59c754e-61eb-4392-8c4a-2c0ac72bfcd4]';
    function isComponentDefinition(obj) {
        return typeof obj === 'object' && obj && obj[COMPONENT_DEFINITION_BRAND];
    }

    var CompiledExpression = function () {
        function CompiledExpression() {}

        CompiledExpression.prototype.toJSON = function () {
            return 'UNIMPL: ' + this.type.toUpperCase();
        };

        return CompiledExpression;
    }();

    APPEND_OPCODES.add(29 /* Text */, function (vm, _ref18) {
        var text = _ref18.op1;

        vm.stack().appendText(vm.constants.getString(text));
    });
    APPEND_OPCODES.add(30 /* Comment */, function (vm, _ref19) {
        var text = _ref19.op1;

        vm.stack().appendComment(vm.constants.getString(text));
    });
    APPEND_OPCODES.add(32 /* OpenElement */, function (vm, _ref20) {
        var tag = _ref20.op1;

        vm.stack().openElement(vm.constants.getString(tag));
    });
    APPEND_OPCODES.add(33 /* PushRemoteElement */, function (vm) {
        var reference = vm.frame.getOperand();
        var cache = (0, _reference2.isConst)(reference) ? undefined : new _reference2.ReferenceCache(reference);
        var element = cache ? cache.peek() : reference.value();
        vm.stack().pushRemoteElement(element);
        if (cache) {
            vm.updateWith(new Assert(cache));
        }
    });
    APPEND_OPCODES.add(34 /* PopRemoteElement */, function (vm) {
        return vm.stack().popRemoteElement();
    });
    APPEND_OPCODES.add(35 /* OpenComponentElement */, function (vm, _ref21) {
        var _tag = _ref21.op1;

        var tag = vm.constants.getString(_tag);
        vm.stack().openElement(tag, new ComponentElementOperations(vm.env));
    });
    APPEND_OPCODES.add(36 /* OpenDynamicElement */, function (vm) {
        var tagName = vm.frame.getOperand().value();
        vm.stack().openElement(tagName);
    });

    var ClassList = function () {
        function ClassList() {

            this.list = null;
            this.isConst = true;
        }

        ClassList.prototype.append = function (reference) {
            var list = this.list,
                isConst$$1 = this.isConst;

            if (list === null) list = this.list = [];
            list.push(reference);
            this.isConst = isConst$$1 && (0, _reference2.isConst)(reference);
        };

        ClassList.prototype.toReference = function () {
            var list = this.list,
                isConst$$1 = this.isConst;

            if (!list) return NULL_REFERENCE;
            if (isConst$$1) return PrimitiveReference.create(toClassName(list));
            return new ClassListReference(list);
        };

        return ClassList;
    }();

    var ClassListReference = function (_CachedReference) {
        (0, _emberBabel.inherits)(ClassListReference, _CachedReference);

        function ClassListReference(list) {

            var _this19 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference.call(this));

            _this19.list = [];
            _this19.tag = (0, _reference2.combineTagged)(list);
            _this19.list = list;
            return _this19;
        }

        ClassListReference.prototype.compute = function () {
            return toClassName(this.list);
        };

        return ClassListReference;
    }(_reference2.CachedReference);

    function toClassName(list) {
        var ret = [],
            i,
            value;
        for (i = 0; i < list.length; i++) {
            value = list[i].value();

            if (value !== false && value !== null && value !== undefined) ret.push(value);
        }
        return ret.length === 0 ? null : ret.join(' ');
    }

    var SimpleElementOperations = function () {
        function SimpleElementOperations(env) {

            this.env = env;
            this.opcodes = null;
            this.classList = null;
        }

        SimpleElementOperations.prototype.addStaticAttribute = function (element, name, value) {
            if (name === 'class') {
                this.addClass(PrimitiveReference.create(value));
            } else {
                this.env.getAppendOperations().setAttribute(element, name, value);
            }
        };

        SimpleElementOperations.prototype.addStaticAttributeNS = function (element, namespace, name, value) {
            this.env.getAppendOperations().setAttribute(element, name, value, namespace);
        };

        SimpleElementOperations.prototype.addDynamicAttribute = function (element, name, reference, isTrusting) {
            var attributeManager, attribute;

            if (name === 'class') {
                this.addClass(reference);
            } else {
                attributeManager = this.env.attributeFor(element, name, isTrusting);
                attribute = new DynamicAttribute(element, attributeManager, name, reference);

                this.addAttribute(attribute);
            }
        };

        SimpleElementOperations.prototype.addDynamicAttributeNS = function (element, namespace, name, reference, isTrusting) {
            var attributeManager = this.env.attributeFor(element, name, isTrusting, namespace);
            var nsAttribute = new DynamicAttribute(element, attributeManager, name, reference, namespace);
            this.addAttribute(nsAttribute);
        };

        SimpleElementOperations.prototype.flush = function (element, vm) {
            var env = vm.env,
                i,
                attributeManager,
                attribute,
                opcode;
            var opcodes = this.opcodes,
                classList = this.classList;

            for (i = 0; opcodes && i < opcodes.length; i++) {
                vm.updateWith(opcodes[i]);
            }
            if (classList) {
                attributeManager = env.attributeFor(element, 'class', false);
                attribute = new DynamicAttribute(element, attributeManager, 'class', classList.toReference());
                opcode = attribute.flush(env);

                if (opcode) {
                    vm.updateWith(opcode);
                }
            }
            this.opcodes = null;
            this.classList = null;
        };

        SimpleElementOperations.prototype.addClass = function (reference) {
            var classList = this.classList;

            if (!classList) {
                classList = this.classList = new ClassList();
            }
            classList.append(reference);
        };

        SimpleElementOperations.prototype.addAttribute = function (attribute) {
            var opcode = attribute.flush(this.env),
                opcodes;
            if (opcode) {
                opcodes = this.opcodes;


                if (!opcodes) {
                    opcodes = this.opcodes = [];
                }
                opcodes.push(opcode);
            }
        };

        return SimpleElementOperations;
    }();

    var ComponentElementOperations = function () {
        function ComponentElementOperations(env) {

            this.env = env;
            this.attributeNames = null;
            this.attributes = null;
            this.classList = null;
        }

        ComponentElementOperations.prototype.addStaticAttribute = function (element, name, value) {
            if (name === 'class') {
                this.addClass(PrimitiveReference.create(value));
            } else if (this.shouldAddAttribute(name)) {
                this.addAttribute(name, new StaticAttribute(element, name, value));
            }
        };

        ComponentElementOperations.prototype.addStaticAttributeNS = function (element, namespace, name, value) {
            if (this.shouldAddAttribute(name)) {
                this.addAttribute(name, new StaticAttribute(element, name, value, namespace));
            }
        };

        ComponentElementOperations.prototype.addDynamicAttribute = function (element, name, reference, isTrusting) {
            var attributeManager, attribute;

            if (name === 'class') {
                this.addClass(reference);
            } else if (this.shouldAddAttribute(name)) {
                attributeManager = this.env.attributeFor(element, name, isTrusting);
                attribute = new DynamicAttribute(element, attributeManager, name, reference);

                this.addAttribute(name, attribute);
            }
        };

        ComponentElementOperations.prototype.addDynamicAttributeNS = function (element, namespace, name, reference, isTrusting) {
            var attributeManager, nsAttribute;

            if (this.shouldAddAttribute(name)) {
                attributeManager = this.env.attributeFor(element, name, isTrusting, namespace);
                nsAttribute = new DynamicAttribute(element, attributeManager, name, reference, namespace);

                this.addAttribute(name, nsAttribute);
            }
        };

        ComponentElementOperations.prototype.flush = function (element, vm) {
            var env = this.env,
                i,
                opcode,
                attributeManager,
                attribute,
                _opcode;
            var attributes = this.attributes,
                classList = this.classList;

            for (i = 0; attributes && i < attributes.length; i++) {
                opcode = attributes[i].flush(env);

                if (opcode) {
                    vm.updateWith(opcode);
                }
            }
            if (classList) {
                attributeManager = env.attributeFor(element, 'class', false);
                attribute = new DynamicAttribute(element, attributeManager, 'class', classList.toReference());
                _opcode = attribute.flush(env);

                if (_opcode) {
                    vm.updateWith(_opcode);
                }
            }
        };

        ComponentElementOperations.prototype.shouldAddAttribute = function (name) {
            return !this.attributeNames || this.attributeNames.indexOf(name) === -1;
        };

        ComponentElementOperations.prototype.addClass = function (reference) {
            var classList = this.classList;

            if (!classList) {
                classList = this.classList = new ClassList();
            }
            classList.append(reference);
        };

        ComponentElementOperations.prototype.addAttribute = function (name, attribute) {
            var attributeNames = this.attributeNames,
                attributes = this.attributes;

            if (!attributeNames) {
                attributeNames = this.attributeNames = [];
                attributes = this.attributes = [];
            }
            attributeNames.push(name);
            (0, _util.unwrap)(attributes).push(attribute);
        };

        return ComponentElementOperations;
    }();

    APPEND_OPCODES.add(37 /* FlushElement */, function (vm) {
        var stack = vm.stack();
        var action = 'FlushElementOpcode#evaluate';
        stack.expectOperations(action).flush(stack.expectConstructing(action), vm);
        stack.flushElement();
    });
    APPEND_OPCODES.add(38 /* CloseElement */, function (vm) {
        return vm.stack().closeElement();
    });
    APPEND_OPCODES.add(39 /* PopElement */, function (vm) {
        return vm.stack().popElement();
    });
    APPEND_OPCODES.add(40 /* StaticAttr */, function (vm, _ref22) {
        var _name = _ref22.op1,
            _value = _ref22.op2,
            _namespace = _ref22.op3,
            namespace;

        var name = vm.constants.getString(_name);
        var value = vm.constants.getString(_value);
        if (_namespace) {
            namespace = vm.constants.getString(_namespace);

            vm.stack().setStaticAttributeNS(namespace, name, value);
        } else {
            vm.stack().setStaticAttribute(name, value);
        }
    });
    APPEND_OPCODES.add(41 /* Modifier */, function (vm, _ref23) {
        var _name = _ref23.op1,
            _manager = _ref23.op2,
            _args = _ref23.op3;

        var manager = vm.constants.getOther(_manager);
        var rawArgs = vm.constants.getExpression(_args);
        var stack = vm.stack();
        var element = stack.constructing,
            updateOperations = stack.updateOperations;

        var args = rawArgs.evaluate(vm);
        var dynamicScope = vm.dynamicScope();
        var modifier = manager.create(element, args, dynamicScope, updateOperations);
        vm.env.scheduleInstallModifier(modifier, manager);
        var destructor = manager.getDestructor(modifier);
        if (destructor) {
            vm.newDestroyable(destructor);
        }
        vm.updateWith(new UpdateModifierOpcode(manager, modifier, args));
    });

    var UpdateModifierOpcode = function (_UpdatingOpcode6) {
        (0, _emberBabel.inherits)(UpdateModifierOpcode, _UpdatingOpcode6);

        function UpdateModifierOpcode(manager, modifier, args) {

            var _this20 = (0, _emberBabel.possibleConstructorReturn)(this, _UpdatingOpcode6.call(this));

            _this20.manager = manager;
            _this20.modifier = modifier;
            _this20.args = args;
            _this20.type = "update-modifier";
            _this20.tag = args.tag;
            _this20.lastUpdated = args.tag.value();
            return _this20;
        }

        UpdateModifierOpcode.prototype.evaluate = function (vm) {
            var manager = this.manager,
                modifier = this.modifier,
                tag = this.tag,
                lastUpdated = this.lastUpdated;

            if (!tag.validate(lastUpdated)) {
                vm.env.scheduleUpdateModifier(modifier, manager);
                this.lastUpdated = tag.value();
            }
        };

        UpdateModifierOpcode.prototype.toJSON = function () {
            return {
                guid: this._guid,
                type: this.type,
                args: [JSON.stringify(this.args)]
            };
        };

        return UpdateModifierOpcode;
    }(UpdatingOpcode);

    var StaticAttribute = function () {
        function StaticAttribute(element, name, value, namespace) {

            this.element = element;
            this.name = name;
            this.value = value;
            this.namespace = namespace;
        }

        StaticAttribute.prototype.flush = function (env) {
            env.getAppendOperations().setAttribute(this.element, this.name, this.value, this.namespace);
            return null;
        };

        return StaticAttribute;
    }();

    var DynamicAttribute = function () {
        function DynamicAttribute(element, attributeManager, name, reference, namespace) {

            this.element = element;
            this.attributeManager = attributeManager;
            this.name = name;
            this.reference = reference;
            this.namespace = namespace;
            this.cache = null;
            this.tag = reference.tag;
        }

        DynamicAttribute.prototype.patch = function (env) {
            var element = this.element,
                cache = this.cache;

            var value = (0, _util.expect)(cache, 'must patch after flush').revalidate();
            if ((0, _reference2.isModified)(value)) {
                this.attributeManager.updateAttribute(env, element, value, this.namespace);
            }
        };

        DynamicAttribute.prototype.flush = function (env) {
            var reference = this.reference,
                element = this.element,
                value,
                cache,
                _value2;

            if ((0, _reference2.isConst)(reference)) {
                value = reference.value();

                this.attributeManager.setAttribute(env, element, value, this.namespace);
                return null;
            } else {
                cache = this.cache = new _reference2.ReferenceCache(reference);
                _value2 = cache.peek();

                this.attributeManager.setAttribute(env, element, _value2, this.namespace);
                return new PatchElementOpcode(this);
            }
        };

        DynamicAttribute.prototype.toJSON = function () {
            var element = this.element,
                namespace = this.namespace,
                name = this.name,
                cache = this.cache;

            var formattedElement = formatElement(element);
            var lastValue = (0, _util.expect)(cache, 'must serialize after flush').peek();
            if (namespace) {
                return {
                    element: formattedElement,
                    type: 'attribute',
                    namespace: namespace,
                    name: name,
                    lastValue: lastValue
                };
            }
            return {
                element: formattedElement,
                type: 'attribute',
                namespace: namespace === undefined ? null : namespace,
                name: name,
                lastValue: lastValue
            };
        };

        return DynamicAttribute;
    }();

    function formatElement(element) {
        return JSON.stringify('<' + element.tagName.toLowerCase() + ' />');
    }
    APPEND_OPCODES.add(42 /* DynamicAttrNS */, function (vm, _ref24) {
        var _name = _ref24.op1,
            _namespace = _ref24.op2,
            trusting = _ref24.op3;

        var name = vm.constants.getString(_name);
        var namespace = vm.constants.getString(_namespace);
        var reference = vm.frame.getOperand();
        vm.stack().setDynamicAttributeNS(namespace, name, reference, !!trusting);
    });
    APPEND_OPCODES.add(43 /* DynamicAttr */, function (vm, _ref25) {
        var _name = _ref25.op1,
            trusting = _ref25.op2;

        var name = vm.constants.getString(_name);
        var reference = vm.frame.getOperand();
        vm.stack().setDynamicAttribute(name, reference, !!trusting);
    });

    var PatchElementOpcode = function (_UpdatingOpcode7) {
        (0, _emberBabel.inherits)(PatchElementOpcode, _UpdatingOpcode7);

        function PatchElementOpcode(operation) {

            var _this21 = (0, _emberBabel.possibleConstructorReturn)(this, _UpdatingOpcode7.call(this));

            _this21.type = "patch-element";
            _this21.tag = operation.tag;
            _this21.operation = operation;
            return _this21;
        }

        PatchElementOpcode.prototype.evaluate = function (vm) {
            this.operation.patch(vm.env);
        };

        PatchElementOpcode.prototype.toJSON = function () {
            var _guid = this._guid,
                type = this.type,
                operation = this.operation;

            return {
                guid: _guid,
                type: type,
                details: operation.toJSON()
            };
        };

        return PatchElementOpcode;
    }(UpdatingOpcode);

    var First = function () {
        function First(node) {

            this.node = node;
        }

        First.prototype.firstNode = function () {
            return this.node;
        };

        return First;
    }();

    var Last = function () {
        function Last(node) {

            this.node = node;
        }

        Last.prototype.lastNode = function () {
            return this.node;
        };

        return Last;
    }();

    var Fragment = function () {
        function Fragment(bounds$$1) {

            this.bounds = bounds$$1;
        }

        Fragment.prototype.parentElement = function () {
            return this.bounds.parentElement();
        };

        Fragment.prototype.firstNode = function () {
            return this.bounds.firstNode();
        };

        Fragment.prototype.lastNode = function () {
            return this.bounds.lastNode();
        };

        Fragment.prototype.update = function (bounds$$1) {
            this.bounds = bounds$$1;
        };

        return Fragment;
    }();

    var ElementStack = function () {
        function ElementStack(env, parentNode, nextSibling) {

            this.constructing = null;
            this.operations = null;
            this.elementStack = new _util.Stack();
            this.nextSiblingStack = new _util.Stack();
            this.blockStack = new _util.Stack();
            this.env = env;
            this.dom = env.getAppendOperations();
            this.updateOperations = env.getDOM();
            this.element = parentNode;
            this.nextSibling = nextSibling;
            this.defaultOperations = new SimpleElementOperations(env);
            this.elementStack.push(this.element);
            this.nextSiblingStack.push(this.nextSibling);
        }

        ElementStack.forInitialRender = function (env, parentNode, nextSibling) {
            return new ElementStack(env, parentNode, nextSibling);
        };

        ElementStack.resume = function (env, tracker, nextSibling) {
            var parentNode = tracker.parentElement();
            var stack = new ElementStack(env, parentNode, nextSibling);
            stack.pushBlockTracker(tracker);
            return stack;
        };

        ElementStack.prototype.expectConstructing = function (method) {
            return (0, _util.expect)(this.constructing, method + ' should only be called while constructing an element');
        };

        ElementStack.prototype.expectOperations = function (method) {
            return (0, _util.expect)(this.operations, method + ' should only be called while constructing an element');
        };

        ElementStack.prototype.block = function () {
            return (0, _util.expect)(this.blockStack.current, "Expected a current block tracker");
        };

        ElementStack.prototype.popElement = function () {
            var elementStack = this.elementStack,
                nextSiblingStack = this.nextSiblingStack;

            var topElement = elementStack.pop();
            nextSiblingStack.pop();
            // LOGGER.debug(`-> element stack ${this.elementStack.toArray().map(e => e.tagName).join(', ')}`);
            this.element = (0, _util.expect)(elementStack.current, "can't pop past the last element");
            this.nextSibling = nextSiblingStack.current;
            return topElement;
        };

        ElementStack.prototype.pushSimpleBlock = function () {
            var tracker = new SimpleBlockTracker(this.element);
            this.pushBlockTracker(tracker);
            return tracker;
        };

        ElementStack.prototype.pushUpdatableBlock = function () {
            var tracker = new UpdatableBlockTracker(this.element);
            this.pushBlockTracker(tracker);
            return tracker;
        };

        ElementStack.prototype.pushBlockTracker = function (tracker) {
            var isRemote = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            var current = this.blockStack.current;
            if (current !== null) {
                current.newDestroyable(tracker);
                if (!isRemote) {
                    current.newBounds(tracker);
                }
            }
            this.blockStack.push(tracker);
            return tracker;
        };

        ElementStack.prototype.pushBlockList = function (list) {
            var tracker = new BlockListTracker(this.element, list);
            var current = this.blockStack.current;
            if (current !== null) {
                current.newDestroyable(tracker);
                current.newBounds(tracker);
            }
            this.blockStack.push(tracker);
            return tracker;
        };

        ElementStack.prototype.popBlock = function () {
            this.block().finalize(this);
            return (0, _util.expect)(this.blockStack.pop(), "Expected popBlock to return a block");
        };

        ElementStack.prototype.openElement = function (tag) {
            var operations = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.defaultOperations;

            var element = this.dom.createElement(tag, this.element);
            this.constructing = element;
            this.operations = operations;
            return element;
        };

        ElementStack.prototype.flushElement = function () {
            var parent = this.element;
            var element = (0, _util.expect)(this.constructing, 'flushElement should only be called when constructing an element');
            this.dom.insertBefore(parent, element, this.nextSibling);
            this.constructing = null;
            this.operations = null;
            this.pushElement(element);
            this.block().openElement(element);
        };

        ElementStack.prototype.pushRemoteElement = function (element) {
            this.pushElement(element);
            var tracker = new RemoteBlockTracker(element);
            this.pushBlockTracker(tracker, true);
        };

        ElementStack.prototype.popRemoteElement = function () {
            this.popBlock();
            this.popElement();
        };

        ElementStack.prototype.pushElement = function (element) {
            this.element = element;
            this.elementStack.push(element);
            // LOGGER.debug(`-> element stack ${this.elementStack.toArray().map(e => e.tagName).join(', ')}`);
            this.nextSibling = null;
            this.nextSiblingStack.push(null);
        };

        ElementStack.prototype.newDestroyable = function (d) {
            this.block().newDestroyable(d);
        };

        ElementStack.prototype.newBounds = function (bounds$$1) {
            this.block().newBounds(bounds$$1);
        };

        ElementStack.prototype.appendText = function (string) {
            var dom = this.dom;

            var text = dom.createTextNode(string);
            dom.insertBefore(this.element, text, this.nextSibling);
            this.block().newNode(text);
            return text;
        };

        ElementStack.prototype.appendComment = function (string) {
            var dom = this.dom;

            var comment = dom.createComment(string);
            dom.insertBefore(this.element, comment, this.nextSibling);
            this.block().newNode(comment);
            return comment;
        };

        ElementStack.prototype.setStaticAttribute = function (name, value) {
            this.expectOperations('setStaticAttribute').addStaticAttribute(this.expectConstructing('setStaticAttribute'), name, value);
        };

        ElementStack.prototype.setStaticAttributeNS = function (namespace, name, value) {
            this.expectOperations('setStaticAttributeNS').addStaticAttributeNS(this.expectConstructing('setStaticAttributeNS'), namespace, name, value);
        };

        ElementStack.prototype.setDynamicAttribute = function (name, reference, isTrusting) {
            this.expectOperations('setDynamicAttribute').addDynamicAttribute(this.expectConstructing('setDynamicAttribute'), name, reference, isTrusting);
        };

        ElementStack.prototype.setDynamicAttributeNS = function (namespace, name, reference, isTrusting) {
            this.expectOperations('setDynamicAttributeNS').addDynamicAttributeNS(this.expectConstructing('setDynamicAttributeNS'), namespace, name, reference, isTrusting);
        };

        ElementStack.prototype.closeElement = function () {
            this.block().closeElement();
            this.popElement();
        };

        return ElementStack;
    }();

    var SimpleBlockTracker = function () {
        function SimpleBlockTracker(parent) {

            this.parent = parent;
            this.first = null;
            this.last = null;
            this.destroyables = null;
            this.nesting = 0;
        }

        SimpleBlockTracker.prototype.destroy = function () {
            var destroyables = this.destroyables,
                i;

            if (destroyables && destroyables.length) {
                for (i = 0; i < destroyables.length; i++) {
                    destroyables[i].destroy();
                }
            }
        };

        SimpleBlockTracker.prototype.parentElement = function () {
            return this.parent;
        };

        SimpleBlockTracker.prototype.firstNode = function () {
            return this.first && this.first.firstNode();
        };

        SimpleBlockTracker.prototype.lastNode = function () {
            return this.last && this.last.lastNode();
        };

        SimpleBlockTracker.prototype.openElement = function (element) {
            this.newNode(element);
            this.nesting++;
        };

        SimpleBlockTracker.prototype.closeElement = function () {
            this.nesting--;
        };

        SimpleBlockTracker.prototype.newNode = function (node) {
            if (this.nesting !== 0) return;
            if (!this.first) {
                this.first = new First(node);
            }
            this.last = new Last(node);
        };

        SimpleBlockTracker.prototype.newBounds = function (bounds$$1) {
            if (this.nesting !== 0) return;
            if (!this.first) {
                this.first = bounds$$1;
            }
            this.last = bounds$$1;
        };

        SimpleBlockTracker.prototype.newDestroyable = function (d) {
            this.destroyables = this.destroyables || [];
            this.destroyables.push(d);
        };

        SimpleBlockTracker.prototype.finalize = function (stack) {
            if (!this.first) {
                stack.appendComment('');
            }
        };

        return SimpleBlockTracker;
    }();

    var RemoteBlockTracker = function (_SimpleBlockTracker) {
        (0, _emberBabel.inherits)(RemoteBlockTracker, _SimpleBlockTracker);

        function RemoteBlockTracker() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _SimpleBlockTracker.apply(this, arguments));
        }

        RemoteBlockTracker.prototype.destroy = function () {
            _SimpleBlockTracker.prototype.destroy.call(this);
            clear(this);
        };

        return RemoteBlockTracker;
    }(SimpleBlockTracker);

    var UpdatableBlockTracker = function (_SimpleBlockTracker2) {
        (0, _emberBabel.inherits)(UpdatableBlockTracker, _SimpleBlockTracker2);

        function UpdatableBlockTracker() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _SimpleBlockTracker2.apply(this, arguments));
        }

        UpdatableBlockTracker.prototype.reset = function (env) {
            var destroyables = this.destroyables,
                i;

            if (destroyables && destroyables.length) {
                for (i = 0; i < destroyables.length; i++) {
                    env.didDestroy(destroyables[i]);
                }
            }
            var nextSibling = clear(this);
            this.destroyables = null;
            this.first = null;
            this.last = null;
            return nextSibling;
        };

        return UpdatableBlockTracker;
    }(SimpleBlockTracker);

    var BlockListTracker = function () {
        function BlockListTracker(parent, boundList) {

            this.parent = parent;
            this.boundList = boundList;
            this.parent = parent;
            this.boundList = boundList;
        }

        BlockListTracker.prototype.destroy = function () {
            this.boundList.forEachNode(function (node) {
                return node.destroy();
            });
        };

        BlockListTracker.prototype.parentElement = function () {
            return this.parent;
        };

        BlockListTracker.prototype.firstNode = function () {
            var head = this.boundList.head();
            return head && head.firstNode();
        };

        BlockListTracker.prototype.lastNode = function () {
            var tail = this.boundList.tail();
            return tail && tail.lastNode();
        };

        BlockListTracker.prototype.openElement = function () {
            (0, _util.assert)(false, 'Cannot openElement directly inside a block list');
        };

        BlockListTracker.prototype.closeElement = function () {
            (0, _util.assert)(false, 'Cannot closeElement directly inside a block list');
        };

        BlockListTracker.prototype.newNode = function () {
            (0, _util.assert)(false, 'Cannot create a new node directly inside a block list');
        };

        BlockListTracker.prototype.newBounds = function () {};

        BlockListTracker.prototype.newDestroyable = function () {};

        BlockListTracker.prototype.finalize = function () {};

        return BlockListTracker;
    }();

    var CompiledValue = function (_CompiledExpression) {
        (0, _emberBabel.inherits)(CompiledValue, _CompiledExpression);

        function CompiledValue(value) {

            var _this24 = (0, _emberBabel.possibleConstructorReturn)(this, _CompiledExpression.call(this));

            _this24.type = "value";
            _this24.reference = PrimitiveReference.create(value);
            return _this24;
        }

        CompiledValue.prototype.evaluate = function () {
            return this.reference;
        };

        CompiledValue.prototype.toJSON = function () {
            return JSON.stringify(this.reference.value());
        };

        return CompiledValue;
    }(CompiledExpression);

    var CompiledHasBlock = function (_CompiledExpression2) {
        (0, _emberBabel.inherits)(CompiledHasBlock, _CompiledExpression2);

        function CompiledHasBlock(inner) {

            var _this25 = (0, _emberBabel.possibleConstructorReturn)(this, _CompiledExpression2.call(this));

            _this25.inner = inner;
            _this25.type = "has-block";
            return _this25;
        }

        CompiledHasBlock.prototype.evaluate = function (vm) {
            var block = this.inner.evaluate(vm);
            return PrimitiveReference.create(!!block);
        };

        CompiledHasBlock.prototype.toJSON = function () {
            return 'has-block(' + this.inner.toJSON() + ')';
        };

        return CompiledHasBlock;
    }(CompiledExpression);

    var CompiledHasBlockParams = function (_CompiledExpression3) {
        (0, _emberBabel.inherits)(CompiledHasBlockParams, _CompiledExpression3);

        function CompiledHasBlockParams(inner) {

            var _this26 = (0, _emberBabel.possibleConstructorReturn)(this, _CompiledExpression3.call(this));

            _this26.inner = inner;
            _this26.type = "has-block-params";
            return _this26;
        }

        CompiledHasBlockParams.prototype.evaluate = function (vm) {
            var block = this.inner.evaluate(vm);
            var hasLocals = block && block.symbolTable.getSymbols().locals;
            return PrimitiveReference.create(!!hasLocals);
        };

        CompiledHasBlockParams.prototype.toJSON = function () {
            return 'has-block-params(' + this.inner.toJSON() + ')';
        };

        return CompiledHasBlockParams;
    }(CompiledExpression);

    var CompiledGetBlockBySymbol = function () {
        function CompiledGetBlockBySymbol(symbol, debug) {

            this.symbol = symbol;
            this.debug = debug;
        }

        CompiledGetBlockBySymbol.prototype.evaluate = function (vm) {
            return vm.scope().getBlock(this.symbol);
        };

        CompiledGetBlockBySymbol.prototype.toJSON = function () {
            return 'get-block($' + this.symbol + '(' + this.debug + '))';
        };

        return CompiledGetBlockBySymbol;
    }();

    var CompiledInPartialGetBlock = function () {
        function CompiledInPartialGetBlock(symbol, name) {

            this.symbol = symbol;
            this.name = name;
        }

        CompiledInPartialGetBlock.prototype.evaluate = function (vm) {
            var symbol = this.symbol,
                name = this.name;

            var args = vm.scope().getPartialArgs(symbol);
            return args.blocks[name];
        };

        CompiledInPartialGetBlock.prototype.toJSON = function () {
            return 'get-block($' + this.symbol + '($ARGS).' + this.name + '))';
        };

        return CompiledInPartialGetBlock;
    }();

    var CompiledBlock = function (start, end) {

        this.start = start;
        this.end = end;
    };

    var CompiledProgram = function (_CompiledBlock) {
        (0, _emberBabel.inherits)(CompiledProgram, _CompiledBlock);

        function CompiledProgram(start, end, symbols) {

            var _this27 = (0, _emberBabel.possibleConstructorReturn)(this, _CompiledBlock.call(this, start, end));

            _this27.symbols = symbols;
            return _this27;
        }

        return CompiledProgram;
    }(CompiledBlock);

    var Labels = function () {
        function Labels() {

            this.labels = (0, _util.dict)();
            this.jumps = [];
            this.ranges = [];
        }

        Labels.prototype.label = function (name, index) {
            this.labels[name] = index;
        };

        Labels.prototype.jump = function (at, Target, target) {
            this.jumps.push({ at: at, target: target, Target: Target });
        };

        Labels.prototype.range = function (at, Range, start, end) {
            this.ranges.push({ at: at, start: start, end: end, Range: Range });
        };

        Labels.prototype.patch = function (opcodes) {
            var i, _jumps$i, at, target, Target, _ranges$_i, at, start, end, Range, _i;

            for (i = 0; i < this.jumps.length; i++) {
                _jumps$i = this.jumps[i], at = _jumps$i.at, target = _jumps$i.target, Target = _jumps$i.Target;


                opcodes.set(at, Target, this.labels[target]);
            }
            for (_i = 0; _i < this.ranges.length; _i++) {
                _ranges$_i = this.ranges[_i], at = _ranges$_i.at, start = _ranges$_i.start, end = _ranges$_i.end, Range = _ranges$_i.Range;


                opcodes.set(at, Range, this.labels[start], this.labels[end] - 1);
            }
        };

        return Labels;
    }();

    var BasicOpcodeBuilder = function () {
        function BasicOpcodeBuilder(symbolTable, env, program) {

            this.symbolTable = symbolTable;
            this.env = env;
            this.program = program;
            this.labelsStack = new _util.Stack();
            this.constants = env.constants;
            this.start = program.next;
        }

        BasicOpcodeBuilder.prototype.opcode = function (name, op1, op2, op3) {
            this.push(name, op1, op2, op3);
        };

        BasicOpcodeBuilder.prototype.push = function (type) {
            var op1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
            var op2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
            var op3 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

            this.program.push(type, op1, op2, op3);
        };

        BasicOpcodeBuilder.prototype.startLabels = function () {
            this.labelsStack.push(new Labels());
        };

        BasicOpcodeBuilder.prototype.stopLabels = function () {
            var label = (0, _util.expect)(this.labelsStack.pop(), 'unbalanced push and pop labels');
            label.patch(this.program);
        };

        BasicOpcodeBuilder.prototype.putPartialDefinition = function (_definition) {
            var definition = this.constants.other(_definition);
            this.opcode(50 /* PutPartial */, definition);
        };

        BasicOpcodeBuilder.prototype.putDynamicPartialDefinition = function () {
            this.opcode(49 /* PutDynamicPartial */, this.constants.other(this.symbolTable));
        };

        BasicOpcodeBuilder.prototype.evaluatePartial = function () {
            this.opcode(51 /* EvaluatePartial */, this.constants.other(this.symbolTable), this.constants.other((0, _util.dict)()));
        };

        BasicOpcodeBuilder.prototype.putComponentDefinition = function (definition) {
            this.opcode(23 /* PutComponent */, this.other(definition));
        };

        BasicOpcodeBuilder.prototype.putDynamicComponentDefinition = function () {
            this.opcode(22 /* PutDynamicComponent */);
        };

        BasicOpcodeBuilder.prototype.openComponent = function (args, shadow) {
            this.opcode(24 /* OpenComponent */, this.args(args), shadow ? this.block(shadow) : 0);
        };

        BasicOpcodeBuilder.prototype.didCreateElement = function () {
            this.opcode(25 /* DidCreateElement */);
        };

        BasicOpcodeBuilder.prototype.shadowAttributes = function () {
            this.opcode(26 /* ShadowAttributes */);
            this.opcode(21 /* CloseBlock */);
        };

        BasicOpcodeBuilder.prototype.didRenderLayout = function () {
            this.opcode(27 /* DidRenderLayout */);
        };

        BasicOpcodeBuilder.prototype.closeComponent = function () {
            this.opcode(28 /* CloseComponent */);
        };

        BasicOpcodeBuilder.prototype.dynamicContent = function (Opcode) {
            this.opcode(31 /* DynamicContent */, this.other(Opcode));
        };

        BasicOpcodeBuilder.prototype.cautiousAppend = function () {
            this.dynamicContent(new OptimizedCautiousAppendOpcode());
        };

        BasicOpcodeBuilder.prototype.trustingAppend = function () {
            this.dynamicContent(new OptimizedTrustingAppendOpcode());
        };

        BasicOpcodeBuilder.prototype.guardedCautiousAppend = function (expression) {
            this.dynamicContent(new GuardedCautiousAppendOpcode(this.compileExpression(expression), this.symbolTable));
        };

        BasicOpcodeBuilder.prototype.guardedTrustingAppend = function (expression) {
            this.dynamicContent(new GuardedTrustingAppendOpcode(this.compileExpression(expression), this.symbolTable));
        };

        BasicOpcodeBuilder.prototype.text = function (_text) {
            this.opcode(29 /* Text */, this.constants.string(_text));
        };

        BasicOpcodeBuilder.prototype.openPrimitiveElement = function (tag) {
            this.opcode(32 /* OpenElement */, this.constants.string(tag));
        };

        BasicOpcodeBuilder.prototype.openComponentElement = function (tag) {
            this.opcode(35 /* OpenComponentElement */, this.constants.string(tag));
        };

        BasicOpcodeBuilder.prototype.openDynamicPrimitiveElement = function () {
            this.opcode(36 /* OpenDynamicElement */);
        };

        BasicOpcodeBuilder.prototype.flushElement = function () {
            this.opcode(37 /* FlushElement */);
        };

        BasicOpcodeBuilder.prototype.closeElement = function () {
            this.opcode(38 /* CloseElement */);
        };

        BasicOpcodeBuilder.prototype.staticAttr = function (_name, _namespace, _value) {
            var name = this.constants.string(_name);
            var namespace = _namespace ? this.constants.string(_namespace) : 0;
            var value = this.constants.string(_value);
            this.opcode(40 /* StaticAttr */, name, value, namespace);
        };

        BasicOpcodeBuilder.prototype.dynamicAttrNS = function (_name, _namespace, trusting) {
            var name = this.constants.string(_name);
            var namespace = this.constants.string(_namespace);
            this.opcode(42 /* DynamicAttrNS */, name, namespace, trusting | 0);
        };

        BasicOpcodeBuilder.prototype.dynamicAttr = function (_name, trusting) {
            var name = this.constants.string(_name);
            this.opcode(43 /* DynamicAttr */, name, trusting | 0);
        };

        BasicOpcodeBuilder.prototype.comment = function (_comment) {
            var comment = this.constants.string(_comment);
            this.opcode(30 /* Comment */, comment);
        };

        BasicOpcodeBuilder.prototype.modifier = function (_name, _args) {
            var args = this.constants.expression(this.compile(_args));
            var _modifierManager = this.env.lookupModifier(_name, this.symbolTable);
            var modifierManager = this.constants.other(_modifierManager);
            var name = this.constants.string(_name);
            this.opcode(41 /* Modifier */, name, modifierManager, args);
        };

        BasicOpcodeBuilder.prototype.putIterator = function () {
            this.opcode(44 /* PutIterator */);
        };

        BasicOpcodeBuilder.prototype.enterList = function (start, end) {
            this.push(45 /* EnterList */);
            this.labels.range(this.pos, 45 /* EnterList */, start, end);
        };

        BasicOpcodeBuilder.prototype.exitList = function () {
            this.opcode(46 /* ExitList */);
        };

        BasicOpcodeBuilder.prototype.enterWithKey = function (start, end) {
            this.push(47 /* EnterWithKey */);
            this.labels.range(this.pos, 47 /* EnterWithKey */, start, end);
        };

        BasicOpcodeBuilder.prototype.nextIter = function (end) {
            this.push(48 /* NextIter */);
            this.labels.jump(this.pos, 48 /* NextIter */, end);
        };

        BasicOpcodeBuilder.prototype.openBlock = function (_args, _inner) {
            var args = this.constants.expression(this.compile(_args));
            var inner = this.constants.other(_inner);
            this.opcode(20 /* OpenBlock */, inner, args);
        };

        BasicOpcodeBuilder.prototype.closeBlock = function () {
            this.opcode(21 /* CloseBlock */);
        };

        BasicOpcodeBuilder.prototype.pushRemoteElement = function () {
            this.opcode(33 /* PushRemoteElement */);
        };

        BasicOpcodeBuilder.prototype.popRemoteElement = function () {
            this.opcode(34 /* PopRemoteElement */);
        };

        BasicOpcodeBuilder.prototype.popElement = function () {
            this.opcode(39 /* PopElement */);
        };

        BasicOpcodeBuilder.prototype.label = function (name) {
            this.labels.label(name, this.nextPos);
        };

        BasicOpcodeBuilder.prototype.pushChildScope = function () {
            this.opcode(0 /* PushChildScope */);
        };

        BasicOpcodeBuilder.prototype.popScope = function () {
            this.opcode(1 /* PopScope */);
        };

        BasicOpcodeBuilder.prototype.pushDynamicScope = function () {
            this.opcode(2 /* PushDynamicScope */);
        };

        BasicOpcodeBuilder.prototype.popDynamicScope = function () {
            this.opcode(3 /* PopDynamicScope */);
        };

        BasicOpcodeBuilder.prototype.putNull = function () {
            this.opcode(4 /* Put */, this.constants.NULL_REFERENCE);
        };

        BasicOpcodeBuilder.prototype.putValue = function (_expression) {
            var expr$$1 = this.constants.expression(this.compileExpression(_expression));
            this.opcode(5 /* EvaluatePut */, expr$$1);
        };

        BasicOpcodeBuilder.prototype.putArgs = function (_args) {
            var args = this.constants.expression(this.compile(_args));
            this.opcode(6 /* PutArgs */, args);
        };

        BasicOpcodeBuilder.prototype.bindDynamicScope = function (_names) {
            this.opcode(12 /* BindDynamicScope */, this.names(_names));
        };

        BasicOpcodeBuilder.prototype.bindPositionalArgs = function (_names, _symbols) {
            this.opcode(7 /* BindPositionalArgs */, this.names(_names), this.symbols(_symbols));
        };

        BasicOpcodeBuilder.prototype.bindNamedArgs = function (_names, _symbols) {
            this.opcode(8 /* BindNamedArgs */, this.names(_names), this.symbols(_symbols));
        };

        BasicOpcodeBuilder.prototype.bindBlocks = function (_names, _symbols) {
            this.opcode(9 /* BindBlocks */, this.names(_names), this.symbols(_symbols));
        };

        BasicOpcodeBuilder.prototype.enter = function (_enter, exit) {
            this.push(13 /* Enter */);
            this.labels.range(this.pos, 13 /* Enter */, _enter, exit);
        };

        BasicOpcodeBuilder.prototype.exit = function () {
            this.opcode(14 /* Exit */);
        };

        BasicOpcodeBuilder.prototype.evaluate = function (_block) {
            var block = this.constants.block(_block);
            this.opcode(15 /* Evaluate */, block);
        };

        BasicOpcodeBuilder.prototype.test = function (testFunc) {
            var _func = void 0;
            if (testFunc === 'const') {
                _func = ConstTest;
            } else if (testFunc === 'simple') {
                _func = SimpleTest;
            } else if (testFunc === 'environment') {
                _func = EnvironmentTest;
            } else if (typeof testFunc === 'function') {
                _func = testFunc;
            } else {
                throw new Error('unreachable');
            }
            var func = this.constants.function(_func);
            this.opcode(19 /* Test */, func);
        };

        BasicOpcodeBuilder.prototype.jump = function (target) {
            this.push(16 /* Jump */);
            this.labels.jump(this.pos, 16 /* Jump */, target);
        };

        BasicOpcodeBuilder.prototype.jumpIf = function (target) {
            this.push(17 /* JumpIf */);
            this.labels.jump(this.pos, 17 /* JumpIf */, target);
        };

        BasicOpcodeBuilder.prototype.jumpUnless = function (target) {
            this.push(18 /* JumpUnless */);
            this.labels.jump(this.pos, 18 /* JumpUnless */, target);
        };

        BasicOpcodeBuilder.prototype.names = function (_names) {
            var _this28 = this;

            var names = _names.map(function (n) {
                return _this28.constants.string(n);
            });
            return this.constants.array(names);
        };

        BasicOpcodeBuilder.prototype.symbols = function (_symbols2) {
            return this.constants.array(_symbols2);
        };

        BasicOpcodeBuilder.prototype.other = function (value) {
            return this.constants.other(value);
        };

        BasicOpcodeBuilder.prototype.args = function (_args2) {
            return this.constants.expression(this.compile(_args2));
        };

        BasicOpcodeBuilder.prototype.block = function (_block3) {
            return this.constants.block(_block3);
        };

        (0, _emberBabel.createClass)(BasicOpcodeBuilder, [{
            key: 'end',
            get: function () {
                return this.program.next;
            }
        }, {
            key: 'pos',
            get: function () {
                return this.program.current;
            }
        }, {
            key: 'nextPos',
            get: function () {
                return this.program.next;
            }
        }, {
            key: 'labels',
            get: function () {
                return (0, _util.expect)(this.labelsStack.current, 'bug: not in a label stack');
            }
        }]);
        return BasicOpcodeBuilder;
    }();

    function isCompilableExpression(expr$$1) {
        return expr$$1 && typeof expr$$1['compile'] === 'function';
    }

    var OpcodeBuilder = function (_BasicOpcodeBuilder) {
        (0, _emberBabel.inherits)(OpcodeBuilder, _BasicOpcodeBuilder);

        function OpcodeBuilder(symbolTable, env) {
            var program = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : env.program;


            var _this29 = (0, _emberBabel.possibleConstructorReturn)(this, _BasicOpcodeBuilder.call(this, symbolTable, env, program));

            _this29.component = new ComponentBuilder(_this29);
            return _this29;
        }

        OpcodeBuilder.prototype.compile = function (expr$$1) {
            if (isCompilableExpression(expr$$1)) {
                return expr$$1.compile(this);
            } else {
                return expr$$1;
            }
        };

        OpcodeBuilder.prototype.compileExpression = function (expression) {
            if (expression instanceof CompiledExpression) {
                return expression;
            } else {
                return expr(expression, this);
            }
        };

        OpcodeBuilder.prototype.bindPositionalArgsForLocals = function (locals) {
            var names = Object.keys(locals),
                i;
            var symbols = new Array(names.length); //Object.keys(locals).map(name => locals[name]);
            for (i = 0; i < names.length; i++) {
                symbols[i] = locals[names[i]];
            }
            this.opcode(7 /* BindPositionalArgs */, this.symbols(symbols));
        };

        OpcodeBuilder.prototype.preludeForLayout = function (layout) {
            var symbols = layout.symbolTable.getSymbols(),
                named,
                namedNames,
                namedSymbols,
                yields,
                yieldNames,
                yieldSymbols;
            if (symbols.named) {
                named = symbols.named;
                namedNames = Object.keys(named);
                namedSymbols = namedNames.map(function (n) {
                    return named[n];
                });

                this.opcode(8 /* BindNamedArgs */, this.names(namedNames), this.symbols(namedSymbols));
            }
            this.opcode(11 /* BindCallerScope */);
            if (symbols.yields) {
                yields = symbols.yields;
                yieldNames = Object.keys(yields);
                yieldSymbols = yieldNames.map(function (n) {
                    return yields[n];
                });

                this.opcode(9 /* BindBlocks */, this.names(yieldNames), this.symbols(yieldSymbols));
            }
            if (symbols.partialArgs) {
                this.opcode(10 /* BindPartialArgs */, symbols.partialArgs);
            }
        };

        OpcodeBuilder.prototype.yield = function (args, to) {
            var yields = void 0,
                partial = void 0;
            var inner = void 0;
            if (yields = this.symbolTable.getSymbol('yields', to)) {
                inner = new CompiledGetBlockBySymbol(yields, to);
            } else if (partial = this.symbolTable.getPartialArgs()) {
                inner = new CompiledInPartialGetBlock(partial, to);
            } else {
                throw new Error('[BUG] ${to} is not a valid block name.');
            }
            this.openBlock(args, inner);
            this.closeBlock();
        };

        OpcodeBuilder.prototype.labelled = function (args, callback) {
            if (args) this.putArgs(args);
            this.startLabels();
            this.enter('BEGIN', 'END');
            this.label('BEGIN');
            callback(this, 'BEGIN', 'END');
            this.label('END');
            this.exit();
            this.stopLabels();
        };

        OpcodeBuilder.prototype.iter = function (callback) {
            this.startLabels();
            this.enterList('BEGIN', 'END');
            this.label('ITER');
            this.nextIter('BREAK');
            this.enterWithKey('BEGIN', 'END');
            this.label('BEGIN');
            callback(this, 'BEGIN', 'END');
            this.label('END');
            this.exit();
            this.jump('ITER');
            this.label('BREAK');
            this.exitList();
            this.stopLabels();
        };

        OpcodeBuilder.prototype.unit = function (callback) {
            this.startLabels();
            callback(this);
            this.stopLabels();
        };

        return OpcodeBuilder;
    }(BasicOpcodeBuilder);

    var ComponentLayoutBuilder = function () {
        function ComponentLayoutBuilder(env) {

            this.env = env;
        }

        ComponentLayoutBuilder.prototype.wrapLayout = function (layout) {
            this.inner = new WrappedBuilder(this.env, layout);
        };

        ComponentLayoutBuilder.prototype.fromLayout = function (layout) {
            this.inner = new UnwrappedBuilder(this.env, layout);
        };

        ComponentLayoutBuilder.prototype.compile = function () {
            return this.inner.compile();
        };

        (0, _emberBabel.createClass)(ComponentLayoutBuilder, [{
            key: 'tag',
            get: function () {
                return this.inner.tag;
            }
        }, {
            key: 'attrs',
            get: function () {
                return this.inner.attrs;
            }
        }]);
        return ComponentLayoutBuilder;
    }();

    var WrappedBuilder = function () {
        function WrappedBuilder(env, layout) {

            this.env = env;
            this.layout = layout;
            this.tag = new ComponentTagBuilder();
            this.attrs = new ComponentAttrsBuilder();
        }

        WrappedBuilder.prototype.compile = function () {
            var env = this.env,
                layout = this.layout;

            var symbolTable = layout.symbolTable;
            var b = builder(env, layout.symbolTable);
            b.startLabels();
            var dynamicTag = this.tag.getDynamic();
            var staticTag = void 0;
            if (dynamicTag) {
                b.putValue(dynamicTag);
                b.test('simple');
                b.jumpUnless('BODY');
                b.openDynamicPrimitiveElement();
                b.didCreateElement();
                this.attrs['buffer'].forEach(function (statement) {
                    return compileStatement(statement, b);
                });
                b.flushElement();
                b.label('BODY');
            } else if (staticTag = this.tag.getStatic()) {
                b.openPrimitiveElement(staticTag);
                b.didCreateElement();
                this.attrs['buffer'].forEach(function (statement) {
                    return compileStatement(statement, b);
                });
                b.flushElement();
            }
            b.preludeForLayout(layout);
            layout.statements.forEach(function (statement) {
                return compileStatement(statement, b);
            });
            if (dynamicTag) {
                b.putValue(dynamicTag);
                b.test('simple');
                b.jumpUnless('END');
                b.closeElement();
                b.label('END');
            } else if (staticTag) {
                b.closeElement();
            }
            b.didRenderLayout();
            b.stopLabels();
            return new CompiledProgram(b.start, b.end, symbolTable.size);
        };

        return WrappedBuilder;
    }();

    function isOpenElement(value) {
        var type = value[0];
        return type === _wireFormat.Ops.OpenElement || type === _wireFormat.Ops.OpenPrimitiveElement;
    }

    var UnwrappedBuilder = function () {
        function UnwrappedBuilder(env, layout) {

            this.env = env;
            this.layout = layout;
            this.attrs = new ComponentAttrsBuilder();
        }

        UnwrappedBuilder.prototype.compile = function () {
            var env = this.env,
                layout = this.layout,
                i,
                statement;

            var b = builder(env, layout.symbolTable);
            b.startLabels();
            b.preludeForLayout(layout);
            var attrs = this.attrs['buffer'];
            var attrsInserted = false;
            for (i = 0; i < layout.statements.length; i++) {
                statement = layout.statements[i];

                if (!attrsInserted && isOpenElement(statement)) {
                    b.openComponentElement(statement[1]);
                    b.didCreateElement();
                    b.shadowAttributes();
                    attrs.forEach(function (statement) {
                        return compileStatement(statement, b);
                    });
                    attrsInserted = true;
                } else {
                    compileStatement(statement, b);
                }
            }
            b.didRenderLayout();
            b.stopLabels();
            return new CompiledProgram(b.start, b.end, layout.symbolTable.size);
        };

        (0, _emberBabel.createClass)(UnwrappedBuilder, [{
            key: 'tag',
            get: function () {
                throw new Error('BUG: Cannot call `tag` on an UnwrappedBuilder');
            }
        }]);
        return UnwrappedBuilder;
    }();

    var ComponentTagBuilder = function () {
        function ComponentTagBuilder() {

            this.isDynamic = null;
            this.isStatic = null;
            this.staticTagName = null;
            this.dynamicTagName = null;
        }

        ComponentTagBuilder.prototype.getDynamic = function () {
            if (this.isDynamic) {
                return this.dynamicTagName;
            }
        };

        ComponentTagBuilder.prototype.getStatic = function () {
            if (this.isStatic) {
                return this.staticTagName;
            }
        };

        ComponentTagBuilder.prototype.static = function (tagName) {
            this.isStatic = true;
            this.staticTagName = tagName;
        };

        ComponentTagBuilder.prototype.dynamic = function (tagName) {
            this.isDynamic = true;
            this.dynamicTagName = [_wireFormat.Ops.Function, tagName];
        };

        return ComponentTagBuilder;
    }();

    var ComponentAttrsBuilder = function () {
        function ComponentAttrsBuilder() {

            this.buffer = [];
        }

        ComponentAttrsBuilder.prototype.static = function (name, value) {
            this.buffer.push([_wireFormat.Ops.StaticAttr, name, value, null]);
        };

        ComponentAttrsBuilder.prototype.dynamic = function (name, value) {
            this.buffer.push([_wireFormat.Ops.DynamicAttr, name, [_wireFormat.Ops.Function, value], null]);
        };

        return ComponentAttrsBuilder;
    }();

    var ComponentBuilder = function () {
        function ComponentBuilder(builder) {

            this.builder = builder;
            this.env = builder.env;
        }

        ComponentBuilder.prototype.static = function (definition, args, _symbolTable, shadow) {
            this.builder.unit(function (b) {
                b.putComponentDefinition(definition);
                b.openComponent(compileBaselineArgs(args, b), shadow);
                b.closeComponent();
            });
        };

        ComponentBuilder.prototype.dynamic = function (definitionArgs, definition, args, _symbolTable, shadow) {
            this.builder.unit(function (b) {
                b.putArgs(compileArgs(definitionArgs[0], definitionArgs[1], b));
                b.putValue([_wireFormat.Ops.Function, definition]);
                b.test('simple');
                b.enter('BEGIN', 'END');
                b.label('BEGIN');
                b.jumpUnless('END');
                b.putDynamicComponentDefinition();
                b.openComponent(compileBaselineArgs(args, b), shadow);
                b.closeComponent();
                b.label('END');
                b.exit();
            });
        };

        return ComponentBuilder;
    }();

    function builder(env, symbolTable) {
        return new OpcodeBuilder(symbolTable, env);
    }

    function entryPoint(meta) {
        return new ProgramSymbolTable(meta);
    }
    function layout(meta, wireNamed, wireYields, hasPartials) {
        var _symbols3 = symbols(wireNamed, wireYields, hasPartials),
            named = _symbols3.named,
            yields = _symbols3.yields,
            partialSymbol = _symbols3.partialSymbol,
            size = _symbols3.size;

        return new ProgramSymbolTable(meta, named, yields, partialSymbol, size);
    }
    function block(parent, locals) {
        var localsMap = null,
            map$$1;
        var program = parent['program'];
        if (locals.length !== 0) {
            map$$1 = localsMap = (0, _util.dict)();

            locals.forEach(function (l) {
                return map$$1[l] = program.size++;
            });
        }
        return new BlockSymbolTable(parent, program, localsMap);
    }
    function symbols(named, yields, hasPartials) {
        var yieldsMap = null,
            map$$1,
            _map$$;
        var namedMap = null;
        var size = 1;
        if (yields.length !== 0) {
            map$$1 = yieldsMap = (0, _util.dict)();

            yields.forEach(function (y) {
                return map$$1[y] = size++;
            });
        }
        if (named.length !== 0) {
            _map$$ = namedMap = (0, _util.dict)();

            named.forEach(function (y) {
                return _map$$[y] = size++;
            });
        }
        var partialSymbol = hasPartials ? size++ : null;
        return { named: namedMap, yields: yieldsMap, partialSymbol: partialSymbol, size: size };
    }

    var ProgramSymbolTable = function () {
        function ProgramSymbolTable(meta) {
            var named = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
            var yields = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
            var partialArgs = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
            var size = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 1;


            this.meta = meta;
            this.named = named;
            this.yields = yields;
            this.partialArgs = partialArgs;
            this.size = size;
            this.program = this;
        }

        ProgramSymbolTable.prototype.getMeta = function () {
            return this.meta;
        };

        ProgramSymbolTable.prototype.getSymbols = function () {
            return {
                named: this.named,
                yields: this.yields,
                locals: null,
                partialArgs: this.partialArgs
            };
        };

        ProgramSymbolTable.prototype.getSymbol = function (kind, name) {
            if (kind === 'local') return null;
            return this[kind] && this[kind][name];
        };

        ProgramSymbolTable.prototype.getPartialArgs = function () {
            return this.partialArgs || 0;
        };

        return ProgramSymbolTable;
    }();

    var BlockSymbolTable = function () {
        function BlockSymbolTable(parent, program, locals) {

            this.parent = parent;
            this.program = program;
            this.locals = locals;
        }

        BlockSymbolTable.prototype.getMeta = function () {
            return this.program.getMeta();
        };

        BlockSymbolTable.prototype.getSymbols = function () {
            return {
                named: null,
                yields: null,
                locals: this.locals,
                partialArgs: null
            };
        };

        BlockSymbolTable.prototype.getSymbol = function (kind, name) {
            if (kind === 'local') {
                return this.getLocal(name);
            } else {
                return this.program.getSymbol(kind, name);
            }
        };

        BlockSymbolTable.prototype.getLocal = function (name) {
            var locals = this.locals,
                parent = this.parent;

            var symbol = locals && locals[name];
            if (!symbol && parent) {
                symbol = parent.getSymbol('local', name);
            }
            return symbol;
        };

        BlockSymbolTable.prototype.getPartialArgs = function () {
            return this.program.getPartialArgs();
        };

        return BlockSymbolTable;
    }();

    var Specialize = function () {
        function Specialize() {

            this.names = (0, _util.dict)();
            this.funcs = [];
        }

        Specialize.prototype.add = function (name, func) {
            this.funcs.push(func);
            this.names[name] = this.funcs.length - 1;
        };

        Specialize.prototype.specialize = function (sexp, table) {
            var name = sexp[0];
            var index = this.names[name];
            if (index === undefined) return sexp;
            var func = this.funcs[index];
            (0, _util.assert)(!!func, 'expected a specialization for ' + sexp[0]);
            return func(sexp, table);
        };

        return Specialize;
    }();

    var SPECIALIZE = new Specialize();
    var E = _wireFormat.Expressions;
    var Ops$3 = _wireFormat.Ops;

    SPECIALIZE.add(Ops$3.Append, function (sexp) {
        var expression = sexp[1],
            path;
        if (Array.isArray(expression) && E.isGet(expression)) {
            path = expression[1];

            if (path.length !== 1) {
                return [Ops$3.UnoptimizedAppend, sexp[1], sexp[2]];
            }
        }
        return [Ops$3.OptimizedAppend, sexp[1], sexp[2]];
    });
    SPECIALIZE.add(Ops$3.DynamicAttr, function (sexp) {
        return [Ops$3.AnyDynamicAttr, sexp[1], sexp[2], sexp[3], false];
    });
    SPECIALIZE.add(Ops$3.TrustingAttr, function (sexp) {
        return [Ops$3.AnyDynamicAttr, sexp[1], sexp[2], sexp[3], true];
    });
    SPECIALIZE.add(Ops$3.Partial, function (sexp) {
        var expression = sexp[1];
        if (typeof expression === 'string') {
            return [Ops$3.StaticPartial, expression];
        } else {
            return [Ops$3.DynamicPartial, expression];
        }
    });

    function compileStatement(statement, builder$$1) {
        var refined = SPECIALIZE.specialize(statement, builder$$1.symbolTable);
        STATEMENTS.compile(refined, builder$$1);
    }

    var Template = function (statements, symbolTable) {

        this.statements = statements;
        this.symbolTable = symbolTable;
    };

    var Layout = function (_Template) {
        (0, _emberBabel.inherits)(Layout, _Template);

        function Layout() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _Template.apply(this, arguments));
        }

        return Layout;
    }(Template);

    var EntryPoint = function (_Template2) {
        (0, _emberBabel.inherits)(EntryPoint, _Template2);

        function EntryPoint() {

            var _this31 = (0, _emberBabel.possibleConstructorReturn)(this, _Template2.apply(this, arguments));

            _this31.compiled = null;
            return _this31;
        }

        EntryPoint.prototype.compile = function (env) {
            var compiled = this.compiled,
                table,
                b,
                i,
                statement,
                refined;
            if (!compiled) {
                table = this.symbolTable;
                b = builder(env, table);

                for (i = 0; i < this.statements.length; i++) {
                    statement = this.statements[i];
                    refined = SPECIALIZE.specialize(statement, table);

                    STATEMENTS.compile(refined, b);
                }
                compiled = this.compiled = new CompiledProgram(b.start, b.end, this.symbolTable.size);
            }
            return compiled;
        };

        return EntryPoint;
    }(Template);

    var InlineBlock = function (_Template3) {
        (0, _emberBabel.inherits)(InlineBlock, _Template3);

        function InlineBlock() {

            var _this32 = (0, _emberBabel.possibleConstructorReturn)(this, _Template3.apply(this, arguments));

            _this32.compiled = null;
            return _this32;
        }

        InlineBlock.prototype.splat = function (builder$$1) {
            var table = builder$$1.symbolTable,
                i,
                statement,
                refined;
            var locals = table.getSymbols().locals;
            if (locals) {
                builder$$1.pushChildScope();
                builder$$1.bindPositionalArgsForLocals(locals);
            }
            for (i = 0; i < this.statements.length; i++) {
                statement = this.statements[i];
                refined = SPECIALIZE.specialize(statement, table);

                STATEMENTS.compile(refined, builder$$1);
            }
            if (locals) {
                builder$$1.popScope();
            }
        };

        InlineBlock.prototype.compile = function (env) {
            var compiled = this.compiled,
                table,
                b;
            if (!compiled) {
                table = this.symbolTable;
                b = builder(env, table);

                this.splat(b);
                compiled = this.compiled = new CompiledBlock(b.start, b.end);
            }
            return compiled;
        };

        return InlineBlock;
    }(Template);

    var PartialBlock = function (_Template4) {
        (0, _emberBabel.inherits)(PartialBlock, _Template4);

        function PartialBlock() {

            var _this33 = (0, _emberBabel.possibleConstructorReturn)(this, _Template4.apply(this, arguments));

            _this33.compiled = null;
            return _this33;
        }

        PartialBlock.prototype.compile = function (env) {
            var compiled = this.compiled,
                table,
                b,
                i,
                statement,
                refined;
            if (!compiled) {
                table = this.symbolTable;
                b = builder(env, table);

                for (i = 0; i < this.statements.length; i++) {
                    statement = this.statements[i];
                    refined = SPECIALIZE.specialize(statement, table);

                    STATEMENTS.compile(refined, b);
                }
                compiled = this.compiled = new CompiledProgram(b.start, b.end, table.size);
            }
            return compiled;
        };

        return PartialBlock;
    }(Template);

    var Scanner = function () {
        function Scanner(block$$1, meta, env) {

            this.block = block$$1;
            this.meta = meta;
            this.env = env;
        }

        Scanner.prototype.scanEntryPoint = function () {
            var block$$1 = this.block,
                meta = this.meta;

            var symbolTable = entryPoint(meta);
            var child = scanBlock(block$$1, symbolTable, this.env);
            return new EntryPoint(child.statements, symbolTable);
        };

        Scanner.prototype.scanLayout = function () {
            var block$$1 = this.block,
                meta = this.meta;
            var named = block$$1.named,
                yields = block$$1.yields,
                hasPartials = block$$1.hasPartials;

            var symbolTable = layout(meta, named, yields, hasPartials);
            var child = scanBlock(block$$1, symbolTable, this.env);
            return new Layout(child.statements, symbolTable);
        };

        Scanner.prototype.scanPartial = function (symbolTable) {
            var block$$1 = this.block;

            var child = scanBlock(block$$1, symbolTable, this.env);
            return new PartialBlock(child.statements, symbolTable);
        };

        return Scanner;
    }();

    function scanBlock(_ref26, symbolTable, env) {
        var statements = _ref26.statements;

        return new RawInlineBlock(env, symbolTable, statements).scan();
    }
    var BaselineSyntax;
    (function (BaselineSyntax) {
        var Ops$$1 = _wireFormat.Ops;

        BaselineSyntax.isScannedComponent = (0, _wireFormat.is)(Ops$$1.ScannedComponent);
        BaselineSyntax.isPrimitiveElement = (0, _wireFormat.is)(Ops$$1.OpenPrimitiveElement);
        BaselineSyntax.isOptimizedAppend = (0, _wireFormat.is)(Ops$$1.OptimizedAppend);
        BaselineSyntax.isUnoptimizedAppend = (0, _wireFormat.is)(Ops$$1.UnoptimizedAppend);
        BaselineSyntax.isAnyAttr = (0, _wireFormat.is)(Ops$$1.AnyDynamicAttr);
        BaselineSyntax.isStaticPartial = (0, _wireFormat.is)(Ops$$1.StaticPartial);
        BaselineSyntax.isDynamicPartial = (0, _wireFormat.is)(Ops$$1.DynamicPartial);
        BaselineSyntax.isFunctionExpression = (0, _wireFormat.is)(Ops$$1.Function);
        BaselineSyntax.isNestedBlock = (0, _wireFormat.is)(Ops$$1.NestedBlock);
        BaselineSyntax.isScannedBlock = (0, _wireFormat.is)(Ops$$1.ScannedBlock);
        BaselineSyntax.isDebugger = (0, _wireFormat.is)(Ops$$1.Debugger);
        var NestedBlock;
        (function (NestedBlock) {
            NestedBlock.defaultBlock = function (sexp) {
                return sexp[4];
            };

            NestedBlock.inverseBlock = function (sexp) {
                return sexp[5];
            };

            NestedBlock.params = function (sexp) {
                return sexp[2];
            };

            NestedBlock.hash = function (sexp) {
                return sexp[3];
            };
        })(NestedBlock = BaselineSyntax.NestedBlock || (BaselineSyntax.NestedBlock = {}));
    })(BaselineSyntax || (exports.BaselineSyntax = BaselineSyntax = {}));
    var Ops$2 = _wireFormat.Ops;

    var RawInlineBlock = function () {
        function RawInlineBlock(env, table, statements) {

            this.env = env;
            this.table = table;
            this.statements = statements;
        }

        RawInlineBlock.prototype.scan = function () {
            var buffer = [];
            this.specializeStatements(this.statements, buffer);
            return new InlineBlock(buffer, this.table);
        };

        RawInlineBlock.prototype.specializeStatements = function (statements, buffer) {
            var i, statement;

            for (i = 0; i < statements.length; i++) {
                statement = statements[i];

                this.specializeStatement(statement, buffer);
            }
        };

        RawInlineBlock.prototype.specializeStatement = function (statement, buffer) {
            if (_wireFormat.Statements.isBlock(statement)) {
                buffer.push(this.specializeBlock(statement));
            } else if (_wireFormat.Statements.isComponent(statement)) {
                buffer.push.apply(buffer, this.specializeComponent(statement));
            } else {
                buffer.push(statement);
            }
        };

        RawInlineBlock.prototype.specializeBlock = function (block$$1) {
            var path = block$$1[1],
                params = block$$1[2],
                hash = block$$1[3],
                template = block$$1[4],
                inverse = block$$1[5];

            return [Ops$2.ScannedBlock, path, params, hash, this.child(template), this.child(inverse)];
        };

        RawInlineBlock.prototype.specializeComponent = function (sexp) {
            var tag = sexp[1],
                component = sexp[2],
                child,
                attrs,
                buff;

            if (this.env.hasComponentDefinition(tag, this.table)) {
                child = this.child(component);
                attrs = new RawInlineBlock(this.env, this.table, component.attrs);

                return [[Ops$2.ScannedComponent, tag, attrs, component.args, child]];
            } else {
                buff = [];

                buff.push([Ops$2.OpenElement, tag, []]);
                this.specializeStatements(component.attrs, buff);
                buff.push([Ops$2.FlushElement]);
                this.specializeStatements(component.statements, buff);
                buff.push([Ops$2.CloseElement]);
                return buff;
            }
        };

        RawInlineBlock.prototype.child = function (block$$1) {
            if (!block$$1) return null;
            var table = block(this.table, block$$1.locals);
            return new RawInlineBlock(this.env, table, block$$1.statements);
        };

        return RawInlineBlock;
    }();

    var CompiledLookup = function (_CompiledExpression4) {
        (0, _emberBabel.inherits)(CompiledLookup, _CompiledExpression4);

        function CompiledLookup(base, path) {

            var _this34 = (0, _emberBabel.possibleConstructorReturn)(this, _CompiledExpression4.call(this));

            _this34.base = base;
            _this34.path = path;
            _this34.type = "lookup";
            return _this34;
        }

        CompiledLookup.create = function (base, path) {
            if (path.length === 0) {
                return base;
            } else {
                return new this(base, path);
            }
        };

        CompiledLookup.prototype.evaluate = function (vm) {
            var base = this.base,
                path = this.path;

            return (0, _reference2.referenceFromParts)(base.evaluate(vm), path);
        };

        CompiledLookup.prototype.toJSON = function () {
            return this.base.toJSON() + '.' + this.path.join('.');
        };

        return CompiledLookup;
    }(CompiledExpression);

    var CompiledSelf = function (_CompiledExpression5) {
        (0, _emberBabel.inherits)(CompiledSelf, _CompiledExpression5);

        function CompiledSelf() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _CompiledExpression5.apply(this, arguments));
        }

        CompiledSelf.prototype.evaluate = function (vm) {
            return vm.getSelf();
        };

        CompiledSelf.prototype.toJSON = function () {
            return 'self';
        };

        return CompiledSelf;
    }(CompiledExpression);

    var CompiledSymbol = function (_CompiledExpression6) {
        (0, _emberBabel.inherits)(CompiledSymbol, _CompiledExpression6);

        function CompiledSymbol(symbol, debug) {

            var _this36 = (0, _emberBabel.possibleConstructorReturn)(this, _CompiledExpression6.call(this));

            _this36.symbol = symbol;
            _this36.debug = debug;
            return _this36;
        }

        CompiledSymbol.prototype.evaluate = function (vm) {
            return vm.referenceForSymbol(this.symbol);
        };

        CompiledSymbol.prototype.toJSON = function () {
            return '$' + this.symbol + '(' + this.debug + ')';
        };

        return CompiledSymbol;
    }(CompiledExpression);

    var CompiledInPartialName = function (_CompiledExpression7) {
        (0, _emberBabel.inherits)(CompiledInPartialName, _CompiledExpression7);

        function CompiledInPartialName(symbol, name) {

            var _this37 = (0, _emberBabel.possibleConstructorReturn)(this, _CompiledExpression7.call(this));

            _this37.symbol = symbol;
            _this37.name = name;
            return _this37;
        }

        CompiledInPartialName.prototype.evaluate = function (vm) {
            var symbol = this.symbol,
                name = this.name;

            var args = vm.scope().getPartialArgs(symbol);
            return args.named.get(name);
        };

        CompiledInPartialName.prototype.toJSON = function () {
            return '$' + this.symbol + '($ARGS).' + this.name;
        };

        return CompiledInPartialName;
    }(CompiledExpression);

    var CompiledHelper = function (_CompiledExpression8) {
        (0, _emberBabel.inherits)(CompiledHelper, _CompiledExpression8);

        function CompiledHelper(name, helper, args, symbolTable) {

            var _this38 = (0, _emberBabel.possibleConstructorReturn)(this, _CompiledExpression8.call(this));

            _this38.name = name;
            _this38.helper = helper;
            _this38.args = args;
            _this38.symbolTable = symbolTable;
            _this38.type = "helper";
            return _this38;
        }

        CompiledHelper.prototype.evaluate = function (vm) {
            var helper = this.helper;

            return helper(vm, this.args.evaluate(vm), this.symbolTable);
        };

        CompiledHelper.prototype.toJSON = function () {
            return '`' + this.name + '($ARGS)`';
        };

        return CompiledHelper;
    }(CompiledExpression);

    var CompiledConcat = function () {
        function CompiledConcat(parts) {

            this.parts = parts;
            this.type = "concat";
        }

        CompiledConcat.prototype.evaluate = function (vm) {
            var parts = new Array(this.parts.length),
                i;
            for (i = 0; i < this.parts.length; i++) {
                parts[i] = this.parts[i].evaluate(vm);
            }
            return new ConcatReference(parts);
        };

        CompiledConcat.prototype.toJSON = function () {
            return 'concat(' + this.parts.map(function (expr) {
                return expr.toJSON();
            }).join(", ") + ')';
        };

        return CompiledConcat;
    }();

    var ConcatReference = function (_CachedReference2) {
        (0, _emberBabel.inherits)(ConcatReference, _CachedReference2);

        function ConcatReference(parts) {

            var _this39 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference2.call(this));

            _this39.parts = parts;
            _this39.tag = (0, _reference2.combineTagged)(parts);
            return _this39;
        }

        ConcatReference.prototype.compute = function () {
            var parts = new Array(),
                i,
                value;
            for (i = 0; i < this.parts.length; i++) {
                value = this.parts[i].value();

                if (value !== null && value !== undefined) {
                    parts[i] = castToString(value);
                }
            }
            if (parts.length > 0) {
                return parts.join('');
            }
            return null;
        };

        return ConcatReference;
    }(_reference2.CachedReference);

    function castToString(value) {
        if (typeof value['toString'] !== 'function') {
            return '';
        }
        return String(value);
    }

    var CompiledFunctionExpression = function (_CompiledExpression9) {
        (0, _emberBabel.inherits)(CompiledFunctionExpression, _CompiledExpression9);

        function CompiledFunctionExpression(func, symbolTable) {

            var _this40 = (0, _emberBabel.possibleConstructorReturn)(this, _CompiledExpression9.call(this));

            _this40.func = func;
            _this40.symbolTable = symbolTable;
            _this40.type = "function";
            _this40.func = func;
            return _this40;
        }

        CompiledFunctionExpression.prototype.evaluate = function (vm) {
            var func = this.func,
                symbolTable = this.symbolTable;

            return func(vm, symbolTable);
        };

        CompiledFunctionExpression.prototype.toJSON = function () {
            var func = this.func;

            if (func.name) {
                return '`' + func.name + '(...)`';
            } else {
                return "`func(...)`";
            }
        };

        return CompiledFunctionExpression;
    }(CompiledExpression);

    var _BaselineSyntax$Neste = BaselineSyntax.NestedBlock,
        defaultBlock = _BaselineSyntax$Neste.defaultBlock,
        params = _BaselineSyntax$Neste.params,
        hash = _BaselineSyntax$Neste.hash;

    function debugCallback(context, get) {
        console.info('Use `context`, and `get(<path>)` to debug this template.');
        /* tslint:disable */
        debugger;
        /* tslint:enable */
        return { context: context, get: get };
    }
    function getter(vm, builder) {
        return function (path) {
            var parts = path.split('.');
            if (parts[0] === 'this') {
                parts[0] = null;
            }
            return compileRef(parts, builder).evaluate(vm);
        };
    }
    var callback = debugCallback;
    // For testing purposes


    var Compilers = function () {
        function Compilers() {

            this.names = (0, _util.dict)();
            this.funcs = [];
        }

        Compilers.prototype.add = function (name, func) {
            this.funcs.push(func);
            this.names[name] = this.funcs.length - 1;
        };

        Compilers.prototype.compile = function (sexp, builder) {
            var name = sexp[0];
            var index = this.names[name];
            var func = this.funcs[index];
            (0, _util.assert)(!!func, 'expected an implementation for ' + sexp[0]);
            return func(sexp, builder);
        };

        return Compilers;
    }();

    var Ops$1 = _wireFormat.Ops;

    var STATEMENTS = new Compilers();
    STATEMENTS.add(Ops$1.Text, function (sexp, builder) {
        builder.text(sexp[1]);
    });
    STATEMENTS.add(Ops$1.Comment, function (sexp, builder) {
        builder.comment(sexp[1]);
    });
    STATEMENTS.add(Ops$1.CloseElement, function (_sexp, builder) {
        _util.LOGGER.trace('close-element statement');
        builder.closeElement();
    });
    STATEMENTS.add(Ops$1.FlushElement, function (_sexp, builder) {
        builder.flushElement();
    });
    STATEMENTS.add(Ops$1.Modifier, function (sexp, builder) {
        var path = sexp[1],
            params = sexp[2],
            hash = sexp[3];

        var args = compileArgs(params, hash, builder);
        if (builder.env.hasModifier(path[0], builder.symbolTable)) {
            builder.modifier(path[0], args);
        } else {
            throw new Error('Compile Error ' + path.join('.') + ' is not a modifier: Helpers may not be used in the element form.');
        }
    });
    STATEMENTS.add(Ops$1.StaticAttr, function (sexp, builder) {
        var name = sexp[1],
            value = sexp[2],
            namespace = sexp[3];

        builder.staticAttr(name, namespace, value);
    });
    STATEMENTS.add(Ops$1.AnyDynamicAttr, function (sexp, builder) {
        var name = sexp[1],
            value = sexp[2],
            namespace = sexp[3],
            trusting = sexp[4];

        builder.putValue(value);
        if (namespace) {
            builder.dynamicAttrNS(name, namespace, trusting);
        } else {
            builder.dynamicAttr(name, trusting);
        }
    });
    STATEMENTS.add(Ops$1.OpenElement, function (sexp, builder) {
        _util.LOGGER.trace('open-element statement');
        builder.openPrimitiveElement(sexp[1]);
    });
    STATEMENTS.add(Ops$1.OptimizedAppend, function (sexp, builder) {
        var value = sexp[1],
            trustingMorph = sexp[2];

        var _builder$env$macros = builder.env.macros(),
            inlines = _builder$env$macros.inlines;

        var returned = inlines.compile(sexp, builder) || value;
        if (returned === true) return;
        builder.putValue(returned[1]);
        if (trustingMorph) {
            builder.trustingAppend();
        } else {
            builder.cautiousAppend();
        }
    });
    STATEMENTS.add(Ops$1.UnoptimizedAppend, function (sexp, builder) {
        var value = sexp[1],
            trustingMorph = sexp[2];

        var _builder$env$macros2 = builder.env.macros(),
            inlines = _builder$env$macros2.inlines;

        var returned = inlines.compile(sexp, builder) || value;
        if (returned === true) return;
        if (trustingMorph) {
            builder.guardedTrustingAppend(returned[1]);
        } else {
            builder.guardedCautiousAppend(returned[1]);
        }
    });
    STATEMENTS.add(Ops$1.NestedBlock, function (sexp, builder) {
        var _builder$env$macros3 = builder.env.macros(),
            blocks = _builder$env$macros3.blocks;

        blocks.compile(sexp, builder);
    });
    STATEMENTS.add(Ops$1.ScannedBlock, function (sexp, builder) {
        var path = sexp[1],
            params = sexp[2],
            hash = sexp[3],
            template = sexp[4],
            inverse = sexp[5];

        var templateBlock = template && template.scan();
        var inverseBlock = inverse && inverse.scan();

        var _builder$env$macros4 = builder.env.macros(),
            blocks = _builder$env$macros4.blocks;

        blocks.compile([Ops$1.NestedBlock, path, params, hash, templateBlock, inverseBlock], builder);
    });
    STATEMENTS.add(Ops$1.ScannedComponent, function (sexp, builder) {
        var tag = sexp[1],
            attrs = sexp[2],
            rawArgs = sexp[3],
            rawBlock = sexp[4];

        var block = rawBlock && rawBlock.scan();
        var args = compileBlockArgs(null, rawArgs, { default: block, inverse: null }, builder);
        var definition = builder.env.getComponentDefinition(tag, builder.symbolTable);
        builder.putComponentDefinition(definition);
        builder.openComponent(args, attrs.scan());
        builder.closeComponent();
    });
    STATEMENTS.add(Ops$1.StaticPartial, function (sexp, builder) {
        var name = sexp[1];

        if (!builder.env.hasPartial(name, builder.symbolTable)) {
            throw new Error('Compile Error: Could not find a partial named "' + name + '"');
        }
        var definition = builder.env.lookupPartial(name, builder.symbolTable);
        builder.putPartialDefinition(definition);
        builder.evaluatePartial();
    });
    STATEMENTS.add(Ops$1.DynamicPartial, function (sexp, builder) {
        var name = sexp[1];

        builder.startLabels();
        builder.putValue(name);
        builder.test('simple');
        builder.enter('BEGIN', 'END');
        builder.label('BEGIN');
        builder.jumpUnless('END');
        builder.putDynamicPartialDefinition();
        builder.evaluatePartial();
        builder.label('END');
        builder.exit();
        builder.stopLabels();
    });
    STATEMENTS.add(Ops$1.Yield, function (sexp, builder) {
        var to = sexp[1],
            params = sexp[2];

        var args = compileArgs(params, null, builder);
        builder.yield(args, to);
    });
    STATEMENTS.add(Ops$1.Debugger, function (sexp, builder) {
        builder.putValue([Ops$1.Function, function (vm) {
            var context = vm.getSelf().value();

            callback(context, function (path) {
                return getter(vm, builder)(path).value();
            });
        }]);
        return sexp;
    });
    var EXPRESSIONS = new Compilers();
    function expr(expression, builder) {
        if (Array.isArray(expression)) {
            return EXPRESSIONS.compile(expression, builder);
        } else {
            return new CompiledValue(expression);
        }
    }
    EXPRESSIONS.add(Ops$1.Unknown, function (sexp, builder) {
        var path = sexp[1];
        var name = path[0];
        if (builder.env.hasHelper(name, builder.symbolTable)) {
            return new CompiledHelper(name, builder.env.lookupHelper(name, builder.symbolTable), CompiledArgs.empty(), builder.symbolTable);
        } else {
            return compileRef(path, builder);
        }
    });
    EXPRESSIONS.add(Ops$1.Concat, function (sexp, builder) {
        var params = sexp[1].map(function (p) {
            return expr(p, builder);
        });
        return new CompiledConcat(params);
    });
    EXPRESSIONS.add(Ops$1.Function, function (sexp, builder) {
        return new CompiledFunctionExpression(sexp[1], builder.symbolTable);
    });
    EXPRESSIONS.add(Ops$1.Helper, function (sexp, builder) {
        var env = builder.env,
            symbolTable = builder.symbolTable,
            args;
        var _sexp$ = sexp[1],
            name = _sexp$[0],
            params = sexp[2],
            hash = sexp[3];

        if (env.hasHelper(name, symbolTable)) {
            args = compileArgs(params, hash, builder);

            return new CompiledHelper(name, env.lookupHelper(name, symbolTable), args, symbolTable);
        } else {
            throw new Error('Compile Error: ' + name + ' is not a helper');
        }
    });
    EXPRESSIONS.add(Ops$1.Get, function (sexp, builder) {
        return compileRef(sexp[1], builder);
    });
    EXPRESSIONS.add(Ops$1.Undefined, function () {
        return new CompiledValue(undefined);
    });
    EXPRESSIONS.add(Ops$1.Arg, function (sexp, builder) {
        var parts = sexp[1],
            path,
            inner,
            _path,
            _inner2;

        var head = parts[0];
        var named = void 0,
            partial = void 0;
        if (named = builder.symbolTable.getSymbol('named', head)) {
            path = parts.slice(1);
            inner = new CompiledSymbol(named, head);

            return CompiledLookup.create(inner, path);
        } else if (partial = builder.symbolTable.getPartialArgs()) {
            _path = parts.slice(1);
            _inner2 = new CompiledInPartialName(partial, head);

            return CompiledLookup.create(_inner2, _path);
        } else {
            throw new Error('[BUG] @' + parts.join('.') + ' is not a valid lookup path.');
        }
    });
    EXPRESSIONS.add(Ops$1.HasBlock, function (sexp, builder) {
        var blockName = sexp[1],
            inner,
            _inner3;
        var yields = void 0,
            partial = void 0;
        if (yields = builder.symbolTable.getSymbol('yields', blockName)) {
            inner = new CompiledGetBlockBySymbol(yields, blockName);

            return new CompiledHasBlock(inner);
        } else if (partial = builder.symbolTable.getPartialArgs()) {
            _inner3 = new CompiledInPartialGetBlock(partial, blockName);

            return new CompiledHasBlock(_inner3);
        } else {
            throw new Error('[BUG] ${blockName} is not a valid block name.');
        }
    });
    EXPRESSIONS.add(Ops$1.HasBlockParams, function (sexp, builder) {
        var blockName = sexp[1],
            inner,
            _inner4;
        var yields = void 0,
            partial = void 0;
        if (yields = builder.symbolTable.getSymbol('yields', blockName)) {
            inner = new CompiledGetBlockBySymbol(yields, blockName);

            return new CompiledHasBlockParams(inner);
        } else if (partial = builder.symbolTable.getPartialArgs()) {
            _inner4 = new CompiledInPartialGetBlock(partial, blockName);

            return new CompiledHasBlockParams(_inner4);
        } else {
            throw new Error('[BUG] ${blockName} is not a valid block name.');
        }
    });
    function compileArgs(params, hash, builder) {
        var compiledParams = compileParams(params, builder);
        var compiledHash = compileHash(hash, builder);
        return CompiledArgs.create(compiledParams, compiledHash, EMPTY_BLOCKS);
    }
    function compileBlockArgs(params, hash, blocks, builder) {
        var compiledParams = compileParams(params, builder);
        var compiledHash = compileHash(hash, builder);
        return CompiledArgs.create(compiledParams, compiledHash, blocks);
    }
    function compileBaselineArgs(args, builder) {
        var params = args[0],
            hash = args[1],
            _default = args[2],
            inverse = args[3];

        return CompiledArgs.create(compileParams(params, builder), compileHash(hash, builder), { default: _default, inverse: inverse });
    }
    function compileParams(params, builder) {
        if (!params || params.length === 0) return COMPILED_EMPTY_POSITIONAL_ARGS;
        var compiled = new Array(params.length),
            i;
        for (i = 0; i < params.length; i++) {
            compiled[i] = expr(params[i], builder);
        }
        return CompiledPositionalArgs.create(compiled);
    }
    function compileHash(hash, builder) {
        if (!hash) return COMPILED_EMPTY_NAMED_ARGS;
        var keys = hash[0],
            values = hash[1],
            i;

        if (keys.length === 0) return COMPILED_EMPTY_NAMED_ARGS;
        var compiled = new Array(values.length);
        for (i = 0; i < values.length; i++) {
            compiled[i] = expr(values[i], builder);
        }
        return new CompiledNamedArgs(keys, compiled);
    }
    function compileRef(parts, builder) {
        var head = parts[0],
            inner,
            path,
            _path2,
            _inner5,
            _inner6;
        var local = void 0;
        if (head === null) {
            inner = new CompiledSelf();
            path = parts.slice(1);

            return CompiledLookup.create(inner, path);
        } else if (local = builder.symbolTable.getSymbol('local', head)) {
            _path2 = parts.slice(1);
            _inner5 = new CompiledSymbol(local, head);

            return CompiledLookup.create(_inner5, _path2);
        } else {
            _inner6 = new CompiledSelf();

            return CompiledLookup.create(_inner6, parts);
        }
    }

    var Blocks = function () {
        function Blocks() {

            this.names = (0, _util.dict)();
            this.funcs = [];
        }

        Blocks.prototype.add = function (name, func) {
            this.funcs.push(func);
            this.names[name] = this.funcs.length - 1;
        };

        Blocks.prototype.addMissing = function (func) {
            this.missing = func;
        };

        Blocks.prototype.compile = function (sexp, builder) {
            // assert(sexp[1].length === 1, 'paths in blocks are not supported');
            var name = sexp[1][0],
                func,
                handled,
                _func2;
            var index = this.names[name];
            if (index === undefined) {
                (0, _util.assert)(!!this.missing, name + ' not found, and no catch-all block handler was registered');
                func = this.missing;
                handled = func(sexp, builder);

                (0, _util.assert)(!!handled, name + ' not found, and the catch-all block handler didn\'t handle it');
            } else {
                _func2 = this.funcs[index];

                _func2(sexp, builder);
            }
        };

        return Blocks;
    }();

    var BLOCKS = new Blocks();

    var Inlines = function () {
        function Inlines() {

            this.names = (0, _util.dict)();
            this.funcs = [];
        }

        Inlines.prototype.add = function (name, func) {
            this.funcs.push(func);
            this.names[name] = this.funcs.length - 1;
        };

        Inlines.prototype.addMissing = function (func) {
            this.missing = func;
        };

        Inlines.prototype.compile = function (sexp, builder) {
            var value = sexp[1],
                func,
                returned,
                _func3,
                _returned;
            // TODO: Fix this so that expression macros can return
            // things like components, so that {{component foo}}
            // is the same as {{(component foo)}}
            if (!Array.isArray(value)) return ['expr', value];
            var path = void 0;
            var params = void 0;
            var hash = void 0;
            if (value[0] === Ops$1.Helper) {
                path = value[1];
                params = value[2];
                hash = value[3];
            } else if (value[0] === Ops$1.Unknown) {
                path = value[1];
                params = hash = null;
            } else {
                return ['expr', value];
            }
            if (path.length > 1 && !params && !hash) {
                return ['expr', value];
            }
            var name = path[0];
            var index = this.names[name];
            if (index === undefined && this.missing) {
                func = this.missing;
                returned = func(path, params, hash, builder);

                return returned === false ? ['expr', value] : returned;
            } else if (index !== undefined) {
                _func3 = this.funcs[index];
                _returned = _func3(path, params, hash, builder);

                return _returned === false ? ['expr', value] : _returned;
            } else {
                return ['expr', value];
            }
        };

        return Inlines;
    }();

    var INLINES = new Inlines();
    populateBuiltins(BLOCKS, INLINES);
    function populateBuiltins() {
        var blocks = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Blocks();
        var inlines = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Inlines();

        blocks.add('if', function (sexp, builder) {
            var params = sexp[2],
                hash = sexp[3],
                _default = sexp[4],
                inverse = sexp[5];

            var args = compileArgs(params, hash, builder);
            builder.putArgs(args);
            builder.test('environment');
            builder.labelled(null, function (b) {
                if (_default && inverse) {
                    b.jumpUnless('ELSE');
                    b.evaluate(_default);
                    b.jump('END');
                    b.label('ELSE');
                    b.evaluate(inverse);
                } else if (_default) {
                    b.jumpUnless('END');
                    b.evaluate(_default);
                } else {
                    throw (0, _util.unreachable)();
                }
            });
        });
        blocks.add('-in-element', function (sexp, builder) {
            var block = defaultBlock(sexp);
            var args = compileArgs(params(sexp), null, builder);
            builder.putArgs(args);
            builder.test('simple');
            builder.labelled(null, function (b) {
                b.jumpUnless('END');
                b.pushRemoteElement();
                b.evaluate((0, _util.unwrap)(block));
                b.popRemoteElement();
            });
        });
        blocks.add('-with-dynamic-vars', function (sexp, builder) {
            var block = defaultBlock(sexp);
            var args = compileArgs(params(sexp), hash(sexp), builder);
            builder.unit(function (b) {
                b.putArgs(args);
                b.pushDynamicScope();
                b.bindDynamicScope(args.named.keys);
                b.evaluate((0, _util.unwrap)(block));
                b.popDynamicScope();
            });
        });
        blocks.add('unless', function (sexp, builder) {
            var params = sexp[2],
                hash = sexp[3],
                _default = sexp[4],
                inverse = sexp[5];

            var args = compileArgs(params, hash, builder);
            builder.putArgs(args);
            builder.test('environment');
            builder.labelled(null, function (b) {
                if (_default && inverse) {
                    b.jumpIf('ELSE');
                    b.evaluate(_default);
                    b.jump('END');
                    b.label('ELSE');
                    b.evaluate(inverse);
                } else if (_default) {
                    b.jumpIf('END');
                    b.evaluate(_default);
                } else {
                    throw (0, _util.unreachable)();
                }
            });
        });
        blocks.add('with', function (sexp, builder) {
            var params = sexp[2],
                hash = sexp[3],
                _default = sexp[4],
                inverse = sexp[5];

            var args = compileArgs(params, hash, builder);
            builder.putArgs(args);
            builder.test('environment');
            builder.labelled(null, function (b) {
                if (_default && inverse) {
                    b.jumpUnless('ELSE');
                    b.evaluate(_default);
                    b.jump('END');
                    b.label('ELSE');
                    b.evaluate(inverse);
                } else if (_default) {
                    b.jumpUnless('END');
                    b.evaluate(_default);
                } else {
                    throw (0, _util.unreachable)();
                }
            });
        });
        blocks.add('each', function (sexp, builder) {
            var params = sexp[2],
                hash = sexp[3],
                _default = sexp[4],
                inverse = sexp[5];

            var args = compileArgs(params, hash, builder);
            builder.labelled(args, function (b) {
                b.putIterator();
                if (inverse) {
                    b.jumpUnless('ELSE');
                } else {
                    b.jumpUnless('END');
                }
                b.iter(function (b) {
                    b.evaluate((0, _util.unwrap)(_default));
                });
                if (inverse) {
                    b.jump('END');
                    b.label('ELSE');
                    b.evaluate(inverse);
                }
            });
        });
        return { blocks: blocks, inlines: inlines };
    }

    var badProtocols = ['javascript:', 'vbscript:'];
    var badTags = ['A', 'BODY', 'LINK', 'IMG', 'IFRAME', 'BASE', 'FORM'];
    var badTagsForDataURI = ['EMBED'];
    var badAttributes = ['href', 'src', 'background', 'action'];
    var badAttributesForDataURI = ['src'];
    function has(array, item) {
        return array.indexOf(item) !== -1;
    }
    function checkURI(tagName, attribute) {
        return (tagName === null || has(badTags, tagName)) && has(badAttributes, attribute);
    }
    function checkDataURI(tagName, attribute) {
        if (tagName === null) return false;
        return has(badTagsForDataURI, tagName) && has(badAttributesForDataURI, attribute);
    }
    function requiresSanitization(tagName, attribute) {
        return checkURI(tagName, attribute) || checkDataURI(tagName, attribute);
    }
    function sanitizeAttributeValue(env, element, attribute, value) {
        var tagName = null,
            protocol;
        if (value === null || value === undefined) {
            return value;
        }
        if (isSafeString(value)) {
            return value.toHTML();
        }
        if (!element) {
            tagName = null;
        } else {
            tagName = element.tagName.toUpperCase();
        }
        var str = normalizeTextValue(value);
        if (checkURI(tagName, attribute)) {
            protocol = env.protocolForURL(str);

            if (has(badProtocols, protocol)) {
                return 'unsafe:' + str;
            }
        }
        if (checkDataURI(tagName, attribute)) {
            return 'unsafe:' + str;
        }
        return str;
    }

    /*
     * @method normalizeProperty
     * @param element {HTMLElement}
     * @param slotName {String}
     * @returns {Object} { name, type }
     */
    function normalizeProperty(element, slotName) {
        var type = void 0,
            normalized = void 0,
            lower;
        if (slotName in element) {
            normalized = slotName;
            type = 'prop';
        } else {
            lower = slotName.toLowerCase();

            if (lower in element) {
                type = 'prop';
                normalized = lower;
            } else {
                type = 'attr';
                normalized = slotName;
            }
        }
        if (type === 'prop' && (normalized.toLowerCase() === 'style' || preferAttr(element.tagName, normalized))) {
            type = 'attr';
        }
        return { normalized: normalized, type: type };
    }

    // properties that MUST be set as attributes, due to:
    // * browser bug
    // * strange spec outlier
    var ATTR_OVERRIDES = {
        // phantomjs < 2.0 lets you set it as a prop but won't reflect it
        // back to the attribute. button.getAttribute('type') === null
        BUTTON: { type: true, form: true },
        INPUT: {
            // Some version of IE (like IE9) actually throw an exception
            // if you set input.type = 'something-unknown'
            type: true,
            form: true,
            // Chrome 46.0.2464.0: 'autocorrect' in document.createElement('input') === false
            // Safari 8.0.7: 'autocorrect' in document.createElement('input') === false
            // Mobile Safari (iOS 8.4 simulator): 'autocorrect' in document.createElement('input') === true
            autocorrect: true,
            // Chrome 54.0.2840.98: 'list' in document.createElement('input') === true
            // Safari 9.1.3: 'list' in document.createElement('input') === false
            list: true
        },
        // element.form is actually a legitimate readOnly property, that is to be
        // mutated, but must be mutated by setAttribute...
        SELECT: { form: true },
        OPTION: { form: true },
        TEXTAREA: { form: true },
        LABEL: { form: true },
        FIELDSET: { form: true },
        LEGEND: { form: true },
        OBJECT: { form: true }
    };
    function preferAttr(tagName, propName) {
        var tag = ATTR_OVERRIDES[tagName.toUpperCase()];
        return tag && tag[propName.toLowerCase()] || false;
    }

    var innerHTMLWrapper = {
        colgroup: { depth: 2, before: '<table><colgroup>', after: '</colgroup></table>' },
        table: { depth: 1, before: '<table>', after: '</table>' },
        tbody: { depth: 2, before: '<table><tbody>', after: '</tbody></table>' },
        tfoot: { depth: 2, before: '<table><tfoot>', after: '</tfoot></table>' },
        thead: { depth: 2, before: '<table><thead>', after: '</thead></table>' },
        tr: { depth: 3, before: '<table><tbody><tr>', after: '</tr></tbody></table>' }
    };
    // Patch:    innerHTML Fix
    // Browsers: IE9
    // Reason:   IE9 don't allow us to set innerHTML on col, colgroup, frameset,
    //           html, style, table, tbody, tfoot, thead, title, tr.
    // Fix:      Wrap the innerHTML we are about to set in its parents, apply the
    //           wrapped innerHTML on a div, then move the unwrapped nodes into the
    //           target position.

    function treeConstruction(document, DOMTreeConstructionClass) {
        if (!document) return DOMTreeConstructionClass;
        if (!shouldApplyFix(document)) {
            return DOMTreeConstructionClass;
        }
        var div = document.createElement('div');
        return function (_DOMTreeConstructionC) {
            (0, _emberBabel.inherits)(DOMTreeConstructionWithInnerHTMLFix, _DOMTreeConstructionC);

            function DOMTreeConstructionWithInnerHTMLFix() {
                return (0, _emberBabel.possibleConstructorReturn)(this, _DOMTreeConstructionC.apply(this, arguments));
            }

            DOMTreeConstructionWithInnerHTMLFix.prototype.insertHTMLBefore = function (parent, html, reference) {
                if (html === null || html === '') {
                    return _DOMTreeConstructionC.prototype.insertHTMLBefore.call(this, parent, html, reference);
                }
                var parentTag = parent.tagName.toLowerCase();
                var wrapper = innerHTMLWrapper[parentTag];
                if (wrapper === undefined) {
                    return _DOMTreeConstructionC.prototype.insertHTMLBefore.call(this, parent, html, reference);
                }
                return fixInnerHTML(parent, wrapper, div, html, reference);
            };

            return DOMTreeConstructionWithInnerHTMLFix;
        }(DOMTreeConstructionClass);
    }
    function fixInnerHTML(parent, wrapper, div, html, reference) {
        var wrappedHtml = wrapper.before + html + wrapper.after,
            i;
        div.innerHTML = wrappedHtml;
        var parentNode = div;
        for (i = 0; i < wrapper.depth; i++) {
            parentNode = parentNode.childNodes[0];
        }

        var _moveNodesBefore = moveNodesBefore(parentNode, parent, reference),
            first = _moveNodesBefore[0],
            last = _moveNodesBefore[1];

        return new ConcreteBounds(parent, first, last);
    }
    function shouldApplyFix(document) {
        var table = document.createElement('table');
        try {
            table.innerHTML = '<tbody></tbody>';
        } catch (e) {} finally {
            if (table.childNodes.length !== 0) {
                // It worked as expected, no fix required
                return false;
            }
        }
        return true;
    }

    // Patch:    insertAdjacentHTML on SVG Fix
    // Browsers: Safari, IE, Edge, Firefox ~33-34
    // Reason:   insertAdjacentHTML does not exist on SVG elements in Safari. It is
    //           present but throws an exception on IE and Edge. Old versions of
    //           Firefox create nodes in the incorrect namespace.
    // Fix:      Since IE and Edge silently fail to create SVG nodes using
    //           innerHTML, and because Firefox may create nodes in the incorrect
    //           namespace using innerHTML on SVG elements, an HTML-string wrapping
    //           approach is used. A pre/post SVG tag is added to the string, then
    //           that whole string is added to a div. The created nodes are plucked
    //           out and applied to the target location on DOM.
    function treeConstruction$1(document, TreeConstructionClass, svgNamespace) {
        if (!document) return TreeConstructionClass;
        if (!shouldApplyFix$1(document, svgNamespace)) {
            return TreeConstructionClass;
        }
        var div = document.createElement('div');
        return function (_TreeConstructionClas) {
            (0, _emberBabel.inherits)(TreeConstructionWithSVGInnerHTMLFix, _TreeConstructionClas);

            function TreeConstructionWithSVGInnerHTMLFix() {
                return (0, _emberBabel.possibleConstructorReturn)(this, _TreeConstructionClas.apply(this, arguments));
            }

            TreeConstructionWithSVGInnerHTMLFix.prototype.insertHTMLBefore = function (parent, html, reference) {
                if (html === null || html === '') {
                    return _TreeConstructionClas.prototype.insertHTMLBefore.call(this, parent, html, reference);
                }
                if (parent.namespaceURI !== svgNamespace) {
                    return _TreeConstructionClas.prototype.insertHTMLBefore.call(this, parent, html, reference);
                }
                return fixSVG(parent, div, html, reference);
            };

            return TreeConstructionWithSVGInnerHTMLFix;
        }(TreeConstructionClass);
    }
    function fixSVG(parent, div, html, reference) {
        div.innerHTML = '<svg>' + html + '</svg>';
        // IE, Edge: also do not correctly support using `innerHTML` on SVG
        // namespaced elements. So here a wrapper is used.


        var _moveNodesBefore2 = moveNodesBefore(div.firstChild, parent, reference),
            first = _moveNodesBefore2[0],
            last = _moveNodesBefore2[1];

        return new ConcreteBounds(parent, first, last);
    }
    function shouldApplyFix$1(document, svgNamespace) {
        var svg = document.createElementNS(svgNamespace, 'svg');
        try {
            svg['insertAdjacentHTML']('beforeEnd', '<circle></circle>');
        } catch (e) {
            // IE, Edge: Will throw, insertAdjacentHTML is unsupported on SVG
            // Safari: Will throw, insertAdjacentHTML is not present on SVG
        } finally {
            // FF: Old versions will create a node in the wrong namespace
            if (svg.childNodes.length === 1 && (0, _util.unwrap)(svg.firstChild).namespaceURI === 'http://www.w3.org/2000/svg') {
                // The test worked as expected, no fix required
                return false;
            }
            return true;
        }
    }

    // Patch:    Adjacent text node merging fix
    // Browsers: IE, Edge, Firefox w/o inspector open
    // Reason:   These browsers will merge adjacent text nodes. For exmaple given
    //           <div>Hello</div> with div.insertAdjacentHTML(' world') browsers
    //           with proper behavior will populate div.childNodes with two items.
    //           These browsers will populate it with one merged node instead.
    // Fix:      Add these nodes to a wrapper element, then iterate the childNodes
    //           of that wrapper and move the nodes to their target location. Note
    //           that potential SVG bugs will have been handled before this fix.
    //           Note that this fix must only apply to the previous text node, as
    //           the base implementation of `insertHTMLBefore` already handles
    //           following text nodes correctly.

    function treeConstruction$2(document, TreeConstructionClass) {
        if (!document) return TreeConstructionClass;
        if (!shouldApplyFix$2(document)) {
            return TreeConstructionClass;
        }
        return function (_TreeConstructionClas2) {
            (0, _emberBabel.inherits)(TreeConstructionWithTextNodeMergingFix, _TreeConstructionClas2);

            function TreeConstructionWithTextNodeMergingFix(document) {

                var _this46 = (0, _emberBabel.possibleConstructorReturn)(this, _TreeConstructionClas2.call(this, document));

                _this46.uselessComment = _this46.createComment('');
                return _this46;
            }

            TreeConstructionWithTextNodeMergingFix.prototype.insertHTMLBefore = function (parent, html, reference) {
                if (html === null) {
                    return _TreeConstructionClas2.prototype.insertHTMLBefore.call(this, parent, html, reference);
                }
                var didSetUselessComment = false;
                var nextPrevious = reference ? reference.previousSibling : parent.lastChild;
                if (nextPrevious && nextPrevious instanceof Text) {
                    didSetUselessComment = true;
                    parent.insertBefore(this.uselessComment, reference);
                }
                var bounds = _TreeConstructionClas2.prototype.insertHTMLBefore.call(this, parent, html, reference);
                if (didSetUselessComment) {
                    parent.removeChild(this.uselessComment);
                }
                return bounds;
            };

            return TreeConstructionWithTextNodeMergingFix;
        }(TreeConstructionClass);
    }
    function shouldApplyFix$2(document) {
        var mergingTextDiv = document.createElement('div');
        mergingTextDiv.innerHTML = 'first';
        mergingTextDiv.insertAdjacentHTML('beforeEnd', 'second');
        if (mergingTextDiv.childNodes.length === 2) {
            // It worked as expected, no fix required
            return false;
        }
        return true;
    }

    var SVG_NAMESPACE$$1 = 'http://www.w3.org/2000/svg';
    // http://www.w3.org/TR/html/syntax.html#html-integration-point
    var SVG_INTEGRATION_POINTS = { foreignObject: 1, desc: 1, title: 1 };
    // http://www.w3.org/TR/html/syntax.html#adjust-svg-attributes
    // TODO: Adjust SVG attributes
    // http://www.w3.org/TR/html/syntax.html#parsing-main-inforeign
    // TODO: Adjust SVG elements
    // http://www.w3.org/TR/html/syntax.html#parsing-main-inforeign
    var BLACKLIST_TABLE = Object.create(null);
    ["b", "big", "blockquote", "body", "br", "center", "code", "dd", "div", "dl", "dt", "em", "embed", "h1", "h2", "h3", "h4", "h5", "h6", "head", "hr", "i", "img", "li", "listing", "main", "meta", "nobr", "ol", "p", "pre", "ruby", "s", "small", "span", "strong", "strike", "sub", "sup", "table", "tt", "u", "ul", "var"].forEach(function (tag) {
        return BLACKLIST_TABLE[tag] = 1;
    });
    var WHITESPACE = /[\t-\r \xA0\u1680\u180E\u2000-\u200A\u2028\u2029\u202F\u205F\u3000\uFEFF]/;
    var doc = typeof document === 'undefined' ? null : document;

    function moveNodesBefore(source, target, nextSibling) {
        var first = source.firstChild;
        var last = null;
        var current = first;
        while (current) {
            last = current;
            current = current.nextSibling;
            target.insertBefore(last, nextSibling);
        }
        return [first, last];
    }
    var DOM;
    (function (DOM) {
        var TreeConstruction = function () {
            function TreeConstruction(document) {

                this.document = document;
                this.setupUselessElement();
            }

            TreeConstruction.prototype.setupUselessElement = function () {
                this.uselessElement = this.document.createElement('div');
            };

            TreeConstruction.prototype.createElement = function (tag, context) {
                var isElementInSVGNamespace = void 0,
                    isHTMLIntegrationPoint = void 0;
                if (context) {
                    isElementInSVGNamespace = context.namespaceURI === SVG_NAMESPACE$$1 || tag === 'svg';
                    isHTMLIntegrationPoint = SVG_INTEGRATION_POINTS[context.tagName];
                } else {
                    isElementInSVGNamespace = tag === 'svg';
                    isHTMLIntegrationPoint = false;
                }
                if (isElementInSVGNamespace && !isHTMLIntegrationPoint) {
                    // FIXME: This does not properly handle <font> with color, face, or
                    // size attributes, which is also disallowed by the spec. We should fix
                    // this.
                    if (BLACKLIST_TABLE[tag]) {
                        throw new Error('Cannot create a ' + tag + ' inside an SVG context');
                    }
                    return this.document.createElementNS(SVG_NAMESPACE$$1, tag);
                } else {
                    return this.document.createElement(tag);
                }
            };

            TreeConstruction.prototype.createElementNS = function (namespace, tag) {
                return this.document.createElementNS(namespace, tag);
            };

            TreeConstruction.prototype.setAttribute = function (element, name, value, namespace) {
                if (namespace) {
                    element.setAttributeNS(namespace, name, value);
                } else {
                    element.setAttribute(name, value);
                }
            };

            TreeConstruction.prototype.createTextNode = function (text) {
                return this.document.createTextNode(text);
            };

            TreeConstruction.prototype.createComment = function (data) {
                return this.document.createComment(data);
            };

            TreeConstruction.prototype.insertBefore = function (parent, node, reference) {
                parent.insertBefore(node, reference);
            };

            TreeConstruction.prototype.insertHTMLBefore = function (parent, html, reference) {
                return _insertHTMLBefore(this.uselessElement, parent, reference, html);
            };

            return TreeConstruction;
        }();

        DOM.TreeConstruction = TreeConstruction;
        var appliedTreeContruction = TreeConstruction;
        appliedTreeContruction = treeConstruction$2(doc, appliedTreeContruction);
        appliedTreeContruction = treeConstruction(doc, appliedTreeContruction);
        appliedTreeContruction = treeConstruction$1(doc, appliedTreeContruction, SVG_NAMESPACE$$1);
        DOM.DOMTreeConstruction = appliedTreeContruction;
    })(DOM || (DOM = {}));

    var DOMChanges = function () {
        function DOMChanges(document) {

            this.document = document;
            this.namespace = null;
            this.uselessElement = this.document.createElement('div');
        }

        DOMChanges.prototype.setAttribute = function (element, name, value) {
            element.setAttribute(name, value);
        };

        DOMChanges.prototype.setAttributeNS = function (element, namespace, name, value) {
            element.setAttributeNS(namespace, name, value);
        };

        DOMChanges.prototype.removeAttribute = function (element, name) {
            element.removeAttribute(name);
        };

        DOMChanges.prototype.removeAttributeNS = function (element, namespace, name) {
            element.removeAttributeNS(namespace, name);
        };

        DOMChanges.prototype.createTextNode = function (text) {
            return this.document.createTextNode(text);
        };

        DOMChanges.prototype.createComment = function (data) {
            return this.document.createComment(data);
        };

        DOMChanges.prototype.createElement = function (tag, context) {
            var isElementInSVGNamespace = void 0,
                isHTMLIntegrationPoint = void 0;
            if (context) {
                isElementInSVGNamespace = context.namespaceURI === SVG_NAMESPACE$$1 || tag === 'svg';
                isHTMLIntegrationPoint = SVG_INTEGRATION_POINTS[context.tagName];
            } else {
                isElementInSVGNamespace = tag === 'svg';
                isHTMLIntegrationPoint = false;
            }
            if (isElementInSVGNamespace && !isHTMLIntegrationPoint) {
                // FIXME: This does not properly handle <font> with color, face, or
                // size attributes, which is also disallowed by the spec. We should fix
                // this.
                if (BLACKLIST_TABLE[tag]) {
                    throw new Error('Cannot create a ' + tag + ' inside an SVG context');
                }
                return this.document.createElementNS(SVG_NAMESPACE$$1, tag);
            } else {
                return this.document.createElement(tag);
            }
        };

        DOMChanges.prototype.insertHTMLBefore = function (_parent, nextSibling, html) {
            return _insertHTMLBefore(this.uselessElement, _parent, nextSibling, html);
        };

        DOMChanges.prototype.insertNodeBefore = function (parent, node, reference) {
            var firstChild, lastChild;

            if (isDocumentFragment(node)) {
                firstChild = node.firstChild, lastChild = node.lastChild;


                this.insertBefore(parent, node, reference);
                return new ConcreteBounds(parent, firstChild, lastChild);
            } else {
                this.insertBefore(parent, node, reference);
                return new SingleNodeBounds(parent, node);
            }
        };

        DOMChanges.prototype.insertTextBefore = function (parent, nextSibling, text) {
            var textNode = this.createTextNode(text);
            this.insertBefore(parent, textNode, nextSibling);
            return textNode;
        };

        DOMChanges.prototype.insertBefore = function (element, node, reference) {
            element.insertBefore(node, reference);
        };

        DOMChanges.prototype.insertAfter = function (element, node, reference) {
            this.insertBefore(element, node, reference.nextSibling);
        };

        return DOMChanges;
    }();

    function _insertHTMLBefore(_useless, _parent, _nextSibling, html) {
        // TypeScript vendored an old version of the DOM spec where `insertAdjacentHTML`
        // only exists on `HTMLElement` but not on `Element`. We actually work with the
        // newer version of the DOM API here (and monkey-patch this method in `./compat`
        // when we detect older browsers). This is a hack to work around this limitation.
        var parent = _parent;
        var useless = _useless;
        var nextSibling = _nextSibling;
        var prev = nextSibling ? nextSibling.previousSibling : parent.lastChild;
        var last = void 0;
        if (html === null || html === '') {
            return new ConcreteBounds(parent, null, null);
        }
        if (nextSibling === null) {
            parent.insertAdjacentHTML('beforeEnd', html);
            last = parent.lastChild;
        } else if (nextSibling instanceof HTMLElement) {
            nextSibling.insertAdjacentHTML('beforeBegin', html);
            last = nextSibling.previousSibling;
        } else {
            // Non-element nodes do not support insertAdjacentHTML, so add an
            // element and call it on that element. Then remove the element.
            //
            // This also protects Edge, IE and Firefox w/o the inspector open
            // from merging adjacent text nodes. See ./compat/text-node-merging-fix.ts
            parent.insertBefore(useless, nextSibling);
            useless.insertAdjacentHTML('beforeBegin', html);
            last = useless.previousSibling;
            parent.removeChild(useless);
        }
        var first = prev ? prev.nextSibling : parent.firstChild;
        return new ConcreteBounds(parent, first, last);
    }
    function isDocumentFragment(node) {
        return node.nodeType === Node.DOCUMENT_FRAGMENT_NODE;
    }
    var helper = DOMChanges;
    helper = function (document, DOMChangesClass) {
        if (!document) return DOMChangesClass;
        if (!shouldApplyFix$2(document)) {
            return DOMChangesClass;
        }
        return function (_DOMChangesClass3) {
            (0, _emberBabel.inherits)(DOMChangesWithTextNodeMergingFix, _DOMChangesClass3);

            function DOMChangesWithTextNodeMergingFix(document) {

                var _this45 = (0, _emberBabel.possibleConstructorReturn)(this, _DOMChangesClass3.call(this, document));

                _this45.uselessComment = document.createComment('');
                return _this45;
            }

            DOMChangesWithTextNodeMergingFix.prototype.insertHTMLBefore = function (parent, nextSibling, html) {
                if (html === null) {
                    return _DOMChangesClass3.prototype.insertHTMLBefore.call(this, parent, nextSibling, html);
                }
                var didSetUselessComment = false;
                var nextPrevious = nextSibling ? nextSibling.previousSibling : parent.lastChild;
                if (nextPrevious && nextPrevious instanceof Text) {
                    didSetUselessComment = true;
                    parent.insertBefore(this.uselessComment, nextSibling);
                }
                var bounds = _DOMChangesClass3.prototype.insertHTMLBefore.call(this, parent, nextSibling, html);
                if (didSetUselessComment) {
                    parent.removeChild(this.uselessComment);
                }
                return bounds;
            };

            return DOMChangesWithTextNodeMergingFix;
        }(DOMChangesClass);
    }(doc, helper);
    helper = function (document, DOMChangesClass) {
        if (!document) return DOMChangesClass;
        if (!shouldApplyFix(document)) {
            return DOMChangesClass;
        }
        var div = document.createElement('div');
        return function (_DOMChangesClass) {
            (0, _emberBabel.inherits)(DOMChangesWithInnerHTMLFix, _DOMChangesClass);

            function DOMChangesWithInnerHTMLFix() {
                return (0, _emberBabel.possibleConstructorReturn)(this, _DOMChangesClass.apply(this, arguments));
            }

            DOMChangesWithInnerHTMLFix.prototype.insertHTMLBefore = function (parent, nextSibling, html) {
                if (html === null || html === '') {
                    return _DOMChangesClass.prototype.insertHTMLBefore.call(this, parent, nextSibling, html);
                }
                var parentTag = parent.tagName.toLowerCase();
                var wrapper = innerHTMLWrapper[parentTag];
                if (wrapper === undefined) {
                    return _DOMChangesClass.prototype.insertHTMLBefore.call(this, parent, nextSibling, html);
                }
                return fixInnerHTML(parent, wrapper, div, html, nextSibling);
            };

            return DOMChangesWithInnerHTMLFix;
        }(DOMChangesClass);
    }(doc, helper);
    helper = function (document, DOMChangesClass, svgNamespace) {
        if (!document) return DOMChangesClass;
        if (!shouldApplyFix$1(document, svgNamespace)) {
            return DOMChangesClass;
        }
        var div = document.createElement('div');
        return function (_DOMChangesClass2) {
            (0, _emberBabel.inherits)(DOMChangesWithSVGInnerHTMLFix, _DOMChangesClass2);

            function DOMChangesWithSVGInnerHTMLFix() {
                return (0, _emberBabel.possibleConstructorReturn)(this, _DOMChangesClass2.apply(this, arguments));
            }

            DOMChangesWithSVGInnerHTMLFix.prototype.insertHTMLBefore = function (parent, nextSibling, html) {
                if (html === null || html === '') {
                    return _DOMChangesClass2.prototype.insertHTMLBefore.call(this, parent, nextSibling, html);
                }
                if (parent.namespaceURI !== svgNamespace) {
                    return _DOMChangesClass2.prototype.insertHTMLBefore.call(this, parent, nextSibling, html);
                }
                return fixSVG(parent, div, html, nextSibling);
            };

            return DOMChangesWithSVGInnerHTMLFix;
        }(DOMChangesClass);
    }(doc, helper, SVG_NAMESPACE$$1);
    var helper$1 = helper;
    var DOMTreeConstruction = DOM.DOMTreeConstruction;

    function defaultManagers(element, attr) {
        var tagName = element.tagName;
        var isSVG = element.namespaceURI === SVG_NAMESPACE$$1;
        if (isSVG) {
            return defaultAttributeManagers(tagName, attr);
        }

        var _normalizeProperty = normalizeProperty(element, attr),
            type = _normalizeProperty.type,
            normalized = _normalizeProperty.normalized;

        if (type === 'attr') {
            return defaultAttributeManagers(tagName, normalized);
        } else {
            return defaultPropertyManagers(tagName, normalized);
        }
    }
    function defaultPropertyManagers(tagName, attr) {
        if (requiresSanitization(tagName, attr)) {
            return new SafePropertyManager(attr);
        }
        if (isUserInputValue(tagName, attr)) {
            return INPUT_VALUE_PROPERTY_MANAGER;
        }
        if (isOptionSelected(tagName, attr)) {
            return OPTION_SELECTED_MANAGER;
        }
        return new PropertyManager(attr);
    }
    function defaultAttributeManagers(tagName, attr) {
        if (requiresSanitization(tagName, attr)) {
            return new SafeAttributeManager(attr);
        }
        return new AttributeManager(attr);
    }


    var AttributeManager = function () {
        function AttributeManager(attr) {

            this.attr = attr;
        }

        AttributeManager.prototype.setAttribute = function (env, element, value, namespace) {
            var dom = env.getAppendOperations();
            var normalizedValue = normalizeAttributeValue(value);
            if (!isAttrRemovalValue(normalizedValue)) {
                dom.setAttribute(element, this.attr, normalizedValue, namespace);
            }
        };

        AttributeManager.prototype.updateAttribute = function (env, element, value, namespace) {
            if (value === null || value === undefined || value === false) {
                if (namespace) {
                    env.getDOM().removeAttributeNS(element, namespace, this.attr);
                } else {
                    env.getDOM().removeAttribute(element, this.attr);
                }
            } else {
                this.setAttribute(env, element, value);
            }
        };

        return AttributeManager;
    }();

    var PropertyManager = function (_AttributeManager) {
        (0, _emberBabel.inherits)(PropertyManager, _AttributeManager);

        function PropertyManager() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _AttributeManager.apply(this, arguments));
        }

        PropertyManager.prototype.setAttribute = function (_env, element, value) {
            if (!isAttrRemovalValue(value)) {
                element[this.attr] = value;
            }
        };

        PropertyManager.prototype.removeAttribute = function (env, element, namespace) {
            var attr = this.attr;

            if (namespace) {
                env.getDOM().removeAttributeNS(element, namespace, attr);
            } else {
                env.getDOM().removeAttribute(element, attr);
            }
        };

        PropertyManager.prototype.updateAttribute = function (env, element, value, namespace) {
            // ensure the property is always updated
            element[this.attr] = value;
            if (isAttrRemovalValue(value)) {
                this.removeAttribute(env, element, namespace);
            }
        };

        return PropertyManager;
    }(AttributeManager);

    function normalizeAttributeValue(value) {
        if (value === false || value === undefined || value === null) {
            return null;
        }
        if (value === true) {
            return '';
        }
        // onclick function etc in SSR
        if (typeof value === 'function') {
            return null;
        }
        return String(value);
    }
    function isAttrRemovalValue(value) {
        return value === null || value === undefined;
    }

    var SafePropertyManager = function (_PropertyManager) {
        (0, _emberBabel.inherits)(SafePropertyManager, _PropertyManager);

        function SafePropertyManager() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _PropertyManager.apply(this, arguments));
        }

        SafePropertyManager.prototype.setAttribute = function (env, element, value) {
            _PropertyManager.prototype.setAttribute.call(this, env, element, sanitizeAttributeValue(env, element, this.attr, value));
        };

        SafePropertyManager.prototype.updateAttribute = function (env, element, value) {
            _PropertyManager.prototype.updateAttribute.call(this, env, element, sanitizeAttributeValue(env, element, this.attr, value));
        };

        return SafePropertyManager;
    }(PropertyManager);

    function isUserInputValue(tagName, attribute) {
        return (tagName === 'INPUT' || tagName === 'TEXTAREA') && attribute === 'value';
    }

    var InputValuePropertyManager = function (_AttributeManager2) {
        (0, _emberBabel.inherits)(InputValuePropertyManager, _AttributeManager2);

        function InputValuePropertyManager() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _AttributeManager2.apply(this, arguments));
        }

        InputValuePropertyManager.prototype.setAttribute = function (_env, element, value) {
            element.value = normalizeTextValue(value);
        };

        InputValuePropertyManager.prototype.updateAttribute = function (_env, element, value) {
            var input = element;
            var currentValue = input.value;
            var normalizedValue = normalizeTextValue(value);
            if (currentValue !== normalizedValue) {
                input.value = normalizedValue;
            }
        };

        return InputValuePropertyManager;
    }(AttributeManager);

    var INPUT_VALUE_PROPERTY_MANAGER = new InputValuePropertyManager('value');
    function isOptionSelected(tagName, attribute) {
        return tagName === 'OPTION' && attribute === 'selected';
    }

    var OptionSelectedManager = function (_PropertyManager2) {
        (0, _emberBabel.inherits)(OptionSelectedManager, _PropertyManager2);

        function OptionSelectedManager() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _PropertyManager2.apply(this, arguments));
        }

        OptionSelectedManager.prototype.setAttribute = function (_env, element, value) {
            if (value !== null && value !== undefined && value !== false) {
                element.selected = true;
            }
        };

        OptionSelectedManager.prototype.updateAttribute = function (_env, element, value) {
            var option = element;
            if (value) {
                option.selected = true;
            } else {
                option.selected = false;
            }
        };

        return OptionSelectedManager;
    }(PropertyManager);

    var OPTION_SELECTED_MANAGER = new OptionSelectedManager('selected');

    var SafeAttributeManager = function (_AttributeManager3) {
        (0, _emberBabel.inherits)(SafeAttributeManager, _AttributeManager3);

        function SafeAttributeManager() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _AttributeManager3.apply(this, arguments));
        }

        SafeAttributeManager.prototype.setAttribute = function (env, element, value) {
            _AttributeManager3.prototype.setAttribute.call(this, env, element, sanitizeAttributeValue(env, element, this.attr, value));
        };

        SafeAttributeManager.prototype.updateAttribute = function (env, element, value) {
            _AttributeManager3.prototype.updateAttribute.call(this, env, element, sanitizeAttributeValue(env, element, this.attr, value));
        };

        return SafeAttributeManager;
    }(AttributeManager);

    var Scope = function () {
        function Scope(references) {
            var callerScope = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;


            this.callerScope = null;
            this.slots = references;
            this.callerScope = callerScope;
        }

        Scope.root = function (self) {
            var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0,
                i;

            var refs = new Array(size + 1);
            for (i = 0; i <= size; i++) {
                refs[i] = UNDEFINED_REFERENCE;
            }
            return new Scope(refs).init({ self: self });
        };

        Scope.prototype.init = function (_ref27) {
            var self = _ref27.self;

            this.slots[0] = self;
            return this;
        };

        Scope.prototype.getSelf = function () {
            return this.slots[0];
        };

        Scope.prototype.getSymbol = function (symbol) {
            return this.slots[symbol];
        };

        Scope.prototype.getBlock = function (symbol) {
            return this.slots[symbol];
        };

        Scope.prototype.getPartialArgs = function (symbol) {
            return this.slots[symbol];
        };

        Scope.prototype.bindSymbol = function (symbol, value) {
            this.slots[symbol] = value;
        };

        Scope.prototype.bindBlock = function (symbol, value) {
            this.slots[symbol] = value;
        };

        Scope.prototype.bindPartialArgs = function (symbol, value) {
            this.slots[symbol] = value;
        };

        Scope.prototype.bindCallerScope = function (scope) {
            this.callerScope = scope;
        };

        Scope.prototype.getCallerScope = function () {
            return this.callerScope;
        };

        Scope.prototype.child = function () {
            return new Scope(this.slots.slice(), this.callerScope);
        };

        return Scope;
    }();

    var Transaction = function () {
        function Transaction() {

            this.scheduledInstallManagers = [];
            this.scheduledInstallModifiers = [];
            this.scheduledUpdateModifierManagers = [];
            this.scheduledUpdateModifiers = [];
            this.createdComponents = [];
            this.createdManagers = [];
            this.updatedComponents = [];
            this.updatedManagers = [];
            this.destructors = [];
        }

        Transaction.prototype.didCreate = function (component, manager) {
            this.createdComponents.push(component);
            this.createdManagers.push(manager);
        };

        Transaction.prototype.didUpdate = function (component, manager) {
            this.updatedComponents.push(component);
            this.updatedManagers.push(manager);
        };

        Transaction.prototype.scheduleInstallModifier = function (modifier, manager) {
            this.scheduledInstallManagers.push(manager);
            this.scheduledInstallModifiers.push(modifier);
        };

        Transaction.prototype.scheduleUpdateModifier = function (modifier, manager) {
            this.scheduledUpdateModifierManagers.push(manager);
            this.scheduledUpdateModifiers.push(modifier);
        };

        Transaction.prototype.didDestroy = function (d) {
            this.destructors.push(d);
        };

        Transaction.prototype.commit = function () {
            var createdComponents = this.createdComponents,
                createdManagers = this.createdManagers,
                i,
                component,
                manager,
                _i2,
                _component2,
                _manager2,
                _i3,
                _i4,
                _manager3,
                modifier,
                _i5,
                _manager4,
                _modifier;

            for (i = 0; i < createdComponents.length; i++) {
                component = createdComponents[i];
                manager = createdManagers[i];

                manager.didCreate(component);
            }
            var updatedComponents = this.updatedComponents,
                updatedManagers = this.updatedManagers;

            for (_i2 = 0; _i2 < updatedComponents.length; _i2++) {
                _component2 = updatedComponents[_i2];
                _manager2 = updatedManagers[_i2];

                _manager2.didUpdate(_component2);
            }
            var destructors = this.destructors;

            for (_i3 = 0; _i3 < destructors.length; _i3++) {
                destructors[_i3].destroy();
            }
            var scheduledInstallManagers = this.scheduledInstallManagers,
                scheduledInstallModifiers = this.scheduledInstallModifiers;

            for (_i4 = 0; _i4 < scheduledInstallManagers.length; _i4++) {
                _manager3 = scheduledInstallManagers[_i4];
                modifier = scheduledInstallModifiers[_i4];

                _manager3.install(modifier);
            }
            var scheduledUpdateModifierManagers = this.scheduledUpdateModifierManagers,
                scheduledUpdateModifiers = this.scheduledUpdateModifiers;

            for (_i5 = 0; _i5 < scheduledUpdateModifierManagers.length; _i5++) {
                _manager4 = scheduledUpdateModifierManagers[_i5];
                _modifier = scheduledUpdateModifiers[_i5];

                _manager4.update(_modifier);
            }
        };

        return Transaction;
    }();

    var Opcode = function () {
        function Opcode(array) {

            this.array = array;
            this.offset = 0;
        }

        (0, _emberBabel.createClass)(Opcode, [{
            key: 'type',
            get: function () {
                return this.array[this.offset];
            }
        }, {
            key: 'op1',
            get: function () {
                return this.array[this.offset + 1];
            }
        }, {
            key: 'op2',
            get: function () {
                return this.array[this.offset + 2];
            }
        }, {
            key: 'op3',
            get: function () {
                return this.array[this.offset + 3];
            }
        }]);
        return Opcode;
    }();

    var Program = function () {
        function Program() {

            this.opcodes = [];
            this._offset = 0;
            this._opcode = new Opcode(this.opcodes);
        }

        Program.prototype.opcode = function (offset) {
            this._opcode.offset = offset;
            return this._opcode;
        };

        Program.prototype.set = function (pos, type) {
            var op1 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
            var op2 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
            var op3 = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;

            this.opcodes[pos] = type;
            this.opcodes[pos + 1] = op1;
            this.opcodes[pos + 2] = op2;
            this.opcodes[pos + 3] = op3;
        };

        Program.prototype.push = function (type) {
            var op1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
            var op2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
            var op3 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

            var offset = this._offset;
            this.opcodes[this._offset++] = type;
            this.opcodes[this._offset++] = op1;
            this.opcodes[this._offset++] = op2;
            this.opcodes[this._offset++] = op3;
            return offset;
        };

        (0, _emberBabel.createClass)(Program, [{
            key: 'next',
            get: function () {
                return this._offset;
            }
        }, {
            key: 'current',
            get: function () {
                return this._offset - 4;
            }
        }]);
        return Program;
    }();

    var Environment = function () {
        function Environment(_ref28) {
            var appendOperations = _ref28.appendOperations,
                updateOperations = _ref28.updateOperations;


            this._macros = null;
            this._transaction = null;
            this.constants = new Constants();
            this.program = new Program();
            this.appendOperations = appendOperations;
            this.updateOperations = updateOperations;
        }

        Environment.prototype.toConditionalReference = function (reference) {
            return new ConditionalReference(reference);
        };

        Environment.prototype.getAppendOperations = function () {
            return this.appendOperations;
        };

        Environment.prototype.getDOM = function () {
            return this.updateOperations;
        };

        Environment.prototype.getIdentity = function (object) {
            return (0, _util.ensureGuid)(object) + '';
        };

        Environment.prototype.begin = function () {
            this._transaction = new Transaction();
        };

        Environment.prototype.didCreate = function (component, manager) {
            this.transaction.didCreate(component, manager);
        };

        Environment.prototype.didUpdate = function (component, manager) {
            this.transaction.didUpdate(component, manager);
        };

        Environment.prototype.scheduleInstallModifier = function (modifier, manager) {
            this.transaction.scheduleInstallModifier(modifier, manager);
        };

        Environment.prototype.scheduleUpdateModifier = function (modifier, manager) {
            this.transaction.scheduleUpdateModifier(modifier, manager);
        };

        Environment.prototype.didDestroy = function (d) {
            this.transaction.didDestroy(d);
        };

        Environment.prototype.commit = function () {
            this.transaction.commit();
            this._transaction = null;
        };

        Environment.prototype.attributeFor = function (element, attr, isTrusting, namespace) {
            return defaultManagers(element, attr, isTrusting, namespace === undefined ? null : namespace);
        };

        Environment.prototype.macros = function () {
            var macros = this._macros;
            if (!macros) {
                this._macros = macros = populateBuiltins();
            }
            return macros;
        };

        (0, _emberBabel.createClass)(Environment, [{
            key: 'transaction',
            get: function () {
                return (0, _util.expect)(this._transaction, 'must be in a transaction');
            }
        }]);
        return Environment;
    }();

    var RenderResult = function () {
        function RenderResult(env, updating, bounds$$1) {

            this.env = env;
            this.updating = updating;
            this.bounds = bounds$$1;
        }

        RenderResult.prototype.rerender = function () {
            var _ref29 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { alwaysRevalidate: false },
                _ref29$alwaysRevalida = _ref29.alwaysRevalidate,
                alwaysRevalidate = _ref29$alwaysRevalida === undefined ? false : _ref29$alwaysRevalida;

            var env = this.env,
                updating = this.updating;

            var vm = new UpdatingVM(env, { alwaysRevalidate: alwaysRevalidate });
            vm.execute(updating, this);
        };

        RenderResult.prototype.parentElement = function () {
            return this.bounds.parentElement();
        };

        RenderResult.prototype.firstNode = function () {
            return this.bounds.firstNode();
        };

        RenderResult.prototype.lastNode = function () {
            return this.bounds.lastNode();
        };

        RenderResult.prototype.opcodes = function () {
            return this.updating;
        };

        RenderResult.prototype.handleException = function () {
            throw "this should never happen";
        };

        RenderResult.prototype.destroy = function () {
            this.bounds.destroy();
            clear(this.bounds);
        };

        return RenderResult;
    }();

    var CapturedFrame = function (operand, args, condition) {

        this.operand = operand;
        this.args = args;
        this.condition = condition;
    };

    var Frame = function () {
        function Frame(start, end) {
            var component = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
            var manager = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
            var shadow = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;


            this.start = start;
            this.end = end;
            this.component = component;
            this.manager = manager;
            this.shadow = shadow;
            this.operand = null;
            this.immediate = null;
            this.args = null;
            this.callerScope = null;
            this.blocks = null;
            this.condition = null;
            this.iterator = null;
            this.key = null;
            this.ip = start;
        }

        Frame.prototype.capture = function () {
            return new CapturedFrame(this.operand, this.args, this.condition);
        };

        Frame.prototype.restore = function (frame) {
            this.operand = frame.operand;
            this.args = frame.args;
            this.condition = frame.condition;
        };

        return Frame;
    }();

    var FrameStack = function () {
        function FrameStack() {

            this.frames = [];
            this.frame = -1;
        }

        FrameStack.prototype.push = function (start, end) {
            var component = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null,
                frame;
            var manager = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
            var shadow = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;

            var pos = ++this.frame;
            if (pos < this.frames.length) {
                frame = this.frames[pos];

                frame.start = frame.ip = start;
                frame.end = end;
                frame.component = component;
                frame.manager = manager;
                frame.shadow = shadow;
                frame.operand = null;
                frame.immediate = null;
                frame.args = null;
                frame.callerScope = null;
                frame.blocks = null;
                frame.condition = null;
                frame.iterator = null;
                frame.key = null;
            } else {
                this.frames[pos] = new Frame(start, end, component, manager, shadow);
            }
        };

        FrameStack.prototype.pop = function () {
            this.frame--;
        };

        FrameStack.prototype.capture = function () {
            return this.currentFrame.capture();
        };

        FrameStack.prototype.restore = function (frame) {
            this.currentFrame.restore(frame);
        };

        FrameStack.prototype.getStart = function () {
            return this.currentFrame.start;
        };

        FrameStack.prototype.getEnd = function () {
            return this.currentFrame.end;
        };

        FrameStack.prototype.getCurrent = function () {
            return this.currentFrame.ip;
        };

        FrameStack.prototype.setCurrent = function (ip) {
            return this.currentFrame.ip = ip;
        };

        FrameStack.prototype.getOperand = function () {
            return (0, _util.unwrap)(this.currentFrame.operand);
        };

        FrameStack.prototype.setOperand = function (operand) {
            return this.currentFrame.operand = operand;
        };

        FrameStack.prototype.getImmediate = function () {
            return this.currentFrame.immediate;
        };

        FrameStack.prototype.setImmediate = function (value) {
            return this.currentFrame.immediate = value;
        };

        FrameStack.prototype.getArgs = function () {
            return this.currentFrame.args;
        };

        FrameStack.prototype.setArgs = function (args) {
            return this.currentFrame.args = args;
        };

        FrameStack.prototype.getCondition = function () {
            return (0, _util.unwrap)(this.currentFrame.condition);
        };

        FrameStack.prototype.setCondition = function (condition) {
            return this.currentFrame.condition = condition;
        };

        FrameStack.prototype.getIterator = function () {
            return (0, _util.unwrap)(this.currentFrame.iterator);
        };

        FrameStack.prototype.setIterator = function (iterator) {
            return this.currentFrame.iterator = iterator;
        };

        FrameStack.prototype.getKey = function () {
            return this.currentFrame.key;
        };

        FrameStack.prototype.setKey = function (key) {
            return this.currentFrame.key = key;
        };

        FrameStack.prototype.getBlocks = function () {
            return (0, _util.unwrap)(this.currentFrame.blocks);
        };

        FrameStack.prototype.setBlocks = function (blocks) {
            return this.currentFrame.blocks = blocks;
        };

        FrameStack.prototype.getCallerScope = function () {
            return (0, _util.unwrap)(this.currentFrame.callerScope);
        };

        FrameStack.prototype.setCallerScope = function (callerScope) {
            return this.currentFrame.callerScope = callerScope;
        };

        FrameStack.prototype.getComponent = function () {
            return (0, _util.unwrap)(this.currentFrame.component);
        };

        FrameStack.prototype.getManager = function () {
            return (0, _util.unwrap)(this.currentFrame.manager);
        };

        FrameStack.prototype.getShadow = function () {
            return this.currentFrame.shadow;
        };

        FrameStack.prototype.goto = function (ip) {
            this.setCurrent(ip);
        };

        FrameStack.prototype.nextStatement = function (env) {
            var frame, ip, end, program;

            while (this.frame !== -1) {
                frame = this.frames[this.frame];
                ip = frame.ip;
                end = frame.end;

                if (ip < end) {
                    program = env.program;

                    frame.ip += 4;
                    return program.opcode(ip);
                } else {
                    this.pop();
                }
            }
            return null;
        };

        (0, _emberBabel.createClass)(FrameStack, [{
            key: 'currentFrame',
            get: function () {
                return this.frames[this.frame];
            }
        }]);
        return FrameStack;
    }();

    var VM = function () {
        function VM(env, scope, dynamicScope, elementStack) {

            this.env = env;
            this.elementStack = elementStack;
            this.dynamicScopeStack = new _util.Stack();
            this.scopeStack = new _util.Stack();
            this.updatingOpcodeStack = new _util.Stack();
            this.cacheGroups = new _util.Stack();
            this.listBlockStack = new _util.Stack();
            this.frame = new FrameStack();
            this.env = env;
            this.constants = env.constants;
            this.elementStack = elementStack;
            this.scopeStack.push(scope);
            this.dynamicScopeStack.push(dynamicScope);
        }

        VM.initial = function (env, self, dynamicScope, elementStack, compiledProgram) {
            var size = compiledProgram.symbols,
                start = compiledProgram.start,
                end = compiledProgram.end;

            var scope = Scope.root(self, size);
            var vm = new VM(env, scope, dynamicScope, elementStack);
            vm.prepare(start, end);
            return vm;
        };

        VM.prototype.capture = function () {
            return {
                env: this.env,
                scope: this.scope(),
                dynamicScope: this.dynamicScope(),
                frame: this.frame.capture()
            };
        };

        VM.prototype.goto = function (ip) {
            this.frame.goto(ip);
        };

        VM.prototype.beginCacheGroup = function () {
            this.cacheGroups.push(this.updating().tail());
        };

        VM.prototype.commitCacheGroup = function () {
            //        JumpIfNotModified(END)
            //        (head)
            //        (....)
            //        (tail)
            //        DidModify
            // END:   Noop
            var END = new LabelOpcode("END");
            var opcodes = this.updating();
            var marker = this.cacheGroups.pop();
            var head = marker ? opcodes.nextNode(marker) : opcodes.head();
            var tail = opcodes.tail();
            var tag = (0, _reference2.combineSlice)(new _util.ListSlice(head, tail));
            var guard = new JumpIfNotModifiedOpcode(tag, END);
            opcodes.insertBefore(guard, head);
            opcodes.append(new DidModifyOpcode(guard));
            opcodes.append(END);
        };

        VM.prototype.enter = function (start, end) {
            var updating = new _util.LinkedList();
            var tracker = this.stack().pushUpdatableBlock();
            var state = this.capture();
            var tryOpcode = new TryOpcode(start, end, state, tracker, updating);
            this.didEnter(tryOpcode, updating);
        };

        VM.prototype.enterWithKey = function (key, start, end) {
            var updating = new _util.LinkedList();
            var tracker = this.stack().pushUpdatableBlock();
            var state = this.capture();
            var tryOpcode = new TryOpcode(start, end, state, tracker, updating);
            this.listBlock().map[key] = tryOpcode;
            this.didEnter(tryOpcode, updating);
        };

        VM.prototype.enterList = function (start, end) {
            var updating = new _util.LinkedList();
            var tracker = this.stack().pushBlockList(updating);
            var state = this.capture();
            var artifacts = this.frame.getIterator().artifacts;
            var opcode = new ListBlockOpcode(start, end, state, tracker, updating, artifacts);
            this.listBlockStack.push(opcode);
            this.didEnter(opcode, updating);
        };

        VM.prototype.didEnter = function (opcode, updating) {
            this.updateWith(opcode);
            this.updatingOpcodeStack.push(updating);
        };

        VM.prototype.exit = function () {
            this.stack().popBlock();
            this.updatingOpcodeStack.pop();
            var parent = this.updating().tail();
            parent.didInitializeChildren();
        };

        VM.prototype.exitList = function () {
            this.exit();
            this.listBlockStack.pop();
        };

        VM.prototype.updateWith = function (opcode) {
            this.updating().append(opcode);
        };

        VM.prototype.listBlock = function () {
            return (0, _util.expect)(this.listBlockStack.current, 'expected a list block');
        };

        VM.prototype.updating = function () {
            return (0, _util.expect)(this.updatingOpcodeStack.current, 'expected updating opcode on the updating opcode stack');
        };

        VM.prototype.stack = function () {
            return this.elementStack;
        };

        VM.prototype.scope = function () {
            return (0, _util.expect)(this.scopeStack.current, 'expected scope on the scope stack');
        };

        VM.prototype.dynamicScope = function () {
            return (0, _util.expect)(this.dynamicScopeStack.current, 'expected dynamic scope on the dynamic scope stack');
        };

        VM.prototype.pushFrame = function (block, args, callerScope) {
            this.frame.push(block.start, block.end);
            if (args) this.frame.setArgs(args);
            if (args && args.blocks) this.frame.setBlocks(args.blocks);
            if (callerScope) this.frame.setCallerScope(callerScope);
        };

        VM.prototype.pushComponentFrame = function (layout, args, callerScope, component, manager, shadow) {
            this.frame.push(layout.start, layout.end, component, manager, shadow);
            if (args) this.frame.setArgs(args);
            if (args && args.blocks) this.frame.setBlocks(args.blocks);
            if (callerScope) this.frame.setCallerScope(callerScope);
        };

        VM.prototype.pushEvalFrame = function (start, end) {
            this.frame.push(start, end);
        };

        VM.prototype.pushChildScope = function () {
            this.scopeStack.push(this.scope().child());
        };

        VM.prototype.pushCallerScope = function () {
            this.scopeStack.push((0, _util.expect)(this.scope().getCallerScope(), 'pushCallerScope is called when a caller scope is present'));
        };

        VM.prototype.pushDynamicScope = function () {
            var child = this.dynamicScope().child();
            this.dynamicScopeStack.push(child);
            return child;
        };

        VM.prototype.pushRootScope = function (self, size) {
            var scope = Scope.root(self, size);
            this.scopeStack.push(scope);
            return scope;
        };

        VM.prototype.popScope = function () {
            this.scopeStack.pop();
        };

        VM.prototype.popDynamicScope = function () {
            this.dynamicScopeStack.pop();
        };

        VM.prototype.newDestroyable = function (d) {
            this.stack().newDestroyable(d);
        };

        VM.prototype.getSelf = function () {
            return this.scope().getSelf();
        };

        VM.prototype.referenceForSymbol = function (symbol) {
            return this.scope().getSymbol(symbol);
        };

        VM.prototype.getArgs = function () {
            return this.frame.getArgs();
        };

        VM.prototype.resume = function (start, end, frame) {
            return this.execute(start, end, function (vm) {
                return vm.frame.restore(frame);
            });
        };

        VM.prototype.execute = function (start, end, initialize) {
            this.prepare(start, end, initialize);
            var result = void 0;
            while (true) {
                result = this.next();
                if (result.done) break;
            }
            return result.value;
        };

        VM.prototype.prepare = function (start, end, initialize) {
            var elementStack = this.elementStack,
                frame = this.frame,
                updatingOpcodeStack = this.updatingOpcodeStack;

            elementStack.pushSimpleBlock();
            updatingOpcodeStack.push(new _util.LinkedList());
            frame.push(start, end);
            if (initialize) initialize(this);
        };

        VM.prototype.next = function () {
            var frame = this.frame,
                env = this.env,
                updatingOpcodeStack = this.updatingOpcodeStack,
                elementStack = this.elementStack;

            var opcode = void 0;
            if (opcode = frame.nextStatement(env)) {
                APPEND_OPCODES.evaluate(this, opcode);
                return { done: false, value: null };
            }
            return {
                done: true,
                value: new RenderResult(env, (0, _util.expect)(updatingOpcodeStack.pop(), 'there should be a final updating opcode stack'), elementStack.popBlock())
            };
        };

        VM.prototype.evaluateOpcode = function (opcode) {
            APPEND_OPCODES.evaluate(this, opcode);
        };

        VM.prototype.invokeBlock = function (block, args) {
            var compiled = block.compile(this.env);
            this.pushFrame(compiled, args);
        };

        VM.prototype.invokePartial = function (block) {
            var compiled = block.compile(this.env);
            this.pushFrame(compiled);
        };

        VM.prototype.invokeLayout = function (args, layout, callerScope, component, manager, shadow) {
            this.pushComponentFrame(layout, args, callerScope, component, manager, shadow);
        };

        VM.prototype.evaluateOperand = function (expr) {
            this.frame.setOperand(expr.evaluate(this));
        };

        VM.prototype.evaluateArgs = function (args) {
            var evaledArgs = this.frame.setArgs(args.evaluate(this));
            this.frame.setOperand(evaledArgs.positional.at(0));
        };

        VM.prototype.bindPositionalArgs = function (symbols) {
            var args = (0, _util.expect)(this.frame.getArgs(), 'bindPositionalArgs assumes a previous setArgs'),
                i;
            var positional = args.positional;

            var scope = this.scope();
            for (i = 0; i < symbols.length; i++) {
                scope.bindSymbol(symbols[i], positional.at(i));
            }
        };

        VM.prototype.bindNamedArgs = function (names, symbols) {
            var args = (0, _util.expect)(this.frame.getArgs(), 'bindNamedArgs assumes a previous setArgs'),
                i,
                name;
            var scope = this.scope();
            var named = args.named;

            for (i = 0; i < names.length; i++) {
                name = this.constants.getString(names[i]);

                scope.bindSymbol(symbols[i], named.get(name));
            }
        };

        VM.prototype.bindBlocks = function (names, symbols) {
            var blocks = this.frame.getBlocks(),
                i,
                name;
            var scope = this.scope();
            for (i = 0; i < names.length; i++) {
                name = this.constants.getString(names[i]);

                scope.bindBlock(symbols[i], blocks && blocks[name] || null);
            }
        };

        VM.prototype.bindPartialArgs = function (symbol) {
            var args = (0, _util.expect)(this.frame.getArgs(), 'bindPartialArgs assumes a previous setArgs');
            var scope = this.scope();
            (0, _util.assert)(args, "Cannot bind named args");
            scope.bindPartialArgs(symbol, args);
        };

        VM.prototype.bindCallerScope = function () {
            var callerScope = this.frame.getCallerScope();
            var scope = this.scope();
            (0, _util.assert)(callerScope, "Cannot bind caller scope");
            scope.bindCallerScope(callerScope);
        };

        VM.prototype.bindDynamicScope = function (names) {
            var args = (0, _util.expect)(this.frame.getArgs(), 'bindDynamicScope assumes a previous setArgs'),
                i,
                name;
            var scope = this.dynamicScope();
            (0, _util.assert)(args, "Cannot bind dynamic scope");
            for (i = 0; i < names.length; i++) {
                name = this.constants.getString(names[i]);

                scope.set(name, args.named.get(name));
            }
        };

        return VM;
    }();

    var UpdatingVM = function () {
        function UpdatingVM(env, _ref30) {
            var _ref30$alwaysRevalida = _ref30.alwaysRevalidate,
                alwaysRevalidate = _ref30$alwaysRevalida === undefined ? false : _ref30$alwaysRevalida;


            this.frameStack = new _util.Stack();
            this.env = env;
            this.constants = env.constants;
            this.dom = env.getDOM();
            this.alwaysRevalidate = alwaysRevalidate;
        }

        UpdatingVM.prototype.execute = function (opcodes, handler) {
            var frameStack = this.frameStack,
                opcode;

            this.try(opcodes, handler);
            while (true) {
                if (frameStack.isEmpty()) break;
                opcode = this.frame.nextStatement();

                if (opcode === null) {
                    this.frameStack.pop();
                    continue;
                }
                opcode.evaluate(this);
            }
        };

        UpdatingVM.prototype.goto = function (op) {
            this.frame.goto(op);
        };

        UpdatingVM.prototype.try = function (ops, handler) {
            this.frameStack.push(new UpdatingVMFrame(this, ops, handler));
        };

        UpdatingVM.prototype.throw = function () {
            this.frame.handleException();
            this.frameStack.pop();
        };

        UpdatingVM.prototype.evaluateOpcode = function (opcode) {
            opcode.evaluate(this);
        };

        (0, _emberBabel.createClass)(UpdatingVM, [{
            key: 'frame',
            get: function () {
                return (0, _util.expect)(this.frameStack.current, 'bug: expected a frame');
            }
        }]);
        return UpdatingVM;
    }();

    var BlockOpcode = function (_UpdatingOpcode8) {
        (0, _emberBabel.inherits)(BlockOpcode, _UpdatingOpcode8);

        function BlockOpcode(start, end, state, bounds$$1, children) {

            var _this52 = (0, _emberBabel.possibleConstructorReturn)(this, _UpdatingOpcode8.call(this));

            _this52.start = start;
            _this52.end = end;
            _this52.type = "block";
            _this52.next = null;
            _this52.prev = null;
            var env = state.env,
                scope = state.scope,
                dynamicScope = state.dynamicScope,
                frame = state.frame;

            _this52.children = children;
            _this52.env = env;
            _this52.scope = scope;
            _this52.dynamicScope = dynamicScope;
            _this52.frame = frame;
            _this52.bounds = bounds$$1;
            return _this52;
        }

        BlockOpcode.prototype.parentElement = function () {
            return this.bounds.parentElement();
        };

        BlockOpcode.prototype.firstNode = function () {
            return this.bounds.firstNode();
        };

        BlockOpcode.prototype.lastNode = function () {
            return this.bounds.lastNode();
        };

        BlockOpcode.prototype.evaluate = function (vm) {
            vm.try(this.children, null);
        };

        BlockOpcode.prototype.destroy = function () {
            this.bounds.destroy();
        };

        BlockOpcode.prototype.didDestroy = function () {
            this.env.didDestroy(this.bounds);
        };

        BlockOpcode.prototype.toJSON = function () {
            var details = (0, _util.dict)();
            details["guid"] = '' + this._guid;
            return {
                guid: this._guid,
                type: this.type,
                details: details,
                children: this.children.toArray().map(function (op) {
                    return op.toJSON();
                })
            };
        };

        return BlockOpcode;
    }(UpdatingOpcode);

    var TryOpcode = function (_BlockOpcode) {
        (0, _emberBabel.inherits)(TryOpcode, _BlockOpcode);

        function TryOpcode(start, end, state, bounds$$1, children) {

            var _this53 = (0, _emberBabel.possibleConstructorReturn)(this, _BlockOpcode.call(this, start, end, state, bounds$$1, children));

            _this53.type = "try";
            _this53.tag = _this53._tag = new _reference2.UpdatableTag(_reference2.CONSTANT_TAG);
            return _this53;
        }

        TryOpcode.prototype.didInitializeChildren = function () {
            this._tag.update((0, _reference2.combineSlice)(this.children));
        };

        TryOpcode.prototype.evaluate = function (vm) {
            vm.try(this.children, this);
        };

        TryOpcode.prototype.handleException = function () {
            var env = this.env,
                scope = this.scope,
                start = this.start,
                end = this.end,
                dynamicScope = this.dynamicScope,
                frame = this.frame;

            var elementStack = ElementStack.resume(this.env, this.bounds, this.bounds.reset(env));
            var vm = new VM(env, scope, dynamicScope, elementStack);
            var result = vm.resume(start, end, frame);
            this.children = result.opcodes();
            this.didInitializeChildren();
        };

        TryOpcode.prototype.toJSON = function () {
            var json = _BlockOpcode.prototype.toJSON.call(this);
            var details = json["details"];
            if (!details) {
                details = json["details"] = {};
            }
            return _BlockOpcode.prototype.toJSON.call(this);
        };

        return TryOpcode;
    }(BlockOpcode);

    var ListRevalidationDelegate = function () {
        function ListRevalidationDelegate(opcode, marker) {

            this.opcode = opcode;
            this.marker = marker;
            this.didInsert = false;
            this.didDelete = false;
            this.map = opcode.map;
            this.updating = opcode['children'];
        }

        ListRevalidationDelegate.prototype.insert = function (key, item, memo, before) {
            var map$$1 = this.map,
                opcode = this.opcode,
                updating = this.updating;

            var nextSibling = null;
            var reference = null;
            if (before) {
                reference = map$$1[before];
                nextSibling = reference['bounds'].firstNode();
            } else {
                nextSibling = this.marker;
            }
            var vm = opcode.vmForInsertion(nextSibling);
            var tryOpcode = null;
            vm.execute(opcode.start, opcode.end, function (vm) {
                vm.frame.setArgs(EvaluatedArgs.positional([item, memo]));
                vm.frame.setOperand(item);
                vm.frame.setCondition(new _reference2.ConstReference(true));
                vm.frame.setKey(key);
                var state = vm.capture();
                var tracker = vm.stack().pushUpdatableBlock();
                tryOpcode = new TryOpcode(opcode.start, opcode.end, state, tracker, vm.updating());
            });
            tryOpcode.didInitializeChildren();
            updating.insertBefore(tryOpcode, reference);
            map$$1[key] = tryOpcode;
            this.didInsert = true;
        };

        ListRevalidationDelegate.prototype.retain = function () {};

        ListRevalidationDelegate.prototype.move = function (key, _item, _memo, before) {
            var map$$1 = this.map,
                updating = this.updating;

            var entry = map$$1[key];
            var reference = map$$1[before] || null;
            if (before) {
                _move(entry, reference.firstNode());
            } else {
                _move(entry, this.marker);
            }
            updating.remove(entry);
            updating.insertBefore(entry, reference);
        };

        ListRevalidationDelegate.prototype.delete = function (key) {
            var map$$1 = this.map;

            var opcode = map$$1[key];
            opcode.didDestroy();
            clear(opcode);
            this.updating.remove(opcode);
            delete map$$1[key];
            this.didDelete = true;
        };

        ListRevalidationDelegate.prototype.done = function () {
            this.opcode.didInitializeChildren(this.didInsert || this.didDelete);
        };

        return ListRevalidationDelegate;
    }();

    var ListBlockOpcode = function (_BlockOpcode2) {
        (0, _emberBabel.inherits)(ListBlockOpcode, _BlockOpcode2);

        function ListBlockOpcode(start, end, state, bounds$$1, children, artifacts) {

            var _this54 = (0, _emberBabel.possibleConstructorReturn)(this, _BlockOpcode2.call(this, start, end, state, bounds$$1, children));

            _this54.type = "list-block";
            _this54.map = (0, _util.dict)();
            _this54.lastIterated = _reference2.INITIAL;
            _this54.artifacts = artifacts;
            var _tag = _this54._tag = new _reference2.UpdatableTag(_reference2.CONSTANT_TAG);
            _this54.tag = (0, _reference2.combine)([artifacts.tag, _tag]);
            return _this54;
        }

        ListBlockOpcode.prototype.didInitializeChildren = function () {
            var listDidChange = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

            this.lastIterated = this.artifacts.tag.value();
            if (listDidChange) {
                this._tag.update((0, _reference2.combineSlice)(this.children));
            }
        };

        ListBlockOpcode.prototype.evaluate = function (vm) {
            var artifacts = this.artifacts,
                lastIterated = this.lastIterated,
                bounds$$1,
                dom,
                marker,
                target,
                synchronizer;

            if (!artifacts.tag.validate(lastIterated)) {
                bounds$$1 = this.bounds;
                dom = vm.dom;
                marker = dom.createComment('');

                dom.insertAfter(bounds$$1.parentElement(), marker, (0, _util.expect)(bounds$$1.lastNode(), "can't insert after an empty bounds"));
                target = new ListRevalidationDelegate(this, marker);
                synchronizer = new _reference2.IteratorSynchronizer({ target: target, artifacts: artifacts });

                synchronizer.sync();
                this.parentElement().removeChild(marker);
            }
            // Run now-updated updating opcodes
            _BlockOpcode2.prototype.evaluate.call(this, vm);
        };

        ListBlockOpcode.prototype.vmForInsertion = function (nextSibling) {
            var env = this.env,
                scope = this.scope,
                dynamicScope = this.dynamicScope;

            var elementStack = ElementStack.forInitialRender(this.env, this.bounds.parentElement(), nextSibling);
            return new VM(env, scope, dynamicScope, elementStack);
        };

        ListBlockOpcode.prototype.toJSON = function () {
            var json = _BlockOpcode2.prototype.toJSON.call(this);
            var map$$1 = this.map;
            var inner = Object.keys(map$$1).map(function (key) {
                return JSON.stringify(key) + ': ' + map$$1[key]._guid;
            }).join(", ");
            var details = json["details"];
            if (!details) {
                details = json["details"] = {};
            }
            details["map"] = '{' + inner + '}';
            return json;
        };

        return ListBlockOpcode;
    }(BlockOpcode);

    var UpdatingVMFrame = function () {
        function UpdatingVMFrame(vm, ops, exceptionHandler) {

            this.vm = vm;
            this.ops = ops;
            this.exceptionHandler = exceptionHandler;
            this.vm = vm;
            this.ops = ops;
            this.current = ops.head();
        }

        UpdatingVMFrame.prototype.goto = function (op) {
            this.current = op;
        };

        UpdatingVMFrame.prototype.nextStatement = function () {
            var current = this.current,
                ops = this.ops;

            if (current) this.current = ops.nextNode(current);
            return current;
        };

        UpdatingVMFrame.prototype.handleException = function () {
            if (this.exceptionHandler) {
                this.exceptionHandler.handleException();
            }
        };

        return UpdatingVMFrame;
    }();

    APPEND_OPCODES.add(31 /* DynamicContent */, function (vm, _ref31) {
        var append = _ref31.op1;

        var opcode = vm.constants.getOther(append);
        opcode.evaluate(vm);
    });
    function isEmpty(value) {
        return value === null || value === undefined || typeof value['toString'] !== 'function';
    }
    function normalizeTextValue(value) {
        if (isEmpty(value)) {
            return '';
        }
        return String(value);
    }
    function normalizeTrustedValue(value) {
        if (isEmpty(value)) {
            return '';
        }
        if (isString(value)) {
            return value;
        }
        if (isSafeString(value)) {
            return value.toHTML();
        }
        if (isNode(value)) {
            return value;
        }
        return String(value);
    }
    function normalizeValue(value) {
        if (isEmpty(value)) {
            return '';
        }
        if (isString(value)) {
            return value;
        }
        if (isSafeString(value) || isNode(value)) {
            return value;
        }
        return String(value);
    }

    var AppendDynamicOpcode = function () {
        function AppendDynamicOpcode() {}

        AppendDynamicOpcode.prototype.evaluate = function (vm) {
            var reference = vm.frame.getOperand();
            var normalized = this.normalize(reference);
            var value = void 0,
                cache = void 0;
            if ((0, _reference2.isConst)(reference)) {
                value = normalized.value();
            } else {
                cache = new _reference2.ReferenceCache(normalized);
                value = cache.peek();
            }
            var stack = vm.stack();
            var upsert = this.insert(vm.env.getAppendOperations(), stack, value);
            var bounds$$1 = new Fragment(upsert.bounds);
            stack.newBounds(bounds$$1);
            if (cache /* i.e. !isConst(reference) */) {
                    vm.updateWith(this.updateWith(vm, reference, cache, bounds$$1, upsert));
                }
        };

        return AppendDynamicOpcode;
    }();

    var GuardedAppendOpcode = function (_AppendDynamicOpcode) {
        (0, _emberBabel.inherits)(GuardedAppendOpcode, _AppendDynamicOpcode);

        function GuardedAppendOpcode(expression, symbolTable) {

            var _this55 = (0, _emberBabel.possibleConstructorReturn)(this, _AppendDynamicOpcode.call(this));

            _this55.expression = expression;
            _this55.symbolTable = symbolTable;
            _this55.start = -1;
            _this55.end = -1;
            return _this55;
        }

        GuardedAppendOpcode.prototype.evaluate = function (vm) {
            var value;

            if (this.start === -1) {
                vm.evaluateOperand(this.expression);
                value = vm.frame.getOperand().value();

                if (isComponentDefinition(value)) {
                    this.deopt(vm.env);
                    vm.pushEvalFrame(this.start, this.end);
                } else {
                    _AppendDynamicOpcode.prototype.evaluate.call(this, vm);
                }
            } else {
                vm.pushEvalFrame(this.start, this.end);
            }
        };

        GuardedAppendOpcode.prototype.deopt = function (env) {
            var _this56 = this;

            // At compile time, we determined that this append callsite might refer
            // to a local variable/property lookup that resolves to a component
            // definition at runtime.
            //
            // We could have eagerly compiled this callsite into something like this:
            //
            //   {{#if (is-component-definition foo)}}
            //     {{component foo}}
            //   {{else}}
            //     {{foo}}
            //   {{/if}}
            //
            // However, in practice, there might be a large amout of these callsites
            // and most of them would resolve to a simple value lookup. Therefore, we
            // tried to be optimistic and assumed that the callsite will resolve to
            // appending a simple value.
            //
            // However, we have reached here because at runtime, the guard conditional
            // have detected that this callsite is indeed referring to a component
            // definition object. Since this is likely going to be true for other
            // instances of the same callsite, it is now appropiate to deopt into the
            // expanded version that handles both cases. The compilation would look
            // like this:
            //
            //               PutValue(expression)
            //               Test(is-component-definition)
            //               Enter(BEGIN, END)
            //   BEGIN:      Noop
            //               JumpUnless(VALUE)
            //               PutDynamicComponentDefinitionOpcode
            //               OpenComponent
            //               CloseComponent
            //               Jump(END)
            //   VALUE:      Noop
            //               OptimizedAppend
            //   END:        Noop
            //               Exit
            //
            // Keep in mind that even if we *don't* reach here at initial render time,
            // it is still possible (although quite rare) that the simple value we
            // encounter during initial render could later change into a component
            // definition object at update time. That is handled by the "lazy deopt"
            // code on the update side (scroll down for the next big block of comment).
            var dsl = new OpcodeBuilder(this.symbolTable, env);
            dsl.putValue(this.expression);
            dsl.test(IsComponentDefinitionReference.create);
            dsl.labelled(null, function (dsl, _BEGIN, END) {
                dsl.jumpUnless('VALUE');
                dsl.putDynamicComponentDefinition();
                dsl.openComponent(CompiledArgs.empty());
                dsl.closeComponent();
                dsl.jump(END);
                dsl.label('VALUE');
                dsl.dynamicContent(new _this56.AppendOpcode());
            });
            this.start = dsl.start;
            this.end = dsl.end;
            // From this point on, we have essentially replaced ourselves with a new set
            // of opcodes. Since we will always be executing the new/deopted code, it's
            // a good idea (as a pattern) to null out any unneeded fields here to avoid
            // holding on to unneeded/stale objects:
            // QUESTION: Shouldn't this whole object be GCed? If not, why not?
            this.expression = null;
            return dsl.start;
        };

        return GuardedAppendOpcode;
    }(AppendDynamicOpcode);

    var IsComponentDefinitionReference = function (_ConditionalReference) {
        (0, _emberBabel.inherits)(IsComponentDefinitionReference, _ConditionalReference);

        function IsComponentDefinitionReference() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _ConditionalReference.apply(this, arguments));
        }

        IsComponentDefinitionReference.create = function (inner) {
            return new IsComponentDefinitionReference(inner);
        };

        IsComponentDefinitionReference.prototype.toBool = function (value) {
            return isComponentDefinition(value);
        };

        return IsComponentDefinitionReference;
    }(ConditionalReference);

    var UpdateOpcode = function (_UpdatingOpcode9) {
        (0, _emberBabel.inherits)(UpdateOpcode, _UpdatingOpcode9);

        function UpdateOpcode(cache, bounds$$1, upsert) {

            var _this58 = (0, _emberBabel.possibleConstructorReturn)(this, _UpdatingOpcode9.call(this));

            _this58.cache = cache;
            _this58.bounds = bounds$$1;
            _this58.upsert = upsert;
            _this58.tag = cache.tag;
            return _this58;
        }

        UpdateOpcode.prototype.evaluate = function (vm) {
            var value = this.cache.revalidate(),
                bounds$$1,
                upsert,
                dom,
                cursor;
            if ((0, _reference2.isModified)(value)) {
                bounds$$1 = this.bounds, upsert = this.upsert;
                dom = vm.dom;


                if (!this.upsert.update(dom, value)) {
                    cursor = new Cursor(bounds$$1.parentElement(), clear(bounds$$1));

                    upsert = this.upsert = this.insert(vm.env.getAppendOperations(), cursor, value);
                }
                bounds$$1.update(upsert.bounds);
            }
        };

        UpdateOpcode.prototype.toJSON = function () {
            var guid = this._guid,
                type = this.type,
                cache = this.cache;

            return {
                guid: guid,
                type: type,
                details: { lastValue: JSON.stringify(cache.peek()) }
            };
        };

        return UpdateOpcode;
    }(UpdatingOpcode);

    var GuardedUpdateOpcode = function (_UpdateOpcode) {
        (0, _emberBabel.inherits)(GuardedUpdateOpcode, _UpdateOpcode);

        function GuardedUpdateOpcode(reference, cache, bounds$$1, upsert, appendOpcode, state) {

            var _this59 = (0, _emberBabel.possibleConstructorReturn)(this, _UpdateOpcode.call(this, cache, bounds$$1, upsert));

            _this59.reference = reference;
            _this59.appendOpcode = appendOpcode;
            _this59.state = state;
            _this59.deopted = null;
            _this59.tag = _this59._tag = new _reference2.UpdatableTag(_this59.tag);
            return _this59;
        }

        GuardedUpdateOpcode.prototype.evaluate = function (vm) {
            if (this.deopted) {
                vm.evaluateOpcode(this.deopted);
            } else {
                if (isComponentDefinition(this.reference.value())) {
                    this.lazyDeopt(vm);
                } else {
                    _UpdateOpcode.prototype.evaluate.call(this, vm);
                }
            }
        };

        GuardedUpdateOpcode.prototype.lazyDeopt = function (vm) {
            var bounds$$1 = this.bounds,
                appendOpcode = this.appendOpcode,
                state = this.state;

            var env = vm.env;
            var deoptStart = appendOpcode.deopt(env);
            var enter = (0, _util.expect)(env.program.opcode(deoptStart + 8), 'hardcoded deopt location');
            var start = enter.op1,
                end = enter.op2;

            var tracker = new UpdatableBlockTracker(bounds$$1.parentElement());
            tracker.newBounds(this.bounds);
            var children = new _util.LinkedList();
            state.frame.condition = IsComponentDefinitionReference.create((0, _util.expect)(state.frame['operand'], 'operand should be populated'));
            var deopted = this.deopted = new TryOpcode(start, end, state, tracker, children);
            this._tag.update(deopted.tag);
            vm.evaluateOpcode(deopted);
            vm.throw();
            // From this point on, we have essentially replaced ourselve with a new
            // opcode. Since we will always be executing the new/deopted code, it's a
            // good idea (as a pattern) to null out any unneeded fields here to avoid
            // holding on to unneeded/stale objects:
            // QUESTION: Shouldn't this whole object be GCed? If not, why not?
            this._tag = null;
            this.reference = null;
            this.cache = null;
            this.bounds = null;
            this.upsert = null;
            this.appendOpcode = null;
            this.state = null;
        };

        GuardedUpdateOpcode.prototype.toJSON = function () {
            var guid = this._guid,
                type = this.type,
                deopted = this.deopted;

            if (deopted) {
                return {
                    guid: guid,
                    type: type,
                    deopted: true,
                    children: [deopted.toJSON()]
                };
            } else {
                return _UpdateOpcode.prototype.toJSON.call(this);
            }
        };

        return GuardedUpdateOpcode;
    }(UpdateOpcode);

    var OptimizedCautiousAppendOpcode = function (_AppendDynamicOpcode2) {
        (0, _emberBabel.inherits)(OptimizedCautiousAppendOpcode, _AppendDynamicOpcode2);

        function OptimizedCautiousAppendOpcode() {

            var _this60 = (0, _emberBabel.possibleConstructorReturn)(this, _AppendDynamicOpcode2.apply(this, arguments));

            _this60.type = 'optimized-cautious-append';
            return _this60;
        }

        OptimizedCautiousAppendOpcode.prototype.normalize = function (reference) {
            return (0, _reference2.map)(reference, normalizeValue);
        };

        OptimizedCautiousAppendOpcode.prototype.insert = function (dom, cursor, value) {
            return cautiousInsert(dom, cursor, value);
        };

        OptimizedCautiousAppendOpcode.prototype.updateWith = function (_vm, _reference, cache, bounds$$1, upsert) {
            return new OptimizedCautiousUpdateOpcode(cache, bounds$$1, upsert);
        };

        return OptimizedCautiousAppendOpcode;
    }(AppendDynamicOpcode);

    var OptimizedCautiousUpdateOpcode = function (_UpdateOpcode2) {
        (0, _emberBabel.inherits)(OptimizedCautiousUpdateOpcode, _UpdateOpcode2);

        function OptimizedCautiousUpdateOpcode() {

            var _this61 = (0, _emberBabel.possibleConstructorReturn)(this, _UpdateOpcode2.apply(this, arguments));

            _this61.type = 'optimized-cautious-update';
            return _this61;
        }

        OptimizedCautiousUpdateOpcode.prototype.insert = function (dom, cursor, value) {
            return cautiousInsert(dom, cursor, value);
        };

        return OptimizedCautiousUpdateOpcode;
    }(UpdateOpcode);

    var GuardedCautiousAppendOpcode = function (_GuardedAppendOpcode) {
        (0, _emberBabel.inherits)(GuardedCautiousAppendOpcode, _GuardedAppendOpcode);

        function GuardedCautiousAppendOpcode() {

            var _this62 = (0, _emberBabel.possibleConstructorReturn)(this, _GuardedAppendOpcode.apply(this, arguments));

            _this62.type = 'guarded-cautious-append';
            _this62.AppendOpcode = OptimizedCautiousAppendOpcode;
            return _this62;
        }

        GuardedCautiousAppendOpcode.prototype.normalize = function (reference) {
            return (0, _reference2.map)(reference, normalizeValue);
        };

        GuardedCautiousAppendOpcode.prototype.insert = function (dom, cursor, value) {
            return cautiousInsert(dom, cursor, value);
        };

        GuardedCautiousAppendOpcode.prototype.updateWith = function (vm, reference, cache, bounds$$1, upsert) {
            return new GuardedCautiousUpdateOpcode(reference, cache, bounds$$1, upsert, this, vm.capture());
        };

        return GuardedCautiousAppendOpcode;
    }(GuardedAppendOpcode);

    var GuardedCautiousUpdateOpcode = function (_GuardedUpdateOpcode) {
        (0, _emberBabel.inherits)(GuardedCautiousUpdateOpcode, _GuardedUpdateOpcode);

        function GuardedCautiousUpdateOpcode() {

            var _this63 = (0, _emberBabel.possibleConstructorReturn)(this, _GuardedUpdateOpcode.apply(this, arguments));

            _this63.type = 'guarded-cautious-update';
            return _this63;
        }

        GuardedCautiousUpdateOpcode.prototype.insert = function (dom, cursor, value) {
            return cautiousInsert(dom, cursor, value);
        };

        return GuardedCautiousUpdateOpcode;
    }(GuardedUpdateOpcode);

    var OptimizedTrustingAppendOpcode = function (_AppendDynamicOpcode3) {
        (0, _emberBabel.inherits)(OptimizedTrustingAppendOpcode, _AppendDynamicOpcode3);

        function OptimizedTrustingAppendOpcode() {

            var _this64 = (0, _emberBabel.possibleConstructorReturn)(this, _AppendDynamicOpcode3.apply(this, arguments));

            _this64.type = 'optimized-trusting-append';
            return _this64;
        }

        OptimizedTrustingAppendOpcode.prototype.normalize = function (reference) {
            return (0, _reference2.map)(reference, normalizeTrustedValue);
        };

        OptimizedTrustingAppendOpcode.prototype.insert = function (dom, cursor, value) {
            return trustingInsert(dom, cursor, value);
        };

        OptimizedTrustingAppendOpcode.prototype.updateWith = function (_vm, _reference, cache, bounds$$1, upsert) {
            return new OptimizedTrustingUpdateOpcode(cache, bounds$$1, upsert);
        };

        return OptimizedTrustingAppendOpcode;
    }(AppendDynamicOpcode);

    var OptimizedTrustingUpdateOpcode = function (_UpdateOpcode3) {
        (0, _emberBabel.inherits)(OptimizedTrustingUpdateOpcode, _UpdateOpcode3);

        function OptimizedTrustingUpdateOpcode() {

            var _this65 = (0, _emberBabel.possibleConstructorReturn)(this, _UpdateOpcode3.apply(this, arguments));

            _this65.type = 'optimized-trusting-update';
            return _this65;
        }

        OptimizedTrustingUpdateOpcode.prototype.insert = function (dom, cursor, value) {
            return trustingInsert(dom, cursor, value);
        };

        return OptimizedTrustingUpdateOpcode;
    }(UpdateOpcode);

    var GuardedTrustingAppendOpcode = function (_GuardedAppendOpcode2) {
        (0, _emberBabel.inherits)(GuardedTrustingAppendOpcode, _GuardedAppendOpcode2);

        function GuardedTrustingAppendOpcode() {

            var _this66 = (0, _emberBabel.possibleConstructorReturn)(this, _GuardedAppendOpcode2.apply(this, arguments));

            _this66.type = 'guarded-trusting-append';
            _this66.AppendOpcode = OptimizedTrustingAppendOpcode;
            return _this66;
        }

        GuardedTrustingAppendOpcode.prototype.normalize = function (reference) {
            return (0, _reference2.map)(reference, normalizeTrustedValue);
        };

        GuardedTrustingAppendOpcode.prototype.insert = function (dom, cursor, value) {
            return trustingInsert(dom, cursor, value);
        };

        GuardedTrustingAppendOpcode.prototype.updateWith = function (vm, reference, cache, bounds$$1, upsert) {
            return new GuardedTrustingUpdateOpcode(reference, cache, bounds$$1, upsert, this, vm.capture());
        };

        return GuardedTrustingAppendOpcode;
    }(GuardedAppendOpcode);

    var GuardedTrustingUpdateOpcode = function (_GuardedUpdateOpcode2) {
        (0, _emberBabel.inherits)(GuardedTrustingUpdateOpcode, _GuardedUpdateOpcode2);

        function GuardedTrustingUpdateOpcode() {

            var _this67 = (0, _emberBabel.possibleConstructorReturn)(this, _GuardedUpdateOpcode2.apply(this, arguments));

            _this67.type = 'trusting-update';
            return _this67;
        }

        GuardedTrustingUpdateOpcode.prototype.insert = function (dom, cursor, value) {
            return trustingInsert(dom, cursor, value);
        };

        return GuardedTrustingUpdateOpcode;
    }(GuardedUpdateOpcode);

    APPEND_OPCODES.add(49 /* PutDynamicPartial */, function (vm, _ref32) {
        var _symbolTable = _ref32.op1;

        var env = vm.env;
        var symbolTable = vm.constants.getOther(_symbolTable);

        var reference = (0, _reference2.map)(vm.frame.getOperand(), function (name) {
            var normalized = String(name);
            if (!env.hasPartial(normalized, symbolTable)) {
                throw new Error('Could not find a partial named "' + normalized + '"');
            }
            return env.lookupPartial(normalized, symbolTable);
        });
        var cache = (0, _reference2.isConst)(reference) ? undefined : new _reference2.ReferenceCache(reference);
        var definition = cache ? cache.peek() : reference.value();
        vm.frame.setImmediate(definition);
        if (cache) {
            vm.updateWith(new Assert(cache));
        }
    });
    APPEND_OPCODES.add(50 /* PutPartial */, function (vm, _ref33) {
        var _definition = _ref33.op1;

        var definition = vm.constants.getOther(_definition);
        vm.frame.setImmediate(definition);
    });
    APPEND_OPCODES.add(51 /* EvaluatePartial */, function (vm, _ref34) {
        var _symbolTable = _ref34.op1,
            _cache = _ref34.op2;

        var symbolTable = vm.constants.getOther(_symbolTable);
        var cache = vm.constants.getOther(_cache);

        var _vm$frame$getImmediat = vm.frame.getImmediate(),
            template = _vm$frame$getImmediat.template;

        var block = cache[template.id];
        if (!block) {
            block = template.asPartial(symbolTable);
        }
        vm.invokePartial(block);
    });

    var IterablePresenceReference = function () {
        function IterablePresenceReference(artifacts) {

            this.tag = artifacts.tag;
            this.artifacts = artifacts;
        }

        IterablePresenceReference.prototype.value = function () {
            return !this.artifacts.isEmpty();
        };

        return IterablePresenceReference;
    }();

    APPEND_OPCODES.add(44 /* PutIterator */, function (vm) {
        var listRef = vm.frame.getOperand();
        var args = (0, _util.expect)(vm.frame.getArgs(), 'PutIteratorOpcode expects a populated args register');
        var iterable = vm.env.iterableFor(listRef, args);
        var iterator = new _reference2.ReferenceIterator(iterable);
        vm.frame.setIterator(iterator);
        vm.frame.setCondition(new IterablePresenceReference(iterator.artifacts));
    });
    APPEND_OPCODES.add(45 /* EnterList */, function (vm, _ref35) {
        var start = _ref35.op1,
            end = _ref35.op2;

        vm.enterList(start, end);
    });
    APPEND_OPCODES.add(46 /* ExitList */, function (vm) {
        return vm.exitList();
    });
    APPEND_OPCODES.add(47 /* EnterWithKey */, function (vm, _ref36) {
        var start = _ref36.op1,
            end = _ref36.op2;

        var key = (0, _util.expect)(vm.frame.getKey(), 'EnterWithKeyOpcode expects a populated key register');
        vm.enterWithKey(key, start, end);
    });
    var TRUE_REF = new _reference2.ConstReference(true);
    var FALSE_REF = new _reference2.ConstReference(false);
    APPEND_OPCODES.add(48 /* NextIter */, function (vm, _ref37) {
        var end = _ref37.op1;

        var item = vm.frame.getIterator().next();
        if (item) {
            vm.frame.setCondition(TRUE_REF);
            vm.frame.setKey(item.key);
            vm.frame.setOperand(item.value);
            vm.frame.setArgs(EvaluatedArgs.positional([item.value, item.memo]));
        } else {
            vm.frame.setCondition(FALSE_REF);
            vm.goto(end);
        }
    });

    var TemplateIterator = function () {
        function TemplateIterator(vm) {

            this.vm = vm;
        }

        TemplateIterator.prototype.next = function () {
            return this.vm.next();
        };

        return TemplateIterator;
    }();

    var clientId = 0;

    function template(block, id, meta, env) {
        var scanner = new Scanner(block, meta, env);
        var entryPoint = void 0;
        var asEntryPoint = function () {
            if (!entryPoint) entryPoint = scanner.scanEntryPoint();
            return entryPoint;
        };
        var layout = void 0;

        return { id: id, meta: meta, _block: block, asEntryPoint: asEntryPoint, asLayout: function () {
                if (!layout) layout = scanner.scanLayout();
                return layout;
            }, asPartial: function (symbols) {
                return scanner.scanPartial(symbols);
            }, render: function (self, appendTo, dynamicScope) {
                var elementStack = ElementStack.forInitialRender(env, appendTo, null);
                var compiled = asEntryPoint().compile(env);
                var vm = VM.initial(env, self, dynamicScope, elementStack, compiled);
                return new TemplateIterator(vm);
            } };
    }

    var DynamicVarReference = function () {
        function DynamicVarReference(scope, nameRef) {

            this.scope = scope;
            this.nameRef = nameRef;
            var varTag = this.varTag = new _reference2.UpdatableTag(_reference2.CONSTANT_TAG);
            this.tag = (0, _reference2.combine)([nameRef.tag, varTag]);
        }

        DynamicVarReference.prototype.value = function () {
            return this.getVar().value();
        };

        DynamicVarReference.prototype.get = function (key) {
            return this.getVar().get(key);
        };

        DynamicVarReference.prototype.getVar = function () {
            var name = String(this.nameRef.value());
            var ref = this.scope.get(name);
            this.varTag.update(ref.tag);
            return ref;
        };

        return DynamicVarReference;
    }();

    var NodeType;
    (function (NodeType) {
        NodeType[NodeType["Element"] = 0] = "Element";
        NodeType[NodeType["Attribute"] = 1] = "Attribute";
        NodeType[NodeType["Text"] = 2] = "Text";
        NodeType[NodeType["CdataSection"] = 3] = "CdataSection";
        NodeType[NodeType["EntityReference"] = 4] = "EntityReference";
        NodeType[NodeType["Entity"] = 5] = "Entity";
        NodeType[NodeType["ProcessingInstruction"] = 6] = "ProcessingInstruction";
        NodeType[NodeType["Comment"] = 7] = "Comment";
        NodeType[NodeType["Document"] = 8] = "Document";
        NodeType[NodeType["DocumentType"] = 9] = "DocumentType";
        NodeType[NodeType["DocumentFragment"] = 10] = "DocumentFragment";
        NodeType[NodeType["Notation"] = 11] = "Notation";
    })(NodeType || (NodeType = {}));

    var interfaces = Object.freeze({
        get NodeType() {
            return NodeType;
        }
    });

    exports.Simple = interfaces;
    exports.templateFactory = function (_ref38) {
        var templateId = _ref38.id,
            meta = _ref38.meta,
            block = _ref38.block;

        var parsedBlock = void 0;
        var id = templateId || 'client-' + clientId++;

        return { id: id, meta: meta, create: function (env, envMeta) {
                var newMeta = envMeta ? (0, _util.assign)({}, envMeta, meta) : meta;
                if (!parsedBlock) {
                    parsedBlock = JSON.parse(block);
                }
                return template(parsedBlock, id, newMeta, env);
            } };
    };
    exports.NULL_REFERENCE = NULL_REFERENCE;
    exports.UNDEFINED_REFERENCE = UNDEFINED_REFERENCE;
    exports.PrimitiveReference = PrimitiveReference;
    exports.ConditionalReference = ConditionalReference;
    exports.OpcodeBuilderDSL = OpcodeBuilder;
    exports.compileLayout = function (compilable, env) {
        var builder = new ComponentLayoutBuilder(env);
        compilable.compile(builder);
        return builder.compile();
    };
    exports.CompiledBlock = CompiledBlock;
    exports.CompiledProgram = CompiledProgram;
    exports.IAttributeManager = AttributeManager;
    exports.AttributeManager = AttributeManager;
    exports.PropertyManager = PropertyManager;
    exports.INPUT_VALUE_PROPERTY_MANAGER = INPUT_VALUE_PROPERTY_MANAGER;
    exports.defaultManagers = defaultManagers;
    exports.defaultAttributeManagers = defaultAttributeManagers;
    exports.defaultPropertyManagers = defaultPropertyManagers;
    exports.readDOMAttr = function (element, attr) {
        var isSVG = element.namespaceURI === SVG_NAMESPACE$$1;

        var _normalizeProperty2 = normalizeProperty(element, attr),
            type = _normalizeProperty2.type,
            normalized = _normalizeProperty2.normalized;

        if (isSVG) {
            return element.getAttribute(normalized);
        }
        if (type === 'attr') {
            return element.getAttribute(normalized);
        }
        {
            return element[normalized];
        }
    };
    exports.normalizeTextValue = normalizeTextValue;
    exports.CompiledExpression = CompiledExpression;
    exports.CompiledArgs = CompiledArgs;
    exports.CompiledNamedArgs = CompiledNamedArgs;
    exports.CompiledPositionalArgs = CompiledPositionalArgs;
    exports.EvaluatedArgs = EvaluatedArgs;
    exports.EvaluatedNamedArgs = EvaluatedNamedArgs;
    exports.EvaluatedPositionalArgs = EvaluatedPositionalArgs;
    exports.getDynamicVar = function (vm, args) {
        var scope = vm.dynamicScope();
        var nameRef = args.positional.at(0);
        return new DynamicVarReference(scope, nameRef);
    };
    exports.BlockMacros = Blocks;
    exports.InlineMacros = Inlines;
    exports.compileArgs = compileArgs;
    exports.setDebuggerCallback = function (cb) {
        callback = cb;
    };
    exports.resetDebuggerCallback = function () {
        callback = debugCallback;
    };
    exports.BaselineSyntax = BaselineSyntax;
    exports.Layout = Layout;
    exports.UpdatingVM = UpdatingVM;
    exports.RenderResult = RenderResult;
    exports.isSafeString = isSafeString;
    exports.Scope = Scope;
    exports.Environment = Environment;
    exports.PartialDefinition = function (name, template) {

        this.name = name;
        this.template = template;
    };
    exports.ComponentDefinition = function (name, manager, ComponentClass) {

        this[COMPONENT_DEFINITION_BRAND] = true;
        this.name = name;
        this.manager = manager;
        this.ComponentClass = ComponentClass;
    };
    exports.isComponentDefinition = isComponentDefinition;
    exports.DOMChanges = helper$1;
    exports.IDOMChanges = DOMChanges;
    exports.DOMTreeConstruction = DOMTreeConstruction;
    exports.isWhitespace = function (string) {
        return WHITESPACE.test(string);
    };
    exports.insertHTMLBefore = _insertHTMLBefore;
    exports.ElementStack = ElementStack;
    exports.ConcreteBounds = ConcreteBounds;
});