define('smoke-and-mirrors/utils/element/apply-dimensions', ['exports'], function (exports) {
  'use strict';

  exports['default'] = applyDimensions;

  function applyDimensions(element, dimensions) {
    for (var i in dimensions.style) {
      if (dimensions.style.hasOwnProperty(i)) {
        element.style[i] = i === 'boxSizing' ? dimensions.style[i] : dimensions.style[i] + 'px';
      }
    }
  }
});