define('smoke-and-mirrors/-private/scheduler/index', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ('value' in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
      }
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }

  var run = _ember['default'].run;

  function Token() {
    this.cancelled = false;
  }
  function job(cb, token) {
    return function execJob() {
      if (token.cancelled === false) {
        cb();
      }
    };
  }

  var Scheduler = (function () {
    function Scheduler() {
      this.sync = [];
      this.layout = [];
      this.measure = [];
      this.affect = [];
      this._nextFlush = null;
    }

    _createClass(Scheduler, [{
      key: 'schedule',
      value: function schedule(queueName, cb) {
        var token = new Token();

        this[queueName].push(job(cb, token));
        this._flush();

        return token;
      }
    }, {
      key: 'forget',
      value: function forget(token) {
        if (token) {
          token.cancelled = true;
        }
      }
    }, {
      key: '_flush',
      value: function _flush() {
        var _this = this;

        if (this._nextFlush !== null) {
          return;
        }

        this._nextFlush = requestAnimationFrame(function () {
          _this._nextFlush = null;
          _this.flush();
        });
      }
    }, {
      key: 'flush',
      value: function flush() {
        run.begin();
        var i = undefined;
        for (i = 0; i < this.sync.length; i++) {
          run.schedule('actions', this.sync[i]);
          this.sync[i] = undefined;
        }
        this.sync.length = 0;

        for (i = 0; i < this.layout.length; i++) {
          run.schedule('actions', this.layout[i]);
          this.layout[i] = undefined;
        }
        this.layout.length = 0;

        for (i = 0; i < this.affect.length; i++) {
          run.schedule('afterRender', this.affect[i]);
          this.affect[i] = undefined;
        }
        this.affect.length = 0;

        for (i = 0; i < this.measure.length; i++) {
          run.schedule('afterRender', this.measure[i]);
          this.measure[i] = undefined;
        }
        this.measure.length = 0;
        run.end();
      }
    }]);

    return Scheduler;
  })();

  exports.Scheduler = Scheduler;
  exports['default'] = new Scheduler();
});