define('ember-inputmask/components/email-input', ['exports', 'ember-inputmask/components/input-mask'], function (exports, _emberInputmaskComponentsInputMask) {
  'use strict';

  /**
   * `{{email-input}}` component.
   *
   * Displays an input that masks email addresses.
   */

  exports['default'] = _emberInputmaskComponentsInputMask['default'].extend({
    oldComponent: '{{email-input}}',
    newComponent: '{{one-way-email-mask}}',
    mask: 'email'
  });
});