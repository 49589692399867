enifed('ember-glimmer/syntax', ['exports', 'ember-glimmer/syntax/render', 'ember-glimmer/syntax/outlet', 'ember-glimmer/syntax/mount', 'ember-glimmer/syntax/dynamic-component', 'ember-glimmer/utils/bindings', 'ember-glimmer/syntax/-with-dynamic-vars', 'ember-glimmer/syntax/-in-element', 'ember-glimmer/syntax/input', 'ember-glimmer/syntax/-text-area', 'ember-debug'], function (exports, _render, _outlet, _mount, _dynamicComponent, _bindings, _withDynamicVars, _inElement, _input, _textArea, _emberDebug) {
  'use strict';

  exports.experimentalMacros = undefined;
  exports.registerMacros =

  // This is a private API to allow for expiremental macros
  // to be created in user space. Registering a macro should
  // should be done in an initializer.
  function (macro) {
    experimentalMacros.push(macro);
  };
  exports.populateMacros = function (blocks, inlines) {
    var i, macro;

    inlines.add('outlet', _outlet.outletMacro);
    inlines.add('component', _dynamicComponent.inlineComponentMacro);
    inlines.add('render', _render.renderMacro);
    inlines.add('mount', _mount.mountMacro);
    inlines.add('input', _input.inputMacro);
    inlines.add('textarea', _textArea.textAreaMacro);
    inlines.addMissing(refineInlineSyntax);
    blocks.add('component', _dynamicComponent.blockComponentMacro);
    blocks.add('-with-dynamic-vars', _withDynamicVars._withDynamicVarsMacro);
    blocks.add('-in-element', _inElement._inElementMacro);
    blocks.addMissing(refineBlockSyntax);

    for (i = 0; i < experimentalMacros.length; i++) {
      macro = experimentalMacros[i];

      macro(blocks, inlines);
    }

    return { blocks: blocks, inlines: inlines };
  };

  function refineInlineSyntax(path, params, hash, builder) {
    var name = path[0];
    false && !!(builder.env.builtInHelpers[name] && builder.env.owner.hasRegistration('helper:' + name)) && (0, _emberDebug.assert)('You attempted to overwrite the built-in helper "' + name + '" which is not allowed. Please rename the helper.', !(builder.env.builtInHelpers[name] && builder.env.owner.hasRegistration('helper:' + name)));

    if (path.length > 1) {
      return (0, _dynamicComponent.closureComponentMacro)(path, params, hash, null, null, builder);
    }

    var symbolTable = builder.symbolTable;

    var definition = void 0;
    if (name.indexOf('-') > -1) {
      definition = builder.env.getComponentDefinition(path, symbolTable);
    }

    if (definition) {
      (0, _bindings.wrapComponentClassAttribute)(hash);
      builder.component.static(definition, [params, hash, null, null], symbolTable);
      return true;
    }

    return false;
  }

  function refineBlockSyntax(sexp, builder) {
    var path = sexp[1],
        params = sexp[2],
        hash = sexp[3],
        _default = sexp[4],
        inverse = sexp[5];
    var name = path[0];

    if (path.length > 1) {
      return (0, _dynamicComponent.closureComponentMacro)(path, params, hash, _default, inverse, builder);
    }

    if (name.indexOf('-') === -1) {
      return false;
    }

    var symbolTable = builder.symbolTable;

    var definition = void 0;
    if (name.indexOf('-') > -1) {
      definition = builder.env.getComponentDefinition(path, symbolTable);
    }

    if (definition) {
      (0, _bindings.wrapComponentClassAttribute)(hash);
      builder.component.static(definition, [params, hash, _default, inverse], symbolTable);
      return true;
    }

    false && !builder.env.hasHelper(path, symbolTable) && (0, _emberDebug.assert)('A component or helper named "' + name + '" could not be found', builder.env.hasHelper(path, symbolTable));
    false && !!builder.env.hasHelper(path, symbolTable) && (0, _emberDebug.assert)('Helpers may not be used in the block form, for example {{#' + name + '}}{{/' + name + '}}. Please use a component, or alternatively use the helper in combination with a built-in Ember helper, for example {{#if (' + name + ')}}{{/if}}.', !builder.env.hasHelper(path, symbolTable));

    return false;
  }

  var experimentalMacros = exports.experimentalMacros = [];
});