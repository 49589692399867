define('smoke-and-mirrors/-private/ember/utils/identity', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = identity;

  var guidFor = _ember['default'].guidFor;

  function identity(item) {
    var key = undefined;
    var type = typeof item;

    if (type === 'string' || type === 'number') {
      key = item;
    } else {
      key = guidFor(item);
    }

    return key;
  }
});