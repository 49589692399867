enifed('ember-glimmer/syntax/-with-dynamic-vars', ['exports', '@glimmer/runtime', '@glimmer/util'], function (exports, _runtime, _util) {
  'use strict';

  exports._withDynamicVarsMacro = function (sexp, builder) {
    var block = defaultBlock(sexp);
    var args = (0, _runtime.compileArgs)(params(sexp), hash(sexp), builder);

    builder.unit(function (b) {
      b.putArgs(args);
      b.pushDynamicScope();
      b.bindDynamicScope(args.named.keys);
      b.evaluate((0, _util.unwrap)(block));
      b.popDynamicScope();
    });
  };
  var _BaselineSyntax$Neste = _runtime.BaselineSyntax.NestedBlock,
      defaultBlock = _BaselineSyntax$Neste.defaultBlock,
      params = _BaselineSyntax$Neste.params,
      hash = _BaselineSyntax$Neste.hash;
});