enifed('ember-glimmer/helpers/concat', ['exports', 'ember-glimmer/utils/references', '@glimmer/runtime'], function (exports, _references, _runtime) {
  'use strict';

  exports.default = function (vm, args) {
    return new _references.InternalHelperReference(concat, args);
  };

  /**
  @module ember
  @submodule ember-glimmer
  */

  /**
    Concatenates the given arguments into a string.
  
    Example:
  
    ```handlebars
    {{some-component name=(concat firstName " " lastName)}}
  
    {{! would pass name="<first name value> <last name value>" to the component}}
    ```
  
    @public
    @method concat
    @for Ember.Templates.helpers
    @since 1.13.0
  */
  function concat(_ref) {
    var positional = _ref.positional;

    return positional.value().map(_runtime.normalizeTextValue).join('');
  }
});