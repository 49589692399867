enifed('ember-glimmer/utils/process-args', ['exports', 'ember-utils', '@glimmer/reference', 'ember-glimmer/component', 'ember-glimmer/utils/references', 'ember-views', 'ember-glimmer/helpers/action', '@glimmer/runtime'], function (exports, _emberUtils, _reference, _component, _references, _emberViews, _action, _runtime) {
  'use strict';

  exports.ComponentArgs = undefined;
  exports.gatherArgs =

  // Maps all variants of positional and dynamically scoped arguments
  // into the named arguments. Input `args` and return value are both
  // `EvaluatedArgs`.
  function (args, definition) {
    var namedMap = gatherNamedMap(args, definition);
    var positionalValues = gatherPositionalValues(args, definition);
    return mergeArgs(namedMap, positionalValues, args.blocks, definition.ComponentClass.class);
  };

  function gatherNamedMap(args, definition) {
    var namedMap = args.named.map;
    if (definition.args) {
      return (0, _emberUtils.assign)({}, definition.args.named.map, namedMap);
    } else {
      return namedMap;
    }
  }

  function gatherPositionalValues(args, definition) {
    var positionalValues = args.positional.values,
        oldPositional,
        newPositional;
    if (definition.args) {
      oldPositional = definition.args.positional.values;
      newPositional = [];

      newPositional.push.apply(newPositional, oldPositional);
      newPositional.splice.apply(newPositional, [0, positionalValues.length].concat(positionalValues));
      return newPositional;
    } else {
      return positionalValues;
    }
  }

  function mergeArgs(namedMap, positionalValues, blocks, componentClass) {
    var positionalParamsDefinition = componentClass.positionalParams;

    if (positionalParamsDefinition && positionalParamsDefinition.length > 0 && positionalValues.length > 0) {
      if (typeof positionalParamsDefinition === 'string') {
        namedMap = mergeRestArg(namedMap, positionalValues, positionalParamsDefinition);
      } else {
        namedMap = mergePositionalParams(namedMap, positionalValues, positionalParamsDefinition);
      }
    }
    return _runtime.EvaluatedArgs.named(namedMap, blocks);
  }

  var EMPTY_ARGS = {
    tag: _reference.CONSTANT_TAG,
    value: function () {
      var _props;

      return { attrs: {}, props: (_props = { attrs: {} }, _props[_component.ARGS] = {}, _props) };
    }
  };

  // ComponentArgs takes EvaluatedNamedArgs and converts them into the
  // inputs needed by CurlyComponents (attrs and props, with mutable
  // cells, etc).

  exports.ComponentArgs = function () {
    ComponentArgs.create = function (args) {
      if (args.named.keys.length === 0) {
        return EMPTY_ARGS;
      } else {
        return new ComponentArgs(args.named);
      }
    };

    function ComponentArgs(namedArgs) {

      this.tag = namedArgs.tag;
      this.namedArgs = namedArgs;
    }

    ComponentArgs.prototype.value = function () {
      var namedArgs = this.namedArgs,
          value,
          i,
          l,
          name,
          ref;

      var keys = namedArgs.keys;
      var attrs = namedArgs.value();
      var props = Object.create(null);
      var args = Object.create(null);

      props[_component.ARGS] = args;

      for (i = 0, l = keys.length; i < l; i++) {
        name = keys[i];
        ref = namedArgs.get(name);
        value = attrs[name];


        if (typeof value === 'function' && value[_action.ACTION]) {
          attrs[name] = value;
        } else if (ref[_references.UPDATE]) {
          attrs[name] = new MutableCell(ref, value);
        }

        args[name] = ref;
        props[name] = value;
      }

      props.attrs = attrs;

      return { attrs: attrs, props: props };
    };

    return ComponentArgs;
  }();

  function mergeRestArg(namedMap, positionalValues, restArgName) {
    var mergedNamed = (0, _emberUtils.assign)({}, namedMap);
    mergedNamed[restArgName] = _runtime.EvaluatedPositionalArgs.create(positionalValues);
    return mergedNamed;
  }

  function mergePositionalParams(namedMap, values, positionalParamNames) {
    var mergedNamed = (0, _emberUtils.assign)({}, namedMap),
        i,
        name;
    var length = Math.min(values.length, positionalParamNames.length);
    for (i = 0; i < length; i++) {
      name = positionalParamNames[i];

      mergedNamed[name] = values[i];
    }
    return mergedNamed;
  }

  var REF = (0, _emberUtils.symbol)('REF');

  var MutableCell = function () {
    function MutableCell(ref, value) {

      this[_emberViews.MUTABLE_CELL] = true;
      this[REF] = ref;
      this.value = value;
    }

    MutableCell.prototype.update = function (val) {
      this[REF][_references.UPDATE](val);
    };

    return MutableCell;
  }();
});