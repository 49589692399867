define('ember-light-table/components/lt-scrollable', ['exports', 'ember-light-table/templates/components/lt-scrollable'], function (exports, _emberLightTableTemplatesComponentsLtScrollable) {
  'use strict';

  var Component = Ember.Component;

  exports['default'] = Component.extend({
    layout: _emberLightTableTemplatesComponentsLtScrollable['default'],
    tagName: '',
    vertical: true,
    horizontal: false
  });
});