define('ember-tooltips/utils', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var computed = _ember['default'].computed;
  var warn = _ember['default'].warn;
  var onComponentTarget = computed(function () {

    var targetView = undefined;

    if (this.get('_shouldTargetGrandparentView')) {

      /* The parentView is the lazy-render-wrapper
      and we want to ignore that tagless component
      */

      targetView = this.get('parentView.parentView');
    } else {
      targetView = this.get('parentView');
    }

    if (!targetView) {
      warn('No targetView found');

      return null;
    } else if (!targetView.get('elementId')) {
      warn('No targetView.elementId');

      return null;
    } else {
      return '#' + targetView.get('elementId');
    }
  });
  exports.onComponentTarget = onComponentTarget;
});