define("ember-composable-helpers/helpers/filter-by", ["exports", "ember-composable-helpers/utils/is-equal"], function (exports, _emberComposableHelpersUtilsIsEqual) {
  "use strict";

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  })();

  var defineProperty = Ember.defineProperty;

  exports["default"] = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 3),
          byPath = _ref2[0],
          value = _ref2[1],
          array = _ref2[2];

      if (!Ember.isArray(array) && Ember.isArray(value)) {
        array = value;
        value = undefined;
      }

      Ember.set(this, 'array', array);
      Ember.set(this, 'byPath', byPath);
      Ember.set(this, 'value', value);

      return Ember.get(this, 'content');
    },

    byPathDidChange: Ember.observer('byPath', 'value', function () {
      var byPath = Ember.get(this, 'byPath');
      var value = Ember.get(this, 'value');

      if (Ember.isEmpty(byPath)) {
        defineProperty(this, 'content', []);
        return;
      }

      var filterFn = void 0;

      if (Ember.isPresent(value)) {
        if (typeof value === 'function') {
          filterFn = function filterFn(item) {
            return value(Ember.get(item, byPath));
          };
        } else {
          filterFn = function filterFn(item) {
            return (0, _emberComposableHelpersUtilsIsEqual["default"])(Ember.get(item, byPath), value);
          };
        }
      } else {
        filterFn = function filterFn(item) {
          return !!Ember.get(item, byPath);
        };
      }

      var cp = Ember.computed.filter('array.@each.' + byPath, filterFn);

      defineProperty(this, 'content', cp);
    }),

    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });
});