enifed('ember-glimmer/syntax/outlet', ['exports', 'ember-babel', 'ember-utils', '@glimmer/runtime', 'ember-metal', 'ember-glimmer/utils/references', 'ember-glimmer/syntax/abstract-manager', '@glimmer/reference'], function (exports, _emberBabel, _emberUtils, _runtime, _emberMetal, _references, _abstractManager, _reference) {
  'use strict';

  exports.OutletLayoutCompiler = exports.TopLevelOutletComponentDefinition = undefined;
  exports.outletMacro =

  /**
    The `{{outlet}}` helper lets you specify where a child route will render in
    your template. An important use of the `{{outlet}}` helper is in your
    application's `application.hbs` file:
  
    ```handlebars
    {{! app/templates/application.hbs }}
    <!-- header content goes here, and will always display -->
    {{my-header}}
    <div class="my-dynamic-content">
      <!-- this content will change based on the current route, which depends on the current URL -->
      {{outlet}}
    </div>
    <!-- footer content goes here, and will always display -->
    {{my-footer}}
    ```
  
    See [templates guide](http://emberjs.com/guides/templates/the-application-template/) for
    additional information on using `{{outlet}}` in `application.hbs`.
    You may also specify a name for the `{{outlet}}`, which is useful when using more than one
    `{{outlet}}` in a template:
  
    ```handlebars
    {{outlet "menu"}}
    {{outlet "sidebar"}}
    {{outlet "main"}}
    ```
  
    Your routes can then render into a specific one of these `outlet`s by specifying the `outlet`
    attribute in your `renderTemplate` function:
  
    ```javascript
    // app/routes/menu.js
    export default Ember.Route.extend({
      renderTemplate() {
        this.render({ outlet: 'menu' });
      }
    });
    ```
  
    See the [routing guide](http://emberjs.com/guides/routing/rendering-a-template/) for more
    information on how your `route` interacts with the `{{outlet}}` helper.
    Note: Your content __will not render__ if there isn't an `{{outlet}}` for it.
  
    @method outlet
    @param {String} [name]
    @for Ember.Templates.helpers
    @public
  */
  function (path, params, hash, builder) {
    if (!params) {
      params = [];
    }
    var definitionArgs = [params.slice(0, 1), null, null, null];
    builder.component.dynamic(definitionArgs, outletComponentFor, _runtime.CompiledArgs.empty(), builder.symbolTable, null);
    return true;
  };
  /**
  @module ember
  @submodule ember-glimmer
  */
  function outletComponentFor(vm) {
    var _vm$dynamicScope = vm.dynamicScope(),
        outletState = _vm$dynamicScope.outletState;

    var args = vm.getArgs();
    var outletNameRef = void 0;
    if (args.positional.length === 0) {
      outletNameRef = new _reference.ConstReference('main');
    } else {
      outletNameRef = args.positional.at(0);
    }

    return new OutletComponentReference(outletNameRef, outletState);
  }

  var OutletComponentReference = function () {
    function OutletComponentReference(outletNameRef, parentOutletStateRef) {

      this.outletNameRef = outletNameRef;
      this.parentOutletStateRef = parentOutletStateRef;
      this.definition = null;
      this.lastState = null;
      var outletStateTag = this.outletStateTag = new _reference.UpdatableTag(parentOutletStateRef.tag);
      this.tag = (0, _reference.combine)([outletStateTag.tag, outletNameRef.tag]);
    }

    OutletComponentReference.prototype.value = function () {
      var outletNameRef = this.outletNameRef,
          parentOutletStateRef = this.parentOutletStateRef,
          definition = this.definition,
          lastState = this.lastState;

      var outletName = outletNameRef.value();
      var outletStateRef = parentOutletStateRef.get('outlets').get(outletName);
      var newState = this.lastState = outletStateRef.value();

      this.outletStateTag.update(outletStateRef.tag);

      definition = revalidate(definition, lastState, newState);

      var hasTemplate = newState && newState.render.template;

      if (definition) {
        return definition;
      } else if (hasTemplate) {
        return this.definition = new OutletComponentDefinition(outletName, newState.render.template);
      } else {
        return this.definition = null;
      }
    };

    return OutletComponentReference;
  }();

  function revalidate(definition, lastState, newState) {
    if (!lastState && !newState) {
      return definition;
    }

    if (!lastState && newState || lastState && !newState) {
      return null;
    }

    if (newState.render.template === lastState.render.template && newState.render.controller === lastState.render.controller) {
      return definition;
    }

    return null;
  }

  function instrumentationPayload(_ref) {
    var _ref$render = _ref.render,
        name = _ref$render.name,
        outlet = _ref$render.outlet;

    return { object: name + ':' + outlet };
  }

  function NOOP() {}

  var StateBucket = function () {
    function StateBucket(outletState) {

      this.outletState = outletState;
      this.instrument();
    }

    StateBucket.prototype.instrument = function () {
      this.finalizer = (0, _emberMetal._instrumentStart)('render.outlet', instrumentationPayload, this.outletState);
    };

    StateBucket.prototype.finalize = function () {
      var finalizer = this.finalizer;

      finalizer();
      this.finalizer = NOOP;
    };

    return StateBucket;
  }();

  var OutletComponentManager = function (_AbstractManager) {
    (0, _emberBabel.inherits)(OutletComponentManager, _AbstractManager);

    function OutletComponentManager() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager.apply(this, arguments));
    }

    OutletComponentManager.prototype.prepareArgs = function (definition, args) {
      return args;
    };

    OutletComponentManager.prototype.create = function (environment, definition, args, dynamicScope) {

      var outletStateReference = dynamicScope.outletState = dynamicScope.outletState.get('outlets').get(definition.outletName);
      var outletState = outletStateReference.value();
      return new StateBucket(outletState);
    };

    OutletComponentManager.prototype.layoutFor = function (definition, bucket, env) {
      return env.getCompiledBlock(OutletLayoutCompiler, definition.template);
    };

    OutletComponentManager.prototype.getSelf = function (_ref2) {
      var outletState = _ref2.outletState;

      return new _references.RootReference(outletState.render.controller);
    };

    OutletComponentManager.prototype.getTag = function () {
      return null;
    };

    OutletComponentManager.prototype.getDestructor = function () {
      return null;
    };

    OutletComponentManager.prototype.didRenderLayout = function (bucket) {
      bucket.finalize();
    };

    OutletComponentManager.prototype.didCreateElement = function () {};

    OutletComponentManager.prototype.didCreate = function () {};

    OutletComponentManager.prototype.update = function () {};

    OutletComponentManager.prototype.didUpdateLayout = function () {};

    OutletComponentManager.prototype.didUpdate = function () {};

    return OutletComponentManager;
  }(_abstractManager.default);

  var MANAGER = new OutletComponentManager();

  var TopLevelOutletComponentManager = function (_OutletComponentManag) {
    (0, _emberBabel.inherits)(TopLevelOutletComponentManager, _OutletComponentManag);

    function TopLevelOutletComponentManager() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _OutletComponentManag.apply(this, arguments));
    }

    TopLevelOutletComponentManager.prototype.create = function (environment, definition, args, dynamicScope) {
      return new StateBucket(dynamicScope.outletState.value());
    };

    TopLevelOutletComponentManager.prototype.layoutFor = function (definition, bucket, env) {
      return env.getCompiledBlock(TopLevelOutletLayoutCompiler, definition.template);
    };

    return TopLevelOutletComponentManager;
  }(OutletComponentManager);

  var TOP_LEVEL_MANAGER = new TopLevelOutletComponentManager();

  exports.TopLevelOutletComponentDefinition = function (_ComponentDefinition) {
    (0, _emberBabel.inherits)(TopLevelOutletComponentDefinition, _ComponentDefinition);

    function TopLevelOutletComponentDefinition(instance) {

      var _this3 = (0, _emberBabel.possibleConstructorReturn)(this, _ComponentDefinition.call(this, 'outlet', TOP_LEVEL_MANAGER, instance));

      _this3.template = instance.template;
      (0, _emberUtils.generateGuid)(_this3);
      return _this3;
    }

    return TopLevelOutletComponentDefinition;
  }(_runtime.ComponentDefinition);

  var TopLevelOutletLayoutCompiler = function () {
    function TopLevelOutletLayoutCompiler(template) {

      this.template = template;
    }

    TopLevelOutletLayoutCompiler.prototype.compile = function (builder) {
      builder.wrapLayout(this.template.asLayout());
      builder.tag.static('div');
      builder.attrs.static('id', (0, _emberUtils.guidFor)(this));
      builder.attrs.static('class', 'ember-view');
    };

    return TopLevelOutletLayoutCompiler;
  }();

  TopLevelOutletLayoutCompiler.id = 'top-level-outlet';

  var OutletComponentDefinition = function (_ComponentDefinition2) {
    (0, _emberBabel.inherits)(OutletComponentDefinition, _ComponentDefinition2);

    function OutletComponentDefinition(outletName, template) {

      var _this4 = (0, _emberBabel.possibleConstructorReturn)(this, _ComponentDefinition2.call(this, 'outlet', MANAGER, null));

      _this4.outletName = outletName;
      _this4.template = template;
      (0, _emberUtils.generateGuid)(_this4);
      return _this4;
    }

    return OutletComponentDefinition;
  }(_runtime.ComponentDefinition);

  var OutletLayoutCompiler = exports.OutletLayoutCompiler = function () {
    function OutletLayoutCompiler(template) {

      this.template = template;
    }

    OutletLayoutCompiler.prototype.compile = function (builder) {
      builder.wrapLayout(this.template.asLayout());
    };

    return OutletLayoutCompiler;
  }();

  OutletLayoutCompiler.id = 'outlet';
});