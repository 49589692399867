enifed('ember-routing/services/router', ['exports', 'ember-runtime'], function (exports, _emberRuntime) {
  'use strict';

  /**
     The Router service is the public API that provides component/view layer
     access to the router.
  
     @public
     @class RouterService
     @category ember-routing-router-service
   */

  var RouterService = _emberRuntime.Service.extend({
    currentRouteName: (0, _emberRuntime.readOnly)('router.currentRouteName'),
    currentURL: (0, _emberRuntime.readOnly)('router.currentURL'),
    location: (0, _emberRuntime.readOnly)('router.location'),
    rootURL: (0, _emberRuntime.readOnly)('router.rootURL'),
    router: null,

    transitionTo: function () /* routeNameOrUrl, ...models, options */{
      var _router;

      return (_router = this.router).transitionTo.apply(_router, arguments);
    },
    replaceWith: function () /* routeNameOrUrl, ...models, options */{
      var _router2;

      return (_router2 = this.router).replaceWith.apply(_router2, arguments);
    },
    urlFor: function () /* routeName, ...models, options */{
      var _router3;

      return (_router3 = this.router).generate.apply(_router3, arguments);
    }
  }); /**
      @module ember
      @submodule ember-routing
      */

  exports.default = RouterService;
});