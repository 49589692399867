define('ember-rapid-forms/components/html-checkbox', ['exports', 'ember', 'ember-rapid-forms/templates/components/html-checkbox'], function (exports, _ember, _emberRapidFormsTemplatesComponentsHtmlCheckbox) {
  'use strict';

  function setFromName(self, mainComponent, name, optionalSecondName) {
    if (mainComponent && self) {
      var val = mainComponent.get(name);
      if (val) {
        if (optionalSecondName) {
          self.set(optionalSecondName, [val]);
        } else {
          self.set(name, val);
        }
      }
    }
  }

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberRapidFormsTemplatesComponentsHtmlCheckbox['default'],
    tagName: 'input',
    attributeBindings: ['type', 'checked', 'id', 'disabled', 'required', 'autofocus'],

    type: "checkbox",
    checked: false,
    init: function init() {
      this.elementId = this.get('mainComponent.id');
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() /*attrs*/{
      this._super.apply(this, arguments);
      // set it to the correct value of the selection
      this.checked = _ember['default'].computed('mainComponent.model.' + this.get('mainComponent.property'), function () {
        return this.get('mainComponent.model.' + this.get('mainComponent.property'));
      });

      var mainComponent = this.get('mainComponent');

      setFromName(this, mainComponent, 'name');
      setFromName(this, mainComponent, 'id');
      setFromName(this, mainComponent, 'disabled');
      setFromName(this, mainComponent, 'elementClass', 'classNames');
      setFromName(this, mainComponent, 'required');
      setFromName(this, mainComponent, 'autofocus');
    },
    change: function change() {
      var selectedEl = this.$()[0];
      var checked = selectedEl.checked;
      this.set('mainComponent.model.' + this.get('mainComponent.property'), checked);
      var changeAction = this.get('action');
      if (changeAction) {
        changeAction(checked);
      }
    },
    input: function input() {
      // input is always called when input is altert
      // except in IE9 where when cutting or removing things it doesn't get fired
      // https://developer.mozilla.org/en-US/docs/Web/Events/input#Browser_compatibility
      var selectedEl = this.$()[0];
      var checked = selectedEl.checked;
      this.set('mainComponent.model.' + this.get('mainComponent.property'), checked);
      var changeAction = this.get('action');
      if (changeAction) {
        changeAction(checked);
      }
    }
  });
});