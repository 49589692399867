define('smoke-and-mirrors/utils/element/get-width', ['exports'], function (exports) {
  'use strict';

  exports['default'] = getWidth;

  function getWidth(dims, withMargins) {
    var width = undefined;

    switch (dims.boxSizing) {
      case 'border-box':
        width = dims.width + dims.borderLeftWidth + dims.borderRightWidth + dims.paddingLeft + dims.paddingRight;
        break;
      case 'content-box':
        width = dims.width;
        break;
      default:
        width = dims.width;
        break;
    }
    if (withMargins) {
      width += dims.marginLeft + dims.marginRight;
    }
    return width;
  }
});