define('smoke-and-mirrors/-private/ember/ext-require', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = function (moduleName) {
    var exportName = arguments.length <= 1 || arguments[1] === undefined ? 'default' : arguments[1];

    var module = _ember['default'].__loader.require(moduleName);

    return module[exportName];
  };
});