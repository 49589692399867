enifed('ember-glimmer/syntax/mount', ['exports', 'ember-babel', '@glimmer/runtime', '@glimmer/reference', 'ember-debug', 'ember-glimmer/utils/references', 'ember-routing', 'ember-glimmer/syntax/outlet', 'ember-glimmer/syntax/abstract-manager'], function (exports, _emberBabel, _runtime, _reference, _emberDebug, _references, _emberRouting, _outlet, _abstractManager) {
  'use strict';

  exports.mountMacro =

  /**
    The `{{mount}}` helper lets you embed a routeless engine in a template.
    Mounting an engine will cause an instance to be booted and its `application`
    template to be rendered.
  
    For example, the following template mounts the `ember-chat` engine:
  
    ```handlebars
    {{! application.hbs }}
    {{mount "ember-chat"}}
    ```
  
    Currently, the engine name is the only argument that can be passed to
    `{{mount}}`.
  
    @method mount
    @for Ember.Templates.helpers
    @category ember-application-engines
    @public
  */
  /**
  @module ember
  @submodule ember-glimmer
  */
  function (path, params, hash, builder) {
    false && !(params.length === 1 && hash === null) && (0, _emberDebug.assert)('You can only pass a single argument to the {{mount}} helper, e.g. {{mount "chat-engine"}}.', params.length === 1 && hash === null);

    var definitionArgs = [params.slice(0, 1), null, null, null];

    builder.component.dynamic(definitionArgs, dynamicEngineFor, [null, null, null, null], builder.symbolTable);
    return true;
  };

  function dynamicEngineFor(vm, symbolTable) {
    var env = vm.env;
    var args = vm.getArgs();
    var nameRef = args.positional.at(0);

    return new DynamicEngineReference({ nameRef: nameRef, env: env, symbolTable: symbolTable });
  }

  var DynamicEngineReference = function () {
    function DynamicEngineReference(_ref) {
      var nameRef = _ref.nameRef,
          env = _ref.env,
          symbolTable = _ref.symbolTable,
          args = _ref.args;


      this.tag = nameRef.tag;
      this.nameRef = nameRef;
      this.env = env;
      this.symbolTable = symbolTable;
      this._lastName = undefined;
      this._lastDef = undefined;
    }

    DynamicEngineReference.prototype.value = function () {
      var env = this.env,
          nameRef = this.nameRef;

      var nameOrDef = nameRef.value();

      if (typeof nameOrDef === 'string') {
        if (this._lastName === nameOrDef) {
          return this._lastDef;
        }

        false && !env.owner.hasRegistration('engine:' + nameOrDef) && (0, _emberDebug.assert)('You used `{{mount \'' + nameOrDef + '\'}}`, but the engine \'' + nameOrDef + '\' can not be found.', env.owner.hasRegistration('engine:' + nameOrDef));

        if (!env.owner.hasRegistration('engine:' + nameOrDef)) {
          return null;
        }

        this._lastName = nameOrDef;
        this._lastDef = new MountDefinition(nameOrDef);

        return this._lastDef;
      } else {
        false && !(nameOrDef === null || nameOrDef === undefined) && (0, _emberDebug.assert)('Invalid engine name \'' + nameOrDef + '\' specified, engine name must be either a string, null or undefined.', nameOrDef === null || nameOrDef === undefined);

        return null;
      }
    };

    return DynamicEngineReference;
  }();

  var MountManager = function (_AbstractManager) {
    (0, _emberBabel.inherits)(MountManager, _AbstractManager);

    function MountManager() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager.apply(this, arguments));
    }

    MountManager.prototype.prepareArgs = function (definition, args) {
      return args;
    };

    MountManager.prototype.create = function (environment, _ref2, args, dynamicScope) {
      var name = _ref2.name;

      dynamicScope.outletState = _reference.UNDEFINED_REFERENCE;

      var engine = environment.owner.buildChildEngineInstance(name);

      engine.boot();

      return engine;
    };

    MountManager.prototype.layoutFor = function (definition, engine, env) {
      var template = engine.lookup('template:application');
      return env.getCompiledBlock(_outlet.OutletLayoutCompiler, template);
    };

    MountManager.prototype.getSelf = function (engine) {
      var applicationFactory = engine.factoryFor('controller:application');
      var factory = applicationFactory || (0, _emberRouting.generateControllerFactory)(engine, 'application');
      return new _references.RootReference(factory.create());
    };

    MountManager.prototype.getTag = function () {
      return null;
    };

    MountManager.prototype.getDestructor = function (engine) {
      return engine;
    };

    MountManager.prototype.didCreateElement = function () {};

    MountManager.prototype.didRenderLayout = function () {};

    MountManager.prototype.didCreate = function () {};

    MountManager.prototype.update = function () {};

    MountManager.prototype.didUpdateLayout = function () {};

    MountManager.prototype.didUpdate = function () {};

    return MountManager;
  }(_abstractManager.default);

  var MOUNT_MANAGER = new MountManager();

  var MountDefinition = function (_ComponentDefinition) {
    (0, _emberBabel.inherits)(MountDefinition, _ComponentDefinition);

    function MountDefinition(name) {
      return (0, _emberBabel.possibleConstructorReturn)(this, _ComponentDefinition.call(this, name, MOUNT_MANAGER, null));
    }

    return MountDefinition;
  }(_runtime.ComponentDefinition);
});