define('ember-light-table/components/lt-foot', ['exports', 'ember-light-table/templates/components/lt-foot', 'ember-light-table/mixins/table-header'], function (exports, _emberLightTableTemplatesComponentsLtFoot, _emberLightTableMixinsTableHeader) {
  'use strict';

  var Component = Ember.Component,
      get = Ember.get,
      trySet = Ember.trySet;

  /**
   * @module Light Table
   */

  /**
   * ```hbs
   * {{#light-table table as |t|}}
   *   {{t.foot onColumnClick=(action 'sortByColumn')}}
   * {{/light-table}}
   * ```
   * If you want to define your own tfoot, just declare the contextual component in a block.
   *
   * ```hbs
   * {{#light-table table as |t|}}
   *   {{#t.foot onColumnClick=(action 'sortByColumn') as |columns table|}}
   *     {{#each columns as |column|}}
   *       {{!-- ... --}}
   *     {{/each}}
   *   {{/t.foot}}
   * {{/light-table}}
   * ```
   *
   * will be empty
   *
   * @class t.foot
   * @uses TableHeaderMixin
   */

  exports['default'] = Component.extend(_emberLightTableMixinsTableHeader['default'], {
    layout: _emberLightTableTemplatesComponentsLtFoot['default'],
    classNames: ['lt-foot-wrap'],
    table: null,
    sharedOptions: null,

    init: function init() {
      this._super.apply(this, arguments);

      trySet(this, 'sharedOptions.fixedFooter', get(this, 'fixed'));
    }
  });
});