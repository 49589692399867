define('ember-inputmask/components/one-way-phone-mask', ['exports', 'ember-inputmask/components/one-way-input-mask'], function (exports, _emberInputmaskComponentsOneWayInputMask) {
  'use strict';

  /**
   * `{{one-way-phone-mask}}` component.
   *
   * Displays an input that masks a US phone number. Country code
   * not included because this is specifically US formatting.
   *
   * Future: Add config options that allow users to set locality
   * app wide.
   *
   * @param {boolean} extensions Allows optional extensions to be added to numbers
   */
  exports['default'] = _emberInputmaskComponentsOneWayInputMask['default'].extend({
    NON_ATTRIBUTE_BOUND_PROPS: _emberInputmaskComponentsOneWayInputMask.DEFAULT_NON_BOUND_PROPS.concat('extensions'),

    /**
     * Whether or not to include extension in the mask
     */
    extensions: false,

    /**
     * @override
     */
    mask: Ember.computed('extensions', function () {
      if (Ember.get(this, 'extensions')) {
        return '(999) 999-9999[ x 9{1,4}]';
      }

      return '(999) 999-9999';
    })
  });
});