define('ember-data-sails/mixins/with-logger', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var LEVELS = 'debug info notice warn error'.split(' ');

  exports.LEVELS = LEVELS;
  var levelMap = {
    notice: 'log'
  };
  var methods = {};

  LEVELS.forEach(function (level) {
    methods[level] = _ember['default'].run.bind(_ember['default'].Logger, levelMap[level] || level, '[ed-sails]');
  });

  /**
   * Mix logging methods in our class depending on the configured log level
   * @since 0.0.10
   * @class WithLoggerMixin
   * @extends Ember.Logger
   * @extensionFor Ember.Object
   */
  exports['default'] = _ember['default'].Mixin.create(methods);
});