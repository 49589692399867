define('ember-inputmask/components/one-way-date-mask', ['exports', 'ember-inputmask/components/one-way-input-mask'], function (exports, _emberInputmaskComponentsOneWayInputMask) {
  'use strict';

  /**
   * `{{one-way-date-mask}}` component.
   *
   * Displays an input that masks to date
   */
  exports['default'] = _emberInputmaskComponentsOneWayInputMask['default'].extend({
    /**
     * @override
     */
    mask: 'date'
  });
});