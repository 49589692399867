define('ember-inputmask/components/one-way-ssn-mask', ['exports', 'ember-inputmask/components/one-way-input-mask'], function (exports, _emberInputmaskComponentsOneWayInputMask) {
  'use strict';

  /**
   * `{{one-way-ssn-mask}}` component
   *  Displays an input that masks SSN format
   */
  exports['default'] = _emberInputmaskComponentsOneWayInputMask['default'].extend({
    /**
     * @override
     */
    mask: '999-99-9999'
  });
});