enifed('ember-glimmer/helpers/-normalize-class', ['exports', 'ember-glimmer/utils/references', 'ember-runtime'], function (exports, _references, _emberRuntime) {
  'use strict';

  exports.default = function (vm, args) {
    return new _references.InternalHelperReference(normalizeClass, args);
  };

  function normalizeClass(_ref) {
    var positional = _ref.positional,
        named = _ref.named;

    var classNameParts = positional.at(0).value().split('.');
    var className = classNameParts[classNameParts.length - 1];
    var value = positional.at(1).value();

    if (value === true) {
      return _emberRuntime.String.dasherize(className);
    } else if (!value && value !== 0) {
      return '';
    } else {
      return String(value);
    }
  }
});