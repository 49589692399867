define('smoke-and-mirrors/components/pre-render/component', ['exports', 'ember', 'smoke-and-mirrors/components/pre-render/template', 'smoke-and-mirrors/utils/element/get-dimensions', 'smoke-and-mirrors/utils/element/apply-dimensions'], function (exports, _ember, _smokeAndMirrorsComponentsPreRenderTemplate, _smokeAndMirrorsUtilsElementGetDimensions, _smokeAndMirrorsUtilsElementApplyDimensions) {
  'use strict';

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;

  exports['default'] = Component.extend({
    layout: _smokeAndMirrorsComponentsPreRenderTemplate['default'],

    renderInParent: false,
    parent: null, // parent element
    destination: computed('renderInParent', 'parent', function () {
      var renderInParent = this.get('renderInParent');
      var parent = this.get('parent');

      this.__smAppendBody((parent || this.element).cloneNode(false));
      return renderInParent ? parent || this.element : this._fragmentBody;
    }),

    didPreRender: function didPreRender() /* dimensions */{},
    didMoveElement: function didMoveElement() {},

    __smGetComputedStyle: function __smGetComputedStyle() {
      var bodyStyle = (0, _smokeAndMirrorsUtilsElementGetDimensions['default'])(this._fragmentBody);

      this.didPreRender(bodyStyle);
    },

    __smUpdateFragmentStyles: function __smUpdateFragmentStyles() {
      var parent = this.get('parent') || this.element;

      if (parent === this.element) {
        if (!this.get('parentElementDidInsert')) {
          return;
        }
      }

      var parentWrapper = parent.parentNode;
      var computedStyle = (0, _smokeAndMirrorsUtilsElementGetDimensions['default'])(parentWrapper);

      (0, _smokeAndMirrorsUtilsElementApplyDimensions['default'])(this._fragmentWrapper, computedStyle);

      this.__smGetComputedStyle();
    },

    _fragment: null,
    _fragmentWrapper: null,
    _fragmentBody: null,
    __smSetupFragment: function __smSetupFragment() {
      if (this._fragment) {
        return;
      }
      var fragment = document.createElement('div');

      fragment.style.position = 'absolute';
      fragment.style.maxWidth = '9999px';
      fragment.style.top = '0px';
      fragment.style.left = '-10000px';
      fragment.style.opacity = 0;

      this._fragment = fragment;
      this._fragmentWrapper = document.createElement('div');
      fragment.appendChild(this._fragmentWrapper);
      document.body.appendChild(fragment);
    },

    __smAppendBody: function __smAppendBody(clone) {
      this._fragmentBody = clone;
      this._fragmentWrapper.appendChild(clone);
    },

    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      this._firstNode = this.element.firstChild;
      this._lastNode = this.element.lastChild;
      this.appendToDestination();
    },

    didInsertElement: function didInsertElement() {
      this.set('parentElementDidInsert', true);
      if (!this.get('parent')) {
        this.__smUpdateFragmentStyles();
      }
    },

    willDestroyElement: function willDestroyElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var firstNode = this._firstNode;
      var lastNode = this._lastNode;

      run.schedule('render', function () {
        _this.removeRange(firstNode, lastNode);
      });
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this._fragment = null;
      this._fragmentBody = null;
      this._fragmentWrapper = null;
    },

    destinationDidChange: observer('destination', function () {
      var destinationElement = this.get('destination');

      if (destinationElement !== this._firstNode.parentNode) {
        run.schedule('render', this, 'appendToDestination');
      }
    }),

    appendToDestination: function appendToDestination() {
      var destinationElement = this.get('destination');
      var currentActiveElement = document.activeElement;

      this.appendRange(destinationElement, this._firstNode, this._lastNode);
      if (document.activeElement !== currentActiveElement) {
        currentActiveElement.focus();
      }

      if (destinationElement === this._fragmentBody) {
        this.__smUpdateFragmentStyles();
      }

      this.didMoveElement();
    },

    appendRange: function appendRange(destinationElement, firstNode, lastNode) {
      while (firstNode) {
        destinationElement.insertBefore(firstNode, null);
        firstNode = firstNode !== lastNode ? lastNode.parentNode.firstChild : null;
      }
    },

    removeRange: function removeRange(firstNode, lastNode) {
      var node = lastNode;

      do {
        var next = node.previousSibling;

        if (node.parentNode) {
          node.parentNode.removeChild(node);
          if (node === firstNode) {
            break;
          }
        }
        node = next;
      } while (node);
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.__smSetupFragment();
    }
  });
});