define('ember-keyboard/initializers/ember-keyboard-first-responder-inputs', ['exports', 'ember-keyboard'], function (exports, _emberKeyboard) {
  'use strict';

  exports.initialize = initialize;

  function initialize() {
    Ember.TextField.reopen(_emberKeyboard.EKMixin, _emberKeyboard.EKFirstResponderOnFocusMixin);
    Ember.TextArea.reopen(_emberKeyboard.EKMixin, _emberKeyboard.EKFirstResponderOnFocusMixin);
  }

  exports['default'] = {
    name: 'ember-keyboard-first-responder-inputs',
    initialize: initialize
  };
});