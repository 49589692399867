define('ember-rapid-forms/components/em-form', ['exports', 'ember', 'ember-rapid-forms/utils/utils', 'ember-rapid-forms/templates/components/em-form'], function (exports, _ember, _emberRapidFormsUtilsUtils, _emberRapidFormsTemplatesComponentsEmForm) {
  'use strict';

  /*
  Form View
  
  A component for rendering a form element.
  
  Syntax:
  {{em-form
      //The layout of the form
      form_layout="form|inline|horizontal"
      //The model bound to the form if any
      model="some_model_instance"
      //The action to be invoked on the controller when a form is submitted.
      action="some_action"
      //if true a submit button will be rendered
      submitButton=true|false
      //if true validation icons will be rendered
      validationIcons=true|false
  }}
  */
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberRapidFormsTemplatesComponentsEmForm['default'],
    tagName: 'form',
    classNameBindings: ['formLayoutClass'],
    attributeBindings: ['role'],
    role: 'form',
    formLayoutClass: _ember['default'].computed('formLayout', {
      get: function get() {
        switch (this.get('formLayout')) {
          case 'horizontal':
          case 'inline':
            return "form-" + this.get('formLayout');
          default:
            return 'form';
        }
      }
    }),
    isDefaultLayout: _emberRapidFormsUtilsUtils['default'].createBoundSwitchAccessor('form', 'formLayout', 'form'),
    isInline: _emberRapidFormsUtilsUtils['default'].createBoundSwitchAccessor('inline', 'formLayout', 'form'),
    isHorizontal: _emberRapidFormsUtilsUtils['default'].createBoundSwitchAccessor('horizontal', 'formLayout', 'form'),
    action: 'submit',
    model: null,
    formLayout: 'form',
    submitButton: true,
    validationIcons: true,
    showErrorsOnRender: false,
    showErrorsOnFocusIn: false,
    showErrorsOnSubmit: true,

    /*
    Form submit
     Optionally execute model validations and perform a form submission.
     */
    submit: function submit(e) {
      var promise;
      if (e) {
        e.preventDefault();
      }
      if (_ember['default'].isNone(this.get('model.validate'))) {
        return this.sendAction('action', this.get('model'));
      } else {
        promise = this.get('model').validate();
        return promise.then((function (_this) {
          _this.set('isSubmitted', true);
          return function () {
            if (_this.get('model.isValid')) {
              return _this.sendAction('action', _this.get('model'));
            }
          };
        })(this))['catch'](function () {});
      }
    }
  });
});