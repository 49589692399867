enifed('ember-glimmer/syntax/render', ['exports', 'ember-babel', '@glimmer/runtime', '@glimmer/reference', 'ember-debug', 'ember-glimmer/utils/references', 'ember-routing', 'ember-glimmer/syntax/outlet', 'ember-glimmer/syntax/abstract-manager'], function (exports, _emberBabel, _runtime, _reference, _emberDebug, _references, _emberRouting, _outlet, _abstractManager) {
  'use strict';

  exports.renderMacro =

  /**
    Calling ``{{render}}`` from within a template will insert another
    template that matches the provided name. The inserted template will
    access its properties on its own controller (rather than the controller
    of the parent template).
  
    If a view class with the same name exists, the view class also will be used.
    Note: A given controller may only be used *once* in your app in this manner.
    A singleton instance of the controller will be created for you.
  
    Example:
  
    ```javascript
    App.NavigationController = Ember.Controller.extend({
      who: "world"
    });
    ```
  
    ```handlebars
    <!-- navigation.hbs -->
    Hello, {{who}}.
    ```
  
    ```handlebars
    <!-- application.hbs -->
    <h1>My great app</h1>
    {{render "navigation"}}
    ```
  
    ```html
    <h1>My great app</h1>
    <div class='ember-view'>
      Hello, world.
    </div>
    ```
  
    Optionally you may provide a second argument: a property path
    that will be bound to the `model` property of the controller.
    If a `model` property path is specified, then a new instance of the
    controller will be created and `{{render}}` can be used multiple times
    with the same name.
  
    For example if you had this `author` template.
  
    ```handlebars
    <div class="author">
      Written by {{firstName}} {{lastName}}.
      Total Posts: {{postCount}}
    </div>
    ```
  
    You could render it inside the `post` template using the `render` helper.
  
    ```handlebars
    <div class="post">
      <h1>{{title}}</h1>
      <div>{{body}}</div>
      {{render "author" author}}
    </div>
    ```
  
    @method render
    @for Ember.Templates.helpers
    @param {String} name
    @param {Object?} context
    @param {Hash} options
    @return {String} HTML string
    @public
  */
  /**
  @module ember
  @submodule ember-glimmer
  */
  function (path, params, hash, builder) {
    if (!params) {
      params = [];
    }
    var definitionArgs = [params.slice(0), hash, null, null];
    var args = [params.slice(1), hash, null, null];
    builder.component.dynamic(definitionArgs, makeComponentDefinition, args, builder.symbolTable);
    return true;
  };

  function makeComponentDefinition(vm) {
    var env = vm.env,
        controllerNameRef;
    var args = vm.getArgs();
    var nameRef = args.positional.at(0);

    false && !(0, _reference.isConst)(nameRef) && (0, _emberDebug.assert)('The first argument of {{render}} must be quoted, e.g. {{render "sidebar"}}.', (0, _reference.isConst)(nameRef));
    false && !(args.positional.length === 1 || !(0, _reference.isConst)(args.positional.at(1))) && (0, _emberDebug.assert)('The second argument of {{render}} must be a path, e.g. {{render "post" post}}.', args.positional.length === 1 || !(0, _reference.isConst)(args.positional.at(1)));

    var templateName = nameRef.value();

    false && !env.owner.hasRegistration('template:' + templateName) && (0, _emberDebug.assert)('You used `{{render \'' + templateName + '\'}}`, but \'' + templateName + '\' can not be found as a template.', env.owner.hasRegistration('template:' + templateName));

    var template = env.owner.lookup('template:' + templateName);

    var controllerName = void 0;

    if (args.named.has('controller')) {
      controllerNameRef = args.named.get('controller');


      false && !(0, _reference.isConst)(controllerNameRef) && (0, _emberDebug.assert)('The controller argument for {{render}} must be quoted, e.g. {{render "sidebar" controller="foo"}}.', (0, _reference.isConst)(controllerNameRef));

      controllerName = controllerNameRef.value();

      false && !env.owner.hasRegistration('controller:' + controllerName) && (0, _emberDebug.assert)('The controller name you supplied \'' + controllerName + '\' did not resolve to a controller.', env.owner.hasRegistration('controller:' + controllerName));
    } else {
      controllerName = templateName;
    }

    if (args.positional.length === 1) {
      return new _reference.ConstReference(new RenderDefinition(controllerName, template, env, SINGLETON_RENDER_MANAGER));
    } else {
      return new _reference.ConstReference(new RenderDefinition(controllerName, template, env, NON_SINGLETON_RENDER_MANAGER));
    }
  }

  var AbstractRenderManager = function (_AbstractManager) {
    (0, _emberBabel.inherits)(AbstractRenderManager, _AbstractManager);

    function AbstractRenderManager() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager.apply(this, arguments));
    }

    AbstractRenderManager.prototype.prepareArgs = function (definition, args) {
      return args;
    };

    AbstractRenderManager.prototype.layoutFor = function (definition, bucket, env) {
      return env.getCompiledBlock(_outlet.OutletLayoutCompiler, definition.template);
    };

    AbstractRenderManager.prototype.getSelf = function (_ref) {
      var controller = _ref.controller;

      return new _references.RootReference(controller);
    };

    AbstractRenderManager.prototype.getTag = function () {
      return null;
    };

    AbstractRenderManager.prototype.getDestructor = function () {
      return null;
    };

    AbstractRenderManager.prototype.didCreateElement = function () {};

    AbstractRenderManager.prototype.didRenderLayout = function () {};

    AbstractRenderManager.prototype.didCreate = function () {};

    AbstractRenderManager.prototype.update = function () {};

    AbstractRenderManager.prototype.didUpdateLayout = function () {};

    AbstractRenderManager.prototype.didUpdate = function () {};

    return AbstractRenderManager;
  }(_abstractManager.default);

  var SingletonRenderManager = function (_AbstractRenderManage) {
    (0, _emberBabel.inherits)(SingletonRenderManager, _AbstractRenderManage);

    function SingletonRenderManager() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractRenderManage.apply(this, arguments));
    }

    SingletonRenderManager.prototype.create = function (environment, definition, args, dynamicScope) {
      var name = definition.name,
          env = definition.env;

      var controller = env.owner.lookup('controller:' + name) || (0, _emberRouting.generateController)(env.owner, name);

      if (dynamicScope.rootOutletState) {
        dynamicScope.outletState = dynamicScope.rootOutletState.getOrphan(name);
      }

      return { controller: controller };
    };

    return SingletonRenderManager;
  }(AbstractRenderManager);

  var SINGLETON_RENDER_MANAGER = new SingletonRenderManager();

  var NonSingletonRenderManager = function (_AbstractRenderManage2) {
    (0, _emberBabel.inherits)(NonSingletonRenderManager, _AbstractRenderManage2);

    function NonSingletonRenderManager() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractRenderManage2.apply(this, arguments));
    }

    NonSingletonRenderManager.prototype.create = function (environment, definition, args, dynamicScope) {
      var name = definition.name,
          env = definition.env;

      var modelRef = args.positional.at(0);
      var controllerFactory = env.owner.factoryFor('controller:' + name);

      var factory = controllerFactory || (0, _emberRouting.generateControllerFactory)(env.owner, name);
      var controller = factory.create({ model: modelRef.value() });

      if (dynamicScope.rootOutletState) {
        dynamicScope.outletState = dynamicScope.rootOutletState.getOrphan(name);
      }

      return { controller: controller };
    };

    NonSingletonRenderManager.prototype.update = function (_ref2, args) {
      var controller = _ref2.controller;

      controller.set('model', args.positional.at(0).value());
    };

    NonSingletonRenderManager.prototype.getDestructor = function (_ref3) {
      var controller = _ref3.controller;

      return controller;
    };

    return NonSingletonRenderManager;
  }(AbstractRenderManager);

  var NON_SINGLETON_RENDER_MANAGER = new NonSingletonRenderManager();

  var RenderDefinition = function (_ComponentDefinition) {
    (0, _emberBabel.inherits)(RenderDefinition, _ComponentDefinition);

    function RenderDefinition(name, template, env, manager) {

      var _this4 = (0, _emberBabel.possibleConstructorReturn)(this, _ComponentDefinition.call(this, 'render', manager, null));

      _this4.name = name;
      _this4.template = template;
      _this4.env = env;
      return _this4;
    }

    return RenderDefinition;
  }(_runtime.ComponentDefinition);
});