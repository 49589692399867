enifed('ember-glimmer/utils/references', ['exports', '@glimmer/runtime', 'ember-babel', 'ember-utils', 'ember-metal', '@glimmer/reference', 'ember-glimmer/utils/to-bool', 'ember-glimmer/helper'], function (exports, _runtime, _emberBabel, _emberUtils, _emberMetal, _reference, _toBool, _helper) {
  'use strict';

  exports.UnboundReference = exports.InternalHelperReference = exports.ClassBasedHelperReference = exports.SimpleHelperReference = exports.ConditionalReference = exports.UpdatablePrimitiveReference = exports.UpdatableReference = exports.NestedPropertyReference = exports.RootPropertyReference = exports.PropertyReference = exports.RootReference = exports.CachedReference = exports.UNDEFINED_REFERENCE = exports.NULL_REFERENCE = exports.UPDATE = undefined;
  Object.defineProperty(exports, 'NULL_REFERENCE', {
    enumerable: true,
    get: function () {
      return _runtime.NULL_REFERENCE;
    }
  });
  Object.defineProperty(exports, 'UNDEFINED_REFERENCE', {
    enumerable: true,
    get: function () {
      return _runtime.UNDEFINED_REFERENCE;
    }
  });
  var UPDATE = exports.UPDATE = (0, _emberUtils.symbol)('UPDATE');

  // @abstract
  // @implements PathReference

  var EmberPathReference = function () {
    function EmberPathReference() {}

    EmberPathReference.prototype.get = function (key) {
      return PropertyReference.create(this, key);
    };

    return EmberPathReference;
  }();

  var CachedReference = exports.CachedReference = function (_EmberPathReference) {
    (0, _emberBabel.inherits)(CachedReference, _EmberPathReference);

    function CachedReference() {

      var _this = (0, _emberBabel.possibleConstructorReturn)(this, _EmberPathReference.call(this));

      _this._lastRevision = null;
      _this._lastValue = null;
      return _this;
    }

    CachedReference.prototype.value = function () {
      var tag = this.tag,
          _lastRevision = this._lastRevision,
          _lastValue = this._lastValue;

      if (!_lastRevision || !tag.validate(_lastRevision)) {
        _lastValue = this._lastValue = this.compute();
        this._lastRevision = tag.value();
      }

      return _lastValue;
    };

    // @abstract compute()


    return CachedReference;
  }(EmberPathReference);

  var RootReference = exports.RootReference = function (_ConstReference) {
    (0, _emberBabel.inherits)(RootReference, _ConstReference);

    function RootReference(value) {

      var _this2 = (0, _emberBabel.possibleConstructorReturn)(this, _ConstReference.call(this, value));

      _this2.children = Object.create(null);
      return _this2;
    }

    RootReference.prototype.get = function (propertyKey) {
      var ref = this.children[propertyKey];

      if (!ref) {
        ref = this.children[propertyKey] = new RootPropertyReference(this.inner, propertyKey);
      }

      return ref;
    };

    return RootReference;
  }(_reference.ConstReference);

  var PropertyReference = exports.PropertyReference = function (_CachedReference) {
    (0, _emberBabel.inherits)(PropertyReference, _CachedReference);

    function PropertyReference() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference.apply(this, arguments));
    }

    PropertyReference.create = function (parentReference, propertyKey) {
      if ((0, _reference.isConst)(parentReference)) {
        return new RootPropertyReference(parentReference.value(), propertyKey);
      } else {
        return new NestedPropertyReference(parentReference, propertyKey);
      }
    };

    PropertyReference.prototype.get = function (key) {
      return new NestedPropertyReference(this, key);
    };

    return PropertyReference;
  }(CachedReference);

  var RootPropertyReference = exports.RootPropertyReference = function (_PropertyReference) {
    (0, _emberBabel.inherits)(RootPropertyReference, _PropertyReference);

    function RootPropertyReference(parentValue, propertyKey) {

      var _this4 = (0, _emberBabel.possibleConstructorReturn)(this, _PropertyReference.call(this));

      _this4._parentValue = parentValue;
      _this4._propertyKey = propertyKey;

      _this4.tag = (0, _emberMetal.tagForProperty)(parentValue, propertyKey);

      return _this4;
    }

    RootPropertyReference.prototype.compute = function () {
      var _parentValue = this._parentValue,
          _propertyKey = this._propertyKey;

      return (0, _emberMetal.get)(_parentValue, _propertyKey);
    };

    RootPropertyReference.prototype[UPDATE] = function (value) {
      (0, _emberMetal.set)(this._parentValue, this._propertyKey, value);
    };

    return RootPropertyReference;
  }(PropertyReference);

  var NestedPropertyReference = exports.NestedPropertyReference = function (_PropertyReference2) {
    (0, _emberBabel.inherits)(NestedPropertyReference, _PropertyReference2);

    function NestedPropertyReference(parentReference, propertyKey) {

      var _this5 = (0, _emberBabel.possibleConstructorReturn)(this, _PropertyReference2.call(this));

      var parentReferenceTag = parentReference.tag;
      var parentObjectTag = new _reference.UpdatableTag(_reference.CONSTANT_TAG);

      _this5._parentReference = parentReference;
      _this5._parentObjectTag = parentObjectTag;
      _this5._propertyKey = propertyKey;

      _this5.tag = (0, _reference.combine)([parentReferenceTag, parentObjectTag]);

      return _this5;
    }

    NestedPropertyReference.prototype.compute = function () {
      var _parentReference = this._parentReference,
          _parentObjectTag = this._parentObjectTag,
          _propertyKey = this._propertyKey;

      var parentValue = _parentReference.value();

      _parentObjectTag.update((0, _emberMetal.tagForProperty)(parentValue, _propertyKey));

      if (typeof parentValue === 'string' && _propertyKey === 'length') {
        return parentValue.length;
      }

      if (typeof parentValue === 'object' && parentValue) {

        return (0, _emberMetal.get)(parentValue, _propertyKey);
      } else {
        return undefined;
      }
    };

    NestedPropertyReference.prototype[UPDATE] = function (value) {
      var parent = this._parentReference.value();
      (0, _emberMetal.set)(parent, this._propertyKey, value);
    };

    return NestedPropertyReference;
  }(PropertyReference);

  var UpdatableReference = exports.UpdatableReference = function (_EmberPathReference2) {
    (0, _emberBabel.inherits)(UpdatableReference, _EmberPathReference2);

    function UpdatableReference(value) {

      var _this6 = (0, _emberBabel.possibleConstructorReturn)(this, _EmberPathReference2.call(this));

      _this6.tag = new _reference.DirtyableTag();
      _this6._value = value;
      return _this6;
    }

    UpdatableReference.prototype.value = function () {
      return this._value;
    };

    UpdatableReference.prototype.update = function (value) {
      var _value = this._value;

      if (value !== _value) {
        this.tag.dirty();
        this._value = value;
      }
    };

    return UpdatableReference;
  }(EmberPathReference);

  exports.UpdatablePrimitiveReference = function (_UpdatableReference) {
    (0, _emberBabel.inherits)(UpdatablePrimitiveReference, _UpdatableReference);

    function UpdatablePrimitiveReference() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _UpdatableReference.apply(this, arguments));
    }

    UpdatablePrimitiveReference.prototype.get = function () {
      return _runtime.UNDEFINED_REFERENCE;
    };

    return UpdatablePrimitiveReference;
  }(UpdatableReference);

  exports.ConditionalReference = function (_GlimmerConditionalRe) {
    (0, _emberBabel.inherits)(ConditionalReference, _GlimmerConditionalRe);

    ConditionalReference.create = function (reference) {
      var value;

      if ((0, _reference.isConst)(reference)) {
        value = reference.value();


        if ((0, _emberMetal.isProxy)(value)) {
          return new RootPropertyReference(value, 'isTruthy');
        } else {
          return _runtime.PrimitiveReference.create((0, _toBool.default)(value));
        }
      }

      return new ConditionalReference(reference);
    };

    function ConditionalReference(reference) {

      var _this8 = (0, _emberBabel.possibleConstructorReturn)(this, _GlimmerConditionalRe.call(this, reference));

      _this8.objectTag = new _reference.UpdatableTag(_reference.CONSTANT_TAG);
      _this8.tag = (0, _reference.combine)([reference.tag, _this8.objectTag]);
      return _this8;
    }

    ConditionalReference.prototype.toBool = function (predicate) {
      if ((0, _emberMetal.isProxy)(predicate)) {
        this.objectTag.update((0, _emberMetal.tagForProperty)(predicate, 'isTruthy'));
        return (0, _emberMetal.get)(predicate, 'isTruthy');
      } else {
        this.objectTag.update((0, _emberMetal.tagFor)(predicate));
        return (0, _toBool.default)(predicate);
      }
    };

    return ConditionalReference;
  }(_runtime.ConditionalReference);

  exports.SimpleHelperReference = function (_CachedReference2) {
    (0, _emberBabel.inherits)(SimpleHelperReference, _CachedReference2);

    SimpleHelperReference.create = function (helper, args) {
      var positional, named, positionalValue, namedValue, result;

      if ((0, _reference.isConst)(args)) {
        positional = args.positional, named = args.named;
        positionalValue = positional.value();
        namedValue = named.value();
        result = helper(positionalValue, namedValue);


        if (result === null) {
          return _runtime.NULL_REFERENCE;
        } else if (result === undefined) {
          return _runtime.UNDEFINED_REFERENCE;
        } else if (typeof result === 'object') {
          return new RootReference(result);
        } else {
          return _runtime.PrimitiveReference.create(result);
        }
      } else {
        return new SimpleHelperReference(helper, args);
      }
    };

    function SimpleHelperReference(helper, args) {

      var _this9 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference2.call(this));

      _this9.tag = args.tag;
      _this9.helper = helper;
      _this9.args = args;
      return _this9;
    }

    SimpleHelperReference.prototype.compute = function () {
      var helper = this.helper,
          _args = this.args,
          positional = _args.positional,
          named = _args.named;

      var positionalValue = positional.value();
      var namedValue = named.value();

      return helper(positionalValue, namedValue);
    };

    return SimpleHelperReference;
  }(CachedReference);

  exports.ClassBasedHelperReference = function (_CachedReference3) {
    (0, _emberBabel.inherits)(ClassBasedHelperReference, _CachedReference3);

    ClassBasedHelperReference.create = function (helperClass, vm, args) {
      var instance = helperClass.create();
      vm.newDestroyable(instance);
      return new ClassBasedHelperReference(instance, args);
    };

    function ClassBasedHelperReference(instance, args) {

      var _this10 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference3.call(this));

      _this10.tag = (0, _reference.combine)([instance[_helper.RECOMPUTE_TAG], args.tag]);
      _this10.instance = instance;
      _this10.args = args;
      return _this10;
    }

    ClassBasedHelperReference.prototype.compute = function () {
      var instance = this.instance,
          _args2 = this.args,
          positional = _args2.positional,
          named = _args2.named;

      var positionalValue = positional.value();
      var namedValue = named.value();

      return instance.compute(positionalValue, namedValue);
    };

    return ClassBasedHelperReference;
  }(CachedReference);

  exports.InternalHelperReference = function (_CachedReference4) {
    (0, _emberBabel.inherits)(InternalHelperReference, _CachedReference4);

    function InternalHelperReference(helper, args) {

      var _this11 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference4.call(this));

      _this11.tag = args.tag;
      _this11.helper = helper;
      _this11.args = args;
      return _this11;
    }

    InternalHelperReference.prototype.compute = function () {
      var helper = this.helper,
          args = this.args;

      return helper(args);
    };

    return InternalHelperReference;
  }(CachedReference);

  exports.UnboundReference = function (_ConstReference2) {
    (0, _emberBabel.inherits)(UnboundReference, _ConstReference2);

    function UnboundReference() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _ConstReference2.apply(this, arguments));
    }

    UnboundReference.create = function (value) {
      if (value === null) {
        return _runtime.NULL_REFERENCE;
      } else if (value === undefined) {
        return _runtime.UNDEFINED_REFERENCE;
      } else if (typeof value === 'object') {
        return new UnboundReference(value);
      } else {
        return _runtime.PrimitiveReference.create(value);
      }
    };

    UnboundReference.prototype.get = function (key) {
      return new UnboundReference((0, _emberMetal.get)(this.inner, key));
    };

    return UnboundReference;
  }(_reference.ConstReference);
});