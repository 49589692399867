enifed('@glimmer/node', ['exports', 'ember-babel', '@glimmer/runtime'], function (exports, _emberBabel, _runtime) {
    'use strict';

    exports.NodeDOMTreeConstruction = undefined;

    var NodeDOMTreeConstruction = function (_DOMTreeConstruction) {
        (0, _emberBabel.inherits)(NodeDOMTreeConstruction, _DOMTreeConstruction);

        function NodeDOMTreeConstruction(doc) {
            return (0, _emberBabel.possibleConstructorReturn)(this, _DOMTreeConstruction.call(this, doc));
        }
        // override to prevent usage of `this.document` until after the constructor


        NodeDOMTreeConstruction.prototype.setupUselessElement = function () {};

        NodeDOMTreeConstruction.prototype.insertHTMLBefore = function (parent, html, reference) {
            var prev = reference ? reference.previousSibling : parent.lastChild;
            var raw = this.document.createRawHTMLSection(html);
            parent.insertBefore(raw, reference);
            var first = prev ? prev.nextSibling : parent.firstChild;
            var last = reference ? reference.previousSibling : parent.lastChild;
            return new _runtime.ConcreteBounds(parent, first, last);
        };

        NodeDOMTreeConstruction.prototype.createElement = function (tag) {
            return this.document.createElement(tag);
        };

        NodeDOMTreeConstruction.prototype.setAttribute = function (element, name, value) {
            element.setAttribute(name, value);
        };

        return NodeDOMTreeConstruction;
    }(_runtime.DOMTreeConstruction);

    exports.NodeDOMTreeConstruction = NodeDOMTreeConstruction;
});