enifed('ember-routing/system/dsl', ['exports', 'ember-utils', 'ember-debug'], function (exports, _emberUtils, _emberDebug) {
  'use strict';

  /**
  @module ember
  @submodule ember-routing
  */

  var uuid = 0;

  var DSL = function () {
    function DSL(name, options) {

      this.parent = name;
      this.enableLoadingSubstates = options && options.enableLoadingSubstates;
      this.matches = [];
      this.explicitIndex = undefined;
      this.options = options;
    }

    DSL.prototype.route = function (name) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          fullName,
          dsl;
      var callback = arguments[2];

      var dummyErrorRoute = '/_unused_dummy_error_path_route_' + name + '/:error';
      if (arguments.length === 2 && typeof options === 'function') {
        callback = options;
        options = {};
      }

      false && !function () {
        if (options.overrideNameAssertion === true) {
          return true;
        }

        return ['array', 'basic', 'object', 'application'].indexOf(name) === -1;
      }() && (0, _emberDebug.assert)('\'' + name + '\' cannot be used as a route name.', function () {
        if (options.overrideNameAssertion === true) {
          return true;
        }return ['array', 'basic', 'object', 'application'].indexOf(name) === -1;
      }());

      if (this.enableLoadingSubstates) {
        createRoute(this, name + '_loading', { resetNamespace: options.resetNamespace });
        createRoute(this, name + '_error', { resetNamespace: options.resetNamespace, path: dummyErrorRoute });
      }

      if (callback) {
        fullName = getFullName(this, name, options.resetNamespace);
        dsl = new DSL(fullName, this.options);


        createRoute(dsl, 'loading');
        createRoute(dsl, 'error', { path: dummyErrorRoute });

        callback.call(dsl);

        createRoute(this, name, options, dsl.generate());
      } else {
        createRoute(this, name, options);
      }
    };

    DSL.prototype.push = function (url, name, callback, serialize) {
      var parts = name.split('.'),
          localFullName,
          routeInfo;

      if (this.options.engineInfo) {
        localFullName = name.slice(this.options.engineInfo.fullName.length + 1);
        routeInfo = (0, _emberUtils.assign)({ localFullName: localFullName }, this.options.engineInfo);


        if (serialize) {
          routeInfo.serializeMethod = serialize;
        }

        this.options.addRouteForEngine(name, routeInfo);
      } else if (serialize) {
        throw new Error('Defining a route serializer on route \'' + name + '\' outside an Engine is not allowed.');
      }

      if (url === '' || url === '/' || parts[parts.length - 1] === 'index') {
        this.explicitIndex = true;
      }

      this.matches.push([url, name, callback]);
    };

    DSL.prototype.resource = function (name) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var callback = arguments[2];

      if (arguments.length === 2 && typeof options === 'function') {
        callback = options;
        options = {};
      }

      options.resetNamespace = true;
      false && !false && (0, _emberDebug.deprecate)('this.resource() is deprecated. Use this.route(\'name\', { resetNamespace: true }, function () {}) instead.', false, { id: 'ember-routing.router-resource', until: '3.0.0' });

      this.route(name, options, callback);
    };

    DSL.prototype.generate = function () {
      var dslMatches = this.matches;

      if (!this.explicitIndex) {
        this.route('index', { path: '/' });
      }

      return function (match) {
        var i, dslMatch;

        for (i = 0; i < dslMatches.length; i++) {
          dslMatch = dslMatches[i];

          match(dslMatch[0]).to(dslMatch[1], dslMatch[2]);
        }
      };
    };

    DSL.prototype.mount = function (_name) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          shouldResetEngineInfo,
          oldEngineInfo,
          optionsForChild,
          childDSL,
          substateName,
          _localFullName,
          _routeInfo;

      var engineRouteMap = this.options.resolveRouteMap(_name);
      var name = _name;

      if (options.as) {
        name = options.as;
      }

      var fullName = getFullName(this, name, options.resetNamespace);

      var engineInfo = {
        name: _name,
        instanceId: uuid++,
        mountPoint: fullName,
        fullName: fullName
      };

      var path = options.path;

      if (typeof path !== 'string') {
        path = '/' + name;
      }

      var callback = void 0;
      var dummyErrorRoute = '/_unused_dummy_error_path_route_' + name + '/:error';
      if (engineRouteMap) {
        shouldResetEngineInfo = false;
        oldEngineInfo = this.options.engineInfo;

        if (oldEngineInfo) {
          shouldResetEngineInfo = true;
          this.options.engineInfo = engineInfo;
        }

        optionsForChild = (0, _emberUtils.assign)({ engineInfo: engineInfo }, this.options);
        childDSL = new DSL(fullName, optionsForChild);


        createRoute(childDSL, 'loading');
        createRoute(childDSL, 'error', { path: dummyErrorRoute });

        engineRouteMap.class.call(childDSL);

        callback = childDSL.generate();

        if (shouldResetEngineInfo) {
          this.options.engineInfo = oldEngineInfo;
        }
      }

      var routeInfo = (0, _emberUtils.assign)({ localFullName: 'application' }, engineInfo);

      if (this.enableLoadingSubstates) {
        // These values are important to register the loading routes under their
        // proper names for the Router and within the Engine's registry.
        substateName = name + '_loading';
        _localFullName = 'application_loading';
        _routeInfo = (0, _emberUtils.assign)({ localFullName: _localFullName }, engineInfo);

        createRoute(this, substateName, { resetNamespace: options.resetNamespace });
        this.options.addRouteForEngine(substateName, _routeInfo);

        substateName = name + '_error';
        _localFullName = 'application_error';
        _routeInfo = (0, _emberUtils.assign)({ localFullName: _localFullName }, engineInfo);
        createRoute(this, substateName, { resetNamespace: options.resetNamespace, path: dummyErrorRoute });
        this.options.addRouteForEngine(substateName, _routeInfo);
      }

      this.options.addRouteForEngine(fullName, routeInfo);

      this.push(path, fullName, callback);
    };

    return DSL;
  }();

  exports.default = DSL;

  function canNest(dsl) {
    return dsl.parent && dsl.parent !== 'application';
  }

  function getFullName(dsl, name, resetNamespace) {
    if (canNest(dsl) && resetNamespace !== true) {
      return dsl.parent + '.' + name;
    } else {
      return name;
    }
  }

  function createRoute(dsl, name) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var callback = arguments[3];

    var fullName = getFullName(dsl, name, options.resetNamespace);

    if (typeof options.path !== 'string') {
      options.path = '/' + name;
    }

    dsl.push(options.path, fullName, callback, options.serialize);
  }

  DSL.map = function (callback) {
    var dsl = new DSL();
    callback.call(dsl);
    return dsl;
  };
});