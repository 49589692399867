define('ember-keyboard/mixins/ember-keyboard', ['exports'], function (exports) {
  'use strict';

  exports['default'] = Ember.Mixin.create(Ember.Evented, {
    keyboardPriority: 0,

    keyboard: Ember.inject.service(),

    init: function init() {
      Ember.get(this, 'keyboard').register(this);

      return this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.get(this, 'keyboard').unregister(this);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      Ember.get(this, 'keyboard').unregister(this);
    }
  });
});