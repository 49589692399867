define('ember-scrollable/util/css', ['exports'], function (exports) {
  'use strict';

  var isEmpty = Ember.isEmpty,
      htmlSafe = Ember.String.htmlSafe;

  function styleify(obj) {
    if (isEmpty(obj)) {
      return htmlSafe('');
    }
    var styles = Object.keys(obj).reduce(function (styleString, key) {
      var styleValue = obj[key];
      if (!isEmpty(styleValue)) {
        styleString += key + ': ' + styleValue + '; ';
      }
      return styleString;
    }, '');
    return htmlSafe(styles);
  }

  exports.styleify = styleify;
});