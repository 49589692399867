define('ember-rapid-forms/utils/utils', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Utils;
  exports['default'] = Utils = {
    createBoundSwitchAccessor: function createBoundSwitchAccessor(switchValue, myProperty, myDefault) {
      if (myDefault == null) {
        myDefault = 'default';
      }
      return _ember['default'].computed(myProperty, {
        get: function get() {
          return this.get(myProperty) === switchValue;
        },
        set: function set(key, value) {
          this.set(myProperty, value ? switchValue : myDefault);
        }
      });
    }
  };
});