define('smoke-and-mirrors/-private/radar/models/radar', ['exports', 'ember', 'smoke-and-mirrors/-private/radar/models/satellite', 'smoke-and-mirrors/-private/radar/models/geography', 'smoke-and-mirrors/-private/radar/models/container', 'smoke-and-mirrors/-private/radar/utils/supports-passive', 'smoke-and-mirrors/-private/radar/utils/scroll-handler', 'smoke-and-mirrors/-private/scheduler'], function (exports, _ember, _smokeAndMirrorsPrivateRadarModelsSatellite, _smokeAndMirrorsPrivateRadarModelsGeography, _smokeAndMirrorsPrivateRadarModelsContainer, _smokeAndMirrorsPrivateRadarUtilsSupportsPassive, _smokeAndMirrorsPrivateRadarUtilsScrollHandler, _smokeAndMirrorsPrivateScheduler) {
  'use strict';

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ('value' in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
      }
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }

  var guidFor = _ember['default'].guidFor;

  var DEFAULT_ARRAY_SIZE = 200;

  var Radar = (function () {
    function Radar(state) {
      this.satellites = new Array(DEFAULT_ARRAY_SIZE);
      this.length = 0;
      this.alwaysRemeasure = false;
      this.maxLength = DEFAULT_ARRAY_SIZE;
      this.setState(state || {});
    }

    _createClass(Radar, [{
      key: '_push',
      value: function _push(satellite) {
        var index = this.length++;

        if (index === this.maxLength) {
          this.maxLength *= 2;
          this.satellites.length = this.maxLength;
        }

        this.satellites[index] = satellite;
      }
    }, {
      key: 'setState',
      value: function setState(state) {
        /*
         A scrollable consists of two parts, a telescope
         and a skyline.
          The telescope is the "constrained viewport", while
         the sky is the element with the full height of
         the content.
         */
        if (this.telescope && state.telescope) {
          this._teardownHandlers();
        }
        this.telescope = state.telescope;
        this.sky = state.sky;

        if (this.telescope === window || this.telescope === document.body) {
          this.telescope = _smokeAndMirrorsPrivateRadarModelsContainer['default'];
        }

        this.planet = this.telescope ? new _smokeAndMirrorsPrivateRadarModelsGeography['default'](this.telescope) : undefined;
        this.skyline = this.sky ? new _smokeAndMirrorsPrivateRadarModelsGeography['default'](this.sky) : undefined;

        this.scrollX = this.telescope ? this.telescope.scrollLeft : 0;
        this.scrollY = this.telescope ? this.telescope.scrollTop : 0;
        this.posX = _smokeAndMirrorsPrivateRadarModelsContainer['default'].scrollLeft;
        this.posY = _smokeAndMirrorsPrivateRadarModelsContainer['default'].scrollTop;

        this.minimumMovement = state.minimumMovement || 15;
        this.alwaysRemeasure = state.alwaysRemeasure || false;
        this.isTracking = state.hasOwnProperty('isTracking') ? state.isTracking : true;
        if (this.telescope && this.sky) {
          this._setupHandlers();
        }
      }
    }, {
      key: 'getSatelliteZones',
      value: function getSatelliteZones(satellite) {
        return {
          y: this.getSatelliteYZone(satellite),
          x: this.getSatelliteXZone(satellite)
        };
      }
    }, {
      key: 'getSatelliteYZone',
      value: function getSatelliteYZone(satellite) {
        var satGeo = satellite.geography;
        var distance = 0;
        var yScalar = this.planet.height;

        if (satGeo.bottom > this.planet.top) {
          distance = satGeo.bottom - this.planet.top;

          return Math.floor(distance / yScalar);
        } else if (satGeo.top < this.planet.bottom) {
          distance = satGeo.top - this.planet.bottom;

          return Math.ceil(distance / yScalar);
        }

        return 0;
      }
    }, {
      key: 'getSatelliteXZone',
      value: function getSatelliteXZone(satellite) {
        var satGeo = satellite.geography;
        var distance = 0;
        var xScalar = this.planet.width;

        if (satGeo.right > this.planet.left) {
          distance = satGeo.right - this.planet.left;

          return Math.floor(distance / xScalar);
        } else if (satGeo.left < this.planet.right) {
          distance = satGeo.left - this.planet.right;

          return Math.ceil(distance / xScalar);
        }

        return 0;
      }
    }, {
      key: 'register',
      value: function register(component) {
        var sat = _smokeAndMirrorsPrivateRadarModelsSatellite['default'].create({
          component: component,
          dimensions: undefined,
          element: component.element,
          key: guidFor(component),
          radar: this,
          scalar: undefined
        });

        this._push(sat);

        return sat;
      }
    }, {
      key: 'unregister',
      value: function unregister(component) {
        var key = guidFor(component);

        if (!this.satellites) {
          return;
        }

        for (var i = 0; i < this.length; i++) {
          if (this.satellites[i].key === key) {
            var satellite = this.satellites[i];

            this.satellites.splice(i, 1);
            this.length--;
            this.maxLength--;
            satellite.destroy();
            break;
          }
        }
      }
    }, {
      key: 'isEarthquake',
      value: function isEarthquake(a, b) {
        return Math.abs(b - a) >= this.minimumMovement;
      }
    }, {
      key: 'willShiftSatellites',
      value: function willShiftSatellites() {}
    }, {
      key: 'didShiftSatellites',
      value: function didShiftSatellites() {}
    }, {
      key: 'willResizeSatellites',
      value: function willResizeSatellites() {}
    }, {
      key: 'didResizeSatellites',
      value: function didResizeSatellites() {}
    }, {
      key: 'willAdjustPosition',
      value: function willAdjustPosition() {}
    }, {
      key: 'didAdjustPosition',
      value: function didAdjustPosition() {}
    }, {
      key: 'didRebuild',
      value: function didRebuild() {}
    }, {
      key: '_resize',
      value: function _resize() {
        for (var i = 0; i < this.length; i++) {
          this.satellites[i].resize();
        }
      }
    }, {
      key: 'resizeSatellites',
      value: function resizeSatellites() {
        this.willResizeSatellites();
        this._resize();
        this.didResizeSatellites();
      }
    }, {
      key: 'updateSkyline',
      value: function updateSkyline() {
        if (this.skyline) {
          this.filterMovement({
            top: this.telescope.scrollTop,
            left: this.telescope.scrollLeft
          });
          this.skyline.setState();
        }
      }
    }, {
      key: '_shift',
      value: function _shift(dY, dX) {
        // move the satellites
        for (var i = 0; i < this.length; i++) {
          this.satellites[i].shift(dY, dX);
        }

        // move the sky
        this.skyline.left -= dX;
        this.skyline.right -= dX;
        this.skyline.bottom -= dY;
        this.skyline.top -= dY;
      }
    }, {
      key: 'shiftSatellites',
      value: function shiftSatellites(dY, dX) {
        this.willShiftSatellites(dY, dX);
        this._shift(dY, dX);
        this.didShiftSatellites(dY, dX);
      }
    }, {
      key: 'silentNight',
      value: function silentNight() {
        // Keeps skyline stationary when adding elements
        var _height = this.skyline.height;
        var _width = this.skyline.width;

        this.updateSkyline();

        var _skyline = this.skyline;
        var height = _skyline.height;
        var width = _skyline.width;

        var dY = height - _height;
        var dX = width - _width;

        this.scrollY = this.telescope.scrollTop += dY;
        this.scrollX = this.telescope.scrollLeft += dX;
        this.skyline.left -= dX;
        this.skyline.right -= dX;
        this.skyline.bottom -= dY;
        this.skyline.top -= dY;

        this.posX = _smokeAndMirrorsPrivateRadarModelsContainer['default'].scrollLeft;
        this.posY = _smokeAndMirrorsPrivateRadarModelsContainer['default'].scrollTop;

        for (var i = 0; i < this.length; i++) {
          this.satellites[i].geography.setState();
        }
      }
    }, {
      key: 'rebuild',
      value: function rebuild(dY, dX) {
        this.scrollY = this.telescope.scrollTop;
        this.scrollX = this.telescope.scrollLeft;
        this.posX = _smokeAndMirrorsPrivateRadarModelsContainer['default'].scrollLeft;
        this.posY = _smokeAndMirrorsPrivateRadarModelsContainer['default'].scrollTop;

        this.skyline.setState();
        this.planet.setState();

        for (var i = 0; i < this.length; i++) {
          this.satellites[i].geography.setState();
        }

        this.didRebuild(dY, dX);
      }
    }, {
      key: 'filterMovement',
      value: function filterMovement(offsets) {
        // cache the scroll offset, and discard the cycle if
        // movement is within (x) threshold
        var scrollY = offsets.top;
        var scrollX = offsets.left;
        var _scrollY = this.scrollY;
        var _scrollX = this.scrollX;

        if (this.isEarthquake(_scrollY, scrollY) || this.isEarthquake(_scrollX, scrollX)) {
          this.scrollY = scrollY;
          this.scrollX = scrollX;

          var dY = scrollY - _scrollY;
          var dX = scrollX - _scrollX;

          if (this.alwaysRemeasure) {
            this.rebuild(dY, dX);
            return;
          }

          this.shiftSatellites(dY, dX);
          this.currentOffsets = null;
        }
      }
    }, {
      key: 'updateScrollPosition',
      value: function updateScrollPosition(offsets) {
        var _posX = this.posX;
        var _posY = this.posY;
        var posX = offsets.left;
        var posY = offsets.top;

        if (this.isEarthquake(_posY, posY) || this.isEarthquake(_posX, posX)) {
          this.posY = posY;
          this.posX = posX;
          this.adjustPosition(posY - _posY, posX - _posX);

          if (this.alwaysRemeasure) {
            this.rebuild(posY - _posY, posX - _posX);
            return;
          }

          this.currentAdjustOffsets = null;
        }
      }
    }, {
      key: '_adjustPosition',
      value: function _adjustPosition(dY, dX) {
        this.planet.top -= dY;
        this.planet.bottom -= dY;
        this.planet.left -= dX;
        this.planet.right -= dX;

        this.skyline.top -= dY;
        this.skyline.bottom -= dY;
        this.skyline.left -= dX;
        this.skyline.right -= dX;

        this._shift(dY, dX);
      }
    }, {
      key: 'adjustPosition',
      value: function adjustPosition(dY, dX) {
        this.willAdjustPosition(dY, dX);
        this._adjustPosition(dY, dX);
        this.didAdjustPosition(dY, dX);
      }
    }, {
      key: '_setupHandlers',
      value: function _setupHandlers() {
        var _this = this;

        this._resizeHandler = undefined;
        this._scrollHandler = undefined;
        this._nextResize = null;
        this._nextScroll = null;
        this._nextAdjustment = null;
        this.currentOffsets = null;
        this.currentAdjustOffsets = null;

        this._scrollHandler = function (offsets) {
          if (_this.isTracking) {
            _this.currentOffsets = offsets;

            if (_this._nextScroll === null) {
              _smokeAndMirrorsPrivateScheduler['default'].schedule('layout', function () {
                if (_this.currentOffsets) {
                  _this.filterMovement(_this.currentOffsets);
                }
                _this._nextScroll = null;
              });
            }
          }
        };

        this._resizeHandler = function () {
          if (_this._nextResize === null) {
            _this._nextResize = _smokeAndMirrorsPrivateScheduler['default'].schedule('sync', function () {
              _this.resizeSatellites();
              _this._nextResize = null;
            });
          }
        };
        this._scrollAdjuster = function (offsets) {
          _this.currentAdjustOffsets = offsets;
          if (_this._nextAdjustment === null) {
            _this._nextAdjustment = _smokeAndMirrorsPrivateScheduler['default'].schedule('layout', function () {
              if (_this.currentAdjustOffsets) {
                _this.updateScrollPosition(_this.currentAdjustOffsets);
              }
              _this._nextAdjustment = null;
            });
          }
        };

        var handlerOpts = _smokeAndMirrorsPrivateRadarUtilsSupportsPassive['default'] ? { capture: true, passive: true } : true;

        _smokeAndMirrorsPrivateRadarModelsContainer['default'].addEventListener('resize', this._resizeHandler, handlerOpts);
        (0, _smokeAndMirrorsPrivateRadarUtilsScrollHandler.addScrollHandler)(this.telescope, this._scrollHandler);
        if (this.telescope !== _smokeAndMirrorsPrivateRadarModelsContainer['default']) {
          (0, _smokeAndMirrorsPrivateRadarUtilsScrollHandler.addScrollHandler)(_smokeAndMirrorsPrivateRadarModelsContainer['default'], this._scrollAdjuster);
        }
      }
    }, {
      key: '_teardownHandlers',
      value: function _teardownHandlers() {
        var handlerOpts = _smokeAndMirrorsPrivateRadarUtilsSupportsPassive['default'] ? { capture: true, passive: true } : true;

        _smokeAndMirrorsPrivateRadarModelsContainer['default'].removeEventListener('resize', this._resizeHandler, handlerOpts);
        if (this.telescope) {
          (0, _smokeAndMirrorsPrivateRadarUtilsScrollHandler.removeScrollHandler)(this.telescope, this._scrollHandler);
        }
        if (this.telescope !== _smokeAndMirrorsPrivateRadarModelsContainer['default']) {
          (0, _smokeAndMirrorsPrivateRadarUtilsScrollHandler.removeScrollHandler)(_smokeAndMirrorsPrivateRadarModelsContainer['default'], this._scrollAdjuster);
        }
        _smokeAndMirrorsPrivateScheduler['default'].forget(this._nextResize);
        _smokeAndMirrorsPrivateScheduler['default'].forget(this._nextScroll);
        _smokeAndMirrorsPrivateScheduler['default'].forget(this._nextAdjustment);
        this._scrollHandler = undefined;
        this._resizeHandler = undefined;
        this._scrollAdjuster = undefined;
      }

      // avoid retaining memory by deleting references
      // that likely contain other scopes to be torn down
    }, {
      key: '_teardownHooks',
      value: function _teardownHooks() {
        this.willShiftSatellites = undefined;
        this.didShiftSatellites = undefined;
        this.willResizeSatellites = undefined;
        this.didResizeSatellites = undefined;
        this.willAdjustPosition = undefined;
        this.didAdjustPosition = undefined;
      }
    }, {
      key: 'destroy',
      value: function destroy() {
        this._teardownHandlers();
        this._teardownHooks();
        if (this.satellites) {
          for (var i = 0; i < this.length; i++) {
            this.satellites[i].destroy();
          }
        }
        this.satellites = undefined;
        this.telescope = undefined;
        this.sky = undefined;

        if (this.planet) {
          this.planet.destroy();
        }

        this.planet = undefined;

        if (this.skyline) {
          this.skyline.destroy();
        }

        this.skyline = undefined;
      }
    }]);

    return Radar;
  })();

  exports['default'] = Radar;
});