enifed('ember-glimmer/syntax/curly-component', ['exports', 'ember-babel', 'ember-utils', '@glimmer/runtime', 'ember-glimmer/utils/bindings', 'ember-glimmer/component', 'ember-metal', 'ember-debug', 'ember-views', 'ember-glimmer/utils/process-args', 'container', 'ember-glimmer/syntax/abstract-manager'], function (exports, _emberBabel, _emberUtils, _runtime, _bindings, _component, _emberMetal, _emberDebug, _emberViews, _processArgs, _container, _abstractManager) {
  'use strict';

  exports.RootComponentDefinition = exports.CurlyComponentDefinition = undefined;
  exports.validatePositionalParameters = validatePositionalParameters;

  var _templateObject = (0, _emberBabel.taggedTemplateLiteralLoose)(['template:components/-default'], ['template:components/-default']);

  var DEFAULT_LAYOUT = (0, _container.privatize)(_templateObject);

  function validatePositionalParameters() {}

  function aliasIdToElementId(args, props) {
    if (args.named.has('id')) {
      false && !!args.named.has('elementId') && (0, _emberDebug.assert)('You cannot invoke a component with both \'id\' and \'elementId\' at the same time.', !args.named.has('elementId'));

      props.elementId = props.id;
    }
  }

  // We must traverse the attributeBindings in reverse keeping track of
  // what has already been applied. This is essentially refining the concated
  // properties applying right to left.
  function applyAttributeBindings(element, attributeBindings, component, operations) {
    var seen = [],
        binding,
        parsed,
        attribute;
    var i = attributeBindings.length - 1;

    while (i !== -1) {
      binding = attributeBindings[i];
      parsed = _bindings.AttributeBinding.parse(binding);
      attribute = parsed[1];


      if (seen.indexOf(attribute) === -1) {
        seen.push(attribute);
        _bindings.AttributeBinding.install(element, component, parsed, operations);
      }

      i--;
    }

    if (seen.indexOf('id') === -1) {
      operations.addStaticAttribute(element, 'id', component.elementId);
    }

    if (seen.indexOf('style') === -1) {
      _bindings.IsVisibleBinding.install(element, component, operations);
    }
  }

  function NOOP() {}

  var ComponentStateBucket = function () {
    function ComponentStateBucket(environment, component, args, finalizer) {

      this.environment = environment;
      this.component = component;
      this.classRef = null;
      this.args = args;
      this.argsRevision = args.tag.value();
      this.finalizer = finalizer;
    }

    ComponentStateBucket.prototype.destroy = function () {
      var component = this.component,
          environment = this.environment;

      if (environment.isInteractive) {
        component.trigger('willDestroyElement');
        component.trigger('willClearRender');
      }

      environment.destroyedComponents.push(component);
    };

    ComponentStateBucket.prototype.finalize = function () {
      var finalizer = this.finalizer;

      finalizer();
      this.finalizer = NOOP;
    };

    return ComponentStateBucket;
  }();

  function initialRenderInstrumentDetails(component) {
    return component.instrumentDetails({ initialRender: true });
  }

  function rerenderInstrumentDetails(component) {
    return component.instrumentDetails({ initialRender: false });
  }

  var CurlyComponentManager = function (_AbstractManager) {
    (0, _emberBabel.inherits)(CurlyComponentManager, _AbstractManager);

    function CurlyComponentManager() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager.apply(this, arguments));
    }

    CurlyComponentManager.prototype.prepareArgs = function (definition, args) {
      if (definition.ComponentClass) {
        validatePositionalParameters(args.named, args.positional.values, definition.ComponentClass.class.positionalParams);
      }

      return (0, _processArgs.gatherArgs)(args, definition);
    };

    CurlyComponentManager.prototype.create = function (environment, definition, args, dynamicScope, callerSelfRef, hasBlock) {

      var parentView = dynamicScope.view;

      var factory = definition.ComponentClass;

      var processedArgs = _processArgs.ComponentArgs.create(args);

      var _processedArgs$value = processedArgs.value(),
          props = _processedArgs$value.props;

      aliasIdToElementId(args, props);

      props.parentView = parentView;
      props[_component.HAS_BLOCK] = hasBlock;

      props._targetObject = callerSelfRef.value();

      var component = factory.create(props);

      var finalizer = (0, _emberMetal._instrumentStart)('render.component', initialRenderInstrumentDetails, component);

      dynamicScope.view = component;

      if (parentView !== null) {
        parentView.appendChild(component);
      }

      // We usually do this in the `didCreateElement`, but that hook doesn't fire for tagless components
      if (component.tagName === '') {
        if (environment.isInteractive) {
          component.trigger('willRender');
        }

        component._transitionTo('hasElement');

        if (environment.isInteractive) {
          component.trigger('willInsertElement');
        }
      }

      var bucket = new ComponentStateBucket(environment, component, processedArgs, finalizer);

      if (args.named.has('class')) {
        bucket.classRef = args.named.get('class');
      }

      if (environment.isInteractive && component.tagName !== '') {
        component.trigger('willRender');
      }

      return bucket;
    };

    CurlyComponentManager.prototype.layoutFor = function (definition, bucket, env) {
      var template = definition.template,
          component;
      if (!template) {
        component = bucket.component;


        template = this.templateFor(component, env);
      }
      return env.getCompiledBlock(CurlyComponentLayoutCompiler, template);
    };

    CurlyComponentManager.prototype.templateFor = function (component, env) {
      var Template = (0, _emberMetal.get)(component, 'layout'),
          template;
      var owner = component[_emberUtils.OWNER];
      if (Template) {
        return env.getTemplate(Template, owner);
      }
      var layoutName = (0, _emberMetal.get)(component, 'layoutName');
      if (layoutName) {
        template = owner.lookup('template:' + layoutName);

        if (template) {
          return template;
        }
      }
      return owner.lookup(DEFAULT_LAYOUT);
    };

    CurlyComponentManager.prototype.getSelf = function (_ref) {
      var component = _ref.component;

      return component[_component.ROOT_REF];
    };

    CurlyComponentManager.prototype.didCreateElement = function (_ref2, element, operations) {
      var component = _ref2.component,
          classRef = _ref2.classRef,
          environment = _ref2.environment;

      (0, _emberViews.setViewElement)(component, element);

      var attributeBindings = component.attributeBindings,
          classNames = component.classNames,
          classNameBindings = component.classNameBindings;

      if (attributeBindings && attributeBindings.length) {
        applyAttributeBindings(element, attributeBindings, component, operations);
      } else {
        operations.addStaticAttribute(element, 'id', component.elementId);
        _bindings.IsVisibleBinding.install(element, component, operations);
      }

      if (classRef) {
        operations.addDynamicAttribute(element, 'class', classRef);
      }

      if (classNames && classNames.length) {
        classNames.forEach(function (name) {
          operations.addStaticAttribute(element, 'class', name);
        });
      }

      if (classNameBindings && classNameBindings.length) {
        classNameBindings.forEach(function (binding) {
          _bindings.ClassNameBinding.install(element, component, binding, operations);
        });
      }

      component._transitionTo('hasElement');

      if (environment.isInteractive) {
        component.trigger('willInsertElement');
      }
    };

    CurlyComponentManager.prototype.didRenderLayout = function (bucket, bounds) {
      bucket.component[_component.BOUNDS] = bounds;
      bucket.finalize();
    };

    CurlyComponentManager.prototype.getTag = function (_ref3) {
      var component = _ref3.component;

      return component[_component.DIRTY_TAG];
    };

    CurlyComponentManager.prototype.didCreate = function (_ref4) {
      var component = _ref4.component,
          environment = _ref4.environment;

      if (environment.isInteractive) {
        component._transitionTo('inDOM');
        component.trigger('didInsertElement');
        component.trigger('didRender');
      }
    };

    CurlyComponentManager.prototype.update = function (bucket) {
      var component = bucket.component,
          args = bucket.args,
          argsRevision = bucket.argsRevision,
          environment = bucket.environment,
          _args$value,
          attrs,
          props,
          oldAttrs,
          newAttrs;

      bucket.finalizer = (0, _emberMetal._instrumentStart)('render.component', rerenderInstrumentDetails, component);

      if (!args.tag.validate(argsRevision)) {
        _args$value = args.value(), attrs = _args$value.attrs, props = _args$value.props;


        bucket.argsRevision = args.tag.value();

        oldAttrs = component.attrs;
        newAttrs = attrs;


        component[_component.IS_DISPATCHING_ATTRS] = true;
        component.setProperties(props);
        component[_component.IS_DISPATCHING_ATTRS] = false;

        (0, _emberViews.dispatchLifeCycleHook)(component, 'didUpdateAttrs', oldAttrs, newAttrs);
        (0, _emberViews.dispatchLifeCycleHook)(component, 'didReceiveAttrs', oldAttrs, newAttrs);
      }

      if (environment.isInteractive) {
        component.trigger('willUpdate');
        component.trigger('willRender');
      }
    };

    CurlyComponentManager.prototype.didUpdateLayout = function (bucket) {
      bucket.finalize();
    };

    CurlyComponentManager.prototype.didUpdate = function (_ref5) {
      var component = _ref5.component,
          environment = _ref5.environment;

      if (environment.isInteractive) {
        component.trigger('didUpdate');
        component.trigger('didRender');
      }
    };

    CurlyComponentManager.prototype.getDestructor = function (stateBucket) {
      return stateBucket;
    };

    return CurlyComponentManager;
  }(_abstractManager.default);

  var MANAGER = new CurlyComponentManager();

  var TopComponentManager = function (_CurlyComponentManage) {
    (0, _emberBabel.inherits)(TopComponentManager, _CurlyComponentManage);

    function TopComponentManager() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _CurlyComponentManage.apply(this, arguments));
    }

    TopComponentManager.prototype.create = function (environment, definition, args, dynamicScope) {
      var component = definition.ComponentClass.create();

      var finalizer = (0, _emberMetal._instrumentStart)('render.component', initialRenderInstrumentDetails, component);

      dynamicScope.view = component;

      // We usually do this in the `didCreateElement`, but that hook doesn't fire for tagless components
      if (component.tagName === '') {
        if (environment.isInteractive) {
          component.trigger('willRender');
        }

        component._transitionTo('hasElement');

        if (environment.isInteractive) {
          component.trigger('willInsertElement');
        }
      }

      return new ComponentStateBucket(environment, component, args, finalizer);
    };

    return TopComponentManager;
  }(CurlyComponentManager);

  var ROOT_MANAGER = new TopComponentManager();

  function tagName(vm) {
    var tagName = vm.dynamicScope().view.tagName;

    return _runtime.PrimitiveReference.create(tagName === '' ? null : tagName || 'div');
  }

  function ariaRole(vm) {
    return vm.getSelf().get('ariaRole');
  }

  exports.CurlyComponentDefinition = function (_ComponentDefinition) {
    (0, _emberBabel.inherits)(CurlyComponentDefinition, _ComponentDefinition);

    function CurlyComponentDefinition(name, ComponentClass, template, args) {

      var _this3 = (0, _emberBabel.possibleConstructorReturn)(this, _ComponentDefinition.call(this, name, MANAGER, ComponentClass));

      _this3.template = template;
      _this3.args = args;
      return _this3;
    }

    return CurlyComponentDefinition;
  }(_runtime.ComponentDefinition);

  exports.RootComponentDefinition = function (_ComponentDefinition2) {
    (0, _emberBabel.inherits)(RootComponentDefinition, _ComponentDefinition2);

    function RootComponentDefinition(instance) {

      var _this4 = (0, _emberBabel.possibleConstructorReturn)(this, _ComponentDefinition2.call(this, '-root', ROOT_MANAGER, {
        class: instance.constructor,
        create: function () {
          return instance;
        }
      }));

      _this4.template = undefined;
      _this4.args = undefined;
      return _this4;
    }

    return RootComponentDefinition;
  }(_runtime.ComponentDefinition);

  var CurlyComponentLayoutCompiler = function () {
    function CurlyComponentLayoutCompiler(template) {

      this.template = template;
    }

    CurlyComponentLayoutCompiler.prototype.compile = function (builder) {
      builder.wrapLayout(this.template.asLayout());
      builder.tag.dynamic(tagName);
      builder.attrs.dynamic('role', ariaRole);
      builder.attrs.static('class', 'ember-view');
    };

    return CurlyComponentLayoutCompiler;
  }();

  CurlyComponentLayoutCompiler.id = 'curly';
});