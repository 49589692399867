define('smoke-and-mirrors/-private/radar/models/satellite', ['exports', 'smoke-and-mirrors/-private/radar/models/geography', 'smoke-and-mirrors/-private/radar/models/element', 'perf-primitives/fast-array', 'smoke-and-mirrors/-private/radar/utils/noop-fn'], function (exports, _smokeAndMirrorsPrivateRadarModelsGeography, _smokeAndMirrorsPrivateRadarModelsElement, _perfPrimitivesFastArray, _smokeAndMirrorsPrivateRadarUtilsNoopFn) {
  'use strict';

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ('value' in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
      }
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }

  var SAT_POOL = new _perfPrimitivesFastArray['default'](200, 'Satellite Pool');

  var Satellite = (function () {
    function Satellite(options) {
      this.init(options);
    }

    _createClass(Satellite, [{
      key: 'init',
      value: function init(_ref) {
        var component = _ref.component;
        var defaultDimensions = _ref.dimensions;
        var element = _ref.element;
        var key = _ref.key;
        var radar = _ref.radar;
        var scalar = _ref.scalar;

        this.isVirtual = !element;
        this.radar = radar;
        this.element = _smokeAndMirrorsPrivateRadarModelsElement['default'].create(defaultDimensions, element);
        this.component = component;
        this.geography = new _smokeAndMirrorsPrivateRadarModelsGeography['default'](this.element);
        this.key = key;
        this.scalar = scalar || 1;

        if (component.registerSatellite) {
          component.registerSatellite(this);
        }
      }
    }, {
      key: 'virtualize',
      value: function virtualize() {
        this.isVirtual = true;
        this.element.element = undefined;
        this.geography.element = undefined;
      }
    }, {
      key: 'realize',
      value: function realize(element) {
        this.element.element = element;
        this.geography.element = element;

        this.isVirtual = false;
        this.recalc();
      }
    }, {
      key: 'heightDidChange',
      value: function heightDidChange() /* delta */{}
    }, {
      key: 'widthDidChange',
      value: function widthDidChange() /* delta */{}
    }, {
      key: 'recalc',
      value: function recalc() {
        var cached = this.geography.getState();

        this.resize();

        var dY = this.geography.top - cached.top;
        var dX = this.geography.left - cached.left;

        this.willShift(dY, dX);
        this.didShift(dY, dX);
      }
    }, {
      key: 'resize',
      value: function resize() {
        var cached = this.geography.getState();

        this.geography.setState();

        var heightChange = this.geography.height - cached.height;
        var widthChange = this.geography.width - cached.width;

        if (heightChange) {
          this.heightDidChange(-1 * heightChange);
        }
        if (widthChange) {
          this.widthDidChange(-1 * widthChange);
        }

        return heightChange || widthChange ? { dX: widthChange, dY: heightChange } : undefined;
      }
    }, {
      key: '_shift',
      value: function _shift(dY, dX) {
        this.geography.left -= dX;
        this.geography.right -= dX;
        this.geography.bottom -= dY;
        this.geography.top -= dY;
      }
    }, {
      key: 'willShift',
      value: function willShift() {}
    }, {
      key: 'didShift',
      value: function didShift() {}
    }, {
      key: 'shift',
      value: function shift(dY, dX) {
        this.willShift(dY, dX);
        this._shift(dY, dX);
        this.didShift(dY, dX);
      }
    }, {
      key: '_destroy',
      value: function _destroy() {
        if (this.component.unregisterSatellite) {
          this.component.unregisterSatellite();
        }

        // teardown internal objects
        this.element.destroy();
        this.element = undefined;
        this.component = undefined;
        this.geography.destroy();
        this.geography = undefined;
        this.radar = undefined;

        // teardown hooks
        this.willShift = _smokeAndMirrorsPrivateRadarUtilsNoopFn['default'];
        this.didShift = _smokeAndMirrorsPrivateRadarUtilsNoopFn['default'];
        this.heightDidChange = _smokeAndMirrorsPrivateRadarUtilsNoopFn['default'];
        this.widthDidChange = _smokeAndMirrorsPrivateRadarUtilsNoopFn['default'];
      }
    }, {
      key: 'destroy',
      value: function destroy() {
        this._destroy();

        SAT_POOL.push(this);
      }
    }], [{
      key: 'create',
      value: function create(options) {
        var sat = SAT_POOL.pop();

        if (sat) {
          sat.init(options);
          return sat;
        }

        return new Satellite(options);
      }
    }]);

    return Satellite;
  })();

  exports['default'] = Satellite;
});