define("ember-lifeline/utils/get-or-allocate", ["exports"], function (exports) {
  /**
   * Lazy allocates a property on an object
   *
   * @export
   * @param {Object} obj
   * @param {String} propertyName
   * @param {any} Type
   * @public
   * @returns the value of the newly allocated property
   */
  "use strict";

  exports["default"] = getOrAllocate;

  function getOrAllocate(obj, propertyName, Type) {
    if (!obj[propertyName]) {
      obj[propertyName] = new Type();
    }

    return obj[propertyName];
  }
});