define('ember-keyboard/services/keyboard', ['exports', 'ember-keyboard/listeners/key-events', 'ember-keyboard/utils/handle-key-event'], function (exports, _emberKeyboardListenersKeyEvents, _emberKeyboardUtilsHandleKeyEvent) {
  'use strict';

  exports['default'] = Ember.Service.extend({
    isPropagationEnabled: false,

    registeredResponders: Ember.computed(function () {
      return Ember.A();
    }),

    activeResponders: Ember.computed.filterBy('registeredResponders', 'keyboardActivated'),

    sortedRespondersSortDefinition: Ember.computed('isPropagationEnabled', function () {
      return Ember.get(this, 'isPropagationEnabled') ? ['keyboardPriority:desc'] : ['keyboardFirstResponder:desc', 'keyboardPriority:desc'];
    }),

    sortedResponders: Ember.computed.sort('activeResponders', 'sortedRespondersSortDefinition'),

    firstResponders: Ember.computed.filterBy('sortedResponders', 'keyboardFirstResponder'),

    normalResponders: Ember.computed.filter('sortedResponders.@each.keyboardFirstResponder', function (responder) {
      return !Ember.get(responder, 'keyboardFirstResponder');
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (typeof FastBoot !== 'undefined') {
        return;
      }

      var config = Ember.getOwner(this).resolveRegistration('config:environment') || {};

      var isPropagationEnabled = Boolean(Ember.get(config, 'emberKeyboard.propagation'));
      Ember.set(this, 'isPropagationEnabled', isPropagationEnabled);

      var listeners = Ember.get(config, 'emberKeyboard.listeners') || ['keyUp', 'keyDown', 'keyPress', 'click', 'mouseDown', 'mouseUp', 'touchStart', 'touchEnd'];
      var eventNames = listeners.map(function (name) {
        return name.toLowerCase() + '.ember-keyboard-listener';
      }).join(' ');

      Ember.$(document).on(eventNames, null, function (event) {
        Ember.run(function () {
          if (Ember.get(_this, 'isPropagationEnabled')) {
            (0, _emberKeyboardUtilsHandleKeyEvent.handleKeyEventWithPropagation)(event, Ember.getProperties(_this, 'firstResponders', 'normalResponders'));
          } else {
            (0, _emberKeyboardUtilsHandleKeyEvent.handleKeyEventWithLaxPriorities)(event, Ember.get(_this, 'sortedResponders'));
          }
        });
      });
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (typeof FastBoot !== 'undefined') {
        return;
      }

      Ember.$(document).off('.ember-keyboard-listener');
    },
    register: function register(responder) {
      Ember.get(this, 'registeredResponders').pushObject(responder);
    },
    unregister: function unregister(responder) {
      Ember.get(this, 'registeredResponders').removeObject(responder);
    },
    keyDown: function keyDown() {
      return _emberKeyboardListenersKeyEvents.keyDown.apply(undefined, arguments);
    },
    keyPress: function keyPress() {
      return _emberKeyboardListenersKeyEvents.keyPress.apply(undefined, arguments);
    },
    keyUp: function keyUp() {
      return _emberKeyboardListenersKeyEvents.keyUp.apply(undefined, arguments);
    }
  });
});