define('ember-data-sails/mixins/store', ['exports', 'ember', 'ember-data-sails/adapters/sails-socket'], function (exports, _ember, _emberDataSailsAdaptersSailsSocket) {
  'use strict';

  var StoreMixin = _ember['default'].Mixin.create({
    /**
     * @since 0.0.11
     * @inheritDoc
     * @method pushPayload
     * @param {String|subclass of DS.Model} [type]
     * @param {Object} payload
     * @param {Boolean} [subscribe] Whether to subscribe to pushed models or not (Sails socket)
     */
    pushPayload: function pushPayload() /*type, payload, subscribe*/{
      var args = [].slice.call(arguments),
          sub = false,
          old = this._pushSubscribes;
      if (_ember['default'].typeOf(args[args.length - 1]) === 'boolean') {
        sub = args.pop();
      }
      this._pushSubscribes = sub;
      this._super.apply(this, args);
      this._pushSubscribes = old;
    },

    /**
     * @since 0.0.11
     * @method push
     * @inheritDoc
     */
    push: function push(typeName /*, data, _partial*/) {
      var res = this._super.apply(this, arguments),
          id,
          type,
          adapter;
      if (this._pushSubscribes && res && (id = res.get('id'))) {
        type = this.modelFor(typeName);
        adapter = this.adapterFor(type);
        if (adapter instanceof _emberDataSailsAdaptersSailsSocket['default']) {
          adapter._scheduleSubscribe(type, id);
        }
      }
      return res;
    },

    /**
     * Schedule a subscription to the given model
     *
     * @since 0.0.11
     * @method subscribe
     * @param {String|subclass of DS.Model} type
     * @param {Array<String|Number>|String|Number} ids
     */
    subscribe: function subscribe(type, ids) {
      var adapter;
      if (_ember['default'].typeOf(ids) !== 'array') {
        ids = [ids];
      }
      type = this.modelFor(type);
      adapter = this.adapterFor(type);
      for (var i = 0; i < ids.length; i++) {
        adapter._scheduleSubscribe(type, ids[i]);
      }
    }
  });

  exports['default'] = StoreMixin;
});