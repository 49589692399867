define("layout-bin-packer/entry", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Entry;

  function Entry(height, width, x, y) {
    this.height = height;
    this.width = width;
    this.position = { x: x, y: y };
  }
});