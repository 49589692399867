enifed('ember-glimmer/syntax/dynamic-component', ['exports', '@glimmer/runtime', '@glimmer/reference', 'ember-debug'], function (exports, _runtime, _reference, _emberDebug) {
  'use strict';

  exports.closureComponentMacro = function (path, params, hash, _default, inverse, builder) {
    builder.component.dynamic([[['get', path]], hash, _default, inverse], dynamicComponentFor, [params, hash, _default, inverse], builder.symbolTable);
    return true;
  };
  exports.dynamicComponentMacro = function (params, hash, _default, inverse, builder) {
    var definitionArgs = [params.slice(0, 1), null, null, null];
    var args = [params.slice(1), hash, null, null];
    builder.component.dynamic(definitionArgs, dynamicComponentFor, args, builder.symbolTable);
    return true;
  };
  exports.blockComponentMacro = function (sexp, builder) {
    var params = sexp[2],
        hash = sexp[3],
        _default = sexp[4],
        inverse = sexp[5];

    var definitionArgs = [params.slice(0, 1), null, null, null];
    var args = [params.slice(1), hash, _default, inverse];
    builder.component.dynamic(definitionArgs, dynamicComponentFor, args, builder.symbolTable);
    return true;
  };
  exports.inlineComponentMacro = function (path, params, hash, builder) {
    var definitionArgs = [params.slice(0, 1), null, null, null];
    var args = [params.slice(1), hash, null, null];
    builder.component.dynamic(definitionArgs, dynamicComponentFor, args, builder.symbolTable);
    return true;
  };

  function dynamicComponentFor(vm, symbolTable) {
    var env = vm.env;
    var args = vm.getArgs();
    var nameRef = args.positional.at(0);

    return new DynamicComponentReference({ nameRef: nameRef, env: env, symbolTable: symbolTable });
  }

  var DynamicComponentReference = function () {
    function DynamicComponentReference(_ref) {
      var nameRef = _ref.nameRef,
          env = _ref.env,
          symbolTable = _ref.symbolTable,
          args = _ref.args;


      this.tag = nameRef.tag;
      this.nameRef = nameRef;
      this.env = env;
      this.symbolTable = symbolTable;
      this.args = args;
    }

    DynamicComponentReference.prototype.value = function () {
      var env = this.env,
          nameRef = this.nameRef,
          symbolTable = this.symbolTable,
          definition;

      var nameOrDef = nameRef.value();

      if (typeof nameOrDef === 'string') {
        definition = env.getComponentDefinition([nameOrDef], symbolTable);


        false && !definition && (0, _emberDebug.assert)('Could not find component named "' + nameOrDef + '" (no component or template with that name was found)', definition);

        return definition;
      } else if ((0, _runtime.isComponentDefinition)(nameOrDef)) {
        return nameOrDef;
      } else {
        return null;
      }
    };

    DynamicComponentReference.prototype.get = function () {
      return _reference.UNDEFINED_REFERENCE;
    };

    return DynamicComponentReference;
  }();
});