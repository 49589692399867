define("perf-primitives/-constants", ["exports"], function (exports) {
  "use strict";

  var SMALL_ARRAY_LENGTH = 200;
  exports.SMALL_ARRAY_LENGTH = SMALL_ARRAY_LENGTH;

  var LARGE_ARRAY_LENGTH = 64000;

  exports.LARGE_ARRAY_LENGTH = LARGE_ARRAY_LENGTH;

  var UNDEFINED_KEY = Object.create(null);
  exports.UNDEFINED_KEY = UNDEFINED_KEY;
});