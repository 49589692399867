define('ember-rapid-forms/components/html-custom-input', ['exports', 'ember', 'ember-rapid-forms/templates/components/html-custom-input'], function (exports, _ember, _emberRapidFormsTemplatesComponentsHtmlCustomInput) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberRapidFormsTemplatesComponentsHtmlCustomInput['default'],
    didReceiveAttrs: function didReceiveAttrs() /*attrs*/{
      this._super.apply(this, arguments);
      // set it to the correct value of the selection
      this.selectedValue = _ember['default'].computed.alias('mainComponent.model.' + this.get('mainComponent.property'));
    }
  });
});