define('ember-inputmask/components/one-way-zip-code-mask', ['exports', 'ember-inputmask/components/one-way-input-mask'], function (exports, _emberInputmaskComponentsOneWayInputMask) {
  'use strict';

  /**
   * `{{one-way-zip-code-mask}}` component.
   *
   * Displays an input that masks a US ZIP code.
   *
   * Future: Add config options that allow users to set locality
   * app wide.
   *
   * @param {boolean} fullCode Allows users to optionally enter the full ZIP+4 area code.e
   */
  exports['default'] = _emberInputmaskComponentsOneWayInputMask['default'].extend({
    NON_ATTRIBUTE_BOUND_PROPS: _emberInputmaskComponentsOneWayInputMask.DEFAULT_NON_BOUND_PROPS.concat('fullCode'),

    /**
     * Allows users to optionally enter the full ZIP+4 area code.
     */
    fullCode: false,

    /**
     * @override
     */
    mask: Ember.computed('fullCode', function () {
      if (Ember.get(this, 'fullCode')) {
        return '99999[-9999]';
      }

      return '99999';
    })
  });
});