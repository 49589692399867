enifed('ember-runtime/mixins/enumerable', ['exports', 'ember-utils', 'ember-metal', 'ember-debug', 'ember-runtime/compare', 'require'], function (exports, _emberUtils, _emberMetal, _emberDebug, _compare, _require2) {
  'use strict';

  var _emberA = void 0; /**
                        @module ember
                        @submodule ember-runtime
                        */

  // ..........................................................
  // HELPERS
  //

  function emberA() {
    return (_emberA || (_emberA = (0, _require2.default)('ember-runtime/system/native_array').A))();
  }

  var contexts = [];

  function popCtx() {
    return contexts.length === 0 ? {} : contexts.pop();
  }

  function pushCtx(ctx) {
    contexts.push(ctx);
    return null;
  }

  function iter(key, value) {
    var valueProvided = arguments.length === 2;

    return function (item) {
      var cur = (0, _emberMetal.get)(item, key);
      return valueProvided ? value === cur : !!cur;
    };
  }

  /**
    This mixin defines the common interface implemented by enumerable objects
    in Ember. Most of these methods follow the standard Array iteration
    API defined up to JavaScript 1.8 (excluding language-specific features that
    cannot be emulated in older versions of JavaScript).
  
    This mixin is applied automatically to the Array class on page load, so you
    can use any of these methods on simple arrays. If Array already implements
    one of these methods, the mixin will not override them.
  
    ## Writing Your Own Enumerable
  
    To make your own custom class enumerable, you need two items:
  
    1. You must have a length property. This property should change whenever
       the number of items in your enumerable object changes. If you use this
       with an `Ember.Object` subclass, you should be sure to change the length
       property using `set().`
  
    2. You must implement `nextObject().` See documentation.
  
    Once you have these two methods implemented, apply the `Ember.Enumerable` mixin
    to your class and you will be able to enumerate the contents of your object
    like any other collection.
  
    ## Using Ember Enumeration with Other Libraries
  
    Many other libraries provide some kind of iterator or enumeration like
    facility. This is often where the most common API conflicts occur.
    Ember's API is designed to be as friendly as possible with other
    libraries by implementing only methods that mostly correspond to the
    JavaScript 1.8 API.
  
    @class Enumerable
    @namespace Ember
    @since Ember 0.9
    @private
  */
  var Enumerable = _emberMetal.Mixin.create({

    /**
      __Required.__ You must implement this method to apply this mixin.
       Implement this method to make your class enumerable.
       This method will be called repeatedly during enumeration. The index value
      will always begin with 0 and increment monotonically. You don't have to
      rely on the index value to determine what object to return, but you should
      always check the value and start from the beginning when you see the
      requested index is 0.
       The `previousObject` is the object that was returned from the last call
      to `nextObject` for the current iteration. This is a useful way to
      manage iteration if you are tracing a linked list, for example.
       Finally the context parameter will always contain a hash you can use as
      a "scratchpad" to maintain any other state you need in order to iterate
      properly. The context object is reused and is not reset between
      iterations so make sure you setup the context with a fresh state whenever
      the index parameter is 0.
       Generally iterators will continue to call `nextObject` until the index
      reaches the current length-1. If you run out of data before this
      time for some reason, you should simply return undefined.
       The default implementation of this method simply looks up the index.
      This works great on any Array-like objects.
       @method nextObject
      @param {Number} index the current index of the iteration
      @param {Object} previousObject the value returned by the last call to
        `nextObject`.
      @param {Object} context a context object you can use to maintain state.
      @return {Object} the next object in the iteration or undefined
      @private
    */
    nextObject: null,

    /**
      Helper method returns the first object from a collection. This is usually
      used by bindings and other parts of the framework to extract a single
      object if the enumerable contains only one item.
       If you override this method, you should implement it so that it will
      always return the same value each time it is called. If your enumerable
      contains only one object, this method should always return that object.
      If your enumerable is empty, this method should return `undefined`.
       ```javascript
      let arr = ['a', 'b', 'c'];
      arr.get('firstObject');  // 'a'
       let arr = [];
      arr.get('firstObject');  // undefined
      ```
       @property firstObject
      @return {Object} the object or undefined
      @readOnly
      @public
    */
    firstObject: (0, _emberMetal.computed)('[]', function () {
      if ((0, _emberMetal.get)(this, 'length') === 0) {
        return undefined;
      }

      // handle generic enumerables
      var context = popCtx();
      var ret = this.nextObject(0, null, context);

      pushCtx(context);

      return ret;
    }).readOnly(),

    /**
      Helper method returns the last object from a collection. If your enumerable
      contains only one object, this method should always return that object.
      If your enumerable is empty, this method should return `undefined`.
       ```javascript
      let arr = ['a', 'b', 'c'];
      arr.get('lastObject');  // 'c'
       let arr = [];
      arr.get('lastObject');  // undefined
      ```
       @property lastObject
      @return {Object} the last object or undefined
      @readOnly
      @public
    */
    lastObject: (0, _emberMetal.computed)('[]', function () {
      var len = (0, _emberMetal.get)(this, 'length');

      if (len === 0) {
        return undefined;
      }

      var context = popCtx();
      var idx = 0;
      var last = null;
      var cur = void 0;

      do {
        last = cur;
        cur = this.nextObject(idx++, last, context);
      } while (cur !== undefined);

      pushCtx(context);

      return last;
    }).readOnly(),

    contains: function (obj) {
      false && !false && (0, _emberDebug.deprecate)('`Enumerable#contains` is deprecated, use `Enumerable#includes` instead.', false, { id: 'ember-runtime.enumerable-contains', until: '3.0.0', url: 'http://emberjs.com/deprecations/v2.x#toc_enumerable-contains' });

      var found = this.find(function (item) {
        return item === obj;
      });

      return found !== undefined;
    },
    forEach: function (callback, target) {
      if (typeof callback !== 'function') {
        throw new TypeError();
      }

      var context = popCtx(),
          idx,
          next;
      var len = (0, _emberMetal.get)(this, 'length');
      var last = null;

      if (target === undefined) {
        target = null;
      }

      for (idx = 0; idx < len; idx++) {
        next = this.nextObject(idx, last, context);

        callback.call(target, next, idx, this);
        last = next;
      }

      last = null;
      context = pushCtx(context);

      return this;
    },

    /**
      Alias for `mapBy`
       @method getEach
      @param {String} key name of the property
      @return {Array} The mapped array.
      @public
    */
    getEach: (0, _emberMetal.aliasMethod)('mapBy'),

    setEach: function (key, value) {
      return this.forEach(function (item) {
        return (0, _emberMetal.set)(item, key, value);
      });
    },
    map: function (callback, target) {
      var ret = emberA();

      this.forEach(function (x, idx, i) {
        return ret[idx] = callback.call(target, x, idx, i);
      });

      return ret;
    },
    mapBy: function (key) {
      return this.map(function (next) {
        return (0, _emberMetal.get)(next, key);
      });
    },
    filter: function (callback, target) {
      var ret = emberA();

      this.forEach(function (x, idx, i) {
        if (callback.call(target, x, idx, i)) {
          ret.push(x);
        }
      });

      return ret;
    },
    reject: function (callback, target) {
      return this.filter(function () {
        return !callback.apply(target, arguments);
      });
    },
    filterBy: function () {
      return this.filter(iter.apply(this, arguments));
    },
    rejectBy: function (key, value) {
      var use = arguments.length === 2 ? function (item) {
        return (0, _emberMetal.get)(item, key) === value;
      } : function (item) {
        return !!(0, _emberMetal.get)(item, key);
      };

      return this.reject(use);
    },
    find: function (callback, target) {
      var len = (0, _emberMetal.get)(this, 'length'),
          idx;

      if (target === undefined) {
        target = null;
      }

      var context = popCtx();
      var found = false;
      var last = null;
      var next = void 0,
          ret = void 0;

      for (idx = 0; idx < len && !found; idx++) {
        next = this.nextObject(idx, last, context);

        found = callback.call(target, next, idx, this);
        if (found) {
          ret = next;
        }

        last = next;
      }

      next = last = null;
      context = pushCtx(context);

      return ret;
    },
    findBy: function () {
      return this.find(iter.apply(this, arguments));
    },
    every: function (callback, target) {
      return !this.find(function (x, idx, i) {
        return !callback.call(target, x, idx, i);
      });
    },
    isEvery: function () {
      return this.every(iter.apply(this, arguments));
    },
    any: function (callback, target) {
      var len = (0, _emberMetal.get)(this, 'length'),
          idx;
      var context = popCtx();
      var found = false;
      var last = null;
      var next = void 0;

      if (target === undefined) {
        target = null;
      }

      for (idx = 0; idx < len && !found; idx++) {
        next = this.nextObject(idx, last, context);
        found = callback.call(target, next, idx, this);
        last = next;
      }

      next = last = null;
      context = pushCtx(context);
      return found;
    },
    isAny: function () {
      return this.any(iter.apply(this, arguments));
    },
    reduce: function (callback, initialValue, reducerProperty) {
      if (typeof callback !== 'function') {
        throw new TypeError();
      }

      var ret = initialValue;

      this.forEach(function (item, i) {
        ret = callback(ret, item, i, this, reducerProperty);
      }, this);

      return ret;
    },
    invoke: function (methodName) {
      for (_len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      var ret = emberA(),
          _len,
          args,
          _key;

      this.forEach(function (x, idx) {
        var method = x && x[methodName];

        if ('function' === typeof method) {
          ret[idx] = args ? method.apply(x, args) : x[methodName]();
        }
      }, this);

      return ret;
    },
    toArray: function () {
      var ret = emberA();

      this.forEach(function (o, idx) {
        return ret[idx] = o;
      });

      return ret;
    },
    compact: function () {
      return this.filter(function (value) {
        return value != null;
      });
    },
    without: function (value) {
      if (!this.includes(value)) {
        return this; // nothing to do
      }

      var ret = emberA();

      this.forEach(function (k) {
        // SameValueZero comparison (NaN !== NaN)
        if (!(k === value || k !== k && value !== value)) {
          ret[ret.length] = k;
        }
      });

      return ret;
    },
    uniq: function () {
      var ret = emberA();

      this.forEach(function (k) {
        if (ret.indexOf(k) < 0) {
          ret.push(k);
        }
      });

      return ret;
    },

    /**
      This property will trigger anytime the enumerable's content changes.
      You can observe this property to be notified of changes to the enumerable's
      content.
       For plain enumerables, this property is read only. `Array` overrides
      this method.
       @property []
      @type Array
      @return this
      @private
    */
    '[]': (0, _emberMetal.computed)({
      get: function () {
        return this;
      }
    }),

    addEnumerableObserver: function (target, opts) {
      var willChange = opts && opts.willChange || 'enumerableWillChange';
      var didChange = opts && opts.didChange || 'enumerableDidChange';
      var hasObservers = (0, _emberMetal.get)(this, 'hasEnumerableObservers');

      if (!hasObservers) {
        (0, _emberMetal.propertyWillChange)(this, 'hasEnumerableObservers');
      }

      (0, _emberMetal.addListener)(this, '@enumerable:before', target, willChange);
      (0, _emberMetal.addListener)(this, '@enumerable:change', target, didChange);

      if (!hasObservers) {
        (0, _emberMetal.propertyDidChange)(this, 'hasEnumerableObservers');
      }

      return this;
    },
    removeEnumerableObserver: function (target, opts) {
      var willChange = opts && opts.willChange || 'enumerableWillChange';
      var didChange = opts && opts.didChange || 'enumerableDidChange';
      var hasObservers = (0, _emberMetal.get)(this, 'hasEnumerableObservers');

      if (hasObservers) {
        (0, _emberMetal.propertyWillChange)(this, 'hasEnumerableObservers');
      }

      (0, _emberMetal.removeListener)(this, '@enumerable:before', target, willChange);
      (0, _emberMetal.removeListener)(this, '@enumerable:change', target, didChange);

      if (hasObservers) {
        (0, _emberMetal.propertyDidChange)(this, 'hasEnumerableObservers');
      }

      return this;
    },

    /**
      Becomes true whenever the array currently has observers watching changes
      on the array.
       @property hasEnumerableObservers
      @type Boolean
      @private
    */
    hasEnumerableObservers: (0, _emberMetal.computed)(function () {
      return (0, _emberMetal.hasListeners)(this, '@enumerable:change') || (0, _emberMetal.hasListeners)(this, '@enumerable:before');
    }),

    enumerableContentWillChange: function (removing, adding) {
      var removeCnt = void 0,
          addCnt = void 0,
          hasDelta = void 0;

      if ('number' === typeof removing) {
        removeCnt = removing;
      } else if (removing) {
        removeCnt = (0, _emberMetal.get)(removing, 'length');
      } else {
        removeCnt = removing = -1;
      }

      if ('number' === typeof adding) {
        addCnt = adding;
      } else if (adding) {
        addCnt = (0, _emberMetal.get)(adding, 'length');
      } else {
        addCnt = adding = -1;
      }

      hasDelta = addCnt < 0 || removeCnt < 0 || addCnt - removeCnt !== 0;

      if (removing === -1) {
        removing = null;
      }

      if (adding === -1) {
        adding = null;
      }

      (0, _emberMetal.propertyWillChange)(this, '[]');

      if (hasDelta) {
        (0, _emberMetal.propertyWillChange)(this, 'length');
      }

      (0, _emberMetal.sendEvent)(this, '@enumerable:before', [this, removing, adding]);

      return this;
    },
    enumerableContentDidChange: function (removing, adding) {
      var removeCnt = void 0,
          addCnt = void 0,
          hasDelta = void 0;

      if ('number' === typeof removing) {
        removeCnt = removing;
      } else if (removing) {
        removeCnt = (0, _emberMetal.get)(removing, 'length');
      } else {
        removeCnt = removing = -1;
      }

      if ('number' === typeof adding) {
        addCnt = adding;
      } else if (adding) {
        addCnt = (0, _emberMetal.get)(adding, 'length');
      } else {
        addCnt = adding = -1;
      }

      hasDelta = addCnt < 0 || removeCnt < 0 || addCnt - removeCnt !== 0;

      if (removing === -1) {
        removing = null;
      }

      if (adding === -1) {
        adding = null;
      }

      (0, _emberMetal.sendEvent)(this, '@enumerable:change', [this, removing, adding]);

      if (hasDelta) {
        (0, _emberMetal.propertyDidChange)(this, 'length');
      }

      (0, _emberMetal.propertyDidChange)(this, '[]');

      return this;
    },
    sortBy: function () {
      var sortKeys = arguments;

      return this.toArray().sort(function (a, b) {
        var i, key, propA, propB, compareValue;

        for (i = 0; i < sortKeys.length; i++) {
          key = sortKeys[i];
          propA = (0, _emberMetal.get)(a, key);
          propB = (0, _emberMetal.get)(b, key);
          // return 1 or -1 else continue to the next sortKey

          compareValue = (0, _compare.default)(propA, propB);


          if (compareValue) {
            return compareValue;
          }
        }
        return 0;
      });
    },
    uniqBy: function (key) {
      var ret = emberA();
      var seen = Object.create(null);

      this.forEach(function (item) {
        var guid = (0, _emberUtils.guidFor)((0, _emberMetal.get)(item, key));
        if (!(guid in seen)) {
          seen[guid] = true;
          ret.push(item);
        }
      });

      return ret;
    },
    includes: function (obj) {
      false && !(arguments.length === 1) && (0, _emberDebug.assert)('Enumerable#includes cannot accept a second argument "startAt" as enumerable items are unordered.', arguments.length === 1);

      var len = (0, _emberMetal.get)(this, 'length');
      var idx = void 0,
          next = void 0;
      var last = null;
      var found = false;

      var context = popCtx();

      for (idx = 0; idx < len && !found; idx++) {
        next = this.nextObject(idx, last, context);

        found = obj === next || obj !== obj && next !== next;

        last = next;
      }

      next = last = null;
      context = pushCtx(context);

      return found;
    }
  });

  exports.default = Enumerable;
});