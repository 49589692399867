define("ember-composable-helpers/helpers/group-by", ["exports"], function (exports) {
  "use strict";

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  })();

  var defineProperty = Ember.defineProperty,
      emberObject = Ember.Object;

  var groupFunction = function groupFunction() {
    var array = Ember.get(this, 'array');
    var byPath = Ember.get(this, 'byPath');
    var groups = emberObject.create();

    array.forEach(function (item) {
      var groupName = Ember.get(item, byPath);
      var group = Ember.get(groups, groupName);

      if (!Ember.isArray(group)) {
        group = Ember.A();
        groups['' + groupName] = group;
      }

      group.push(item);
    });

    return groups;
  };

  exports["default"] = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          byPath = _ref2[0],
          array = _ref2[1];

      Ember.set(this, 'array', array);
      Ember.set(this, 'byPath', byPath);

      return Ember.get(this, 'content');
    },

    byPathDidChange: Ember.observer('byPath', function () {
      var byPath = Ember.get(this, 'byPath');

      if (byPath) {
        defineProperty(this, 'content', Ember.computed('array.@each.' + byPath, groupFunction));
      } else {
        defineProperty(this, 'content', null);
      }
    }),

    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });
});