enifed('ember-glimmer/modifiers/action', ['exports', 'ember-utils', 'ember-metal', 'ember-debug', 'ember-views', 'ember-glimmer/helpers/action'], function (exports, _emberUtils, _emberMetal, _emberDebug, _emberViews, _action) {
  'use strict';

  exports.ActionState = exports.ActionHelper = undefined;

  var MODIFIERS = ['alt', 'shift', 'meta', 'ctrl'];
  var POINTER_EVENT_TYPE_REGEX = /^click|mouse|touch/;

  function isAllowedEvent(event, allowedKeys) {
    var i;

    if (allowedKeys === null || typeof allowedKeys === 'undefined') {
      if (POINTER_EVENT_TYPE_REGEX.test(event.type)) {
        return (0, _emberViews.isSimpleClick)(event);
      } else {
        allowedKeys = '';
      }
    }

    if (allowedKeys.indexOf('any') >= 0) {
      return true;
    }

    for (i = 0; i < MODIFIERS.length; i++) {
      if (event[MODIFIERS[i] + 'Key'] && allowedKeys.indexOf(MODIFIERS[i]) === -1) {
        return false;
      }
    }

    return true;
  }

  var ActionHelper = exports.ActionHelper = {
    // registeredActions is re-exported for compatibility with older plugins
    // that were using this undocumented API.
    registeredActions: _emberViews.ActionManager.registeredActions,

    registerAction: function (actionState) {
      var actionId = actionState.actionId;

      _emberViews.ActionManager.registeredActions[actionId] = actionState;

      return actionId;
    },
    unregisterAction: function (actionState) {
      var actionId = actionState.actionId;

      delete _emberViews.ActionManager.registeredActions[actionId];
    }
  };

  var ActionState = exports.ActionState = function () {
    function ActionState(element, actionId, actionName, actionArgs, namedArgs, positionalArgs, implicitTarget, dom) {

      this.element = element;
      this.actionId = actionId;
      this.actionName = actionName;
      this.actionArgs = actionArgs;
      this.namedArgs = namedArgs;
      this.positional = positionalArgs;
      this.implicitTarget = implicitTarget;
      this.dom = dom;
      this.eventName = this.getEventName();
    }

    ActionState.prototype.getEventName = function () {
      return this.namedArgs.get('on').value() || 'click';
    };

    ActionState.prototype.getActionArgs = function () {
      var result = new Array(this.actionArgs.length),
          i;

      for (i = 0; i < this.actionArgs.length; i++) {
        result[i] = this.actionArgs[i].value();
      }

      return result;
    };

    ActionState.prototype.getTarget = function () {
      var implicitTarget = this.implicitTarget,
          namedArgs = this.namedArgs;

      var target = void 0;

      if (namedArgs.has('target')) {
        target = namedArgs.get('target').value();
      } else {
        target = implicitTarget.value();
      }

      return target;
    };

    ActionState.prototype.handler = function (event) {
      var _this = this;

      var actionName = this.actionName,
          namedArgs = this.namedArgs;

      var bubbles = namedArgs.get('bubbles');
      var preventDefault = namedArgs.get('preventDefault');
      var allowedKeys = namedArgs.get('allowedKeys');
      var target = this.getTarget();

      if (!isAllowedEvent(event, allowedKeys.value())) {
        return true;
      }

      if (preventDefault.value() !== false) {
        event.preventDefault();
      }

      if (bubbles.value() === false) {
        event.stopPropagation();
      }

      (0, _emberMetal.run)(function () {
        var args = _this.getActionArgs();
        var payload = {
          args: args,
          target: target
        };
        if (typeof actionName[_action.INVOKE] === 'function') {
          (0, _emberMetal.flaggedInstrument)('interaction.ember-action', payload, function () {
            actionName[_action.INVOKE].apply(actionName, args);
          });
          return;
        }
        if (typeof actionName === 'function') {
          (0, _emberMetal.flaggedInstrument)('interaction.ember-action', payload, function () {
            actionName.apply(target, args);
          });
          return;
        }
        payload.name = actionName;
        if (target.send) {
          (0, _emberMetal.flaggedInstrument)('interaction.ember-action', payload, function () {
            target.send.apply(target, [actionName].concat(args));
          });
        } else {
          false && !(typeof target[actionName] === 'function') && (0, _emberDebug.assert)('The action \'' + actionName + '\' did not exist on ' + target, typeof target[actionName] === 'function');

          (0, _emberMetal.flaggedInstrument)('interaction.ember-action', payload, function () {
            target[actionName].apply(target, args);
          });
        }
      });
    };

    ActionState.prototype.destroy = function () {
      ActionHelper.unregisterAction(this);
    };

    return ActionState;
  }();

  var ActionModifierManager = function () {
    function ActionModifierManager() {}

    ActionModifierManager.prototype.create = function (element, args, dynamicScope, dom) {
      var named = args.named,
          positional = args.positional,
          actionLabel,
          i;

      var implicitTarget = void 0;
      var actionName = void 0;
      var actionNameRef = void 0;
      if (positional.length > 1) {
        implicitTarget = positional.at(0);
        actionNameRef = positional.at(1);

        if (actionNameRef[_action.INVOKE]) {
          actionName = actionNameRef;
        } else {
          actionLabel = actionNameRef._propertyKey;

          actionName = actionNameRef.value();

          false && !(typeof actionName === 'string' || typeof actionName === 'function') && (0, _emberDebug.assert)('You specified a quoteless path, `' + actionLabel + '`, to the ' + '{{action}} helper which did not resolve to an action name (a ' + 'string). Perhaps you meant to use a quoted actionName? (e.g. ' + '{{action "' + actionLabel + '"}}).', typeof actionName === 'string' || typeof actionName === 'function');
        }
      }

      var actionArgs = [];
      // The first two arguments are (1) `this` and (2) the action name.
      // Everything else is a param.
      for (i = 2; i < positional.length; i++) {
        actionArgs.push(positional.at(i));
      }

      var actionId = (0, _emberUtils.uuid)();
      return new ActionState(element, actionId, actionName, actionArgs, named, positional, implicitTarget, dom);
    };

    ActionModifierManager.prototype.install = function (actionState) {
      var dom = actionState.dom,
          element = actionState.element,
          actionId = actionState.actionId;

      ActionHelper.registerAction(actionState);

      dom.setAttribute(element, 'data-ember-action', '');
      dom.setAttribute(element, 'data-ember-action-' + actionId, actionId);
    };

    ActionModifierManager.prototype.update = function (actionState) {
      var positional = actionState.positional;

      var actionNameRef = positional.at(1);

      if (!actionNameRef[_action.INVOKE]) {
        actionState.actionName = actionNameRef.value();
      }

      actionState.eventName = actionState.getEventName();
    };

    ActionModifierManager.prototype.getDestructor = function (modifier) {
      return modifier;
    };

    return ActionModifierManager;
  }();

  exports.default = ActionModifierManager;
});