enifed('ember-glimmer/helpers/query-param', ['exports', 'ember-utils', 'ember-glimmer/utils/references', 'ember-debug', 'ember-routing'], function (exports, _emberUtils, _references, _emberDebug, _emberRouting) {
  'use strict';

  exports.default = function (vm, args) {
    return new _references.InternalHelperReference(queryParams, args);
  };

  /**
    This is a helper to be used in conjunction with the link-to helper.
    It will supply url query parameters to the target route.
  
    Example
  
    ```handlebars
    {{#link-to 'posts' (query-params direction="asc")}}Sort{{/link-to}}
    ```
  
    @method query-params
    @for Ember.Templates.helpers
    @param {Object} hash takes a hash of query parameters
    @return {Object} A `QueryParams` object for `{{link-to}}`
    @public
  */
  /**
  @module ember
  @submodule ember-glimmer
  */
  function queryParams(_ref) {
    var positional = _ref.positional,
        named = _ref.named;
    false && !(positional.value().length === 0) && (0, _emberDebug.assert)('The `query-params` helper only accepts hash parameters, e.g. (query-params queryParamPropertyName=\'foo\') as opposed to just (query-params \'foo\')', positional.value().length === 0);

    return _emberRouting.QueryParams.create({
      values: (0, _emberUtils.assign)({}, named.value())
    });
  }
});