define('ember-rapid-forms/components/em-form-submit', ['exports', 'ember', 'ember-rapid-forms/mixins/in-form', 'ember-rapid-forms/templates/components/em-form-submit'], function (exports, _ember, _emberRapidFormsMixinsInForm, _emberRapidFormsTemplatesComponentsEmFormSubmit) {
  'use strict';

  /*
  Form Submit Button
  
  Syntax:
  {{em-form-submit text="Submit"}}
   */
  exports['default'] = _ember['default'].Component.extend(_emberRapidFormsMixinsInForm['default'], {
    layout: _emberRapidFormsTemplatesComponentsEmFormSubmit['default'],
    classes: 'btn btn-default',
    classNames: ['form-group'],
    text: 'Submit',
    type: 'submit',
    horiClass: 'col-sm-offset-2 col-sm-10',
    disabled: _ember['default'].computed('model.isValid', {
      get: function get() {
        if (this.get('form.showErrorsOnSubmit') && !this.get('form.isSubmitted')) {
          return false;
        }

        if (!_ember['default'].isNone(this.get('model.isValid'))) {
          return !this.get('model.isValid');
        } else {
          return false;
        }
      }
    })
  });
});