define('ember-inputmask/components/one-way-email-mask', ['exports', 'ember-inputmask/components/one-way-input-mask'], function (exports, _emberInputmaskComponentsOneWayInputMask) {
  'use strict';

  /**
   * `{{one-way-email-mask}}` component.
   *
   * Displays an input that masks to email 
   */
  exports['default'] = _emberInputmaskComponentsOneWayInputMask['default'].extend({
    /**
     * @override
     */
    mask: 'email'
  });
});