define('smoke-and-mirrors/utils/get-tag-descendant', ['exports'], function (exports) {
  'use strict';

  exports['default'] = getTagDescendant;

  /*
   * automatic tag matching for lists, selects, and tables
   *
   * @param {String} tag an html tagName
   * @returns {String} tag a new html tagName
   */
  function getTagDescendant(tag) {
    switch (tag) {
      case 'vertical-collection':
        return 'vertical-item';
      case 'horizontal-collection':
        return 'horizontal-item';
      case 'tbody':
      case 'table':
      case 'thead':
      case 'tfoot':
        return 'tr';
      case 'tr':
      case 'th':
        return 'td';
      case 'ul':
      case 'ol':
        return 'li';
      case 'select':
      case 'optgroup':
        return 'option';
      default:
        return 'div';
    }
  }
});