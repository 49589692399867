define('ember-inputmask/components/ssn-input', ['exports', 'ember-inputmask/components/input-mask'], function (exports, _emberInputmaskComponentsInputMask) {
  'use strict';

  /**
   * `{{ssn-input}}` component
   *  Displays an input that masks SSN format
   */

  exports['default'] = _emberInputmaskComponentsInputMask['default'].extend({
    mask: '999-99-9999',

    oldComponent: '{{ssn-input}}',
    newComponent: '{{one-way-ssn-mask}}',

    updateMask: function updateMask() {
      this.set('mask', '999-99-9999');
      this._super();
    },

    _maskShouldChange: Ember.observer('mask', function () {
      Ember.run.once(this, 'updateMask');
    })
  });
});