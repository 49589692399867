define('ember-keyboard/fixtures/code-maps/mac-safari-and-chrome', ['exports'], function (exports) {
  'use strict';

  exports['default'] = {
    93: 'OSRight',
    124: 'PrintScreen',
    125: 'ScrollLock',
    126: 'Pause',
    12: 'NumLock',
    188: 'NumpadComma',
    190: 'NumpadComma',
    187: 'NumpadEqual'
  };
});