define('smoke-and-mirrors/components/vertical-item/component', ['exports', 'ember', 'smoke-and-mirrors/components/vertical-item/template', 'ember-getowner-polyfill', 'smoke-and-mirrors/-private/scheduler'], function (exports, _ember, _smokeAndMirrorsComponentsVerticalItemTemplate, _emberGetownerPolyfill, _smokeAndMirrorsPrivateScheduler) {
  'use strict';

  var Component = _ember['default'].Component;

  /*
   A vertical-item is one that intelligently removes
   its content when scrolled off the screen vertically.
  
   @class vertical-item
   @extends Ember.Component
   @namespace Ember
   **/
  exports['default'] = Component.extend({
    layout: _smokeAndMirrorsComponentsVerticalItemTemplate['default'],
    tagName: 'vertical-item',
    itemTagName: 'vertical-item',

    alwaysRemeasure: false,

    classNames: ['vertical-item'],

    next: function next() {
      var element = this.element.nextElementSibling;

      return element ? this.registry[element.id] : null;
    },

    prev: function prev() {
      var element = this.element.previousElementSibling;

      return element ? this.registry[element.id] : null;
    },

    contentInserted: false,
    _contentInserted: false,

    /*
     * Reveal the Element
     *
     */
    show: function show() {
      if (this._contentInserted) {
        return;
      }

      this._contentInserted = true;
      this.set('contentInserted', true);
      if (this.alwaysRemeasure) {
        this.element.style.height = undefined;
      }
      _smokeAndMirrorsPrivateScheduler['default'].schedule('measure', function () {
        // this.updateHeight();
      });
    },

    /*
     * Destroy the View/Element
     *
     * Unlike the other methods, this method
     * can be called from any state. It is still not valid
     * to transition to it directly, but willDestroy uses it
     * to teardown the instance.
     */
    cull: function cull() {
      if (!this._contentInserted) {
        return;
      }

      // this.updateHeight();
      if (this.alwaysRemeasure && this.element) {
        if (this.setHeightProp) {
          this.element.style.height = this.satellite.geography.height + 'px';
        }
        this.element.style.minHeight = this.satellite.geography.height + 'px';
      }

      this._contentInserted = false;
      this.set('contentInserted', false);
    },

    _hasRealHeight: false,
    updateHeight: function updateHeight() {
      var needsRealHeight = this.alwaysRemeasure;

      if (needsRealHeight) {
        this.satellite.resize();
      }
    },

    defaultHeight: 75,
    index: undefined,
    content: undefined,
    setHeightProp: false,

    radar: undefined,
    satellite: undefined,
    registry: undefined,
    registerSatellite: function registerSatellite(satellite) {
      this.satellite = satellite;
    },
    unregisterSatellite: function unregisterSatellite() {
      this.satellite = undefined;
    },

    _height: 0,

    didInsertElement: function didInsertElement() {
      this.radar.register(this);
    },

    willInsertElement: function willInsertElement() {
      this._super();
      var _height = this.get('_height');
      var defaultHeight = this.get('defaultHeight');

      if (typeof defaultHeight === 'number') {
        defaultHeight = defaultHeight + 'px';
      }

      var height = _height ? _height + 'px' : defaultHeight;

      this.element.style.minHeight = height;

      if (this.setHeightProp) {
        this.element.style.height = height;
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super();
      this.set('contentInserted', false);
      this._contentInserted = false;

      if (this.radar) {
        this.radar.unregister(this);
      }
      this.satellite = undefined;
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.unregister(this);

      if (this.radar) {
        this.radar.unregister(this);
      }
      this.satellite = undefined;
      this.registry = undefined;
    },

    init: function init() {
      this._super();
      this.registry = (0, _emberGetownerPolyfill['default'])(this).lookup('-view-registry:main');
      var tag = this.get('itemTagName');

      this.set('tagName', tag);
      tag = tag.toLowerCase();

      var isTableChild = tag === 'tr' || tag === 'td' || tag === 'th';

      // table children don't respect min-height :'(
      this.setHeightProp = isTableChild || !this.alwaysRemeasure;
      this.register(this);
    }
  });
});