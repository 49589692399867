define('ember-light-table/components/lt-spanned-row', ['exports', 'ember-light-table/templates/components/lt-spanned-row'], function (exports, _emberLightTableTemplatesComponentsLtSpannedRow) {
  'use strict';

  var Component = Ember.Component;

  exports['default'] = Component.extend({
    layout: _emberLightTableTemplatesComponentsLtSpannedRow['default'],
    colspan: 1,
    tagName: '',
    visible: true
  });
});