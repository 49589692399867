define('ember-rapid-forms/components/em-form-control-help', ['exports', 'ember', 'ember-rapid-forms/mixins/in-form', 'ember-rapid-forms/templates/components/em-form-control-help'], function (exports, _ember, _emberRapidFormsMixinsInForm, _emberRapidFormsTemplatesComponentsEmFormControlHelp) {
  'use strict';

  /*
  Form Control Help
  
  Renders a textual help of the control.
  
  Note: currently must be a direct descendant of a form-group or 'property' must be explicitly defined
  
  Syntax:
  {{em-form-control-help}}
   */
  exports['default'] = _ember['default'].Component.extend(_emberRapidFormsMixinsInForm['default'], {
    layout: _emberRapidFormsTemplatesComponentsEmFormControlHelp['default'],
    tagName: 'span',
    classNames: ['help-block'],
    classNameBindings: ['extraClass', 'horiClassCalc'],
    text: null,
    extraClass: null,
    horiClass: 'col-sm-offset-2 col-sm-10',
    horiClassCalc: _ember['default'].computed('form.isHorizontal', {
      get: function get() {
        if (this.get('form.isHorizontal') && this.get('horiClass')) {
          return this.get('horiClass');
        }
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      _ember['default'].defineProperty(this, 'errors', _ember['default'].computed.alias('mainComponent.model.errors.' + this.get('mainComponent.propertyName')));
    },
    helpText: _ember['default'].computed('text', 'errors.firstObject', {
      get: function get() {
        return this.get('errors.firstObject.message') || this.get('errors.firstObject') || this.get('text');
      }
    }),
    hasHelp: _ember['default'].computed('helpText', {
      get: function get() {
        var helpText = this.get('helpText');
        if (!helpText) {
          return false;
        }
        return helpText.length > 0;
      }
    }),
    hasError: _ember['default'].computed('errors.length', {
      get: function get() {
        return this.get('errors') != null;
      }
    })
  });
});