define("ember-scrollable/classes/scrollable", ["exports"], function (exports) {
  "use strict";

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
      }
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var camelize = Ember.String.camelize;

  var Scrollable = (function () {
    function Scrollable(options) {
      _classCallCheck(this, Scrollable);

      this.scrollbarElement = options.scrollbarElement;
      this.contentElement = options.contentElement;
    }

    _createClass(Scrollable, [{
      key: 'scrollbarSize',
      value: function scrollbarSize() {
        return this.scrollbarElement[this.sizeAttr]();
      }
    }, {
      key: 'contentOuterSize',
      value: function contentOuterSize() {
        return this.contentElement[camelize('outer-' + this.sizeAttr)]();
      }
    }, {
      key: 'getHandlePositionAndSize',
      value: function getHandlePositionAndSize(scrollOffset) {
        // we own this data
        var contentSize = this.contentOuterSize();
        // we pass this to the child
        var scrollbarSize = this.scrollbarSize();
        var scrollbarRatio = scrollbarSize / contentSize;

        // Calculate new height/position of drag handle.
        // Offset of 2px allows for a small top/bottom or left/right margin around handle.
        var handleOffset = Math.round(scrollbarRatio * scrollOffset) + 2;

        var handleSize = 0;

        // check if content is scrollbar is longer than content
        if (scrollbarRatio < 1) {
          var handleSizeCalculated = Math.floor(scrollbarRatio * (scrollbarSize - 2)) - 2;
          // check if handleSize is too small
          handleSize = handleSizeCalculated < 10 ? 10 : handleSizeCalculated;
        }

        return { handleOffset: handleOffset, handleSize: handleSize };
      }
    }, {
      key: 'isScrolledToBottom',
      value: function isScrolledToBottom(scrollBuffer, scrollOffset) {
        var contentSize = this.contentOuterSize();
        var scrollbarSize = this.scrollbarSize();

        return scrollOffset + scrollbarSize + scrollBuffer >= contentSize;
      }
    }, {
      key: 'isNecessary',
      get: function get() {
        return this.scrollbarSize() < this.contentOuterSize();
      }
    }]);

    return Scrollable;
  })();

  exports["default"] = Scrollable;
  var Vertical = (function (_Scrollable) {
    _inherits(Vertical, _Scrollable);

    function Vertical(options) {
      _classCallCheck(this, Vertical);

      var _this = _possibleConstructorReturn(this, (Vertical.__proto__ || Object.getPrototypeOf(Vertical)).call(this, options));

      _this.offsetAttr = 'top';
      _this.sizeAttr = 'height';
      return _this;
    }

    return Vertical;
  })(Scrollable);

  exports.Vertical = Vertical;
  var Horizontal = (function (_Scrollable2) {
    _inherits(Horizontal, _Scrollable2);

    function Horizontal(options) {
      _classCallCheck(this, Horizontal);

      var _this2 = _possibleConstructorReturn(this, (Horizontal.__proto__ || Object.getPrototypeOf(Horizontal)).call(this, options));

      _this2.offsetAttr = 'left';
      _this2.sizeAttr = 'width';
      return _this2;
    }

    return Horizontal;
  })(Scrollable);
  exports.Horizontal = Horizontal;
});