define("accounting/parse", ["exports", "accounting/unformat"], function (exports, _accountingUnformat) {
  "use strict";

  /**
   * Alias of unformat
   *
   * @method parse
   * @for accounting
   */
  exports["default"] = _accountingUnformat["default"];
});