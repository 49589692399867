define('ember-inputmask/components/one-way-currency-mask', ['exports', 'ember-inputmask/components/one-way-input-mask'], function (exports, _emberInputmaskComponentsOneWayInputMask) {
  'use strict';

  /**
   * `{{one-way-currency-mask}}` component.
   *
   * Displays an input that masks to currency
   */
  exports['default'] = _emberInputmaskComponentsOneWayInputMask['default'].extend({
    /**
     * @override
     */
    mask: 'currency'
  });
});