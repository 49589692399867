define('smoke-and-mirrors/components/in-viewport/component', ['exports', 'ember', 'smoke-and-mirrors/components/in-viewport/template'], function (exports, _ember, _smokeAndMirrorsComponentsInViewportTemplate) {
  'use strict';

  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;

  exports['default'] = Component.extend({
    layout: _smokeAndMirrorsComponentsInViewportTemplate['default'],
    attributeBindings: ['zoneX:x', 'zoneY:y'],

    inViewport: inject.service('in-viewport'),

    zoneX: 0,
    zoneY: 0,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('inViewport').register(this);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('inViewport').unregister(this);
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get('inViewport').unregister(this);
    }
  });
});