define('ember-resolver/utils/make-dictionary', ['exports'], function (exports) {
  'use strict';

  exports['default'] = makeDictionary;

  function makeDictionary() {
    var cache = Object.create(null);
    cache['_dict'] = null;
    delete cache['_dict'];
    return cache;
  }
});