define('ember-light-table/-private/global-options', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  exports.mergeOptionsWithGlobals = mergeOptionsWithGlobals;

  // eslint-disable-next-line ember-suave/no-direct-property-access
  var assign = Ember.assign || Ember.merge;
  var globalOptions = _emberGetConfig['default']['ember-light-table'] || {};

  exports['default'] = globalOptions;

  function mergeOptionsWithGlobals(options) {
    return assign(assign({}, globalOptions), options);
  }
});