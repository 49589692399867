define('ember-data-sails/initializers/ember-data-sails', ['exports', 'ember', 'ember-data', 'ember-data-sails/mixins/with-logger', 'ember-data-sails/mixins/store', 'ember-data-sails/services/sails-socket'], function (exports, _ember, _emberData, _emberDataSailsMixinsWithLogger, _emberDataSailsMixinsStore, _emberDataSailsServicesSailsSocket) {
  'use strict';

  exports.initialize = initialize;

  _emberData['default'].Store.reopen(_emberDataSailsMixinsStore['default']);

  function initialize(appInstance) {
    var methods, minLevel, shouldLog;
    methods = {};
    minLevel = appInstance.SAILS_LOG_LEVEL;
    shouldLog = false;
    _emberDataSailsMixinsWithLogger.LEVELS.forEach(function (level) {
      if (level === minLevel) {
        shouldLog = true;
      }
      if (!shouldLog) {
        methods[level] = _ember['default'].K;
      }
    });
    _emberDataSailsMixinsWithLogger['default'].reopen(methods);

    appInstance.register('service:sails-socket', _emberDataSailsServicesSailsSocket['default']);

    // setup injections
    appInstance.inject('adapter', 'sailsSocket', 'service:sails-socket');
    appInstance.inject('route', 'sailsSocket', 'service:sails-socket');
    appInstance.inject('controller', 'sailsSocket', 'service:sails-socket');
  }

  var EmberDataSailsInitializer = {
    name: 'ember-data-sails',
    before: 'store',

    initialize: initialize
  };

  exports['default'] = EmberDataSailsInitializer;
});