define('ember-rapid-forms/components/em-form-group', ['exports', 'ember', 'ember-rapid-forms/mixins/in-form', 'ember-rapid-forms/mixins/has-property', 'ember-rapid-forms/mixins/has-property-validation', 'ember-rapid-forms/templates/components/em-form-group'], function (exports, _ember, _emberRapidFormsMixinsInForm, _emberRapidFormsMixinsHasProperty, _emberRapidFormsMixinsHasPropertyValidation, _emberRapidFormsTemplatesComponentsEmFormGroup) {
  'use strict';

  /*
  Form Group
  
  Wraps labels, controls and help message for optimum spacing and validation styles.
  A wrapper for a single input with its assistances views such as label, help message.
  
  A form group can yield the control's view after or within a label, this is dependent on the control
      required layout and is defined byt he yieldInLabel property
  
  
  Syntax:
  {{em-form-group
      //The state of the form group
      status="none|error|warning|success"
      //If true the control view is yieled within the label
      yieldInLabel=true|false
      //If true validation icons will be rendered, by default inherited from the form
      validationIcons: true
      //Label of the form group, default is a human friendly form of the property name
      label="Some label"
  }}
   */
  exports['default'] = _ember['default'].Component.extend(_emberRapidFormsMixinsInForm['default'], _emberRapidFormsMixinsHasProperty['default'], _emberRapidFormsMixinsHasPropertyValidation['default'], {
    tagName: 'div',
    "class": 'form-group',
    htmlComponent: 'em-custom-input',
    layout: _emberRapidFormsTemplatesComponentsEmFormGroup['default'],
    classNameBindings: ['class', 'hasSuccess', 'hasWarning', 'hasError', 'validationIcons:has-feedback'],
    attributeBindings: ['disabled'],
    canShowErrors: false,
    hasSuccess: _ember['default'].computed('status', 'canShowErrors', {
      get: function get() {
        var success;
        success = this.get('validations') && this.get('status') === 'success' && this.get('canShowErrors');
        this.set('success', success);
        return success;
      }
    }),
    hasWarning: _ember['default'].computed('status', 'canShowErrors', {
      get: function get() {
        var warning;
        warning = this.get('validations') && this.get('status') === 'warning' && this.get('canShowErrors');
        this.set('warning', warning);
        return warning;
      }
    }),
    hasError: _ember['default'].computed('status', 'canShowErrors', {
      get: function get() {
        var error;
        error = this.get('validations') && this.get('status') === 'error' && this.get('canShowErrors');
        this.set('error', error);
        return error;
      }
    }),
    validationIcons: _ember['default'].computed.alias('form.validationIcons'),
    successIcon: 'fa fa-check',
    warningIcon: 'fa fa-exclamation-triangle',
    errorIcon: 'fa fa-times',
    validations: true,
    yieldInLabel: false,
    validationIcon: _ember['default'].computed('status', 'canShowErrors', {
      get: function get() {
        if (!this.get('canShowErrors')) {
          return;
        }
        switch (this.get('status')) {
          case 'success':
            return this.get('successIcon');
          case 'warning':
          case 'warn':
            return this.get('warningIcon');
          case 'error':
            return this.get('errorIcon');
          default:
            return null;
        }
      }
    }),
    hideValidationsOnFormChange: _ember['default'].observer('form', 'form.model', function () {
      this.set('canShowErrors', false);
    }),
    shouldShowErrors: _ember['default'].computed('canShowErrors', 'helpText', {
      get: function get() {
        var text = this.get('helpText') || "";
        return text.length > 0 && this.get('canShowErrors');
      }
    }),
    helpText: _ember['default'].computed('text', 'errors.firstObject', {
      get: function get() {
        return this.get('errors.firstObject.message') || this.get('errors.firstObject') || this.get('text');
      }
    }),
    init: function init() {
      return this._super.apply(this, arguments);
    },

    /*
    Observes the helpHasErrors of the help control and modify the 'status' property accordingly.
     */

    focusIn: function focusIn() {
      if (this.get('form.showErrorsOnFocusIn')) {
        return this.set('canShowErrors', true);
      }
    },

    /*
    Listen to the focus out of the form group and display the errors
     */
    focusOut: function focusOut() {
      return this.set('canShowErrors', true);
    },

    /*
    Listen to the keyUp of the form group and display the errors if showOnKeyUp is true.
     */
    keyUp: function keyUp() {
      if (this.get('showOnKeyUp')) {
        return this.set('canShowErrors', true);
      }
    }
  });
});