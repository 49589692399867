define("accounting/format", ["exports", "accounting/format-number"], function (exports, _accountingFormatNumber) {
  "use strict";

  /**
   * Alias of formatNumber
   *
   * @method format
   * @for accounting
   */
  exports["default"] = _accountingFormatNumber["default"];
});