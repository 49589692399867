define('ember-rapid-forms/components/em-form-label', ['exports', 'ember', 'ember-rapid-forms/mixins/in-form', 'ember-rapid-forms/templates/components/em-form-label'], function (exports, _ember, _emberRapidFormsMixinsInForm, _emberRapidFormsTemplatesComponentsEmFormLabel) {
  'use strict';

  /*
  Form Label
  
  When styled with bootstrap, when form is rendered horizontally, the label require the 'extraClass' property to
      be set to a value such 'col-sm-2' to be aligned properly.
  
  Syntax:
  {{em-form-label
      text="Some label"
      extraClass="col-sm-2"
  }}
  
  Or can serve as a block helper for elements that needs to be wrapped within label element.
  {{#em-form-label text="Active?"}}
      {{em-checkbox}}
  {{/em-form-label}}
   */
  exports['default'] = _ember['default'].Component.extend(_emberRapidFormsMixinsInForm['default'], {
    layout: _emberRapidFormsTemplatesComponentsEmFormLabel['default'],
    tagName: 'label',
    classNames: ['control-label'],
    classNameBindings: ['extraClass', 'inlineClassCalc', 'horiClassCalc'],
    attributeBindings: ['for'],
    horiClass: 'col-sm-2',
    horiClassCalc: _ember['default'].computed('form.isHorizontal', {
      get: function get() {
        if (this.get('form.isHorizontal') && this.get('horiClass')) {
          return this.get('horiClass');
        }
      }
    }),
    inlineClass: 'sr-only',
    inlineClassCalc: _ember['default'].computed('form.formLayout', {
      get: function get() {
        if (this.get('form.isInline') && this.get('inlineClass')) {
          return this.get('inlineClass');
        }
      }
    })
  });
});