enifed('backburner', ['exports'], function (exports) {
    'use strict';

    var NUMBER = /\d+/;
    var now = Date.now;
    function each(collection, callback) {
        var i;

        for (i = 0; i < collection.length; i++) {
            callback(collection[i]);
        }
    }
    function isString(suspect) {
        return typeof suspect === 'string';
    }
    function isFunction(suspect) {
        return typeof suspect === 'function';
    }
    function isNumber(suspect) {
        return typeof suspect === 'number';
    }
    function isCoercableNumber(suspect) {
        return isNumber(suspect) || NUMBER.test(suspect);
    }
    function noSuchQueue(name) {
        throw new Error('You attempted to schedule an action in a queue (' + name + ') that doesn\'t exist');
    }
    function noSuchMethod(name) {
        throw new Error('You attempted to schedule an action in a queue (' + name + ') for a method that doesn\'t exist');
    }
    function getOnError(options) {
        return options.onError || options.onErrorTarget && options.onErrorTarget[options.onErrorMethod];
    }
    function findDebouncee(target, method, debouncees) {
        return findItem(target, method, debouncees);
    }
    function findThrottler(target, method, throttlers) {
        return findItem(target, method, throttlers);
    }
    function findItem(target, method, collection) {
        var item = void 0,
            i,
            l;
        var index = -1;
        for (i = 0, l = collection.length; i < l; i++) {
            item = collection[i];
            if (item[0] === target && item[1] === method) {
                index = i;
                break;
            }
        }
        return index;
    }

    function binarySearch(time, timers) {
        var start = 0;
        var end = timers.length - 2;
        var middle = void 0;
        var l = void 0;
        while (start < end) {
            // since timers is an array of pairs 'l' will always
            // be an integer
            l = (end - start) / 2;
            // compensate for the index in case even number
            // of pairs inside timers
            middle = start + l - l % 2;
            if (time >= timers[middle]) {
                start = middle + 2;
            } else {
                end = middle;
            }
        }
        return time >= timers[start] ? start + 2 : start;
    }

    var Queue = function () {
        function Queue(name, options, globalOptions) {

            this.name = name;
            this.globalOptions = globalOptions || {};
            this.options = options;
            this._queue = [];
            this.targetQueues = {};
            this._queueBeingFlushed = undefined;
        }

        Queue.prototype.push = function (target, method, args, stack) {
            var queue = this._queue;
            queue.push(target, method, args, stack);
            return {
                queue: this,
                target: target,
                method: method
            };
        };

        Queue.prototype.pushUnique = function (target, method, args, stack) {
            var KEY = this.globalOptions.GUID_KEY,
                guid;
            if (target && KEY) {
                guid = target[KEY];

                if (guid) {
                    return this.pushUniqueWithGuid(guid, target, method, args, stack);
                }
            }
            this.pushUniqueWithoutGuid(target, method, args, stack);
            return {
                queue: this,
                target: target,
                method: method
            };
        };

        Queue.prototype.flush = function (sync) {
            var queue = this._queue,
                i;
            var length = queue.length;
            if (length === 0) {
                return;
            }
            var globalOptions = this.globalOptions;
            var options = this.options;
            var before = options && options.before;
            var after = options && options.after;
            var onError = globalOptions.onError || globalOptions.onErrorTarget && globalOptions.onErrorTarget[globalOptions.onErrorMethod];
            var target = void 0;
            var method = void 0;
            var args = void 0;
            var errorRecordedForStack = void 0;
            var invoke = onError ? this.invokeWithOnError : this.invoke;
            this.targetQueues = Object.create(null);
            var queueItems = this._queueBeingFlushed = this._queue;
            this._queue = [];
            if (before) {
                before();
            }
            for (i = 0; i < length; i += 4) {
                target = queueItems[i];
                method = queueItems[i + 1];
                args = queueItems[i + 2];
                errorRecordedForStack = queueItems[i + 3]; // Debugging assistance
                if (isString(method)) {
                    method = target[method];
                }
                // method could have been nullified / canceled during flush
                if (method) {
                    //
                    //    ** Attention intrepid developer **
                    //
                    //    To find out the stack of this task when it was scheduled onto
                    //    the run loop, add the following to your app.js:
                    //
                    //    Ember.run.backburner.DEBUG = true; // NOTE: This slows your app, don't leave it on in production.
                    //
                    //    Once that is in place, when you are at a breakpoint and navigate
                    //    here in the stack explorer, you can look at `errorRecordedForStack.stack`,
                    //    which will be the captured stack when this job was scheduled.
                    //
                    //    One possible long-term solution is the following Chrome issue:
                    //       https://bugs.chromium.org/p/chromium/issues/detail?id=332624
                    //
                    invoke(target, method, args, onError, errorRecordedForStack);
                }
            }
            if (after) {
                after();
            }
            this._queueBeingFlushed = undefined;
            if (sync !== false && this._queue.length > 0) {
                // check if new items have been added
                this.flush(true);
            }
        };

        Queue.prototype.cancel = function (actionToCancel) {
            var queue = this._queue,
                targetQueue;
            var currentTarget = void 0;
            var currentMethod = void 0;
            var i = void 0;
            var l = void 0;
            var target = actionToCancel.target,
                method = actionToCancel.method;

            var GUID_KEY = this.globalOptions.GUID_KEY;
            if (GUID_KEY && this.targetQueues && target) {
                targetQueue = this.targetQueues[target[GUID_KEY]];

                if (targetQueue) {
                    for (i = 0, l = targetQueue.length; i < l; i++) {
                        if (targetQueue[i] === method) {
                            targetQueue.splice(i, 1);
                        }
                    }
                }
            }
            for (i = 0, l = queue.length; i < l; i += 4) {
                currentTarget = queue[i];
                currentMethod = queue[i + 1];
                if (currentTarget === target && currentMethod === method) {
                    queue.splice(i, 4);
                    return true;
                }
            }
            // if not found in current queue
            // could be in the queue that is being flushed
            queue = this._queueBeingFlushed;
            if (!queue) {
                return;
            }
            for (i = 0, l = queue.length; i < l; i += 4) {
                currentTarget = queue[i];
                currentMethod = queue[i + 1];
                if (currentTarget === target && currentMethod === method) {
                    // don't mess with array during flush
                    // just nullify the method
                    queue[i + 1] = null;
                    return true;
                }
            }
        };

        Queue.prototype.pushUniqueWithoutGuid = function (target, method, args, stack) {
            var queue = this._queue,
                i,
                l,
                currentTarget,
                currentMethod;
            for (i = 0, l = queue.length; i < l; i += 4) {
                currentTarget = queue[i];
                currentMethod = queue[i + 1];

                if (currentTarget === target && currentMethod === method) {
                    queue[i + 2] = args; // replace args
                    queue[i + 3] = stack; // replace stack
                    return;
                }
            }
            queue.push(target, method, args, stack);
        };

        Queue.prototype.targetQueue = function (_targetQueue, target, method, args, stack) {
            var queue = this._queue,
                i,
                l,
                currentMethod,
                currentIndex;
            for (i = 0, l = _targetQueue.length; i < l; i += 2) {
                currentMethod = _targetQueue[i];
                currentIndex = _targetQueue[i + 1];

                if (currentMethod === method) {
                    queue[currentIndex + 2] = args; // replace args
                    queue[currentIndex + 3] = stack; // replace stack
                    return;
                }
            }
            _targetQueue.push(method, queue.push(target, method, args, stack) - 4);
        };

        Queue.prototype.pushUniqueWithGuid = function (guid, target, method, args, stack) {
            var hasLocalQueue = this.targetQueues[guid];
            if (hasLocalQueue) {
                this.targetQueue(hasLocalQueue, target, method, args, stack);
            } else {
                this.targetQueues[guid] = [method, this._queue.push(target, method, args, stack) - 4];
            }
            return {
                queue: this,
                target: target,
                method: method
            };
        };

        Queue.prototype.invoke = function (target, method, args /*, onError, errorRecordedForStack */) {
            if (args && args.length > 0) {
                method.apply(target, args);
            } else {
                method.call(target);
            }
        };

        Queue.prototype.invokeWithOnError = function (target, method, args, onError, errorRecordedForStack) {
            try {
                if (args && args.length > 0) {
                    method.apply(target, args);
                } else {
                    method.call(target);
                }
            } catch (error) {
                onError(error, errorRecordedForStack);
            }
        };

        return Queue;
    }();

    var DeferredActionQueues = function () {
        function DeferredActionQueues(queueNames, options) {

            var queues = this.queues = {};
            this.queueNames = queueNames = queueNames || [];
            this.options = options;
            each(queueNames, function (queueName) {
                queues[queueName] = new Queue(queueName, options[queueName], options);
            });
        }

        DeferredActionQueues.prototype.schedule = function (name, target, method, args, onceFlag, stack) {
            var queues = this.queues;
            var queue = queues[name];
            if (!queue) {
                noSuchQueue(name);
            }
            if (!method) {
                noSuchMethod(name);
            }
            if (onceFlag) {
                return queue.pushUnique(target, method, args, stack);
            } else {
                return queue.push(target, method, args, stack);
            }
        };

        DeferredActionQueues.prototype.flush = function () {
            var queue = void 0;
            var queueName = void 0;
            var queueNameIndex = 0;
            var numberOfQueues = this.queueNames.length;
            while (queueNameIndex < numberOfQueues) {
                queueName = this.queueNames[queueNameIndex];
                queue = this.queues[queueName];
                if (queue._queue.length === 0) {
                    queueNameIndex++;
                } else {
                    queue.flush(false /* async */);
                    queueNameIndex = 0;
                }
            }
        };

        return DeferredActionQueues;
    }();

    var Backburner = function () {
        function Backburner(queueNames, options) {
            var _this = this;

            this.DEBUG = false;
            this._autorun = null;
            this.queueNames = queueNames;
            this.options = options || {};
            if (!this.options.defaultQueue) {
                this.options.defaultQueue = queueNames[0];
            }
            this.currentInstance = null;
            this.instanceStack = [];
            this._debouncees = [];
            this._throttlers = [];
            this._eventCallbacks = {
                end: [],
                begin: []
            };
            this._boundClearItems = function (item) {
                _this._platform.clearTimeout(item[2]);
            };
            this._timerTimeoutId = undefined;
            this._timers = [];
            this._platform = this.options._platform || {
                setTimeout: function (fn, ms) {
                    return setTimeout(fn, ms);
                },
                clearTimeout: function (id) {
                    clearTimeout(id);
                }
            };
            this._boundRunExpiredTimers = function () {
                _this._runExpiredTimers();
            };
            this._boundAutorunEnd = function () {
                _this._autorun = null;
                _this.end();
            };
        }

        Backburner.prototype.begin = function () {
            var options = this.options;
            var onBegin = options && options.onBegin;
            var previousInstance = this.currentInstance;
            if (previousInstance) {
                this.instanceStack.push(previousInstance);
            }
            var current = this.currentInstance = new DeferredActionQueues(this.queueNames, options);
            this._trigger('begin', current, previousInstance);
            if (onBegin) {
                onBegin(current, previousInstance);
            }
            return current;
        };

        Backburner.prototype.end = function () {
            var options = this.options;
            var onEnd = options && options.onEnd;
            var currentInstance = this.currentInstance;
            var nextInstance = null;
            if (!currentInstance) {
                throw new Error('end called without begin');
            }
            // Prevent double-finally bug in Safari 6.0.2 and iOS 6
            // This bug appears to be resolved in Safari 6.0.5 and iOS 7
            var finallyAlreadyCalled = false;
            try {
                currentInstance.flush();
            } finally {
                if (!finallyAlreadyCalled) {
                    finallyAlreadyCalled = true;
                    this.currentInstance = null;
                    if (this.instanceStack.length) {
                        nextInstance = this.instanceStack.pop();
                        this.currentInstance = nextInstance;
                    }
                    this._trigger('end', currentInstance, nextInstance);
                    if (onEnd) {
                        onEnd(currentInstance, nextInstance);
                    }
                }
            }
        };

        Backburner.prototype.on = function (eventName, callback) {
            if (typeof callback !== 'function') {
                throw new TypeError('Callback must be a function');
            }
            var callbacks = this._eventCallbacks[eventName];
            if (callbacks) {
                callbacks.push(callback);
            } else {
                throw new TypeError('Cannot on() event ' + eventName + ' because it does not exist');
            }
        };

        Backburner.prototype.off = function (eventName, callback) {
            var callbacks, callbackFound, i;

            if (eventName) {
                callbacks = this._eventCallbacks[eventName];
                callbackFound = false;

                if (!callbacks) {
                    return;
                }
                if (callback) {
                    for (i = 0; i < callbacks.length; i++) {
                        if (callbacks[i] === callback) {
                            callbackFound = true;
                            callbacks.splice(i, 1);
                            i--;
                        }
                    }
                }
                if (!callbackFound) {
                    throw new TypeError('Cannot off() callback that does not exist');
                }
            } else {
                throw new TypeError('Cannot off() event ' + eventName + ' because it does not exist');
            }
        };

        Backburner.prototype.run = function (target, method) {
            for (_len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
                args[_key - 2] = arguments[_key];
            }

            var length = arguments.length,
                _len,
                args,
                _key;
            var _method = void 0;
            var _target = void 0;
            if (length === 1) {
                _method = target;
                _target = null;
            } else {
                _target = target;
                _method = method;
            }
            if (isString(_method)) {
                _method = _target[_method];
            }
            var onError = getOnError(this.options);
            this.begin();
            if (onError) {
                try {
                    return _method.apply(_target, args);
                } catch (error) {
                    onError(error);
                } finally {
                    this.end();
                }
            } else {
                try {
                    return _method.apply(_target, args);
                } finally {
                    this.end();
                }
            }
        };

        Backburner.prototype.join = function () {
            if (!this.currentInstance) {
                return this.run.apply(this, arguments);
            }
            var length = arguments.length,
                args,
                i;
            var method = void 0;
            var target = void 0;
            if (length === 1) {
                method = arguments[0];
                target = null;
            } else {
                target = arguments[0];
                method = arguments[1];
            }
            if (isString(method)) {
                method = target[method];
            }
            if (length === 1) {
                return method();
            } else if (length === 2) {
                return method.call(target);
            } else {
                args = new Array(length - 2);

                for (i = 0; i < length - 2; i++) {
                    args[i] = arguments[i + 2];
                }
                return method.apply(target, args);
            }
        };

        Backburner.prototype.defer = function (queueName /* , target, method, args */) {
            var length = arguments.length,
                i;
            var method = void 0;
            var target = void 0;
            var args = void 0;
            if (length === 2) {
                method = arguments[1];
                target = null;
            } else {
                target = arguments[1];
                method = arguments[2];
            }
            if (isString(method)) {
                method = target[method];
            }
            var stack = this.DEBUG ? new Error() : undefined;
            if (length > 3) {
                args = new Array(length - 3);
                for (i = 3; i < length; i++) {
                    args[i - 3] = arguments[i];
                }
            } else {
                args = undefined;
            }
            return this._ensureInstance().schedule(queueName, target, method, args, false, stack);
        };

        Backburner.prototype.deferOnce = function (queueName /* , target, method, args */) {
            var length = arguments.length,
                i;
            var method = void 0;
            var target = void 0;
            var args = void 0;
            if (length === 2) {
                method = arguments[1];
                target = null;
            } else {
                target = arguments[1];
                method = arguments[2];
            }
            if (isString(method)) {
                method = target[method];
            }
            var stack = this.DEBUG ? new Error() : undefined;
            if (length > 3) {
                args = new Array(length - 3);
                for (i = 3; i < length; i++) {
                    args[i - 3] = arguments[i];
                }
            } else {
                args = undefined;
            }
            var currentInstance = this._ensureInstance();
            return currentInstance.schedule(queueName, target, method, args, true, stack);
        };

        Backburner.prototype.setTimeout = function () {
            var l = arguments.length,
                x,
                last;
            var args = new Array(l);
            for (x = 0; x < l; x++) {
                args[x] = arguments[x];
            }
            var length = args.length;
            var method = void 0;
            var wait = void 0;
            var target = void 0;
            var methodOrTarget = void 0;
            var methodOrWait = void 0;
            var methodOrArgs = void 0;
            if (length === 0) {
                return;
            } else if (length === 1) {
                method = args.shift();
                wait = 0;
            } else if (length === 2) {
                methodOrTarget = args[0];
                methodOrWait = args[1];
                if (isFunction(methodOrWait) || isFunction(methodOrTarget[methodOrWait])) {
                    target = args.shift();
                    method = args.shift();
                    wait = 0;
                } else if (isCoercableNumber(methodOrWait)) {
                    method = args.shift();
                    wait = args.shift();
                } else {
                    method = args.shift();
                    wait = 0;
                }
            } else {
                last = args[args.length - 1];

                if (isCoercableNumber(last)) {
                    wait = args.pop();
                } else {
                    wait = 0;
                }
                methodOrTarget = args[0];
                methodOrArgs = args[1];
                if (isFunction(methodOrArgs) || isString(methodOrArgs) && methodOrTarget !== null && methodOrArgs in methodOrTarget) {
                    target = args.shift();
                    method = args.shift();
                } else {
                    method = args.shift();
                }
            }
            var executeAt = now() + parseInt(wait !== wait ? 0 : wait, 10);
            if (isString(method)) {
                method = target[method];
            }
            var onError = getOnError(this.options);

            return this._setTimeout(function () {
                if (onError) {
                    try {
                        method.apply(target, args);
                    } catch (e) {
                        onError(e);
                    }
                } else {
                    method.apply(target, args);
                }
            }, executeAt);
        };

        Backburner.prototype.throttle = function (target, method /* , args, wait, [immediate] */) {
            var backburner = this,
                i;
            var args = new Array(arguments.length);
            for (i = 0; i < arguments.length; i++) {
                args[i] = arguments[i];
            }
            var immediate = args.pop();
            var wait = void 0;
            var throttler = void 0;
            var index = void 0;
            var timer = void 0;
            if (isNumber(immediate) || isString(immediate)) {
                wait = immediate;
                immediate = true;
            } else {
                wait = args.pop();
            }
            wait = parseInt(wait, 10);
            index = findThrottler(target, method, this._throttlers);
            if (index > -1) {
                return this._throttlers[index];
            } // throttled
            timer = this._platform.setTimeout(function () {
                if (!immediate) {
                    backburner.run.apply(backburner, args);
                }
                index = findThrottler(target, method, backburner._throttlers);
                if (index > -1) {
                    backburner._throttlers.splice(index, 1);
                }
            }, wait);
            if (immediate) {
                this.join.apply(this, args);
            }
            throttler = [target, method, timer];
            this._throttlers.push(throttler);
            return throttler;
        };

        Backburner.prototype.debounce = function (target, method /* , args, wait, [immediate] */) {
            var backburner = this,
                i;
            var args = new Array(arguments.length);
            for (i = 0; i < arguments.length; i++) {
                args[i] = arguments[i];
            }
            var immediate = args.pop();
            var wait = void 0;
            var index = void 0;
            var debouncee = void 0;
            var timer = void 0;
            if (isNumber(immediate) || isString(immediate)) {
                wait = immediate;
                immediate = false;
            } else {
                wait = args.pop();
            }
            wait = parseInt(wait, 10);
            // Remove debouncee
            index = findDebouncee(target, method, this._debouncees);
            if (index > -1) {
                debouncee = this._debouncees[index];
                this._debouncees.splice(index, 1);
                this._platform.clearTimeout(debouncee[2]);
            }
            timer = this._platform.setTimeout(function () {
                if (!immediate) {
                    backburner.run.apply(backburner, args);
                }
                index = findDebouncee(target, method, backburner._debouncees);
                if (index > -1) {
                    backburner._debouncees.splice(index, 1);
                }
            }, wait);
            if (immediate && index === -1) {
                backburner.run.apply(backburner, args);
            }
            debouncee = [target, method, timer];
            backburner._debouncees.push(debouncee);
            return debouncee;
        };

        Backburner.prototype.cancelTimers = function () {
            each(this._throttlers, this._boundClearItems);
            this._throttlers = [];
            each(this._debouncees, this._boundClearItems);
            this._debouncees = [];
            this._clearTimerTimeout();
            this._timers = [];
            if (this._autorun) {
                this._platform.clearTimeout(this._autorun);
                this._autorun = null;
            }
        };

        Backburner.prototype.hasTimers = function () {
            return !!this._timers.length || !!this._debouncees.length || !!this._throttlers.length || this._autorun;
        };

        Backburner.prototype.cancel = function (timer) {
            var timerType = typeof timer,
                i,
                l;
            if (timer && timerType === 'object' && timer.queue && timer.method) {
                return timer.queue.cancel(timer);
            } else if (timerType === 'function') {
                for (i = 0, l = this._timers.length; i < l; i += 2) {
                    if (this._timers[i + 1] === timer) {
                        this._timers.splice(i, 2); // remove the two elements
                        if (i === 0) {
                            this._reinstallTimerTimeout();
                        }
                        return true;
                    }
                }
            } else if (Object.prototype.toString.call(timer) === '[object Array]') {
                return this._cancelItem(findThrottler, this._throttlers, timer) || this._cancelItem(findDebouncee, this._debouncees, timer);
            } else {}
        };

        Backburner.prototype._setTimeout = function (fn, executeAt) {
            if (this._timers.length === 0) {
                this._timers.push(executeAt, fn);
                this._installTimerTimeout();
                return fn;
            }
            // find position to insert
            var i = binarySearch(executeAt, this._timers);
            this._timers.splice(i, 0, executeAt, fn);
            // we should be the new earliest timer if i == 0
            if (i === 0) {
                this._reinstallTimerTimeout();
            }
            return fn;
        };

        Backburner.prototype._cancelItem = function (findMethod, array, timer) {
            var item = void 0;
            var index = void 0;
            if (timer.length < 3) {
                return false;
            }
            index = findMethod(timer[0], timer[1], array);
            if (index > -1) {
                item = array[index];
                if (item[2] === timer[2]) {
                    array.splice(index, 1);
                    this._platform.clearTimeout(timer[2]);
                    return true;
                }
            }
            return false;
        };

        Backburner.prototype._trigger = function (eventName, arg1, arg2) {
            var callbacks = this._eventCallbacks[eventName],
                i;
            if (callbacks) {
                for (i = 0; i < callbacks.length; i++) {
                    callbacks[i](arg1, arg2);
                }
            }
        };

        Backburner.prototype._runExpiredTimers = function () {
            this._timerTimeoutId = undefined;
            this.run(this, this._scheduleExpiredTimers);
        };

        Backburner.prototype._scheduleExpiredTimers = function () {
            var n = now(),
                executeAt,
                fn;
            var timers = this._timers;
            var i = 0;
            var l = timers.length;
            for (; i < l; i += 2) {
                executeAt = timers[i];
                fn = timers[i + 1];

                if (executeAt <= n) {
                    this.defer(this.options.defaultQueue, null, fn);
                } else {
                    break;
                }
            }
            timers.splice(0, i);
            this._installTimerTimeout();
        };

        Backburner.prototype._reinstallTimerTimeout = function () {
            this._clearTimerTimeout();
            this._installTimerTimeout();
        };

        Backburner.prototype._clearTimerTimeout = function () {
            if (!this._timerTimeoutId) {
                return;
            }
            this._platform.clearTimeout(this._timerTimeoutId);
            this._timerTimeoutId = undefined;
        };

        Backburner.prototype._installTimerTimeout = function () {
            if (!this._timers.length) {
                return;
            }
            var minExpiresAt = this._timers[0];
            var n = now();
            var wait = Math.max(0, minExpiresAt - n);
            this._timerTimeoutId = this._platform.setTimeout(this._boundRunExpiredTimers, wait);
        };

        Backburner.prototype._ensureInstance = function () {
            var currentInstance = this.currentInstance,
                _setTimeout2;
            if (!currentInstance) {
                _setTimeout2 = this._platform.setTimeout;

                currentInstance = this.begin();
                this._autorun = _setTimeout2(this._boundAutorunEnd, 0);
            }
            return currentInstance;
        };

        return Backburner;
    }();

    Backburner.Queue = Queue;
    Backburner.prototype.schedule = Backburner.prototype.defer;
    Backburner.prototype.scheduleOnce = Backburner.prototype.deferOnce;
    Backburner.prototype.later = Backburner.prototype.setTimeout;

    exports.default = Backburner;
});