enifed("@glimmer/wire-format", ["exports"], function (exports) {
    "use strict";

    var Opcodes;
    (function (Opcodes) {
        // Statements
        Opcodes[Opcodes["Text"] = 0] = "Text";
        Opcodes[Opcodes["Append"] = 1] = "Append";
        Opcodes[Opcodes["UnoptimizedAppend"] = 2] = "UnoptimizedAppend";
        Opcodes[Opcodes["OptimizedAppend"] = 3] = "OptimizedAppend";
        Opcodes[Opcodes["Comment"] = 4] = "Comment";
        Opcodes[Opcodes["Modifier"] = 5] = "Modifier";
        Opcodes[Opcodes["Block"] = 6] = "Block";
        Opcodes[Opcodes["ScannedBlock"] = 7] = "ScannedBlock";
        Opcodes[Opcodes["NestedBlock"] = 8] = "NestedBlock";
        Opcodes[Opcodes["Component"] = 9] = "Component";
        Opcodes[Opcodes["ScannedComponent"] = 10] = "ScannedComponent";
        Opcodes[Opcodes["OpenElement"] = 11] = "OpenElement";
        Opcodes[Opcodes["OpenPrimitiveElement"] = 12] = "OpenPrimitiveElement";
        Opcodes[Opcodes["FlushElement"] = 13] = "FlushElement";
        Opcodes[Opcodes["CloseElement"] = 14] = "CloseElement";
        Opcodes[Opcodes["StaticAttr"] = 15] = "StaticAttr";
        Opcodes[Opcodes["DynamicAttr"] = 16] = "DynamicAttr";
        Opcodes[Opcodes["AnyDynamicAttr"] = 17] = "AnyDynamicAttr";
        Opcodes[Opcodes["Yield"] = 18] = "Yield";
        Opcodes[Opcodes["Partial"] = 19] = "Partial";
        Opcodes[Opcodes["StaticPartial"] = 20] = "StaticPartial";
        Opcodes[Opcodes["DynamicPartial"] = 21] = "DynamicPartial";
        Opcodes[Opcodes["DynamicArg"] = 22] = "DynamicArg";
        Opcodes[Opcodes["StaticArg"] = 23] = "StaticArg";
        Opcodes[Opcodes["TrustingAttr"] = 24] = "TrustingAttr";
        Opcodes[Opcodes["Debugger"] = 25] = "Debugger";
        // Expressions
        Opcodes[Opcodes["Unknown"] = 26] = "Unknown";
        Opcodes[Opcodes["Arg"] = 27] = "Arg";
        Opcodes[Opcodes["Get"] = 28] = "Get";
        Opcodes[Opcodes["HasBlock"] = 29] = "HasBlock";
        Opcodes[Opcodes["HasBlockParams"] = 30] = "HasBlockParams";
        Opcodes[Opcodes["Undefined"] = 31] = "Undefined";
        Opcodes[Opcodes["Function"] = 32] = "Function";
        Opcodes[Opcodes["Helper"] = 33] = "Helper";
        Opcodes[Opcodes["Concat"] = 34] = "Concat";
    })(Opcodes || (exports.Ops = Opcodes = {}));

    function is(variant) {
        return function (value) {
            return value[0] === variant;
        };
    }
    var Expressions;
    (function (Expressions) {
        Expressions.isUnknown = is(Opcodes.Unknown);
        Expressions.isArg = is(Opcodes.Arg);
        Expressions.isGet = is(Opcodes.Get);
        Expressions.isConcat = is(Opcodes.Concat);
        Expressions.isHelper = is(Opcodes.Helper);
        Expressions.isHasBlock = is(Opcodes.HasBlock);
        Expressions.isHasBlockParams = is(Opcodes.HasBlockParams);
        Expressions.isUndefined = is(Opcodes.Undefined);

        Expressions.isPrimitiveValue = function (value) {
            if (value === null) {
                return true;
            }
            return typeof value !== 'object';
        };
    })(Expressions || (exports.Expressions = Expressions = {}));
    var Statements;
    (function (Statements) {
        Statements.isText = is(Opcodes.Text);
        Statements.isAppend = is(Opcodes.Append);
        Statements.isComment = is(Opcodes.Comment);
        Statements.isModifier = is(Opcodes.Modifier);
        Statements.isBlock = is(Opcodes.Block);
        Statements.isComponent = is(Opcodes.Component);
        Statements.isOpenElement = is(Opcodes.OpenElement);
        Statements.isFlushElement = is(Opcodes.FlushElement);
        Statements.isCloseElement = is(Opcodes.CloseElement);
        Statements.isStaticAttr = is(Opcodes.StaticAttr);
        Statements.isDynamicAttr = is(Opcodes.DynamicAttr);
        Statements.isYield = is(Opcodes.Yield);
        Statements.isPartial = is(Opcodes.Partial);
        Statements.isDynamicArg = is(Opcodes.DynamicArg);
        Statements.isStaticArg = is(Opcodes.StaticArg);
        Statements.isTrustingAttr = is(Opcodes.TrustingAttr);
        Statements.isDebugger = is(Opcodes.Debugger);
        function isAttribute(val) {
            return val[0] === Opcodes.StaticAttr || val[0] === Opcodes.DynamicAttr;
        }
        Statements.isAttribute = isAttribute;
        function isArgument(val) {
            return val[0] === Opcodes.StaticArg || val[0] === Opcodes.DynamicArg;
        }
        Statements.isArgument = isArgument;

        Statements.isParameter = function (val) {
            return isAttribute(val) || isArgument(val);
        };

        Statements.getParameterName = function (s) {
            return s[1];
        };
    })(Statements || (exports.Statements = Statements = {}));

    exports.is = is;
    exports.Expressions = Expressions;
    exports.Statements = Statements;
    exports.Ops = Opcodes;
});