define('ember-keyboard/mixins/keyboard-first-responder-on-focus', ['exports'], function (exports) {
  'use strict';

  exports['default'] = Ember.Mixin.create({
    makeFirstResponderOnFocusIn: Ember.on('click', 'focusIn', function () {
      Ember.setProperties(this, {
        keyboardActivated: true,
        keyboardFirstResponder: true
      });
    }),

    resignFirstResponderOnFocusOut: Ember.on('focusOut', function () {
      Ember.set(this, 'keyboardFirstResponder', false);
    })
  });
});