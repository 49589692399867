enifed('ember-glimmer/helpers/-html-safe', ['exports', 'ember-glimmer/utils/references', 'ember-glimmer/utils/string'], function (exports, _references, _string) {
  'use strict';

  exports.default = function (vm, args) {
    return new _references.InternalHelperReference(htmlSafe, args);
  };

  function htmlSafe(_ref) {
    var positional = _ref.positional;

    var path = positional.at(0);
    return new _string.SafeString(path.value());
  }
});