define('ember-rapid-forms/components/html-select', ['exports', 'ember', 'ember-rapid-forms/templates/components/html-select'], function (exports, _ember, _emberRapidFormsTemplatesComponentsHtmlSelect) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberRapidFormsTemplatesComponentsHtmlSelect['default'],

    didReceiveAttrs: function didReceiveAttrs() /*attrs*/{
      this._super.apply(this, arguments);
      var content = this.get('content');

      if (!content) {
        this.set('content', []);
        // TODO ember warn no content set
      }
      // set it to the correct value of the selection
      this.selectedValue = _ember['default'].computed('mainComponent.model.' + this.get('mainComponent.property'), function () {
        var propertyIsModel = this.get('mainComponent.propertyIsModel');
        var value = this.get('mainComponent.model.' + this.get('mainComponent.property'));
        if (propertyIsModel && value != null) {
          var optionValuePath = this.get('mainComponent.optionValuePath');
          if (value.get === undefined) {
            value = value[optionValuePath];
          } else {
            value = value.get(optionValuePath);
          }
        }
        return value;
      });
    },

    actions: {
      change: function change() {

        var selectedEl = this.$('select')[0];
        var selectedIndex = selectedEl.selectedIndex;

        // check whether we show prompt the correct to show index is one less
        // when selecting prompt don't change anything
        if (this.get('mainComponent.prompt')) {
          if (selectedIndex !== 0) {
            selectedIndex--;
          } else {
            this.set('mainComponent.model.' + this.get('mainComponent.property'), null);
            return;
          }
        }

        var content = this.get('mainComponent.content');
        var selectedValue = content.objectAt(selectedIndex);
        var optionValuePath = this.get('mainComponent.optionValuePath');
        var propertyIsModel = this.get('mainComponent.propertyIsModel');
        var selectedID;

        if (propertyIsModel) {
          selectedID = selectedValue;
        } else {
          selectedID = selectedValue[optionValuePath];
        }

        this.set('mainComponent.model.' + this.get('mainComponent.property'), selectedID);
        var changeAction = this.get('action');
        if (changeAction) {
          changeAction(selectedID);
        }
      }
    }
  });
});