define('ember-toggle/components/x-toggle-label/component', ['exports', 'ember-toggle/components/x-toggle-label/template'], function (exports, _emberToggleComponentsXToggleLabelTemplate) {
  'use strict';

  exports['default'] = Ember.Component.extend({
    layout: _emberToggleComponentsXToggleLabelTemplate['default'],
    tagName: 'label',
    attributeBindings: ['for'],
    classNames: ['toggle-text', 'toggle-prefix'],
    classNameBindings: ['labelType'],
    'for': Ember.computed.readOnly('switchId'),
    isVisible: Ember.computed.readOnly('show'),
    labelType: Ember.computed('type', function () {
      return this.get('type') + '-label';
    }),
    type: Ember.computed('value', {
      get: function get() {
        return this.get('value') ? 'on' : 'off';
      }
    }),
    click: function click(e) {
      e.stopPropagation();
      e.preventDefault();
      this.sendToggle(this.get('value'));
    }
  });
});